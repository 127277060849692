@import '../../../../mixins.scss';

.scan-company-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;
  > header {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    margin-bottom: 24px;
    min-height: 54px;
    > div {
      display: flex;
      &:last-child {
        align-items: center;
        gap: 14px;
      }
    }
  }
  .scan-company-users {
    overflow: auto;
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    flex: 1;
  }
  .scan-company-fields {
    &:has(*) {
      margin-top: 8px;
    }
    .scan-company-field {
      display: flex;
      flex-direction: column;
      gap: 16px;
      &:not(:last-child) {
        margin-bottom: 16px;
      }
      .scan-company-reports {
      }
    }
  }
  .scan-company-banner {
    @include flex-hv-center;
    gap: 24px;
    padding: 24px;
    border-radius: 14px;
    background-color: rgba(var(--trend-up-color-rgb), 0.17);
    position: relative;
    .scan-company-banner-actions {
      @include flex-hv-center;
      justify-content: flex-end;
      gap: 12px;
      flex-wrap: wrap;
      margin-inline-start: auto;
    }
    h5 {
      font-size: 20px;
      font-weight: 500;
      line-height: 32px;
      letter-spacing: 0.15px;
    }
    p {
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0.1px;
    }
    &.prev-month {
      background-color: #fff9c4;
      p,
      h5 {
        color: rgb(var(--text-inverted-primary-color-rgb));
      }
      button.button-rounded {
        background-color: #ffbf00;
        border-color: #ffbf00;
      }
      button.button-rounded.outlined {
        background-color: transparent;
        border-color: rgba(var(--text-inverted-primary-color-rgb), 0.87);
        color: rgba(var(--text-inverted-primary-color-rgb), 0.87);
      }
    }
    &.admin-msg {
      margin-bottom: 16px;
      padding: 6px 16px;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: row;
      border-radius: 4px;
      gap: 12px;
      background: var(
        --dark-warning-shades-190-p,
        linear-gradient(0deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0.9) 100%),
        #ffa726
      );
      color: #ffa726;
      div {
        p:first-child {
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0.15px;
          margin-bottom: 8px;
        }
        p:last-child {
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.17px;
        }
      }
    }
    .radial-chart-scan-progress-container {
      width: 95px;
      aspect-ratio: 1 / 1;
      > div {
        position: absolute;
        top: 50%;
        transform: translateY(-49%);
        inset-inline-start: 24px;
      }
    }
  }
  .user-download-badge {
    display: flex;
    padding: 4px 12px;
    gap: 8px;
    align-items: center;
    border-radius: 4px;
    background-color: var(--text-third-color);
    color: var(--text-primary-color);
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    &.skipped {
      color: var(--text-secondary-color);
    }
    &.downloading {
      background-color: rgba(var(--trend-up-color-rgb), 0.17);
      color: var(--trend-up-color);
    }
    &.waiting {
      background: rgba(87, 185, 255, 0.17);
      color: rgb(87, 185, 255);
    }
  }
}
