.policies-filter-dropdown-item {
  color: var(--text-primary-color);
  font-family: Rubik;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 20px;
  text-align: start;
  padding: 16px;
  padding-top: 12px;
  display: flex;
  justify-content: space-between;
}

.policies-filter-dropdown-item:hover {
  background-color: var(--background-secondary-color);
}

.policies-filter-dropdown-item>.policies-filter-dropdown-item-value {
  flex: 1;
}