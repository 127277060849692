.associated-agency-item-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  align-items: center;
  .approval-status {
    > div:first-child {
      display: flex;
      padding-inline: 8px;
      gap: 6px;
      align-items: center;
    }
    .button-text {
      padding-inline: 5px;
      height: 22px;
      color: var(--trend-up-color);
      white-space: nowrap;
    }
  }
}

[dir='rtl'] .associated-agency-item-grid {
  .agent-phone-number {
    direction: ltr;
    text-align: end;
  }
}

.no-agencies-found {
  margin-block: auto;
}
