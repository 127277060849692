.agency-house-analytics-incomes {
  display: flex;
  gap: 40px;
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr 450px;
  position: relative;
  flex: 1;
  > main {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 40px;
    overflow: hidden;
    .agency-house-analytics-incomes-content {
      overflow: hidden;
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 40px;
      > *:nth-child(2) {
        flex: 1;
      }
    }
  }
}
