.customer-policies-group {
  margin-bottom: 24px;
  > header {
    display: flex;
    align-items: center;
    gap: 16px;
    > button {
      img {
        height: 18px;
      }
    }
    > p {
      font-size: 20px;
      font-weight: 500;
      line-height: 32px;
      letter-spacing: 0.15px;
      > span {
        color: var(--text-secondary-color);
      }
    }
  }
}
