.customer-groups-wrapper {
  margin-bottom: 20px;
  display: flex;
  gap: 20px;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.25px;
  color: var(--text-secondary-color);
  .customer-groups-container {
    flex: 1;
    > .customer-group-types-container {
      &:not(:last-child) {
        margin-bottom: 6px;
      }
      > .customer-group-type-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      > .customer-groups-list > div {
        > .customer-group-container {
          display: flex;
          align-self: center;
          justify-content: space-between;
          > .customer-group-name {
            flex: 1;
            color: var(--text-primary-color);
            font-size: 16px;
            span {
              font-size: 14px;
              color: var(--text-secondary-color);
            }
          }
        }
        > .customer-group-items-container {
          > .customer-group-item {
            > .customer-group-item-name {
              color: var(--text-primary-color);
              font-size: 12px;
              line-height: 11px;
            }
            > a {
              display: flex;
              align-items: center;
              justify-content: space-between;
              text-decoration: none;
              > div:first-child {
                flex: 1;
                color: var(--text-primary-color);
                font-size: 12px;
                line-height: 1px;
              }
              > div:last-child {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 18px;
                width: 18px;
                padding: 2px;
                > img {
                  height: 100%;
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
  .customer-groups-title {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
  }
  button {
    background: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 24px;
    width: 24px;
    &:active {
      transform: scale(0.98);
    }
    > img {
      height: 20px;
      width: 20px;
    }
  }
}

.customer-group-type-container {
  background-color: rgba(var(--border-primary-color-rgb), 0.38);
  border: var(--border-primary);
  border-radius: 8px;
  padding: 8px;
  > p {
    color: var(--text-secondary-color);
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    margin-bottom: 8px;
  }
  > .customer-groups-list {
    > .customer-groups-list-item {
      // display: flex;
      &:not(:last-child) {
        margin-bottom: 8px;
      }
      > .customer-groups-list-summary-item {
        display: flex;
        align-items: center;
        > .customer-group-name {
          flex: 1;
          font-size: 16px;
          line-height: 22px;
          letter-spacing: 0.25px;
          > span {
            color: var(--text-secondary-color);
          }
        }
        > .customer-group-actions {
          display: flex;
          align-items: center;
          gap: 8px;
        }
      }
    }
  }
  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

[dir='ltr'] .customer-group-type-container button {
  transform: scaleX(-1);
}
