.agency-house-analytics-policies {
  display: flex;
  gap: 40px;
  overflow: hidden;
  display: grid;
  grid-template-columns: 3fr minmax(340px, 1.1fr);
  flex: 1;
  > main {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 40px;
    overflow: hidden;
    .agency-house-analytics-policies-content {
      overflow: hidden;
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 40px;
      > *:nth-child(2) {
        flex: 1;
      }
    }
  }
}
