@media screen and (max-width: 767px) {
  .mobile-modal-base-screen-container {
    position: fixed;
    width: 100%;
    height: 100svh;
    top: 0;
    display: flex;
    flex-direction: column;
  }
}
