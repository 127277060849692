.register-flow-types {
  grid-column: span 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-block: 100px 40px;
  h2,
  p {
    margin: 0;
    font-family: Rubik;
    text-align: center;
  }
  > h2 {
    font-size: 34px;
    font-weight: 500;
    line-height: 123.5%;
    letter-spacing: 0.25px;
    margin-bottom: 12px;
  }
  > p {
    font-size: 16px;
    line-height: 175%;
    letter-spacing: 0.15px;
    color: var(--text-secondary-color);
    margin-bottom: 154px;
    width: 300px;
  }
  > .register-flow-types-cards {
    display: flex;
    justify-content: center;
    gap: 40px;
  }
  > .register-terms-footer {
    margin-top: auto;
  }
}

@media screen and (max-height: 767px) {
  .register-flow-types {
    padding-block: 20px 12px;
    > h2 {
      margin-bottom: 8px;
    }
    > p {
      margin-bottom: 90px;
    }
  }
}
