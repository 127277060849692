.count-badge {
  background-color: var(--trend-down-color);
  color: rgba(255, 255, 255, 1);
  height: 20px;
  min-width: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-inline: 6.5px;
  font-family: Rubik;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px; /* 166.667% */
  letter-spacing: 0.14px;
}
