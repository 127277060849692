.profile-setting-switch-card {
  padding: 24px;
  max-width: 700px;
  border-radius: 8px;
  background-color: rgba(var(--text-primary-color-rgb), 0.17);
  display: flex;
  align-items: center;
  justify-content: space-between;
  > div:first-child {
    > div {
      display: flex;
      align-items: center;
      gap: 10px;
      &:not(:only-child) {
        margin-bottom: 8px;
      }
      > p {
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.4px;
      }
    }
    > p {
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0.4px;
      color: var(--text-secondary-color);
    }
  }
}
