.react-date-picker {
  width: 100%;
  .react-date-picker__wrapper {
    background-color: var(--border-primary-color);
    border: var(--border-primary);
    height: 36px;
    display: flex;
    border-radius: 18px;
    align-items: center;
    padding-inline: 14px;
    .react-date-picker__inputGroup {
      flex: 1;
      display: flex;
      flex-wrap: nowrap;
      direction: ltr;
      justify-content: flex-end;
      input {
        background-color: transparent;
        border: unset;
        outline: unset;
        padding: 0;
        color: var(--text-primary-color);
      }
      input,
      span {
        font-family: Rubik;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.25px;
      }
    }
  }
  &--open {
    .react-date-picker__wrapper {
      border-color: var(--text-primary-color);
    }
  }
  &.no-value {
    .react-date-picker__inputGroup__year {
      flex: 1;
      text-align: right;
    }
    .react-date-picker__inputGroup {
      *:not(input.react-date-picker__inputGroup__year) {
        display: none;
      }
    }
  }
  .react-date-picker__calendar {
    z-index: 1;
    width: 286px;
    .react-calendar {
      box-shadow: 0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
        0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      background-color: var(--border-primary-color);
      border-radius: 8px;
      padding: 8px;
      * {
        font-family: Rubik;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.25px;
      }
      &__navigation {
        display: flex;
        height: 40px;
        padding: 12px 10px;
        border-bottom: 1px solid var(--text-secondary-color);
        margin-bottom: 3px;
        &__arrow {
          height: 16px;
          width: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: var(--text-secondary-color);
        }
      }
      &__tile {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        &--active,
        &:hover {
          background-color: rgba(var(--text-primary-color-rgb), 0.17);
        }
      }
      &__month-view__days__day {
        height: 36px;
        width: 36px;
        &--neighboringMonth {
          color: var(--text-secondary-color);
        }
      }
      &__month-view__weekdays {
        margin-bottom: 3px;
        &__weekday {
          height: 36px;
          width: 36px;
          display: flex;
          align-items: center;
          justify-content: center;
          > abbr {
            text-decoration: none;
          }
        }
      }
      &__century-view,
      &__year-view,
      &__decade-view {
        .react-calendar__tile {
          height: 60px;
        }
      }
    }
    &--closed {
      display: none;
    }
  }
  select {
    appearance: none;
    background: none;
    border: none;
    outline: none;
    pointer-events: none;
    font-family: Rubik;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.25px;
    color: var(--text-primary-color);
    option {
      display: none;
    }
  }
}

[dir='ltr'] .react-date-picker {
  .react-date-picker__wrapper {
    .react-date-picker__inputGroup {
      justify-content: flex-start;
    }
  }
  &.no-value {
    .react-date-picker__inputGroup__year {
      text-align: left;
    }
  }
}
