.policies-all-list-wrapper {
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  .policies-all-list-header {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.4px;
    align-items: end;
    position: sticky;
    top: 0;
    background-color: var(--background-primary-color);
    z-index: 1;
  }
  .policies-all-list-grid {
    padding-inline-end: 16px;
    display: grid;
    gap: 8px;
    &[class*='life'] {
      grid-template-columns: 60px repeat(10, 1fr) 1.5fr 1fr 32px;
      &.extended {
        grid-template-columns: 60px repeat(11, 1fr) 1.5fr 1.5fr 1fr 32px;
      }
      &.has-policy-number {
        grid-template-columns: 60px minmax(4ch, 14ch) repeat(9, 1fr) 1.5fr 1fr 32px;
        &.extended {
          grid-template-columns: 60px minmax(4ch, 14ch) repeat(10, 1fr) 1.5fr 1.5fr 1fr 32px;
        }
      }
    }
    &[class*='elementary'] {
      grid-template-columns: 60px repeat(17, 1fr) 25px;
      &.extended {
        grid-template-columns: 60px repeat(20, 1fr) 25px;
      }
      &.has-policy-number {
        grid-template-columns: 60px minmax(4ch, 14ch) repeat(16, 1fr) 25px;
        &.extended {
          grid-template-columns: 60px minmax(4ch, 14ch) repeat(19, 1fr) 25px;
        }
      }
    }
    &[class*='special'] {
      grid-template-columns: repeat(7, 1fr) 32px;
      padding-inline-start: 16px;
      &.extended {
        grid-template-columns: repeat(8, 1fr) 32px;
      }
    }
    div:has(.commission-rate-cells) {
      text-align: center;
    }
    div:has(.columns-picker-container) {
      justify-self: end;
    }
    .commission-rate-cells {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 8px;
      .financial-value-cell {
        text-align: center;
      }
    }
  }
  .policies-all-list-total {
    background-color: rgba(var(--border-primary-color-rgb), 0.3);
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.25px;
    position: relative;
    > div:first-child {
      background: var(--background-secondary-color);
      height: 100%;
      display: flex;
      border-start-start-radius: 8px;
      border-end-start-radius: 8px;
      align-items: center;
      padding-inline: 16px;
      position: absolute;
    }
  }
  .policies-all-list-footer {
    display: flex;
    justify-content: center;
    margin-top: 8px;
  }
}
