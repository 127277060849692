.download-sidebar-company {
  > a {
    > div:first-child {
      // company logo
      > div:first-child {
        background-color: var(--background-primary-color);
        display: flex;
        align-items: center;
        justify-content: center;
        height: 32px;
        width: 32px;
        border-radius: 8px;
      }
      //company name +  fields
      > div:last-child {
        display: block;
        > p {
          margin: 0;
          font-family: Rubik;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0.17px;
        }
        > p:last-child {
          color: var(--text-secondary-color);
          font-size: 12px;
          letter-spacing: 0.4px;
        }
      }
    }
  }
}
