.no-payment-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Rubik';
  .no-payment-error {
    // color: red;
  }
  h1 {
    text-align: center;
    font-weight: 400;
    letter-spacing: 1px;
    font-size: 24px;
  }
  .trial-empty-placeholder {
    display: flex;
    flex-direction: column;
    align-items: center;
    h3 {
      font-weight: 400;
      font-size: 24px;
    }
  }
  .no-payment-title,
  .no-payment-subtitle {
    margin: 0;
    padding: 0;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    text-align: center;
    letter-spacing: 0.15px;
  }
  .no-payment-subtitle {
    margin-block: 6px 32px;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: var(--text-secondary-color);
  }
}

@media screen and (max-width: 767px) {
  .no-payment-wrapper {
    align-items: unset;
    h1 {
      margin: 0;
      padding: 0;
      font-size: 28px;
    }
  }
}
