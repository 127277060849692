.modal-wrapper > .modal-container:has(.big-agency-payment-frame-container) {
  background-color: #f7f7f7;
  width: 600px;
  .modal-content {
    align-items: center;
    header {
      color: rgba(0, 0, 0, 0.87);
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px;
      letter-spacing: 0.15px;
      position: relative;
      padding: 16px 24px;
      width: 100%;
      button {
        position: absolute;
        top: 50%;
        inset-inline-end: 24px;
        transform: translateY(-50%);
        svg {
          color: rgba(0, 0, 0, 0.87);
        }
      }
    }
  }
}

@media screen and (max-height: 890px) {
  .modal-wrapper > .modal-container:has(.payment-frame-container) {
  }
}

@media screen and (max-width: 767px) {
  .modal-wrapper > .modal-container:has(.payment-frame-container) {
  }
}
