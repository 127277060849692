.agency-house-analytics-cards {
  display: flex;
  gap: 24px;
  align-items: flex-end;
  .agency-house-analytics-card-wrapper {
    flex: 1;
    background-color: var(--border-primary-color);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    .agency-house-analytics-card {
      padding: 24px 16px;
      .agency-house-analytics-card-chips {
        display: flex;
        gap: 8px;
        margin-bottom: 12px;
        .agency-house-analytics-card-chip {
          height: 24px;
          display: flex;
          align-items: center;
          font-size: 13px;
          letter-spacing: 0.16px;
          color: rgba(var(--text-inverted-primary-color-rgb), 1);
          background-color: rgba(211, 212, 217, 0.6);
          border-radius: 12px;
          padding-inline: 10px;
        }
      }
      .agency-house-analytics-card-content {
        display: flex;
        flex-direction: column;
        gap: 20px;
        min-height: 190px;
        justify-content: space-between;
        p {
          color: var(--text-secondary-color);
          font-size: 12px;
          line-height: 20px;
          letter-spacing: 0.4px;
        }
        .agency-house-analytics-card-main {
          .agency-house-analytics-card-name {
            color: var(--text-primary-color);
            font-size: 16px;
            font-weight: 500;
          }
          .agency-house-analytics-card-main-value {
            font-size: 34px;
            line-height: 42px;
            letter-spacing: 0.25px;
            direction: ltr;
          }
        }
        .agency-house-analytics-card-details {
          display: flex;
          gap: 24px;
          .agency-house-analytics-card-details-value {
            font-size: 24px;
            line-height: 32px;
            direction: ltr;
          }
        }
        & + .agency-house-analytics-card-content {
          border-top: 1px solid var(--text-third-color);
          padding-top: 16px;
        }
        &:has(+ .agency-house-analytics-card-content) {
          padding-bottom: 16px;
        }
      }
    }
    &.selected {
      cursor: default;
      border-bottom: 4px solid var(--trend-up-color);
      .agency-house-analytics-card-footer {
        background-color: rgba(var(--trend-up-color-rgb), 0.17);
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--trend-up-color);
        border-bottom-right-radius: 20px;
        border-bottom-left-radius: 20px;
        button {
          font-family: Rubik;
          font-size: 13px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px; /* 169.231% */
          letter-spacing: 0.46px;
          text-transform: uppercase;
          color: var(--trend-up-color);
          gap: 8px;
          svg {
            font-size: 18px;
          }
        }
      }
    }
  }
}
