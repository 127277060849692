.modal-wrapper {
  pointer-events: all;
  .modal-category-wrapper {
    padding: 24px;
    color: var(--text-primary-color);
    font-family: Rubik;
    > .modal-category-title {
      font-size: 16px;
      letter-spacing: 0.5px;
      line-height: 20px;
      margin-bottom: 30px;
    }
    > .modal-category-text {
      font-size: 14px;
      letter-spacing: 0.25px;
      margin-bottom: 30px;
    }
    > .modal-category-btns {
      display: flex;
      justify-content: flex-end;
      gap: 12px;
    }
  }
}
