.input-text-wrapper {
  position: relative;
}

.input-text-container {
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  position: relative;
  overflow: hidden;
}

.input-text-container.disabled {
  opacity: .5;
}

.input-text-container>.input-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  inset-inline-end: 16px;
  top: 50%;
  transform: translateY(-50%);
}

.input-text-container>.input-icon>img {
  width: 24px;
  height: 24px;
}

.input-text-container input {
  background-color: var(--border-primary-color);
  filter: brightness(90%);
  width: 100%;
  height: 100%;
  border: 1px solid transparent;
  outline: none;
  display: flex;
  padding-right: 16px;
  padding-left: 16px;
  padding-top: 12px;
  color: var(--text-primary-color);
  font-family: Rubik;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 20px;
  text-align: start;
  caret-color: var(--trend-up-color);
}

.input-text-container input:focus,
.input-text-container input:not(:placeholder-shown) {
  background-color: var(--border-primary-color);
  filter: brightness(110%);
}

[data-theme='light'] .input-text-container input:focus,
.input-text-container input:not(:placeholder-shown) {
  filter: brightness(100%);
}

/* Hide arrows for input type text */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.input-text-container label {
  color: var(--text-primary-color);
  font-family: Rubik;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 20px;
  text-align: start;
  margin-right: 16px;
  margin-left: 16px;
  position: absolute;
  inset-inline-start: 0;
  bottom: 50%;
  transition: all 0.3s ease-in;
  transform: translateY(50%);
  pointer-events: none;
}

.input-text-container input:focus+label,
.input-text-container input:not(:placeholder-shown)+label {
  color: var(--trend-up-color);
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 16px;
  text-align: start;
  bottom: 50%;
  transform: translateY(calc(-100% + 10px));
}

.input-text-container input:not(:focus):not(:placeholder-shown)+label {
  color: var(--text-primary-color);
}

.input-text-container input:not([value='']):not(:focus)+label {
  /* color: var(--text-secondary-color); */
}

.input-text-container .line {
  position: absolute;
  height: 2px;
  width: 100%;
  background: transparent;
  left: 0;
  bottom: 0;
  transition: transform 250ms ease-in-out;
}

.input-text-container .line:after {
  content: '';
  display: block;
  width: 100%;
  background: var(--trend-up-color);
  height: 2px;
  transition: all 0.5s;
  left: 0;
  bottom: 0;
  transform: scaleX(0);
}

.input-text-container input:focus~.line:after,
.input-text-container input:not(:placeholder-shown)~.line:after {
  transform: scaleX(1);
}

.input-text-container input:not(:focus):not(:placeholder-shown)~.line:after {
  transform: scaleX(1);
  background: var(--text-secondary-color);
}

.input-text-hints-container {
  position: absolute;
  width: 100%;
  margin-top: 3px;
  display: flex;
  justify-content: space-between;
}

.input-text-error-container {
  position: absolute;
  width: 100%;
  margin-top: 3px;
  color: red;
}

.input-text-hints-container>.input-text-hints-chars-count {
  color: var(--text-secondary-color);
  font-family: Rubik;
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 16px;
}