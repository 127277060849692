.input-select-container {
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  position: relative;
  overflow: hidden;
}

.input-select-container .select {
  color: var(--text-primary-color);
  font-family: Rubik;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 28px;
  text-align: end;
  cursor: pointer;
  display: grid;
  grid-template-areas: 'select';
  align-items: center;
  height: 100%;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  align-self: stretch;
  outline: none;
  position: relative;
  flex: 1;
  border: none;
}

.input-select-container .select:after {
  content: '';
  width: 10px;
  height: 5px;
  background-color: var(--text-primary-color);
  clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  justify-self: flex-end;
  margin-right: 1rem;
  margin-left: 1rem;
}

.input-select-container select {
  appearance: none;
  background-color: transparent;
  color: var(--text-primary-color);
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  text-align-last: right;
  height: inherit;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: inherit;
  background-color: var(--border-primary-color);
  filter: brightness(90%);
}

.input-select-container select,
.input-select-container .select:after {
  grid-area: select;
}

.input-select-container select option {
  direction: rtl;
}

.input-select-container select:valid,
.input-select-container select:focus {
  background-color: var(--border-primary-color);
  filter: brightness(110%);
}

.input-select-container .select>select+label {
  color: var(--text-primary-color);
  font-family: Rubik;
  position: absolute;
  transition: all 0.3s ease-in;
  pointer-events: none;
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 16px;
  top: 2px;
  inset-inline-start: 16px;
}

.input-select-container select:focus+label {
  color: var(--trend-up-color);
}

.input-select-container .line {
  position: absolute;
  height: 2px;
  width: 100%;
  background: transparent;
  left: 0;
  bottom: 0;
  transition: transform 250ms ease-in-out;
}

.input-select-container label+.line:after {
  content: '';
  display: block;
  width: 100%;
  background: var(--text-primary-color);
  height: 2px;
  transition: all 0.5s;
  left: 0;
  bottom: 0;
  transform: scaleX(0);
}

.input-select-container select:valid~.line:after {
  transform: scaleX(1);
  background: var(--text-primary-color);
}

.input-select-container select:focus~.line:after {
  transform: scaleX(1);
  background: var(--trend-up-color);
}

.input-select-container .hidden-select-button {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.mobile-select-container>.mobile-select-options-container {
  padding-bottom: 8px;
}

.mobile-select-container>.mobile-select-options-container li {
  padding: 14px 16px;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.17px;
}

.mobile-select-container>.mobile-select-options-container li:not(:last-child) {
  border-bottom: var(--border-primary);
}

[dir='ltr'] .input-select-container select {
  text-align-last: end;
}