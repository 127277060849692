.customer-totals-details {
  flex: 1;
  display: flex;
  flex-direction: column;
  > section:first-child {
    padding: 24px;
    padding-bottom: 40px;
    background-color: rgba(var(--background-secondary-color-rgb), 0.8);
    > div {
      display: flex;
      align-items: center;
      &:first-child {
        justify-content: end;
        margin-bottom: 40px;
      }
      & + div {
        justify-content: space-between;
        margin-bottom: 16px;
        //card icon + text container
        > div:first-child {
          display: flex;
          align-items: center;
          gap: 16px;
          > div:first-child {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 56px;
            width: 56px;
            border-radius: 8px;
            background-color: rgba(var(--text-primary-color-rgb), 0.17);
          }
          > div:nth-child(2) {
            > p {
              font-size: 20px;
              font-weight: 400;
              line-height: 20px;
              letter-spacing: 0.5px;
            }
          }
        }
      }
    }
    > p {
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.4px;
    }
  }
  // list items container
  > section:last-child {
    flex: 1;
    background-color: var(--background-primary-color);
    border-top: var(--border-primary);
    padding-block: 24px;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    ::-webkit-scrollbar {
      width: 10px;
    }
    ::-webkit-scrollbar-thumb {
      border: 3px solid transparent;
      background-color: var(--border-primary-color);
      border-top: none;
      border-bottom: none;
      border-radius: 5px;
      background-clip: padding-box;
      cursor: pointer;
      &:hover {
        background-color: var(--text-primary-color);
      }
    }
  }
}

.modal-sidebar-wrapper > .modal-sidebar:has(.customer-totals-details) {
  background-color: unset;
}
