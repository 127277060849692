.button {
  color: var(--text-primary-color);
  background-color: var(--trend-up-color);
  border: 1px solid var(--trend-up-color);
  border-radius: 4px;
  height: 36px;
  min-width: 86px;
  outline: none;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1.25px;
  line-height: 16px;
  text-align: center;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  padding-left: 26px;
  padding-right: 26px;
}

.button:hover {
  opacity: 0.8;
}

.button:active {
  transform: scale(0.96);
}

.button:disabled {
  opacity: 0.7;
  color: var(--trend-up-color);
  background-color: transparent;
  border: 1px solid var(--trend-up-color);
  cursor: none;
  pointer-events: none;
}

.button.show-loader {
  background-color: rgba(var(--trend-up-color-rgb), 0.38);
  border: none;
  cursor: none;
  pointer-events: none;
  padding: 0;
  color: var(--text-primary-color);
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1.25px;
  line-height: 16px;
  text-align: center;
}

.button.show-loader>div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.button.show-loader>div>.loader-container {
  display: block;
  flex: none;
}

.button.show-loader>div>.loader-container>.loader {
  width: 22px;
  height: 22px;
}