.policies-new-list-item {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.4px;
  position: relative;
  &.has-policy-number > div:nth-child(2) {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  &:not(:last-child) {
    margin-bottom: 8px;
  }
  .policies-new-list-item-icons {
    display: flex;
  }

  //  temporary disabled (task: board - 5570988580 pulse - 7596845841)
  // .policy-list-item-select {
  //   position: absolute;
  //   inset-inline-start: 0;
  //   top: 0;
  //   transform: translate(50%, 50%);
  // }
}

// temporary disabled (task: board - 5570988580 pulse - 7596845841)
// [dir='rtl'] .policies-new-list-item .policy-list-item-select {
//   transform: translate(-50%, 50%);
// }
