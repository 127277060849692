.appbar-container {
  min-height: 64px;
  min-width: 410px;
  background-color: var(--background-secondary-color);
  box-shadow: 0 24px 38px 3px rgb(0 0 0 / 14%), 0 9px 46px 8px rgb(0 0 0 / 12%), 0 11px 15px -7px rgb(0 0 0 / 20%);
  border-radius: 8px;
  border: 1px solid var(--text-primary-color);
  position: relative;
  padding-inline-end: 32px;
  pointer-events: all;
  > .appbar-close-icon {
    position: absolute;
    top: 1px;
    inset-inline-end: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    background-color: var(--border-primary-color);
    border-start-end-radius: 5px;
    border-end-start-radius: 8px;
    cursor: pointer;
    &:hover {
      filter: brightness(1.75);
    }
  }
  .app-bar-buttons-container {
    display: flex;
    justify-content: center;
    .app-bar-button-container {
      padding-inline: 11px;
      justify-content: flex-start;
      &:first-child {
        padding-inline-start: unset;
      }
      &:last-child {
        padding-inline-end: unset;
      }
      &:not(:last-child) {
        border-inline-end: 1px solid var(--text-secondary-color);
      }
      button {
        width: 102px;
        display: flex;
        flex-direction: column;
        gap: 6px;
        height: 100%;
        justify-content: flex-start;
        &:hover {
          opacity: 0.7;
        }
        &:disabled {
          opacity: 0.3;
        }
        div:nth-child(2) {
          font-family: Rubik;
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
        }
      }
    }
  }
}

.categories-wrapper-overlay {
  position: fixed;
  height: 100vh;
  width: 100vw;
  inset: 0;
  z-index: 1;
  pointer-events: all;
  .categories-wrapper {
    position: fixed;
    left: 0;
    top: 0;
    pointer-events: none;
  }
}

@media screen and (max-width: 767px) {
  .appbar-container {
    width: 100%;
    min-width: unset;
  }
}
