.policies-lost-list-wrapper {
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  .policies-lost-list-header {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.4px;
    align-items: end;
    position: sticky;
    top: 0;
    background-color: var(--background-primary-color);
    z-index: 1;
  }
  .policies-lost-list-grid {
    padding-inline-end: 16px;
    display: grid;
    gap: 8px;
    grid-template-columns: 60px 1.6fr 2.1fr repeat(9, 1fr) 1.5fr 25px 36px;
    &.extended {
      grid-template-columns: 60px 1.6fr 2.1fr repeat(10, 1fr) 1.5fr 1.5fr 25px 36px;
    }
    div:has(.commission-rate-cells) {
      text-align: center;
    }
    div:has(.columns-picker-container) {
      justify-self: end;
    }
    .commission-rate-cells {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 8px;
      .financial-value-cell {
        text-align: center;
      }
    }
  }
  .policies-lost-list-total {
    background-color: rgba(var(--border-primary-color-rgb), 0.3);
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.25px;
    position: relative;
    > div:first-child {
      background: var(--background-secondary-color);
      height: 100%;
      display: flex;
      border-start-start-radius: 8px;
      border-end-start-radius: 8px;
      align-items: center;
      padding-inline: 16px;
      position: absolute;
    }
  }
  .policies-lost-list-footer {
    display: flex;
    justify-content: center;
    margin-top: 8px;
  }
}
