.customer-existing-policies-container {
  > div:first-child {
    padding-block: 16px;
    margin-bottom: 16px;
    border-bottom: var(--border-primary);
    display: flex;
    align-items: center;
    gap: 16px;
    > .customer-existing-policies-clear-filters {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 36px;
      padding-right: 16px;
      padding-left: 16px;
      border-radius: 52px;
      background-color: var(--border-primary-color);
      color: var(--text-primary-color);
      font-family: Rubik;
      font-size: 14px;
      letter-spacing: 0.25px;
      line-height: 20px;
      text-align: right;
      cursor: pointer;
      border: 1px solid transparent;
      &:not(:disabled) {
        border: 1px solid var(--text-secondary-color);
      }
      &:active:not(:disabled) {
        transform: scale(0.95);
      }
      &:disabled {
        cursor: default;
        background-color: var(--background-secondary-color);
        color: var(--text-secondary-color);
      }
    }
  }
  > div + div {
    display: grid;
    gap: 16px;
    overflow: hidden;
    > header {
      > button {
        gap: 16px;
        span {
          font-family: Rubik;
          font-size: 20px;
          font-weight: 500;
          line-height: 32px;
          letter-spacing: 0.15px;
        }
      }
    }
    .customer-policies-groups {
      overflow: auto;
    }
  }
}
