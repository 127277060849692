.date-range-picker-container {
  background: var(--background-primary-color);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  display: flex;
  gap: 24px;
}

@media screen and (max-width: 767px) {
  .date-range-picker-container {
    flex-direction: column;
    gap: 0;
  }
}
