.landing-hero {
  p,
  h3 {
    margin: 0;
    padding: 0;
    font-weight: normal;
    font-weight: 400;
  }

  .landing-hero-content {
    width: 400px;
    padding-top: 152px;
    padding-bottom: 140px;

    > .landing-hero-pretitle {
      font-size: 34px;
      line-height: 40px;
      letter-spacing: -0.283333px;
      white-space: pre-line;
      margin-bottom: 9px;
    }

    > .landing-hero-title {
      font-weight: 900;
      font-size: 140px;
      line-height: 108px;
      letter-spacing: -1.16667px;
      margin-bottom: 50px;
    }

    > .landing-hero-subtitle {
      font-size: 26px;
      line-height: 31px;
      letter-spacing: 0.191176px;
      white-space: pre-line;
      margin-bottom: 24px;
    }
  }
  .landing-section-container {
    background-size: 0;
  }
}

@media screen and (max-width: 1460px) {
  .landing-hero {
    // padding: 0px 19px;
    // background-size: 116% !important;
    // background-position: center center !important;
  }
  .landing-hero {
    .landing-hero-img {
      width: 638px;
    }

    .landing-hero-img > img {
      width: 100%;
    }

    .landing-hero-info-container {
      max-width: 319px;
    }

    .landing-hero-content {
      .landing-hero-pretitle {
        font-size: 28px;
        line-height: 30px;
        margin-bottom: 13px;
      }

      .landing-hero-title {
        font-size: 91px;
        line-height: 81px;
      }

      .landing-hero-subtitle {
        font-size: 21px;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .landing-hero {
    // padding: 0px 19px 0 1px;
    // background-size: 183%;

    .landing-hero-content {
      padding: 61px 1px;
    }

    .landing-hero-info-container {
      max-width: 283px;

      .landing-hero-info-text .landing-hero-info-text-regular {
        font-size: 25px;
        line-height: 23px;
        margin-bottom: 3px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .landing-hero {
    // padding: 1px 1px;
    // box-shadow: inset -1px 16px 19px -7px rgb(0 0 0 / 23%);
    // background-image: none !important;
    .landing-hero-content {
      width: 100%;
      max-width: 328px;
      margin: 0 auto;
      padding: 21px 3px;
      .landing-hero-pretitle {
        font-size: 20px;
        line-height: 26px;
        text-align: center;
      }
      .landing-hero-title {
        font-size: 72px;
        line-height: 60px;
        text-align: center;
      }
      .landing-hero-subtitle {
        font-size: 16px;
        line-height: 19px;
        text-align: center;
      }
    }
    .landing-section-container {
      background-position: bottom left;
      height: 100%;
      padding-bottom: 673px !important;
      background-size: 380px;
    }
  }
  .landing-container .landing-btns-container {
    justify-content: center;
  }
}
