.customer-info-container {
  padding: 30px 40px;
  overflow-y: auto;
  > .customer-info-container-header {
    display: flex;
    margin-bottom: 32px;
    justify-content: space-between;
    align-items: center;
    gap: 14px;
    a {
      text-decoration: none;
      color: var(--text-prmary-color);
    }
    .customer-info-container-back {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 16px;
      cursor: pointer;
      // margin-inline-start: auto;
      > .customer-info-container-back-text {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 16px;
        color: var(--text-prmary-color);
        font-family: Rubik;
        font-size: 14px;
        letter-spacing: 0.25px;
        line-height: 20px;
        text-align: right;
        text-decoration: none;
      }
      .customer-info-container-back-icon {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  > .customer-info-cards-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
