@import '../../../../mixins.scss';

.scan-company-header {
  > .scan-company-header-content {
    gap: 10px;
    .scan-company-header-logo {
      @include flex-hv-center;
      > img {
        height: 40px;
        width: 40px;
      }
    }
    .scan-company-header-summary {
      h5 {
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: 0.15px;
      }
    }
    p {
      color: var(--text-secondary-color);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0.1px;
    }
  }
  .scan-company-header-button {
    @include flex-hv-center;
    height: 36px;
    width: 36px;
    // background-color: rgba(var(--trend-up-color-rgb), 0.17);
    border-radius: 18px;
    &:hover {
      background-color: rgba(var(--trend-up-color-rgb), 0.17);
      color: var(--trend-up-color);
      // background-color: red;
    }
  }
  .scan-company-header-button-add-user {
    @include flex-hv-center;
    height: 36px;
    border-radius: 18px;
    gap: 8px;
    padding-inline: 22px;
    border: 1px solid var(--text-primary-color);
    font-family: Rubik;
    font-size: 15px;
    font-weight: 500;
    &:hover {
      background-color: rgba(var(--trend-up-color-rgb), 0.17);
      color: var(--trend-up-color);
      border-color: var(--trend-up-color);
    }
  }
}
