.agencies-list-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  padding-top: 40px;
  p {
    margin: 0;
    font-family: Rubik;
  }
  &.no-agencies {
    align-items: center;
    justify-content: center;
    p {
      color: var(--text-secondary-color);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 175%;
      letter-spacing: 0.15px;
      text-align: center;
      margin-top: 8px;
      margin-bottom: 14px;
    }
  }
  .agencies-list-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 14px;
    .agencies-list-header-filters {
      display: flex;
      gap: 14px;
    }
  }
  .agencies-list-content {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex: 1;
    .agencies-list-heading {
      height: 40px;
      color: var(--text-primary-color);
      font-size: 14px;
      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.17px;
      margin-bottom: 14px;
      > :first-child {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .agencies-list-item-grid,
    .agencies-list-heading {
      display: grid;
      grid-template-columns: 62px 1fr 62px;
      align-items: center;
    }
    .agencies-list {
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow: auto;
    }
  }
  .add-agency-btn {
    cursor: pointer;
    padding-inline: 12px 22px;
    background: var(--trend-up-color);
    border: none;
    color: rgb(255, 255, 255);
    font-size: 15px;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0.46px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    gap: 8px;
    height: 42px;
    border-radius: 21px;
    &:active {
      transform: scale(0.98);
    }
    svg > * > * {
      fill: rgb(255, 255, 255);
    }
  }
}
