:root {
  --add-new-agency-form-container-base-padding: 60px;
  --add-new-agency-form-base-padding: 40px;
  --add-new-agency-form-base-width: 880px;
  --add-new-agency-form-base-inputs-width: 500px;
}

@media screen and (max-height: 767px) {
  :root {
    --add-new-agency-form-container-base-padding: 10px;
    --add-new-agency-form-base-padding: 10px;
    --add-new-agency-form-base-width: 600px;
    --add-new-agency-form-base-inputs-width: 400px;
  }
}

.add-new-agency-form-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-top: var(--add-new-agency-form-container-base-padding);
  background-color: rgb(var(--background-primary-color-rgb));
  h4,
  h5,
  p {
    margin: 0;
    color: var(--text-primary-color);
    font-family: Rubik;
    font-style: normal;
  }
  .add-new-agency-form-body {
    flex: 1;
    width: var(--add-new-agency-form-base-width);
    margin: auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .add-new-agency-form-grid-row {
      display: grid;
      grid-template-columns: 1fr var(--add-new-agency-form-base-inputs-width);
      gap: 10px;
      &:not(:last-child) {
        padding-bottom: var(--add-new-agency-form-base-padding);
      }
      &:not(:first-child):not(:last-child) {
        border-bottom: var(--border-primary);
      }
      &:has(.companies-inputs, .fields-inputs) {
        padding-top: var(--add-new-agency-form-base-padding);
      }
      &:has(.fields-inputs) {
        .add-new-agency-form-grid-row-item {
          :first-child {
            display: flex;
            align-items: center;
            gap: 16px;
          }
          :last-child {
            margin-inline-start: auto;
          }
        }
      }
      &:has(.companies-inputs) {
        overflow: hidden;
        .companies-inputs {
          overflow: auto;
          .add-new-agency-form-grid-row-item {
            display: flex;
            justify-content: space-between;
            .company-info {
              display: flex;
              align-items: center;
              gap: 8px;
              div:has(img) {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 24px;
                width: 24px;
              }
            }
            .company-fields {
              display: flex;
              justify-content: flex-end;
              gap: 12px;
            }
          }
        }
      }
      .add-new-agency-form-grid-row-desc {
        h4 {
          font-size: 24px;
          font-weight: 400;
          line-height: 133.4%;
        }
        h4 + p {
          color: var(--text-secondary-color);
          font-size: 16px;
          font-weight: 500;
          line-height: 175%;
          letter-spacing: 0.15px;
        }
        h5 {
          font-size: 16px;
          font-weight: 500;
          line-height: 175%;
          letter-spacing: 0.15px;
        }
        h5 + p {
          font-size: 14px;
          color: var(--text-secondary-color);
          font-weight: 400;
          line-height: 143%;
          letter-spacing: 0.17px;
        }
      }
      .add-new-agency-form-grid-row-content {
        display: flex;
        flex-direction: column;
        gap: 8px;
        .add-new-agency-form-grid-row-item {
          height: 42px;
          flex-shrink: 0;
          display: flex;
          align-items: center;
          background-color: rgba(255, 255, 255, 0.09);
          padding-inline: 12px;
          border-radius: 8px;
          &.selected {
            background-color: rgba(var(--trend-up-color-rgb), 0.12);
          }
          p {
            color: var(--text-primary-color);
            font-size: 14px;
            font-weight: 400;
            line-height: 143%;
            letter-spacing: 0.17px;
          }
        }
      }
    }
  }
  .add-new-agency-form-footer {
    background-color: var(--background-primary-color);
    box-shadow: 0px -4px 20px 0px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 34px;
    height: 72px;
  }
}

[data-theme='light'] .add-new-agency-form-container {
  .add-new-agency-form-body {
    .add-new-agency-form-grid-row {
      .add-new-agency-form-grid-row-content {
        .add-new-agency-form-grid-row-item {
          background-color: rgba(243, 247, 250, 1);
        }
      }
    }
  }
  .add-new-agency-form-footer {
    box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14),
      0px 11px 15px -7px rgba(0, 0, 0, 0.2);
  }
}
