.context-menu {
  min-width: 128px;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: var(--background-primary-color);
  border-radius: 4px;
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
  > .context-menu-item {
    cursor: default;
    padding: 14px 16px;
    white-space: nowrap;
    color: var(--text-primary-color);
    font-family: Rubik;
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 20px;
    &:not(.disabled) {
      cursor: pointer;
    }
    & > a {
      text-decoration: none;
      color: var(--text-primary-color);
      font-family: Rubik;
      font-size: 16px;
      letter-spacing: 0.5px;
      line-height: 20px;
      padding: unset;
      height: unset;
    }
    &.danger {
      color: var(--trend-down-color);
    }
    &.disabled {
      opacity: 0.6;
    }
    &.blured {
      filter: blur(3px);
    }
    &:hover:not(.disabled),
    &:hover:not(.disabled) > a {
      opacity: 0.6;
      background: var(--card-background-third-color);
    }
  }
}
