.login-step-wrapper {
  flex: 1;
  font-family: 'Rubik';
  h4,
  p {
    margin: 0;
    padding: 0;
  }
  > .login-step {
    max-width: 1240px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    align-items: center;
    > .login-step-content {
      display: flex;
      flex-direction: column;
      gap: 24px;
      > h4 {
        text-align: center;
        font-weight: 700;
        font-size: 34px;
        line-height: 123.5%;
        letter-spacing: 0.25px;
        color: var(--trend-up-color);
      }
      > .login-step-form-container {
        background: rgba(49, 57, 79, 0.38);
        border-inline-start: 4px solid var(--trend-up-color);
        backdrop-filter: blur(30px);
        border-radius: 20px;
        padding: 60px 40px 16px;
        .tabs-navbar ul {
          border-bottom: unset;
          margin-bottom: 32px;
          li {
            flex: 1;
            justify-content: center;
            &:not(.tabs-navbar-active-item) {
              border-bottom: 1px solid var(--border-primary-color);
              line-height: 25px;
            }
          }
        }
        .login-step-form-title,
        .login-step-form-subtitle,
        .login-step-form-label {
          text-align: center;
        }
        .login-step-form-title {
          font-weight: 700;
          font-size: 34px;
          line-height: 123.5%;
          letter-spacing: 0.25px;
        }
        .login-step-form-subtitle {
          font-weight: 400;
          font-size: 16px;
          line-height: 175%;
          letter-spacing: 0.15px;
          margin-bottom: 80px;
        }
        .login-step-form-label {
          display: block;
          margin-bottom: 8px;
          text-align: unset;
        }
        .login-step-form-error {
          margin: 0 22px;
          color: var(--trend-down-color);
          font-weight: 400;
          font-size: 12px;
          line-height: 24px;
        }
        .login-step-form-remark {
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: -0.283333px;
          > span {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
      > .login-step-register {
        font-weight: 400;
        font-size: 16px;
        line-height: 175%;
        text-align: center;
        letter-spacing: 0.15px;
        > a {
          color: var(--trend-up-color);
          text-decoration: none;
        }
      }
    }
  }
  .confirm-dialog-modal-container > .confirm-dialog-modal-content > .confirm-dialog-modal-content-btns {
    padding: 12px 24px;
    justify-content: space-between;
    margin: 0;
    button {
      padding-inline: 6px;
    }
  }
}

[data-theme='light'] .login-step-wrapper > .login-step > .login-step-content > .login-step-form-container {
  background: rgba(255, 255, 255, 0.38);
}
