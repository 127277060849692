.scan-history-sidebar-overlay {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 2;
  backdrop-filter: blur(4px) brightness(70%);
  position: fixed;
  .scan-history-sidebar {
    margin-inline-start: auto;
    height: 100vh;
    width: 330px;
    background: linear-gradient(0deg, rgba(27, 31, 43, 0.95) 0%, rgba(27, 31, 43, 0.95) 100%), #fff;
    overflow: auto;
    display: flex;
    flex-direction: column;
    header {
      display: flex;
      border-bottom: 1px solid var(--dark-other-divider, rgba(255, 255, 255, 0.12));
      align-items: center;
      justify-content: space-between;
      padding: 20px 16px;
      p {
        font-size: 20px;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: 0.15px;
      }
    }
    main {
      padding: 24px 16px;
      overflow: auto;
      display: flex;
      flex-direction: column;
      gap: 32px;
    }
  }
}
