.landing-footer {
  margin-top: auto;
  background-color: #000;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
  .landing-footer-links {
    ul {
      display: flex;
      gap: 14px;
      li {
        position: relative;
        &:not(:last-child):after {
          position: absolute;
          content: '';
          margin: 0 6px;
          top: 50%;
          transform: translateY(-50%);
          height: 2px;
          width: 2px;
          border-radius: 1px;
          background-color: var(--text-primary-color);
        }
        a {
          font-family: 'Rubik';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.25px;
          text-decoration: none;
          color: var(--text-primary-color);
        }
      }
    }
  }
  .landing-footer-copyright {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: var(--text-secondary-color);
  }
  .landing-footer-version {
    position: absolute;
    inset-inline-start: 12px;
    font-size: 10px;
    p {
      span {
        color: var(--text-secondary-color);
      }
    }
  }
}

[data-theme='light'] .landing-footer {
  background-color: rgba(238, 238, 238, 1);
}

@media screen and (max-width: 767px) {
  .landing-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 89px;
    padding: 16px 1px;
    .landing-footer-links {
      margin-bottom: 8px;
    }
  }
}
