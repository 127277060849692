.agency-house-analytics-filters-picker-container {
  position: relative;
  font-family: 'Rubik';
  .agency-house-analytics-filters-picker-btn-container {
    .agency-house-analytics-filters-picker-btn {
      border-radius: 18px;
      height: 36px;
      padding: 6px 16px;
      color: var(--text-secondary-color);
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.25px;
      align-items: center;
      display: flex;
      justify-content: space-between;
      background-color: rgba(var(--border-primary-color-rgb), 0.38);
      gap: 4px;
      &:not(:disabled) {
        cursor: pointer;
      }
      &.open {
      }
      &.has-applied,
      &.open {
        border-color: transparent;
        background-color: rgba(var(--border-primary-color-rgb), 0.8);
        color: var(--text-primary-color);
      }
      &.primary {
        border: 1px solid var(--text-secondary-color);
        height: 34px;
      }
      button {
        svg {
          height: 18px;
          width: 18px;
          color: var(--text-secondary-color);
        }
      }
    }
  }
}
.agency-house-analytics-filters-picker-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 101;
  height: 100vh;
  width: 100vw;
  .agency-house-analytics-filters-picker-main-container {
    z-index: 1;
    position: fixed;
    background: var(--border-primary-color);
    box-shadow: 0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
      0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 12px 8px;
    height: 282px;
    min-width: 212px;
    .agency-house-analytics-filters-picker-agents-container {
      flex: 1;
      padding-inline-end: 6px;
      overflow: auto;

      &::-webkit-scrollbar-thumb {
        background-color: var(--background-primary-color);
        &:hover {
          background-color: var(--text-secondary-color);
        }
      }
    }
    .agency-house-analytics-filters-picker-agent-container {
      display: flex;
      align-items: center;
      gap: 8px;
      height: 36px;
      cursor: pointer;
      &.select-all {
        border-bottom: 1px solid var(--text-third-color);
      }
      span {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.15px;
      }
      svg {
        color: var(--text-secondary-color);
      }
      &.selected {
        svg {
          color: var(--trend-up-color);
        }
      }
      &:hover {
        opacity: 0.7;
      }
    }
  }
}
