.profile-company-instructions-container {
  //background-color: aquamarine;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.4px;
  color: var(--text-secondary-color);
  p,
  ul {
    margin: 0;
    padding: 0;
  }
  ul {
    list-style-type: disc;
    margin-inline-start: 24px;
  }
  > .profile-company-instructions-title {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.4px;
    margin-bottom: 10px;
  }
  > .profile-company-instructions {
    > li {
      margin-bottom: 10px;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
    }
  }
  > .profile-company-instructions-email-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    border: var(--border-primary);
    border-radius: 12px;
    margin-bottom: 10px;
    padding-block: 12px;
    > p {
      font-size: 16px;
      line-height: 28px;
      letter-spacing: 0.15px;
      color: var(--text-primary-color);
      text-align: center;
    }
    button {
      display: flex;
      align-items: center;
      gap: 8px;
      background: transparent;
      border: none;
      color: var(--trend-up-color);
      height: 28px;
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      letter-spacing: 0.17px;
      cursor: pointer;
      &:active {
        transform: scale(0.98);
      }
      > div:has(img) {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  > .profile-company-instructions-video {
    background-color: red;
    // height: 200px;
    display: grid;
  }
}
