.policy-payments-list {
  > .policy-list-item-card {
    > .policy-payments-grid.subgrid {
      .payment-commrate-cell {
        justify-self: flex-start;
        padding-inline: 12px;
        gap: 6px;
        .payment-commrate-cell-button-container {
          button {
            height: 28px;
            width: 28px;
            border-radius: 4px;
            svg {
              font-size: 16px;
              color: var(--text-secondary-color);
            }
            &:hover {
              background-color: rgba(var(--trend-down-color-rgb), 0.1);
              svg {
                color: var(--trend-down-color);
              }
            }
          }
        }
      }
      .payment-commrate-acc-cell {
        border-inline-start: none;
      }
    }
  }
  .report-link-cell {
    a {
      display: flex;
      text-decoration: none;
      color: var(--orange-client);
      align-items: center;
      gap: 4px;
      span {
        white-space: nowrap;
      }
    }
  }
}
