.segmented-picker {
  display: flex;
  background-color: sandybrown;
  height: 36px;
  border-radius: 18px;
  padding: 2px;
  background-color: var(--border-primary-color);
  font-family: Rubik;
  > button {
    font-family: Rubik;
    flex: 1;
    position: relative;
    padding: 8px;
    min-width: 112px;
    border-radius: 16px;
    &.selected {
      background-color: var(--trend-up-color);
    }
  }
  > button:not(.selected) + button:not(.selected) {
    &:before {
      content: '';
      position: absolute;
      width: 1px;
      height: 18px;
      background-color: rgba(84, 84, 88, 0.65);
      inset-block-start: 50%;
      inset-inline-start: 0;
      transform: translateY(-50%);
    }
  }
}
