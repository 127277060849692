.customers-limit-warn-modal {
  position: relative;
  background-color: var(--border-primary-color);
  width: 680px;
  border-radius: 8px;
  box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14),
    0px 11px 15px -7px rgba(0, 0, 0, 0.2);
  header {
    padding: 16px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h5 {
      font-size: 20px;
      font-weight: 500;
      line-height: 32px;
      letter-spacing: 0.15px;
    }
  }
  main {
    padding: 8px 24px;
    div:first-child {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    > div:nth-child(2) {
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-bottom: 24px;
      p {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.15px;
        span {
          color: var(--trend-up-color);
        }
      }
      p:nth-child(2) {
        font-size: 20px;
        font-weight: 500;
        line-height: 32px;
      }
    }
    > div:nth-child(3) {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.17px;
      p:first-child {
        color: var(--trend-up-color);
      }
    }
  }
  footer {
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
  }
}
