.lined-title {
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
  > .lined-title-content {
    font-family: Rubik;
    color: var(--text-primary-color);
    display: flex;
    align-items: center;
    gap: 6px;
    line-height: 20px;
    > .lined-title-selectall-container {
      display: flex;
      align-items: center;
      justify-content: center;
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        background-color: transparent;
        cursor: pointer;
        > img {
          width: 18px;
          height: 18px;
        }
      }
    }
    > .lined-title-content-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      min-width: 8px;
      &.collapsed {
        // transform: rotate(90deg);
      }
      ~ .lined-title-text {
        cursor: pointer;
      }
    }
    .lined-title-text {
      font-size: 16px;
      letter-spacing: 0.5px;
      .lined-title-text {
        font-size: 12px;
      }
    }
    .lined-title-count {
      font-size: 12px;
      letter-spacing: 0.38px;
      color: var(--text-secondary-color);
      .lined-title-count {
        font-size: 10px;
      }
    }
    .lined-title-remark {
      font-size: 14px;
      letter-spacing: 0.25px;
      color: var(--trend-down-color);
    }
    .lined-title-actions {
      display: flex;
      align-items: center;
      gap: 17px;
      margin-inline-start: 12px;
      margin-inline-end: 12px;
      .lined-title-action-icon,
      .lined-title-action-icon > a {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .lined-title-action-icon {
        cursor: pointer;
        img:active {
          transform: scale(0.95);
        }
      }
    }
  }
  > .lined-title-line {
    flex: 1;
    border: 0;
    border-top: var(--border-primary);
  }
  &.small {
    .lined-title-text {
      font-size: 12px;
    }
  }
  .lined-title-count {
    font-size: 10px;
  }
}
