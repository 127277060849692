.agency-house-lobby-totals-card {
  background-color: var(--border-primary-color);
  border-radius: 20px;
  padding: 24px;
  min-height: 333px;
  display: flex;
  flex-direction: column;
  position: relative;
  &.clickable {
    cursor: pointer;
  }
  h5,
  p {
    margin: 0;
  }
  .agency-house-lobby-totals-card-heading {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0.15px;
    svg {
      font-size: 20px;
    }
  }
  .agency-house-lobby-totals-card-header {
    .agency-house-lobby-totals-card-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      background-color: var(--text-third-color);
      height: 56px;
      width: 56px;
      margin-bottom: 16px;
      svg {
        font-size: 40px;
      }
    }
  }
  .agency-house-lobby-totals-card-content {
    flex: 1;
    display: flex;
    gap: 24px;
    .loader-container {
      height: 100%;
    }
    .agency-house-lobby-totals-card-double-values {
      width: 100%;
      margin-top: auto;
      display: flex;
      flex-direction: column;
      gap: 18px;
      > div:nth-child(2) {
        border-top: 1px solid var(--text-third-color);
        padding-top: 12px;
      }
    }
    .agency-house-lobby-totals-card-values {
      margin-top: auto;
      display: flex;
      flex-direction: column;
      gap: 12px;
      flex: 1;
      .agency-house-lobby-totals-card-value-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .agency-house-lobby-totals-card-value {
          font-feature-settings: 'clig' off, 'liga' off;
          font-size: 34px;
          font-weight: 500;
          line-height: 42px;
          letter-spacing: 0.25px;
          span {
            font-size: 24px;
            font-weight: 400;
            line-height: 32px;
          }
        }
        .agency-house-lobby-totals-card-value-warn {
          background-color: rgba(var(--trend-down-color-rgb), 0.17);
          padding-inline: 14px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: var(--trend-down-color);
          border-start-start-radius: 50px;
          border-end-start-radius: 50px;
          margin-inline-end: -24px;
        }
      }
    }
    .agency-house-lobby-totals-card-values-splitted {
      display: flex;
      .agency-house-lobby-totals-card-values-splitted-value {
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.4px;
        &:not(:first-child) {
          border-inline-start: 1px solid var(--text-third-color);
          padding-inline-start: 12px;
        }
        &:first-child {
          padding-inline-end: 12px;
        }
      }
    }
    .agency-house-lobby-totals-card-chart {
      // background-color: rgba($color: #b1f384, $alpha: 0.5);
      flex: 1;
      display: flex;
      align-items: flex-end;
      .agency-house-lobby-totals-chart-xaxis-label {
        color: var(--text-secondary-color);
        fill: var(--text-secondary-color);
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        /* Typography/Caption */
        font-family: Rubik !important;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.4px;
      }
      .apexcharts-canvas {
        > svg:first-child {
          overflow: visible;
        }
      }
      .no-chart-data {
        flex: 1;
        background-color: rgba(255, 255, 255, 0.09);
        --chart-top-offset: 20px;
        display: flex;
        flex-direction: column;
        opacity: 0.5;
        align-items: center;
        justify-content: center;
        gap: 7px;
        height: calc(100% + var(--chart-top-offset));
        margin-top: calc(var(--chart-top-offset) * -1);
        border-radius: 8px;
        p {
          text-align: center;
          width: 170px;
          font-feature-settings: 'clig' off, 'liga' off;
          color: var(--text-secondary-color);
          font-family: Rubik;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0.4px;
        }
      }
    }
    .agency-house-lobby-totals-card-double-chart-wrapper {
      width: 100%;
      display: flex;
      > div {
        display: flex;
        flex-direction: column;
        gap: 12px;
      }
      > div:nth-child(1) {
        padding-inline-end: 24px;
      }
      > div:nth-child(2) {
        padding-inline-start: 24px;
        border-inline-start: 1px solid var(--text-third-color);
      }
    }
    .agency-house-lobby-totals-card-no-data {
      font-feature-settings: 'clig' off, 'liga' off;
      font-size: 20px;
      font-weight: 500;
      line-height: 32px;
      letter-spacing: 0.15px;
    }
  }
}
