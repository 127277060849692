.logo {
  height: 84px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  align-content: center;
  &.bborder {
    border-bottom: var(--border-primary);
  }
  .light img {
    width: 36px;
  }
  .full img {
    height: 23px;
  }
}
