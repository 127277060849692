.back-big-agency-container {
  padding: 8px 24px;
  button {
    margin-inline-start: auto;
    display: inline-flex;
    align-items: center;
    gap: 8px;
    background-color: var(--trend-up-color);
    height: 30px;
    border-radius: 15px;
    padding-inline: 10px;
    text-decoration: none;
    color: var(--text-primary-color);
  }
}
