.customer-groups-list-members-item {
  background-color: red;
  background-color: rgba(var(--border-primary-color-rgb), 0.38);
  padding: 4px 8px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:not(:last-child) {
    margin-bottom: 8px;
  }
  > div {
    align-items: center;
    display: flex;
  }
  > .customer-groups-list-members-item-info {
    gap: 4px;
    p {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.4px;
      & + p {
        color: var(--text-secondary-color);
      }
    }
    svg {
      font-size: 20px;
    }
  }
  > .customer-groups-list-members-item-actions {
    gap: 8px;
    > .customer-groups-list-members-item-action-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 16px;
      width: 16px;
      svg {
        font-size: 16px;
      }
      img {
        height: 12px;
        width: 12px;
      }
    }
  }
}
