.loader-container {
  display: flex;
  flex: 1;
  overflow: hidden;
  .loader {
    border: 4px solid transparent;
    border-top: 4px solid var(--text-secondary-color);
    border-radius: 50%;
    width: 28px;
    height: 28px;
    animation: spin 1s linear infinite;
    margin: auto;
  }
  .loader.extra-small {
    border-width: 2px;
    width: 14px;
    height: 14px;
  }
  .loader.small {
    border-width: 3px;
    width: 18px;
    height: 18px;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
