.register-sidebar {
  grid-area: register-sidebar;
  background-color: var(--card-background-secondary-color);
  border: var(--border-primary);
  border-top: none;
  border-end-end-radius: 20px;
  //border-bottom-left-radius: 18px;
  display: grid;
  grid-template-rows: auto min-content;
  align-items: center;
  position: sticky;
  top: 64px;
  bottom: 0;
  height: calc(100vh - 64px - 26px);
  &--mobile {
    display: none;
  }
  > div {
    > ul {
      margin-inline-end: -24px;
      > li {
        position: relative;
        cursor: default;
        &.active {
          h3 {
            color: var(--text-primary-color);
            font-size: 20px;
            font-weight: 600;
            letter-spacing: 0.25px;
            line-height: 29px;
          }
          h4 {
            font-weight: normal;
            letter-spacing: 0.25px;
            line-height: 20px;
          }
        }
        &.checked.allowed-back {
          > div {
            > div,
            > img {
              cursor: pointer;
            }
          }
        }
        &.checked:not(:last-child):after {
          content: '';
          position: absolute;
          background-color: var(--icon-active-background);
          width: 1px;
          height: 100%;
          transform: translateY(100%);
          inset-inline-end: 23px;
          top: -1px;
        }
        &:not(:last-child) {
          margin-bottom: 3rem;
        }
        > div {
          display: grid;
          gap: 99px;
          grid-template-columns: auto auto;
          justify-content: flex-end;
          > div {
            text-align: end;
            h4 {
              font-size: 14px;
            }
          }
          > img:not(.reg-step-img-enlarged) {
            margin-top: -2px;
            margin-bottom: -4px;
            margin-inline-end: -4px;
            margin-inline-start: -4px;
          }
        }
        h3,
        &.checked h3 {
          letter-spacing: 0.5px;
          line-height: 28px;
        }
      }
    }
  }
  h3,
  h4 {
    margin: 0;
    padding: 0;
    font-weight: normal;
    font-family: Rubik;
    color: var(--text-secondary-color);
  }
}
@media screen and (max-width: 991px) {
  .register-sidebar > div > ul > li > div {
    gap: 39px;
  }
}
@media screen and (max-width: 767px) {
  .register-sidebar {
    margin-top: auto;
    order: 2;
    position: relative;
    height: auto;
    min-height: 56px;
    border-radius: 6px 6px 0 0;
    padding-top: 1px;
    border: none;
    top: 0;
    &--desktop {
      display: none;
    }
    &--mobile {
      display: block;
    }
    .register-sidebar-info {
      display: flex;
      padding: 0 16px;
      justify-content: space-between;
      align-items: center;
      .register-sidebar-title-block {
        display: flex;
        h3 {
          font-size: 14px;
          line-height: 19px;
          color: white;
          margin-right: 9px;
        }
      }

      .register-sidebar-steps-block {
        display: flex;
        font-size: 13px;
        line-height: 19px;
        color: #a7a9af;
        font-family: 'Rubik', sans-serif;
        align-items: center;
        p {
          margin-left: 6px;
        }
        span {
          color: #d3d4d9;
        }
      }
    }
    .status-step-line {
      width: 100%;
      height: 4px;
      background: #2b3245;
      & > div {
        transition: all 0.3s ease-in-out;
        height: 4px;
        background: #02b64a;
        border-radius: 51px 0 0 51px;
      }
    }
  }
}
