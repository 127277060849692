.big-agency-payment-frame-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 400px;
  height: 660px;
  .loader {
    border-top-color: grey;
  }
  > iframe {
    border-radius: 20px;
    background-color: #f7f7f7;
    width: 100%;
    height: 100%;
  }
  > .big-agency-payment-frame-placeholder {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    > h4,
    > h5 {
      margin: 0;
      padding: 0;
      font-family: 'Rubik';
      font-weight: 500;
      font-size: 20px;
      line-height: 32px;
      text-align: center;
      letter-spacing: 0.15px;
      color: rgba(var(--text-inverted-primary-color-rgb), 1);
    }
    > h5 {
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      margin-bottom: 32px;
    }
    &.error {
      > h4,
      > h5 {
        color: var(--trend-down-color);
      }
      > h5 {
        opacity: 0.6;
      }
    }
  }
}

@media screen and (max-height: 890px) {
  .big-agency-payment-frame-container {
    width: 263px;
    height: 440px;
    .credit-only {
      overflow: hidden;
    }
  }
}

// @media screen and (max-width: 767px) {
//   .big-agency-payment-frame-container {
//     width: 312px;
//     height: 100%;
//     > iframe {
//       width: 312px;
//       height: 500px;
//     }
//   }
// }
