.policies-filters {
  > div {
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
    &:not(:last-child) {
      margin-bottom: 24px;
    }
    > * {
      flex: 1;
    }
  }
  .policies-filters-ranges {
    align-items: end;
    .polices-input-range-container {
      flex-basis: 300px;
    }
  }
  .policies-filters-ranges-footer {
    justify-content: center;
    > button {
      flex: unset;
    }
  }
  .policies-filters-sorter {
    > div {
      flex: unset;
      .agency-house-analytics-filters-picker-main-container {
        height: unset;
        min-width: unset;
      }
      .agency-house-analytics-filters-picker-btn {
        min-width: 280px;
      }
    }
  }
  > header {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    gap: 12px;
    padding: 0;
    p {
      font-size: 20px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.25px;
    }
  }
  .policies-filters-tooltip-popper {
    background-color: rgba(var(--border-primary-color-rgb), 0.38);
    font-family: Rubik;
    font-size: 10px;
    font-weight: 500;
    line-height: 14px;
    text-align: center;
  }
  .policies-filters-footer {
    > div:first-child {
      display: flex;
      gap: 8px;
    }
    > div:last-child {
      flex: unset;
      margin-inline-start: auto;
      .policies-export-btn {
        gap: 8px;
      }
      svg {
        font-size: 18px;
      }
    }
  }
}
