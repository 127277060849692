.analytics-changes-switch {
  background-color: var(--border-primary-color);
  padding: 4px;
  height: 42px;
  width: 78px;
  border-radius: 21px;
  position: relative;
  display: flex;
  > button {
    height: 34px;
    width: 34px;
    border-radius: 17px;
    background-color: rgba(var(--text-primary-color-rgb), 0.1);
    opacity: 0.7;
  }
  &.switch-enabled {
    justify-content: flex-end;
    > button {
      background-color: var(--trend-up-color);
      opacity: 1;
    }
  }
}
