.policy-types-list-container {
  padding: 24px;
  padding-bottom: 0;
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  header {
    h3 {
      font-size: 20px;
      font-weight: 500;
      letter-spacing: 0.25px;
    }
  }
  .policies-filters-wrapper {
    padding-inline: unset;
  }
}
