.policies-input-container {
  display: flex;
  padding-inline: 14px;
  background-color: rgba(var(--border-primary-color-rgb), 0.38);
  height: 36px;
  border-radius: 18px;
  align-items: center;
  > .policies-input-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    cursor: pointer;
    > img,
    > svg {
      height: 18px;
      width: 18px;
      color: var(--text-secondary-color);
    }
  }
  > input {
    flex: 1;
    background: none;
    outline: none;
    border: none;
    caret-color: var(--text-primary-color);
    font-family: Rubik;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    &:focus,
    &:not(:placeholder-shown) {
      color: var(--text-primary-color);
    }
  }
  &:has(> input:focus),
  &:has(> input:not(:placeholder-shown)),
  &:has(> .policies-input-icon:active) {
    background-color: rgba(var(--border-primary-color-rgb), 0.8);
  }
}
