.employees-card-item {
  background-color: var(--border-primary-color);
  border-radius: 8px;
  min-height: 48px;
  align-items: center;
  position: relative;
  &:not(:last-child) {
    margin-bottom: 8px;
  }
  > .employee-avatar-container {
    display: flex;
    align-items: center;
    justify-content: center;
    > .employee-avatar {
      background-color: var(--background-primary-color);
      height: 32px;
      width: 32px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &.current {
      > .employee-avatar {
        border: 1px solid var(--trend-up-color);
        box-sizing: content-box;
      }
    }
  }
  > .employee-name-container {
    > .employee-status {
      color: var(--text-secondary-color);
      font-size: 12px;
      letter-spacing: 0.4px;
      line-height: 16px;
    }
  }
  > .employee-preferences-container {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  > .employee-menu-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  > .employees-card-item-loader-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    border-radius: 8px;
    backdrop-filter: blur(2px);
    z-index: 1;
  }
}
