.scan-company-no-login {
  margin-top: 44px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: auto;
  flex: 1;
  .scan-company-no-login-header {
    display: flex;
    justify-content: space-between;
    > p {
      font-size: 24px;
      font-weight: 400;
      line-height: 32px;
    }
    > a {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 16px;
      font-weight: 400;
      text-decoration: none;
      color: var(--text-primary-color);
    }
  }
  .scan-company-users-list {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  .scan-company-user-card {
    position: relative;
    background-color: var(--border-primary-color);
    border-radius: 16px;
    padding: 40px 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    div.user-info {
      p {
        font-size: 24px;
        font-weight: 500;
        line-height: 37.68px;
        letter-spacing: 0.1px;
        margin-bottom: 24px;
      }
      .scan-company-user-fields {
        display: flex;
        gap: 12px;
        > div {
          background-color: var(--trend-up-color);
          font-size: 14px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0.15px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding-inline: 12px;
          height: 28px;
          border-radius: 14px;
        }
      }
    }
    div.user-actions {
      display: flex;
      gap: 12px;
      button {
        background-color: transparent;
        border-width: 2px;
        min-width: 120px;
        &:last-of-type {
          border-color: var(--trend-down-color);
        }
      }
    }
  }
}
