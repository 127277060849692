.profile-field-item {
  background-color: rgba(var(--text-primary-color-rgb), 0.09);
  border-radius: 8px;
  padding: 12px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 340px;
}

.profile-field-item .MuiSwitch-switchBase {
  color: var(--text-primary-color);
}

.profile-field-item-toggle>span>span.MuiSwitch-track {
  background-color: var(--text-secondary-color);
  filter: opacity(50%) brightness(250%);
}

.profile-field-item .MuiSwitch-switchBase.Mui-checked {
  color: var(--trend-up-color);
}

.profile-field-item-toggle>span>.Mui-checked+.MuiSwitch-track {
  background-color: var(--trend-up-color);
  filter: opacity(50%) brightness(250%);
}

.profile-field-item .MuiSwitch-switchBase.Mui-disabled+.MuiSwitch-track {
  opacity: 1;
}

.profile-field-item:not(.selected) .MuiSwitch-colorSecondary.Mui-disabled+.MuiSwitch-track {
  background: var(--background-secondary-color);
}

.profile-field-item>.profile-field-item-content {
  display: flex;
  align-items: center;
}

.profile-field-item>.profile-field-item-content>.profile-field-item-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  min-width: 42px;
}

.profile-field-item>.profile-field-item-content>.profile-field-item-title {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.17px;
}

.profile-field-item:not(.selected)>.profile-field-item-content {
  opacity: 0.38;
}