.workstation-button-mobile {
  padding: 10px 12px;
  height: 36px;
  background: var(--trend-up-color);
  border-radius: 18px;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  color: var(--text-primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--trend-up-color);
  &.secondary {
    background: transparent;
    border-color: rgba(var(--trend-up-color-rgb), 0.38);
    color: var(--trend-up-color);
  }
}
