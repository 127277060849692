.items-appbar-container {
  height: 100%;
  display: flex;
  > .appbar-counter-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 50px;
    background-color: var(--trend-up-color);
    font-size: 20px;
    font-weight: 700;
    border-radius: 8px;
  }
  > .appbar-content-container {
    flex: 1;
    padding: 16px;
  }
}
