.agency-field-chip {
  height: 26px;
  border-radius: 16px;
  background-color: rgba(255, 255, 255, 0.16);
  border: 1px solid rgba(255, 255, 255, 0.6);
  display: flex;
  align-items: center;
  gap: 6px;
  color: var(--text-primary-color);
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.17px;
  padding-inline-start: 10px;
  cursor: pointer;
  &:has(div:only-child) {
    border-color: rgba(255, 255, 255, 0.26);
    background: transparent;
    padding-inline: 10px;
  }
  &:disabled {
    cursor: default;
    opacity: 0.6;
  }
}
