.policy-irreg-item {
  position: relative;
  .irreg-value {
    color: var(--trend-down-color);
  }
  .irreg-remark {
    background-color: rgba(255, 255, 255, 0.17);
    color: var(--text-secondary-color);
    font-size: 12px;
    letter-spacing: 0.4px;
    line-height: 16px;
    padding: 2px 16px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }
}
