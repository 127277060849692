.customers-groups-lists-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 24px 26px;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  > .customers-groups-lists-header {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: var(--text-secondary-color);
    margin-bottom: 12px;
    > .sortable {
      display: flex;
      align-items: center;
      cursor: pointer;
      i {
        margin-left: 4px;
        margin-right: 4px;
      }
    }
  }
  > .customers-groups-lists {
    flex: 1;
    overflow: auto;
  }
  .customers-groups-list-grid {
    display: grid;
    grid-template-columns: 48px 1.5fr 1fr 1fr 1fr 58px;
    &.split-commission {
      grid-template-columns: 48px 1.5fr 1fr 1fr 1fr 1fr 58px;
    }
    div:has(.columns-picker-container) {
      justify-self: end;
    }
  }
}
