.add-agency-type-selector {
  padding-top: 60px;
  h4,
  h5,
  p {
    color: var(--text-primary-color);
    margin: 0;
    font-family: Rubik;
    text-align: center;
  }
  h4 {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
    letter-spacing: 0.15px;
    margin-bottom: 196px;
  }
  .add-agency-type-cards {
    display: flex;
    gap: 40px;
    justify-content: center;
    .add-agency-type-card {
      position: relative;
      width: 320px;
      background-color: rgba(255, 255, 255, 0.09);
      border: 1px solid rgba(0, 0, 0, 0.12);
      border-radius: 8px;
      padding: 24px 22px;
      padding-top: 124px;
      display: flex;
      flex-direction: column;
      align-items: center;
      > div:has(img) {
        position: absolute;
        top: 0;
        transform: translateY(-50%);
      }
      > div:has(svg) {
        position: absolute;
        top: 6px;
        inset-inline-start: 6px;
        display: flex;
      }
      div:has(h5 + p) {
        margin-bottom: 24px;
        h5 {
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 160%;
          letter-spacing: 0.15px;
          margin-bottom: 8px;
        }
        p {
          color: var(--text-secondary-color);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 143%;
          letter-spacing: 0.17px;
        }
      }
      > p {
        margin-bottom: 24px;
        color: var(--text-secondary-color);
        font-size: 12px;
      }
      &:hover,
      &.card-selected {
        border: 1px solid var(--trend-up-color);
      }
    }
  }
  button {
    margin-top: 20px;
    margin-inline: auto;
    background-color: transparent;
    color: var(--trend-up-color);
    border: 1px solid var(--trend-up-color);
    color: rgba(255, 255, 255, 1);
    background-color: var(--trend-up-color);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-family: Rubik;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    padding: 6px 16px;
    height: 36px;
    min-width: 90px;
    border-radius: 18px;
    cursor: pointer;
    &:active:not(:disabled) {
      transform: scale(0.98);
    }
    &:disabled {
      opacity: 0.3;
      cursor: default;
    }
  }
}

[data-theme='dark'] .add-agency-type-cards {
  .add-agency-type-card {
    border: 1px solid transparent;
    background-color: rgba(255, 255, 255, 0.09);
    &:hover,
    &.card-selected {
      background-color: rgba(var(--trend-up-color-rgb), 0.12);
      border: 1px solid var(--trend-up-color);
    }
  }
}

@media screen and (max-height: 767px) {
  .add-agency-type-selector {
    padding-top: 40px;
    h4 {
      margin-bottom: 120px;
    }
  }
}
