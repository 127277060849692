.payment-plans-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 744px;
}

.payment-period-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  .payment-period-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;
    background-color: rgba(49, 57, 79, 0.38);
    padding: 4px;
    border-radius: 16px;

    > .payment-period-text {
      color: var(--text-secondary-color);
      font-family: 'Rubik';
      font-weight: 400;
      font-size: 20px;
      line-height: 22px;
      letter-spacing: 0.25px;
      padding: 1px 16px;

      &.selected {
        color: var(--text-primary-color);
        background-color: var(--border-primary-color);
        border-radius: 12px;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
        cursor: default;
      }

      &:not(.selected) {
        cursor: pointer;
      }
    }
  }
}

.payment-plans-container {
  display: flex;
  flex-wrap: wrap;
  font-family: Rubik;
  gap: 30px;
  justify-content: center;
  align-items: center;
  min-height: 670px;

  h3,
  h4,
  h5 {
    margin: 0;
    padding: 0;
    text-align: center;
    font-weight: 400;
  }

  > .payment-plan-card {
    cursor: pointer;
    width: 340px;
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    border: var(--border-primary);
    border: 1px solid #414c52;
    &.disabled {
      cursor: default;
      filter: grayscale(1);
      opacity: 0.5;
    }
    > .payment-plan-card-header {
      background-color: var(--border-primary-color);
      padding-left: 24px;
      padding-right: 24px;

      > h3 {
        font-size: 20px;
        line-height: 29px;
        letter-spacing: 0.25px;
        font-weight: 500;
        padding-top: 32px;
      }

      > h5 {
        font-family: 'Rubik';
        font-size: 14px;
        line-height: 26px;
        letter-spacing: 0.56px;
        font-weight: 300;
        padding-bottom: 32px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.17);
      }
    }

    > .payment-plan-card-body {
      transition: height 0.25s, background-color 0.4s ease-in-out;
      height: 400px;
      padding: 24px 23px;
      background-color: #282f41;

      > .payment-plan-card-body-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 24px;

        > .payment-plan-card-body-header-title {
          display: flex;
          gap: 12px;
          align-items: flex-end;

          > span {
            font-family: 'Rubik';
            font-size: 40px;
            line-height: 40px;
            letter-spacing: -0.28px;
            text-align: center;
          }

          > .payment-plan-discounts {
            > .payment-plan-discount {
              background-color: rgba(var(--trend-up-color-rgb), 0.38);
              font-family: 'Rubik';
              font-weight: 400;
              font-size: 14px;
              line-height: 17px;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 3.5px 12px;
              border-radius: 12px;
              align-self: center;

              &:not(:last-child) {
                margin-bottom: 4px;
              }
            }
          }
        }

        > .payment-plan-card-body-header-subtitle {
          font-weight: 400;
          font-size: 14px;
          line-height: 26px;
          letter-spacing: 0.5625px;
          color: var(--text-secondary-color);
        }
      }

      > .payment-plan-card-body-content {
        > .payment-plan-benefits {
          > li {
            display: flex;
            gap: 16px;
            font-family: 'Rubik';
            font-weight: 400;
            font-size: 18px;
            line-height: 26px;
            letter-spacing: 0.5625px;
            color: var(--text-secondary-color);

            &:not(:last-child) {
              margin-bottom: 16px;
            }
          }
        }
      }
    }

    > .payment-plan-card-footer {
      height: 52px;
      margin-top: auto;
      background-color: var(--background-primary-color);
      background: rgba(49, 57, 79, 0.38);
      display: flex;
      align-items: center;
      justify-content: center;

      a,
      p {
        width: 100%;
        height: 100%;
        color: white;
        text-align: center;
        padding-top: 9px;
        text-decoration: none;
      }
    }

    &:hover {
      position: relative;
      border-color: var(--trend-up-color);

      &::before {
        content: '';
        width: 24px;
        height: 4px;
        background-color: var(--trend-up-color);
        position: absolute;
        inset-inline-start: 50%;
        transform: translateX(50%);
      }

      > .payment-plan-card-body {
        height: 470px;
      }
    }

    &.most-popular {
      > .payment-plan-card-header {
        background-color: var(--trend-up-color);
      }

      > .payment-plan-card-body {
        background-color: var(--border-primary-color);
      }

      &::before {
        background-color: var(--text-primary-color);
      }
    }
  }
}

.payment-plans-remark-container {
  p {
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 12px;
    line-height: 166%;
    text-align: center;
    letter-spacing: 0.4px;
    color: var(--text-secondary-color);
    margin: 0;
    margin-top: 12px;
  }
}

.payment-plans-mobile-container {
  display: none;
}

@media screen and (max-height: 890px) {
  .payment-plans-wrapper {
    min-height: unset;
    .payment-plans-container {
      min-height: 397px;

      > .payment-plan-card {
        // width: 280px;
        // width: unset;
        // flex: 1;

        > .payment-plan-card-header {
          > h3 {
            padding-top: 6px;
          }

          > h5 {
            padding-bottom: 6px;
            min-height: 60px;
          }
        }

        > .payment-plan-card-body {
          padding: 12px 16px;
          height: 240px;

          > .payment-plan-card-body-header {
            margin-bottom: 12px;

            > .payment-plan-card-body-header-title {
              gap: 6px;
            }
          }

          > .payment-plan-card-body-content {
            > .payment-plan-benefits {
              > li {
                font-size: 14px;
                line-height: 16px;

                &:not(:last-child) {
                  margin-bottom: 8px;
                }
              }
            }
          }
        }

        > .payment-plan-card-footer {
          height: 40px;
        }

        &:hover {
          > .payment-plan-card-body {
            height: 260px;
          }
        }
      }
    }
    .payment-plans-remark-container {
      p {
        margin: 6px;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .payment-plans-container {
    gap: 16px;

    & > .payment-plan-card {
      width: 316px;
    }
  }
}

@media screen and (max-width: 992px) {
  .payment-plans-container {
    gap: 9px;

    & > .payment-plan-card {
      width: 248px;

      & > .payment-plan-card-body {
        height: 380px;

        & > .payment-plan-card-body-content > .payment-plan-benefits {
          & > li {
            font-size: 14px;
            line-height: 23px;
          }
        }
      }

      & > .payment-plan-card-header > h5 {
        font-size: 12px;
        line-height: 19px;
        min-height: 69px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .payment-plans-wrapper {
    min-height: 437px;
  }
  .payment-plans-container {
    gap: 9px;
    min-height: 236px;

    & > .payment-plan-card {
      width: 103px;
      border: 1px solid #41454f;

      &:hover {
        &::before {
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 7px 8px 0 8px;
          border-color: #02b64a transparent transparent transparent;
          transform: none;
          background: none;
          inset-inline-start: 0%;
          margin-right: 43px;
        }

        & > .payment-plan-card-body {
          height: 116px;
        }
      }
      &.most-popular {
        &:hover {
          &::before {
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 7px 8px 0 8px;
            border-color: #ffffff transparent transparent transparent;
            transform: none;
            background: none;
            inset-inline-start: 0%;
            margin-right: 43px;
          }
        }
      }

      & > .payment-plan-card-body {
        height: 116px;
        padding: 15px 13px;
        position: relative;

        & > .payment-plan-card-body-content > .payment-plan-benefits {
          & > li {
            font-size: 14px;
            line-height: 23px;
          }
        }

        & > .payment-plan-card-body-header {
          & > .payment-plan-card-body-header-title {
            display: flex;
            flex-direction: column;
            align-items: center;

            & > .payment-plan-discounts > .payment-plan-discount {
              margin: 1px;
              line-height: 9px;
              height: 23px;
              width: 43px;
              font-size: 13px;
              margin-bottom: -6px;
            }

            span {
              margin-top: 9px;
              font-size: 16px;
              line-height: 13px;
            }

            h4 {
              font-size: 14px;
              line-height: 3px;
            }
          }

          & > .payment-plan-card-body-header-subtitle {
            font-size: 11px;
            margin-top: 13px;
            line-height: 13px;
          }
        }
      }

      & > .payment-plan-card-header {
        & > h3 {
          font-size: 14px;
          padding: 16px 0;
        }

        & > h5 {
          font-size: 12px;
          line-height: 19px;
          min-height: 69px;
        }
      }
    }

    &.payment-plans-container-yearly {
      & > .payment-plan-card {
        & > .payment-plan-card-body {
          padding-top: 3px;
          .payment-plan-discounts {
            position: absolute;
            bottom: 16px;
          }
        }
      }
    }
  }
  .payment-plans-mobile-container {
    display: block;
    background: #222736;
    width: 100%;
    border-radius: 12px 12px 0 0;
    padding: 16px 19px;
    margin-top: 31px;
  }
  .payment-plans-mobile {
    &--header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      div:nth-child(2) {
        a,
        p {
          font-size: 14px;
          border-radius: 4px;
          line-height: 23px;
          text-decoration: none;
          color: var(--trend-up-color);
          border: 1px solid #165d3d;
          padding: 6px 10px;
        }
      }
      div:nth-child(1) {
        p {
          font-size: 14px;
          font-weight: 500;
          text-align: right;
          line-height: 19px;
          margin: 1px;
          color: var(--trend-up-color);
        }
        span {
          font-size: 11px;
          font-weight: 400;
          line-height: 19px;
        }
      }
    }
    &--body {
      h3 {
        display: block;
        font-weight: 500;
        font-size: 12px;
        line-height: 19px;
        margin-top: 23px !important;
        text-align: right;
      }
      p {
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 19px;
        margin: 20px 1px;
        color: var(--text-secondary-color);
        span {
          margin-right: 18px;
        }
      }
    }
  }
  .payment-plans-remark-container {
    max-width: 328px;
    width: 100%;
    margin: 35px auto;

    p {
      margin: 6px;
    }
  }
  .payment-plans-container .desktop-payment-plan-card {
    .payment-plan-card-body-content > .payment-plan-benefits {
      display: none;
    }

    div.payment-plan-card-footer {
      display: none;
    }

    &.payment-plan-card .payment-plan-card-header {
      h5 {
        display: none;
      }
    }
  }
  .payment-period-wrapper .payment-period-container {
    margin-bottom: 23px;
    & > .payment-period-text {
      font-size: 14px;
      line-height: 16px;
      padding: 6px 16px;
    }
  }
}
