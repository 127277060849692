#loading-icon-svg {
  animation: spin 0.7s steps(8, end) infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
