.profile-avatar {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: rgba(var(--text-primary-color-rgb), 0.17);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  button {
    position: absolute;
    bottom: 0;
    inset-inline-start: 0;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: var(--text-primary-color);
    color: rgba(var(--text-inverted-primary-color-rgb), 1);
  }
}
