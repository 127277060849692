.customers-filter-dropdown-item {
  color: var(--text-primary-color);
  font-family: Rubik;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 20px;
  text-align: start;
  padding: 16px;
  padding-top: 12px;
  display: flex;
  justify-content: space-between;
}

.customers-filter-dropdown-item:hover:not(.disabled) {
  background-color: var(--background-secondary-color);
}

.customers-filter-dropdown-item>.customers-filter-dropdown-item-value {
  flex: 1;
}

.customers-filter-dropdown-item.disabled {
  cursor: default;
}

.customers-filter-dropdown-item.disabled>.customers-filter-dropdown-item-value {
  color: var(--text-third-color);
}

.customers-filter-dropdown-item.disabled>.customers-filter-dropdown-item-disabled {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: var(--text-secondary-color);
}