.notify-dialog-modal-container {
  background-color: var(--background-secondary-color);
  box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.2);
  width: 360px;
  border-radius: 4px;
  display: flex;
  color: var(--text-primary-color);
  font-family: Rubik;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 20px;
}

.notify-dialog-modal-container>.notify-dialog-modal {
  flex: 1;
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.notify-dialog-modal-container>.notify-dialog-modal>.notify-dialog-modal-content {
  margin-inline-end: auto;
  display: flex;
  align-items: center;
}

.notify-dialog-modal-container>.notify-dialog-modal>.notify-dialog-modal-content>.notify-dialog-modal-content-text {
  color: var(--text-secondary-color);
  font-family: Rubik;
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 20px;
  margin: 0 16px;
  white-space: pre-wrap;
}

.notify-dialog-modal-container>.notify-dialog-modal>.notify-dialog-modal-content>.notify-dialog-modal-content-counter {
  min-width: 35px;
}

.notify-dialog-modal-container>.notify-dialog-modal>.notify-dialog-modal-btns {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-inline-start: auto;
}

.notify-dialog-modal-container>.notify-dialog-modal>.notify-dialog-modal-btns>button {
  border: none;
  background: transparent;
  color: var(--trend-up-color);
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1.25px;
  line-height: 16px;
  text-align: center;
  cursor: pointer;
  width: 64px;
  height: 36px;
  border: 2px solid transparent;
  margin: 6px 12px;
}

.notify-dialog-modal-container>.notify-dialog-modal>.notify-dialog-modal-btns>button:hover {
  border-color: var(--trend-up-color);
  border-radius: 5px;
  font-weight: bold;
}

.notify-dialog-modal-container>.notify-dialog-modal-btns>button:active {
  transform: scale(0.95);
}

.notify-dialog-modal-container {
  transition: visibility 1s linear 0.4s, opacity 0.4s linear;
}