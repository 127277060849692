.agent-groups-sidebar-container {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  z-index: 2;
  backdrop-filter: blur(4px) brightness(70%);
  position: fixed;
  inset-inline-start: 0;
  inset-block-end: 0;
  &.closed {
    width: 0;
    .agent-groups-sidebar {
      transform: translateX(100%);
    }
  }
}

.agent-groups-sidebar {
  --sidebar-background-color: #242937;
  // position: fixed;
  // inset-inline-start: 0;
  // inset-block-end: 0;
  background-color: var(--sidebar-background-color);
  height: 100%;
  width: 348px;
  display: flex;
  transition: all 0.25s ease-in-out;
  flex-direction: column;
  .agent-groups-sidebar-header {
    display: flex;
    flex-direction: column;
    background-color: var(--background-secondary-color);
    .agent-groups-close-btn-container {
      align-self: end;
      display: flex;
      align-items: center;
      justify-content: center;
      button {
        height: 40px;
        width: 40px;
        background-color: var(--border-primary-color);
        border-end-start-radius: 10px;
        border: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        &:active {
          img {
            transform: scale(0.95);
          }
        }
        &:hover {
          filter: brightness(150%) saturate(140%);
        }
      }
    }
    .agent-groups-sidebar-header-controls {
      display: flex;
      align-items: center;
      gap: 12px;
      padding: 30px 16px 38px;
      .agent-groups-sidebar-header-input-container {
        flex: 1;
        position: relative;
        .agent-groups-sidebar-header-input-ico {
          position: absolute;
          transform: translateY(-50%);
          top: 50%;
          inset-inline-start: 12px;
          height: 18px;
          width: 18px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        input {
          width: 100%;
          height: 40px;
          border: 1px solid #989ba3;
          border-radius: 20px;
          outline: none;
          background-color: #404553;
          color: var(--text-primary-color);
          font-family: 'Rubik';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.25px;
          padding-inline: 38px;
        }
        .agent-groups-sidebar-header-input-clear-btn {
          position: absolute;
          transform: translateY(-50%);
          top: 50%;
          inset-inline-end: 12px;
          height: 18px;
          width: 18px;
          display: flex;
          align-items: center;
          justify-content: center;
          button {
            display: flex;
            align-items: center;
            justify-content: center;
            background: transparent;
            border: none;
            width: 16px;
            height: 16px;
            cursor: pointer;
            &:active {
              transform: scale(0.95);
            }
          }
        }
      }
      .agent-groups-sidebar-header-btn-container {
        display: flex;
        align-items: center;
        justify-content: center;
        button {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          border: none;
          background-color: var(--trend-up-color);
          height: 40px;
          width: 40px;
          border-radius: 20px;
          transition: all 0.2s ease-in-out;
          &:active {
            transform: scale(0.95);
          }
          &.clear-selected-btn {
            background-color: transparent;
            border: 1px solid #989ba3;
          }
        }
      }
    }
  }
  .agent-groups-sidebar-content {
    flex: 1;
    padding: 8px 16px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .agent-groups-sidebar-list-header {
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.4px;
      color: var(--text-secondary-color);
      margin-bottom: 7px;
    }
    .agent-groups-sidebar-list-wrapper {
      overflow: auto;
      .agent-groups-sidebar-list {
        // flex: 1;
        padding: 16px;
        border-radius: 16px;
        background-color: var(--border-primary-color);
        .agent-groups-sidebar-list-extras {
          margin-bottom: 14px;
          display: flex;
          justify-content: space-between;
          font-size: 16px;
        }
        .agent-groups-sidebar-list-item {
          position: relative;
          height: 48px;
          border-radius: 8px;
          background-color: var(--sidebar-background-color);
          display: flex;
          align-items: center;
          padding-inline-end: 8px;
          .agent-groups-sidebar-list-item-selector {
            width: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            button {
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              background: transparent;
              border: none;
              width: 12px;
              height: 12px;
            }
          }
          .agent-groups-sidebar-list-item-info {
            flex: 1 1 0;
            min-width: 0;
            display: flex;
            align-items: center;
            gap: 8px;
            .agent-groups-sidebar-list-item-ava {
              height: 24px;
              width: 24px;
              border-radius: 4px;
              font-size: 12px;
              line-height: 20px;
              display: flex;
              align-items: center;
              justify-content: center;
              text-align: center;
              letter-spacing: 0.4px;
            }
            .agent-groups-sidebar-list-item-name {
              font-size: 14px;
              line-height: 20px;
              letter-spacing: 0.17px;
              flex: 1 1 0;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          .agent-groups-sidebar-list-item-menu {
            margin-inline-start: auto;
          }
          &.selected {
            background: rgba(var(--trend-up-color-rgb), 0.17);
            .agent-groups-sidebar-list-item-info {
              .agent-groups-sidebar-list-item-name {
                color: var(--trend-up-color);
              }
            }
          }
          &:not(:last-child) {
            margin-bottom: 8px;
          }
        }
      }
    }
    .agent-groups-sidebar-no-groups {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      > p {
        width: 125px;
        margin: 0;
        padding: 0;
        font-family: 'Rubik';
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.17px;
        color: var(--text-secondary-color);
        margin-bottom: 12px;
        text-align: center;
      }
      > div > button {
        transition: all 0.2s ease-in-out;
        border: none;
        background-color: var(--trend-up-color);
        display: flex;
        align-items: center;
        gap: 10px;
        border-radius: 100px;
        padding: 8px 16px;
        cursor: pointer;
        .agent-groups-sidebar-no-groups-btn-icon {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .agent-groups-sidebar-no-groups-btn-text {
          font-family: 'Rubik';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.4px;
          text-transform: uppercase;
          color: var(--text-primary-color);
        }
        &:active {
          transform: scale(0.95);
        }
      }
    }
  }
}

.agent-groups-sidebar-list-item-loader-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  border-radius: 8px;
  backdrop-filter: blur(4px);
  z-index: 1;
}

.sidebar-agent-group-menu-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 168px;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  .sidebar-agent-group-menu-item-ico {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
  }
}

@media screen and (max-width: 1024px) {
  .agent-groups-sidebar {
    .agent-groups-sidebar-header {
      .agent-groups-sidebar-header-controls {
        padding-block: 12px;
      }
    }
  }
}

[dir='ltr'] .agent-groups-sidebar-container.closed {
  .agent-groups-sidebar {
    transform: translateX(-100%);
  }
}
