.agency-house-analytics-policies-table-container {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  header {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px; /* 171.429% */
    letter-spacing: 0.17px;
    margin-bottom: 12px;
  }
  .agency-house-analytics-policies-table-grid {
    padding-inline-start: 16px;
    display: grid;
    grid-template-columns: 40px repeat(auto-fit, minmax(100px, 1fr));
  }
  .agency-house-analytics-policies-table {
    overflow: auto;
  }
}

.agency-house-analytics-policies-table-item {
  background-color: rgba(var(--text-primary-color-rgb), 0.08);
  border-radius: 8px;
  height: 52px;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.17px;
  cursor: pointer;
  &.selected {
    background-color: rgba(var(--text-primary-color-rgb), 0.16);
  }
  & > *:first-child {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &:not(:last-child) {
    margin-bottom: 12px;
  }

  .agency-house-analytics-policies-table-item-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
    border-radius: 4px;
    &.small {
      height: 10px;
      width: 10px;
      border-radius: 2px;
    }
  }
}

::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-thumb {
  border: 2px solid transparent;
  background-color: var(--border-primary-color);
  border-top: none;
  border-bottom: none;
  border-radius: 4px;
  border-inline-start-width: 4px;
  border-start-start-radius: 6px;
  border-end-start-radius: 6px;
  background-clip: padding-box;
}
