.policies-filter-dropdown {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  padding-inline: 16px;
  border-radius: 52px;
  background-color: var(--background-secondary-color);
  color: var(--text-secondary-color);
  font-family: Rubik;
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 20px;
  text-align: start;
  cursor: pointer;
  position: relative;
}

.policies-filter-dropdown.open {
  background-color: var(--border-primary-color);
  color: var(--text-primary-color);
}

.policies-filter-dropdown>.policies-filter-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 25px;
}

.policies-filter-dropdown>.policies-filter-content>.policies-filter-dropdown-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.policies-filter-dropdown>.policies-filter-dropdown-items {
  position: absolute;
  top: 36px;
  inset-inline-end: 0;
  width: 280px;
  background-color: var(--background-primary-color);
  border-radius: 4px;
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
  margin-top: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  z-index: 100;
  max-height: 350px;
  overflow: auto;
}