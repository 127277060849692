.modal-agency-invite {
  display: flex;
  width: 1045px;
  height: 738px;
  overflow: hidden;
  border-radius: 8px;
  color: var(--text-primary-color);
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.17px;
  position: relative;
  h5,
  p {
    margin: 0;
  }
  > aside {
    width: 35%;
    background-color: var(--background-secondary-color);
    padding: 24px 40px;
    display: flex;
    flex-direction: column;
    .modal-agency-invite-side-content {
      flex: 1;
      display: flex;
      flex-direction: column;
      > h5 {
        padding: 16px 8px;
        font-size: 20px;
        font-weight: 500;
        line-height: 160%; /* 32px */
        letter-spacing: 0.15px;
      }
      > h5 p {
        font-size: 16px;
        font-weight: 400;
        line-height: 150%; /* 24px */
        letter-spacing: 0.15px;
      }
      > h5 + p + p {
        color: var(--text-secondary-color);
      }
      .modal-agency-invite-payment-desc {
        margin-top: auto;
        position: relative;
        display: flex;
        background: linear-gradient(
            0deg,
            rgba(var(--trend-up-color-rgb), 0.17) 0%,
            rgba(var(--trend-up-color-rgb), 0.17) 100%
          ),
          var(--text-primary-color);
        border-radius: 4px;
        padding: 12px;
        align-items: flex-start;
        div {
          flex: 1;
          p {
            color: rgba(var(--text-inverted-primary-color-rgb), 0.87);
            font-size: 20px;
            font-weight: 500;
            line-height: 120%;
            letter-spacing: 0.15px;
            &:first-child {
              font-size: 14px;
              font-weight: 400;
              line-height: 143%; /* 20.02px */
              letter-spacing: 0.17px;
            }
            &:last-child {
              color: rgba(var(--text-inverted-primary-color-rgb), 0.6);
              font-size: 12px;
              font-weight: 400;
              line-height: 166%;
              letter-spacing: 0.4px;
            }
          }
        }
        > img:first-child {
          position: absolute;
          top: 0px;
          inset-inline-end: 0;
          transform: translateY(-80%) rotate(5deg);
        }
      }
    }
  }
  > main {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: var(--border-primary-color);
    > header {
      height: 80px;
      padding: 16px 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      > h5 {
        padding: 0;
        font-size: 20px;
        font-weight: 500;
        line-height: 160%; /* 32px */
        letter-spacing: 0.15px;
      }
    }
    > .modal-agency-invite-main-content {
      flex: 1;
      padding: 12px 24px;
      overflow: auto;
      border-top: 1px solid var(--text-third-color);
      border-bottom: 1px solid var(--text-third-color);
      .modal-agency-invite-items {
        &:not(:last-child) {
          margin-bottom: 24px;
        }
        .modal-agency-invite-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 42px;
          border-radius: 8px;
          padding-inline: 12px;
          background: rgba(255, 255, 255, 0.09);
          &.checked {
            background: rgba(var(--trend-up-color-rgb), 0.12);
          }
          &:not(:last-child) {
            margin-bottom: 8px;
          }
          &.invite-item-heading {
            background: transparent;
            color: var(--text-secondary-color);
          }
          > div {
            display: flex;
            align-items: center;
            gap: 17px;
            &.company-info {
              gap: 8px;
            }
          }
        }
      }
    }
    > footer {
      height: 60px;
      padding: 12px 24px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 8px;
      .button-rounded.outlined {
        border-color: #f44336;
        color: #f44336;
      }
    }
  }
}

@media screen and (max-height: 767px) {
  .modal-agency-invite {
    width: 970px;
    height: 600px;
  }
}
