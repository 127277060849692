.customer-totals-details-list-grid {
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr 20px;
  padding-inline: 24px;
}
.customer-totals-details-list-header {
  color: var(--text-secondary-color);
  align-items: flex-end;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
  height: 32px;
  margin-bottom: 8px;
}
.customer-totals-details-list-item {
  background-color: var(--border-primary-color);
  height: 64px;
  align-items: center;
  margin-bottom: 8px;
  > div:first-child {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  > p:first-child {
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.1px;
  }
  .customer-totals-details-list-item-value {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
  }
  &:has(+ .subitem),
  &:has(+ .subsubitem) {
    margin-bottom: 0;
  }
}

.subitem {
  height: 64px;
  background-color: rgba(var(--background-secondary-color-rgb), 0.8);
  align-items: center;
  &.open {
    background-color: rgba(var(--text-primary-color-rgb), 0.23);
  }
  // &:not(:has(+ .subsubitem)) {
  //   margin-bottom: 8px;
  // }
}

.subsubitem {
  background-color: rgba(var(--text-primary-color-rgb), 0.08);
}

.customer-totals-details-list {
  flex: 1;
  overflow: auto;
}
