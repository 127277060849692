.customers-filter-advanced-container {
  position: relative;
  .customers-filter-advanced-btn-container {
    button {
      background: var(--background-secondary-color);
      border: 1px solid var(--background-secondary-color);
      border-radius: 18px;
      height: 36px;
      padding: 8px 14px;
      gap: 8px;
      .customers-filter-advanced-btn-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 16px;
        height: 16px;
      }
      .customers-filter-advanced-btn-count {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--trend-up-color);
        color: var(--text-primary-color);
        height: 18px;
        min-width: 18px;
        border-radius: 9px;
        padding-inline: 6px;
      }
      &:not(:disabled) {
        cursor: pointer;
      }
      &.open {
        background: rgba(211, 212, 217, 0.17);
        border-color: rgba(211, 212, 217, 0.6);
      }
      &.has-applied {
        background-color: var(--border-primary-color);
      }
    }
  }
  .customers-filter-advanced-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    height: 100vh;
    width: 100vw;
    .customers-filter-advanced-filters-container {
      z-index: 1;
      position: fixed;
      background: #272d3e;
      box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14),
        0px 9px 46px 8px rgba(0, 0, 0, 0.12);
      border-radius: 8px;
      > header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: var(--border-primary);
        padding: 12px 16px;
        button {
          height: 24px;
          width: 24px;
        }
      }
      > main {
        background: rgba(27, 31, 43, 0.17);
        section {
          padding: 16px;
          &:not(:last-child) {
            border-bottom: var(--border-primary);
          }
          .customers-filter-advanced-filters-row {
            display: grid;
            grid-template-columns: 37px 200px auto;
            gap: 12px;
            align-items: center;
            p.disabled {
              color: var(--text-secondary-color);
            }
            .customers-filter-advanced-filters {
              display: flex;
              gap: 12px;
            }
            &:not(:last-child) {
              margin-bottom: 16px;
            }
          }
        }
      }
      > footer {
        display: flex;
        border-top: var(--border-primary);
        padding: 14px 24px;
        justify-content: flex-end;
        button {
          height: 36px;
          border-radius: 18px;
          padding: 6px 16px;
          background-color: var(--trend-up-color);
          border: none;
          font-family: 'Rubik';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.4px;
          text-transform: uppercase;
          color: #ffffff;
        }
      }
    }
  }
  button {
    background: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all ease-in-out 0.25s;
    &:active:not(:disabled) {
      transform: scale(0.98);
    }
    &:is(:hover, :disabled) {
      opacity: 0.7;
    }
    &:disabled {
      cursor: default;
      //color: red !important;
    }
  }
  p {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.15px;
    margin: 0;
    padding: 0;
  }
}
