.profile-sidebar-email-wrapper {
  --password-icon-translate-x: 16px;
  --line-after-translate-x: 50%;
}

[dir='ltr'] .profile-sidebar-email-wrapper {
  --password-icon-translate-x: -16px;
  --line-after-translate-x: -50%;
}

.profile-sidebar-email-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  height: 100vh;
  width: 100vw;
  z-index: 100;
  -webkit-backdrop-filter: blur(4px) brightness(70%);
  backdrop-filter: blur(4px) brightness(70%);
  > .profile-sidebar-email-download {
    background-color: var(--background-secondary-color);
    font-size: 24px;
    width: 400px;
    padding-inline: 44px;
    padding-bottom: 62px;
    position: relative;
    display: flex;
    flex-direction: column;
    > .sidebar-close-btn {
      position: absolute;
      top: 0;
      right: 0;
      width: 40px;
      height: 40px;
      background-color: var(--border-primary-color);
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom-left-radius: 10px;
      cursor: pointer;
    }
    .profile-sidebar-content {
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      > .profile-sidebar-logo {
        margin: 0 auto;
        margin-top: 50px;
        margin-bottom: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 72px;
        width: 72px;
        border-radius: 12px;
        background-color: var(--background-primary-color);
        border: var(--border-primary);
        img {
          height: 56px;
        }
      }
      > .profile-sidebar-company-name {
        margin-bottom: 40px;
        margin-top: 25px;
        color: var(--text-primary-color);
        font-family: Rubik;
        font-size: 24px;
        letter-spacing: 0.5px;
        line-height: 28px;
        text-align: center;
      }
      > .profile-sidebar-title {
        margin-bottom: 12px;
        color: var(--text-primary-color);
        font-family: Rubik;
        font-size: 16px;
        letter-spacing: 0.5px;
        line-height: 28px;
        text-align: center;
      }

      > .profile-company-credentials {
        display: grid;
        padding: 16px;
        gap: 16px;
        border: var(--border-primary);
        border-radius: 12px;
        .input-container {
          flex: 1 0 48%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;
          input {
            flex: 1;
            background-color: rgba(var(--text-primary-color-rgb), 0.09);
            color: var(--text-primary-color);
            border-top-right-radius: 4px;
            border-top-left-radius: 4px;
            border: none;
            outline: none;
            display: flex;
            padding-inline: 16px;
            padding-top: 20px;
            padding-bottom: 4px;
            font-family: Rubik;
            font-size: 16px;
            letter-spacing: 0.5px;
            line-height: 28px;
            &:has(+ .password-icon) {
              padding-inline-end: 44px;
            }
          }
          input:not([value='']):not(:placeholder-shown) ~ .password-icon {
            display: block;
          }

          input:-webkit-autofill,
          input:-webkit-autofill:hover,
          input:-webkit-autofill:focus,
          input:-webkit-autofill:active {
            -webkit-background-clip: text;
            background-clip: text;
            -webkit-text-fill-color: var(--text-secondary-color) !important;
          }
          input:focus ~ label,
          input:not(:placeholder-shown) ~ label {
            color: var(--text-secondary-color);
            font-size: 12px;
            letter-spacing: 0.4px;
            line-height: 16px;
            transform: translateY(6px);
          }
          .line {
            position: absolute;
            height: 2px;
            width: 100%;
            background: transparent;
            inset-inline-start: 50%;
            bottom: 0;
          }
          .line:after {
            content: '';
            display: block;
            width: 0;
            background: var(--text-primary-color);
            height: 2px;
            transition: all 0.5s;
            inset-inline-start: 50%;
            bottom: 0;
            transform: translateX(var(--line-after-translate-x));
          }
          input:focus ~ .line:after,
          input:not(:placeholder-shown) ~ .line:after {
            width: 100%;
            transform-origin: 50% 0%;
          }
          .password-icon {
            position: absolute;
            inset-inline-end: 0;
            top: 0;
            transform: translate(var(--password-icon-translate-x), 25px);
            cursor: pointer;
            display: none;
            font-size: 16px;
          }
          label {
            color: var(--text-primary-color);
            font-size: 16px;
            letter-spacing: 0.5px;
            line-height: 28px;
            margin-inline: 16px;
            position: absolute;
            inset-inline-start: 0;
            top: 0;
            transition: all 0.3s ease-in;
            transform: translateY(16px);
            pointer-events: none;
          }
          input.warn {
            background-color: rgba(var(--trend-down-color-rgb), 0.17) !important;
          }
          label.warn {
            color: var(--trend-down-color) !important;
          }
          .line.warn:after {
            background: var(--trend-down-color) !important;
          }
        }
      }
      .profile-sidebar-company-instructions {
        //background-color: aqua;
        flex: 1;
        overflow: auto;
        margin-bottom: 10px;
      }
      .profile-sidebar-company-instructions-btn {
        margin-top: auto;
        display: grid;
        button {
          height: 36px;
          border-radius: 27px;
          font-family: 'Rubik';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.4px;
          text-transform: uppercase;
          color: var(--text-primary-color);
          cursor: pointer;
          background-color: var(--trend-up-color);
          border: none;
          display: flex;
          align-items: center;
          justify-content: center;
          &:disabled {
            opacity: 0.3;
            cursor: default;
          }
        }
      }
    }
  }
}
