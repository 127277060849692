.customer-totals-card {
  display: flex;
  > .customer-totals-card-content-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 348px;
    flex: 1;
    > section {
      padding: 16px;
      .customer-totals-card-value-container {
        display: flex;
        align-items: flex-start;
        > div {
          flex: 1;
          > p:first-child {
            font-size: 12px;
            line-height: 20px;
            letter-spacing: 0.4px;
            color: var(--text-secondary-color);
          }
          > p + p {
            font-size: 34px;
            font-weight: 500;
            line-height: 42px;
            color: var(--text-primary-color);
            letter-spacing: 0.25px;
            > span.currency {
              font-size: 16px;
              line-height: 28px;
              letter-spacing: 0.8125px;
              color: var(--text-secondary-color);
              margin-inline: 5px;
            }
          }
        }
      }
      .customer-totals-card-value-remark {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
      }
      .customer-totals-card-diff {
        display: flex;
        align-items: center;
        min-height: 32px;
        span {
          font-size: 14px;
          font-weight: 500;
          line-height: 22px;
          letter-spacing: 0.1px;
          &.customer-totals-card-diff-currency {
            font-size: 12px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.4px;
          }
        }
      }
    }
    > section:first-child {
      background-color: var(--border-primary-color);
      flex: 1;
      > div:first-child {
        display: flex;
        gap: 16px;
        align-items: center;
        margin-bottom: 16px;
        > .totals-card-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 56px;
          width: 56px;
          border-radius: 8px;
          background-color: rgba(var(--text-primary-color-rgb), 0.17);
        }
        div {
          > p {
            font-size: 20px;
            line-height: 20px;
            letter-spacing: 0.5px;
          }
        }
      }
    }
    > section:nth-child(2) {
      background-color: rgba(var(--border-primary-color-rgb), 0.6);
    }
    &:first-child {
      > section:first-child {
        border-start-start-radius: 20px;
      }
      > section:last-child {
        border-end-start-radius: 20px;
      }
    }
    &:last-child {
      > section:first-child {
        border-start-end-radius: 20px;
      }
      > section:last-child {
        border-end-end-radius: 20px;
      }
    }
    &:not(:first-child):last-child {
      border-inline-start: 1px solid rgba(var(--text-primary-color-rgb), 0.2);
    }
  }
}

[dir='ltr'] .customer-totals-card {
  button {
    transform: scaleX(-1);
  }
}
