.landing-irregs {
  padding-top: 100px;
  padding-bottom: 100px;

  p,
  h5 {
    margin: 0;
    padding: 0;
    font-weight: 400;
    white-space: pre-line;
  }

  .landing-irregs-desc {
    max-width: 500px;

    > .landing-irregs-tagline {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.25px;
      color: var(--trend-up-color);
      margin-bottom: 26px;
    }

    > .landing-irregs-title {
      font-size: 48px;
      line-height: 57px;
      margin-bottom: 15px;
    }

    > .landing-irregs-subtitle {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 61px;
    }

    .landing-irregs-cards {
      display: grid;
      grid-template-rows: repeat(3, 1fr);
      gap: 16px;

      > .landing-irregs-card {
        flex: 1;
        background: var(--background-secondary-color);
        box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.24);
        border-radius: 8px;
        padding: 20px;
        display: flex;
        gap: 24px;

        > .landing-irregs-card-icon {
        }

        > .landing-irregs-card-content {
          > .landing-irregs-card-title {
            font-weight: 500;
            font-size: 18px;
            line-height: 26px;
            letter-spacing: 0.5625px;
            white-space: pre-line;
          }

          > .landing-irregs-card-text {
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 0.5px;
            color: var(--text-secondary-color);
          }
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .landing-irregs {
    .landing-irregs-desc {
      max-width: 439px;

      & > .landing-irregs-subtitle {

      }
    }
  }
}

@media screen and (max-width: 767px) {
  .landing-irregs {
    padding: 51px 0px;
    background-position: 3% 26% !important;
    background-size: 103% !important;

    .landing-irregs-desc {
      width: 100%;
      max-width: none;
      padding: 1px 16px;

      & > .landing-irregs-tagline {
        font-size: 14px;
        text-align: center;
        margin-bottom: 13px;
      }

      & > .landing-irregs-title{
        font-size: 30px;
        line-height: 34px;
        text-align: center;
      }

      & > .landing-irregs-subtitle{
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        margin-bottom: 289px;
      }

      .landing-irregs-cards {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 328px;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin: 0 auto;
        gap: 20px;

        & > .landing-irregs-card {
          flex-direction: column;
          padding: 28px 19px;
          gap: 3px;
          border-radius: 20px;

          & > .landing-irregs-card-content {
            & > .landing-irregs-card-title {
              font-size: 16px;
            }

            & > .landing-irregs-card-text {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}