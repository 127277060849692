.trend-container {
  display: flex;
  align-items: baseline;
  gap: 10px;
}

.trend-container.xs {
  gap: 6px;
}

.trend-container > .trend-value-container {
  display: flex;
  align-items: baseline;
  gap: 4px;
}

.trend-container.xs > .trend-value-container {
  gap: 1px;
}

.trend-container > .trend-value-container > .trend-value {
  color: var(--text-primary-color);
  font-family: 'Montserrat';
  font-size: 26px;
  font-weight: bold;
  letter-spacing: 0.81px;
  line-height: 28px;
}

.trend-container > .trend-value-container > .trend-value > span {
  color: var(--text-secondary-color);
  font-family: 'Montserrat';
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.63px;
  line-height: 28px;
}

.trend-container.xs > .trend-value-container > .trend-value {
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.44px;
  line-height: 16px;
}

.trend-container > .trend-value-container > .trend-currency {
  color: var(--text-secondary-color);
  font-family: 'Lucida Grande';
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.63px;
}

.trend-container.xs > .trend-value-container > .trend-currency {
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0.31px;
  line-height: 14px;
}

.trend-container > .trend-icon {
  display: flex;
}

.trend-container.xs > .trend-icon {
  display: flex;
  height: 10px;
}
