.chip {
  width: 80px;
  height: 20px;
  border-radius: 4px;
  font-family: Rubik;
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 16px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 4px;
  padding-right: 4px;
}

.chip:not(:only-child) {
  width: 50px;
}

.chip.red {
  color: var(--text-primary-color);
  background-color: var(--red-client);
}

.chip.orange {
  color: var(--background-primary-color);
  background-color: var(--orange-client);
}

.chip.green {
  color: var(--text-primary-color);
  background-color: var(--green-client);
}

.chip.white {
  color: var(--background-primary-color);
  background-color: var(--text-primary-color);
}

.chip.red-faded {
  color: var(--red-client);
  background-color: var(--irregular-client);
}

.chip.grey {
  color: var(--text-primary-color);
  background-color: var(--border-primary-color);
}