.early-access-success-modal {
  padding: 16px;
  min-height: 263px;
  min-width: 448px;
  > .early-access-success-icon {
    pointer-events: none;
    display: flex;
    width: 105px;
    margin: -68px auto 1px;
    img{
      width: 100%;
    }
  }
  > .early-access-success-header {
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.5px;
    margin-bottom: 16px;
  }
  > .early-access-success-modal-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Rubik', sans-serif;
    h3{
      margin: 31px 0 1px;
    }
    p{
      margin: 1px;
    }
    a{
      display: flex;
      justify-content: center;
      font-family: 'Rubik', sans-serif;
      align-content: center;
      text-decoration: none;
      background: #02B64A;
      color: white;
      border-radius: 51px;
      margin-top: 32px;
      width: 145px;
      height: 40px;
      padding-top: 10px;
    }
  }
}

@media screen and (max-width: 768px) {
  .early-access-success-modal {
    min-width: 304px;
  }
}
