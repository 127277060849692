.customers-policies-totals {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 26px;
  height: 150px;
  &.loading {
    grid-template-columns: 1fr;
  }
  > section {
    cursor: pointer;
    border-radius: 20px;
    background-color: rgba(var(--border-primary-color-rgb), 0.38);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    > p {
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.4px;
    }
    > p:last-child {
      font-size: 26px;
      font-weight: 700;
      line-height: 28px;
      letter-spacing: 0.8125px;
    }
    > * {
      opacity: 0.6;
    }
  }
}
