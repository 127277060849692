.workstation-task-agent-menu-container {
  width: 160px;
  > .workstation-task-agent-menu {
    min-height: 108px;
    max-height: 304px;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 4px;
    background-color: var(--background-primary-color);
    box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12),
      0 5px 5px -3px rgba(0, 0, 0, 0.2);
    cursor: default;
    overflow: auto;
    > .workstation-task-agent-item-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 48px;
      padding-left: 16px;
      padding-right: 16px;
      > .workstation-task-agent-item-text {
        color: var(--text-primary-color);
        font-family: 'Rubik';
        font-size: 16px;
        letter-spacing: 0.5px;
        line-height: 20px;
      }
      &:not(.current) {
        cursor: pointer;
      }
      &:hover {
        background: var(--card-background-primary-color);
      }
    }
    > .workstation-task-agent-menu-loader-overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      border-radius: 4px;
      backdrop-filter: blur(2px);
      z-index: 1;
    }
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
    }
    &::-webkit-scrollbar-thumb {
      background: var(--card-background-selected);
      padding-inline-end: 3px;
      border-inline-end: 3px solid transparent;
      background-clip: padding-box;
      border-radius: 3px;
      border-end-end-radius: 7px;
      border-start-end-radius: 7px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: var(--text-secondary-color);
      padding-inline-end: 3px;
      border-inline-end: 3px solid transparent;
      background-clip: padding-box;
      border-radius: 3px;
      border-end-end-radius: 7px;
      border-start-end-radius: 7px;
    }
  }
}
