.policy-card-actions-list {
  .lined-title {
    margin-bottom: 8px;
  }
  [class*='-grid'] {
    display: grid;
    align-items: center;
    > div {
      padding-inline: 16px;
    }
  }
  .policy-card-actions-default-grid {
    grid-template-columns: 5fr 4fr 1fr 1fr;
  }
  .policy-card-actions-irreg-grid {
    grid-template-columns: 3fr 3fr 1fr 1fr 1fr;
  }
  .policy-card-actions-category-grid {
    grid-template-columns: 2fr 1fr 2fr 4fr 1fr 1fr;
  }
  .actions-comments-cell {
    height: 40px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding-inline: 8px;
    background: var(--background-primary-color);
  }
  .actions-category-arrow-cell {
    color: var(--trend-up-color);
    font-size: 20px;
    justify-self: flex-end;
  }
  .border-inline-start-cell {
    border-inline-start: 1px solid var(--text-third-color);
  }
  .border-inline-end-cell {
    border-inline-end: 1px solid var(--text-third-color);
  }
}
