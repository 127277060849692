.no-layout-container {
  height: 100svh;
  display: flex;
  flex-direction: column;
  font-family: 'Rubik';
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.15px;
  > .no-layout-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}
