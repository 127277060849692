.tasks-search-button-mobile {
  display: none;
}

@media screen and (max-width: 767px) {
  .tasks-search-button-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: none;
    padding: unset;
  }
}
