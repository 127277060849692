.scan-screen {
  display: grid;
  grid-template-columns: auto 1fr;
  padding: 24px;
  gap: 24px;
  height: 100vh;
  overflow: hidden;
  .scan-screen-main {
    width: clamp(80%, 100%, 1080px);
    margin-inline: auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
}
