.employees-container {
  font-family: Rubik;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  margin-bottom: 30px;
  > .employees-header {
    margin-bottom: 8px;
    display: flex;
    > .employees-header-title {
      color: var(--text-secondary-color);
      margin-inline-end: auto;
      padding: 0 8px;
    }
    > .employees-header-action {
      margin-inline-start: auto;
      display: flex;
      gap: 10px;
      cursor: pointer;
      > .employees-header-action-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        > img {
          height: 17px;
        }
      }
      > .employees-header-action-text {
        color: var(--trend-up-color);
      }
      &:active > .employees-header-action-icon {
        > img {
          transform: scale(0.9);
        }
      }
    }
  }
  > .employees-card-container {
    border: var(--border-primary);
    border-radius: 20px;
    background-color: var(--card-background-third-color);
    padding: 20px;
    min-height: 170px;
    display: flex;
    flex-direction: column;
    .employees-card-grid {
      display: grid;
      grid-template-columns: 50px 20fr 17fr 14fr 29fr 48px;
    }
    > .employees-card-header {
      color: var(--text-secondary-color);
      font-size: 12px;
      letter-spacing: 0.4px;
      line-height: 16px;
      margin-bottom: 8px;
    }
    > .employees-card-no-data-container-wrapper {
      flex: 1;
      display: flex;
    }
  }
}
.notify-modals-wrapper {
  position: fixed;
  bottom: 26px;
  left: 26px;
  z-index: 50;
  > .notify-modals-container {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}
