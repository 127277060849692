.customer-add-family-member {
  display: flex;
  width: 18px;
  height: 18px;
  position: relative;
  cursor: pointer;
}

.customer-add-family-member>.icon-tooltip {
  position: absolute;
  visibility: hidden;
  width: 110px;
  border-radius: 4px;
  padding: 4px 12px;
  background-color: var(--border-primary-color);
  color: var(--text-primary-color);
  font-family: Rubik;
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 16px;
  text-align: center;
  top: -100%;
  left: 50%;
  transform: translate(-50%, -100%);
  opacity: 0;
  transition: opacity 1s;
}

.customer-add-family-member:not(.active):hover>.icon-tooltip {
  visibility: visible;
  opacity: 1;
}

.customer-add-family-member:not(.active):hover>.icon-background-hover {
  position: absolute;
  background-color: var(--trend-up-color);
  opacity: 0.17;
  width: 36px;
  height: 36px;
  border-radius: 12px;
  transform-origin: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.customer-add-family-member:hover>img,
.customer-add-family-member.active>img {
  filter: invert(57%) sepia(58%) saturate(5102%) hue-rotate(115deg) brightness(95%) contrast(102%);
}