.landing-contacts {
  background-color: #141720;
  padding-top: 60px;
  padding-bottom: 60px;
  font-family: 'Rubik', sans-serif;
}

.landing-contacts .landing-section-container {
  display: flex;
  width: 100%;
}

.landing-contacts .landing-contacts-container {
  display: flex;
  width: 100%;
}

.landing-contacts .landing-contacts-form-container {
  width: 360px;
}

.landing-contacts .landing-contacts-form-container .landing-contacts-form-header {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 22px;
  margin-bottom: 28px;
  width: 300px;
}

.landing-contacts .landing-contacts-form-container .landing-contacts-form {
  display: flex;
  flex-direction: column;
}

.landing-contacts .landing-contacts-form-container .landing-contacts-form .landing-contacts-form-inputs {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 12px;
}

.landing-contacts .landing-contacts-form-container .landing-contacts-form .landing-contacts-form-inputs input,
.landing-contacts .landing-contacts-form-container .landing-contacts-form .landing-contacts-form-inputs textarea {
  font-family: 'Rubik';
  color: var(--text-primary-color);
  padding: 12px 16px;
  background-color: var(--card-background-primary-color);
  border-radius: 8px;
  border: none;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 28px;
}

.landing-contacts .landing-contacts-form-container .landing-contacts-form .landing-contacts-form-inputs textarea {
  line-height: 20px;
  min-height: 72px;
}

.landing-contacts .landing-contacts-form-container .landing-contacts-form button,
.landing-contacts .landing-contacts-form-container .landing-contacts-form a {
  border-radius: 24px;
  background-color: var(--trend-up-color);
  color: var(--text-primary-color);
  font-family: 'Rubik';
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 28px;
  text-align: center;
  border: none;
  padding: 10px 40px;
  margin-inline-start: auto;
  cursor: pointer;
  text-decoration: none;
}

.landing-contacts .landing-contacts-form-container .landing-contacts-form button:active,
.landing-contacts .landing-contacts-form-container .landing-contacts-form a:active {
  transform: scale(0.97);
}

.landing-contacts .landing-contacts-form-container .landing-contacts-form .landing-contacts-form-link {
  display: flex;
}

.landing-contacts .landing-contacts-form-container .landing-contacts-form button:disabled {
  background: none;
}

.landing-contacts .landing-contacts-nav-container .landing-contacts-navbar {
  margin-inline-start: 180px;
}

.landing-contacts .landing-contacts-nav-container .landing-contacts-navbar ul li:not(:last-of-type) {
  margin-bottom: 20px;
}

.landing-contacts .landing-contacts-nav-container .landing-contacts-navbar a {
  text-decoration: none;
  color: var(--text-primary-color);
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 28px;
  font-weight: 300;
  text-align: center;
}

.landing-contacts .landing-contacts-nav-container .landing-contacts-navbar a:hover {
  color: var(--text-secondary-color);
}

.landing-contacts .landing-contacts-logo-container {
  margin-inline-start: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: red;
}

.landing-contacts .landing-contacts-logo-container img {
  margin-bottom: 20px;
  height: 24px;
}

.landing-contacts .landing-contacts-logo-container div:nth-child(2) {
  margin-bottom: 11px;
  text-align: end;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.5px;
  color: var(--text-secondary-color);
}

.landing-contacts .landing-contacts-logo-container a {
  text-decoration: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.5px;
  color: var(--text-secondary-color);
}

@media screen and (max-width: 1460px) {
  .landing-container .landing-section-container {
    padding: 0 31px;
  }
  .landing-contacts .landing-contacts-wrapper {
    width: 935px;
  }
}

@media screen and (max-width: 991px) {
  .landing-contacts .landing-contacts-wrapper {
    width: 768px;
  }
  .landing-contacts .landing-contacts-nav-container .landing-contacts-navbar {
    margin-right: 91px;
  }
}

@media screen and (max-width: 767px) {
  .landing-contacts .landing-contacts-container {
    width: 100%;
    max-width: 328px;
    margin: 0 auto;
    padding: 1px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .landing-contacts-logo-container {
      order: 1;
      align-items: center;
      margin: 0;
      & > div {
        text-align: center;
      }
    }

    .landing-contacts-form-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      order: 2;
      width: 100%;
      text-align: center;
      margin: 54px 16px;
      .landing-contacts-form {
        width: 100%;
        .landing-contacts-form-inputs {
          width: 100%;
          margin-bottom: 24px;
        }
        .landing-contacts-form-link a {
          margin: 0 auto;
        }
      }
      .landing-contacts-form-header {
        font-size: 16px;
        width: 186px;
        margin-bottom: 16px;
      }
    }

    .landing-contacts-nav-container {
      order: 3;

      .landing-contacts-navbar {
        margin: 0;
        ul li {
          text-align: center;
        }
      }
    }
  }
  .landing-container .landing-section-container {
    padding: 0;
  }
}
