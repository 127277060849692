.agency-house-analytics-incomes-changes-table-container {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  header {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px; /* 171.429% */
    letter-spacing: 0.17px;
    margin-bottom: 12px;
    > div {
      align-items: flex-end;
    }
  }
  .agency-house-analytics-incomes-changes-table-grid {
    display: grid;
    grid-template-columns: 1fr 2fr 2fr;
    &.extended {
      grid-template-columns: 1fr 2fr 2fr 2fr;
    }
    &.shortened {
      grid-template-columns: 1fr 2fr;
    }
    &.split-view {
      grid-template-columns: 1fr 2fr 2fr 2fr 2fr;
    }
    > div {
      display: grid;
      align-items: center;
      padding-inline-start: 16px;
    }
    & > div:has(div + div) {
      grid-template-columns: 1fr 1fr 52px;
      gap: 8px;
      > div:has(button) {
        display: flex;
        align-items: center;
        justify-content: center;
        button {
          height: 20px;
          width: 20px;
        }
      }
    }
  }
  header.agency-house-analytics-incomes-changes-table-grid > * {
    align-items: flex-end;
  }
  .agency-house-analytics-incomes-changes-table {
    overflow: auto;
  }
}

.agency-house-analytics-incomes-changes-table-item {
  border-radius: 8px;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.17px;
  overflow: hidden;
  > div {
    height: 52px;
  }
  > div:first-child {
    background-color: rgba(var(--text-primary-color-rgb), 0.05);
  }
  > div:nth-child(2) {
    background-color: rgba(var(--text-primary-color-rgb), 0.08);
  }
  > div:nth-child(3) {
    background-color: rgba(var(--text-primary-color-rgb), 0.1);
  }
  > div:nth-child(4) {
    background-color: rgba(var(--text-primary-color-rgb), 0.15);
  }
  &:not(:last-child) {
    margin-bottom: 12px;
  }
  & div div:has(.commission-vat) {
    display: flex;
    flex-direction: column;
    .commission-vat {
      font-size: 12px;
      line-height: 10px;
      color: var(--text-secondary-color);
    }
  }
}

.split-view.agency-house-analytics-incomes-changes-table-item {
  > div:nth-child(2),
  > div:nth-child(3) {
    background-color: rgba(var(--text-primary-color-rgb), 0.08);
  }
  > div:nth-child(4),
  > div:nth-child(5) {
    background-color: rgba(var(--text-primary-color-rgb), 0.1);
  }
}

[dir='ltr'] .agency-house-analytics-incomes-changes-table-grid {
  button {
    img {
      transform: scaleX(-1);
    }
  }
}

::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-thumb {
  border: 2px solid transparent;
  background-color: var(--border-primary-color);
  border-top: none;
  border-bottom: none;
  border-radius: 4px;
  border-inline-start-width: 4px;
  border-start-start-radius: 6px;
  border-end-start-radius: 6px;
  background-clip: padding-box;
}
