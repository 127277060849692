.agency-house-analytics-incomes-chart-container {
  &:not(:last-child) {
    margin-bottom: 40px;
  }
  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 11px;
    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.15px;
      span.total-value {
        font-weight: 500;
        line-height: 28px;
      }
    }
  }
  > .agency-house-analytics-incomes-chart-axis-wrapper {
    direction: ltr;
    display: flex;
    border-radius: 8px;
    background-color: var(--border-primary-color);
    padding-inline-end: 32px;
    &.no-chart-data {
      padding: 0;
      height: 265px;
      align-items: center;
      justify-content: center;
    }
    .agency-house-analytics-incomes-chart-x-labels {
      color: red;
      fill: var(--text-secondary-color);
      font-family: Rubik !important;
      font-size: 14px !important;
      font-weight: 400 !important;
      line-height: 143% !important; /* 20.02px */
      letter-spacing: 0.17px !important;
      cursor: pointer;
      &:hover {
        fill: var(--text-primary-color);
      }
    }
    .agency-house-analytics-incomes-chart-y-labels {
      fill: var(--text-secondary-color);
      font-family: Rubik !important;
      font-size: 9px !important;
      font-style: normal !important;
      font-weight: 400 !important;
      line-height: normal !important;
    }
    .apexcharts-backgroundBar {
      visibility: hidden;
      width: 116px !important;
      transform: translateX(-38px) scaleY(2) !important;
      &:has(+ .apexcharts-bar-area[selected='true']) {
        visibility: visible;
      }
    }
    .apexcharts-bar-area:not([selected='true']) {
      cursor: pointer;
    }
    .agency-house-analytics-incomes-chart-wrapper {
      overflow: auto;
      overflow-y: hidden;
      flex: 1;
    }
    .agency-house-analytics-incomes-chart-yaxis-wrapper {
      .agency-house-analytics-incomes-chart-x-labels {
        pointer-events: none;
        fill: red;
        visibility: hidden;
      }
    }
  }
}

::-webkit-scrollbar:horizontal {
  height: 14px;
  cursor: pointer;
}
::-webkit-scrollbar-track:horizontal {
}
::-webkit-scrollbar-thumb:horizontal {
  border: 4px solid transparent;
  background-color: rgba(var(--text-primary-color-rgb), 0.3);
  cursor: pointer;
  border-radius: 8px;
  background-clip: padding-box;
}

@media screen and (max-width: 1366px) {
  .agency-house-analytics-incomes-chart-container {
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
}
