.profile-add-user-modal {
  padding: 24px;
  width: 724px;
  font-family: Rubik;
  > form {
    > .profile-add-user-modal-header {
      margin-bottom: 16px;
      position: relative;
      > h3 {
        margin: 0;
        padding: 0;
        font-weight: normal;
        color: var(--text-primary-color);
        font-size: 16px;
        letter-spacing: 0.5px;
        line-height: 20px;
      }
      > .profile-add-user-modal-error {
        position: absolute;
        color: red;
      }
    }
    > .profile-add-user-modal-content {
      margin-bottom: 24px;
      > .profile-add-user-modal-inputs-group-container {
        .profile-add-user-modal-inputs-group-title {
          margin-bottom: 12px;
          color: var(--text-secondary-color);
          font-size: 12px;
          letter-spacing: 0.4px;
          line-height: 16px;
        }
        > .profile-add-user-modal-inputs-group-row {
          display: flex;
          gap: 10px;
          &:not(:last-child) {
            margin-bottom: 20px;
          }
          > .input-text-wrapper,
          > .input-text-dir-wrapper,
          > .input-select-container,
          > .profile-add-user-modal-input-wrapper,
          > .input-select-multi-container,
          > .input-select-advanced-container {
            flex: 1;
          }
          > .profile-add-user-modal-input-wrapper {
            position: relative;
          }
          > .input-text-dir-wrapper {
            direction: ltr;
          }
        }
        > .profile-add-user-modal-inputs-group-toggles-container {
          background-color: #31394f;
          padding: 24px;
          display: flex;
          border-radius: 8px;
          gap: 70px;
          > .profile-add-user-modal-inputs-group-toggles {
            > .profile-add-user-modal-inputs-group-check {
              > .add-user-modal-toggle {
                display: flex;
                gap: 14px;
                align-items: center;
                &:not(:last-child) {
                  margin-bottom: 14px;
                }
                label {
                  cursor: pointer;
                }
              }
            }
          }
        }
        &:not(:last-child) {
          margin-bottom: 24px;
        }
      }
    }
    > .profile-add-user-modal-footer {
      display: flex;
      justify-content: flex-end;
      .button {
        border-radius: 18px;
      }
    }
  }
  .noagents-groups-placeholder-container {
    padding: 16px 50px 18px;
    > p {
      margin: 0;
      padding: 0;
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.17px;
      &.noagents-groups-placeholder {
        color: var(--text-secondary-color);
        margin-bottom: 8px;
      }
    }
  }
  .agent-groups-default-item {
    background: rgba(39, 45, 62, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
    > button {
      background-color: transparent;
      //border: 1px solid blue;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      padding: 0;
      > .agent-groups-default-item-btn-icon {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      > .agent-groups-default-item-btn-text {
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: var(--trend-up-color);
      }
    }
  }
}
.profile-add-user-modal-loader-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  border-radius: 8px;
  backdrop-filter: blur(2px);
  z-index: 1;
}

@media screen and (max-height: 767px) {
  .profile-add-user-modal {
    > form {
      > .profile-add-user-modal-content {
        > .profile-add-user-modal-inputs-group-container {
          .profile-add-user-modal-inputs-group-title {
            margin-bottom: 8px;
          }
          > .profile-add-user-modal-inputs-group-toggles-container {
            gap: 50px;
            padding: 16px;
            > .profile-add-user-modal-inputs-group-toggles {
              > .profile-add-user-modal-inputs-group-check {
                display: grid;
                grid-template-columns: min-content min-content;
                gap: 10px;
                > .add-user-modal-toggle {
                  gap: 4px;
                  margin-inline-end: 6px;
                  label {
                    white-space: nowrap;
                  }
                  &:not(:last-child) {
                    margin-bottom: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
