.agent-house-avatar-container {
  display: flex;
  margin-bottom: 20px;
  div {
    height: 120px;
    width: 120px;
    border-radius: 8px;
    background-color: rgba(var(--text-primary-color-rgb), 0.17);
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(var(--text-primary-color-rgb), 0.5);
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0.15px;
  }
}
.agent-house-form-body {
  display: flex;
  flex-direction: column;
  h5 {
    color: var(--text-primary-color);
    font-size: 16px;
    font-weight: 500;
    line-height: 175%;
    letter-spacing: 0.15px;
  }
  p {
    color: var(--text-secondary-color);
    font-size: 12px;
    line-height: 166%;
    letter-spacing: 0.4px;
  }
  .agent-house-form-inputs {
    margin-bottom: 48px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: relative;
  }
  .agent-house-form-inputs-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 24px;
  }
  .agent-house-form-radios {
    margin-bottom: 18px;
    h5 {
      margin-bottom: 14px;
    }
    .MuiFormControlLabel-root {
      color: red;
      margin-top: 0;
      span {
        color: var(--text-primary-color);
        font-size: 14px;
        font-family: Rubik;
        line-height: 143%;
        letter-spacing: 0.17px;
        span {
          div {
            svg {
              fill: var(--trend-up-color);
            }
          }
        }
        &:not(.Mui-checked) {
          span {
            div {
              svg:first-child {
                fill: var(--text-secondary-color);
              }
            }
          }
        }
      }
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }
  .agent-house-form-error {
    position: absolute;
    bottom: 0;
    transform: translateY(calc(100% + 4px));
    inset-inline-start: 0;
    font-size: 12px;
    color: red;
  }
}

@media screen and (max-height: 767px) {
  .agent-house-form-container {
    .agent-house-avatar-container {
      margin-bottom: 12px;
    }
    .agent-house-form-body {
      .agent-house-form-inputs {
        margin-bottom: 12px;
        gap: 8px;
      }
      .agent-house-form-radios {
        margin-bottom: 10px;
        h5 {
          margin-bottom: 8px;
        }
        .MuiFormControlLabel-root {
          &:not(:last-child) {
            margin-bottom: 2px;
          }
        }
      }
    }
  }
}
