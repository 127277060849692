.customer-main-container {
  padding: 26px;
  display: grid;
  grid-template-rows: min-content 1fr;
  background-color: rgba(var(--background-secondary-color-rgb), 0.6);
  > *:last-child {
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
}
