.apexcharts-text.apexcharts-datalabel-label,
.apexcharts-text.apexcharts-datalabel-value {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.625px;
  fill: var(--text-primary-color)
}