.customer-tables-navbar {
  border-bottom: var(--border-primary);
  > ul {
    display: flex;
    > li {
      padding: 15px 20px 15px;
      color: var(--text-secondary-color);
      border-bottom: 2px solid transparent;
      font-family: Rubik;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 1.25px;
      line-height: 16px;
      text-align: center;
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 4px;
    }
    > li.active {
      border-bottom-color: var(--trend-up-color);
      color: var(--trend-up-color);
      cursor: default;
    }
    > li:not(.active):not(.disabled):hover {
      color: var(--text-primary-color);
    }
    > li.disabled {
      cursor: default;
    }
    > li.blured {
      filter: blur(3px);
    }
  }
}
