.customers-group-details-sidebar {
  width: 100%;
  border-inline-start: var(--border-primary);
  display: flex;
  flex-direction: column;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  button {
    cursor: pointer;
    background: transparent;
    border: none;
    transition: all 0.25s ease-in-out;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    color: var(--text-primary-color);
    padding: 0;
    display: flex;
    align-items: center;
    &:active:not(:disabled) {
      transform: scale(0.95);
    }
    &:disabled {
      opacity: 0.2;
      cursor: default;
    }
  }
  > .customers-group-details-sidebar-main {
    padding: 50px 24px 24px;
    border-bottom: var(--border-primary);
    > .customers-group-details-sidebar-header {
      margin-bottom: 32px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      > .customers-group-details-sidebar-group-name-type {
        > .customers-group-details-sidebar-group-type {
          font-size: 14px;
          line-height: 17px;
          letter-spacing: 0.25px;
          color: var(--text-secondary-color);
          margin-bottom: 1px;
        }
        > .customers-group-details-sidebar-group-name {
          font-size: 20px;
          line-height: 24px;
          letter-spacing: 0.25px;
          display: flex;
          gap: 10px;
          > button {
            > div:last-child {
              height: 24px;
              width: 24px;
              display: flex;
              align-items: center;
              justify-content: center;
              > img {
                height: 16px;
              }
            }
          }
        }
      }
      > .customers-group-details-sidebar-group-categories {
        > button {
          justify-content: space-between;
          gap: 8px;
          border-radius: 18px;
          padding: 6px 16px;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.4px;
          text-transform: uppercase;
          background-color: rgba(var(--trend-up-color-rgb), 0.38);
          > div {
            display: flex;
            align-items: center;
            justify-content: center;
            &:first-child {
              width: 18px;
              height: 18px;
              > img {
                height: 100%;
                width: 100%;
              }
            }
          }
        }
      }
    }
    > .customers-group-details-sidebar-charts {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 8px;
      position: relative;
      > .customers-group-details-sidebar-charts-loading-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        border-radius: 8px;
        backdrop-filter: blur(4px);
        z-index: 1;
      }
      > .customers-group-details-sidebar-chart {
        // background-color: green;
        height: 156px;
      }
    }
  }
  > .customers-group-details-sidebar-actions {
    border-bottom: var(--border-primary);
    display: flex;
    > div:not(:last-child) {
      border-inline-end: var(--border-primary);
    }
    > .customers-group-details-sidebar-search {
      flex: 1;
      .policies-search-input-container {
        margin-inline: unset;
        height: 100%;
        > input {
          height: 100%;
          border-radius: unset;
          background: transparent;
        }
      }
    }
    > .customers-group-details-sidebar-add-member {
      padding: 14px 24px;
      > button {
        font-size: 14px;
        line-height: 24px;
        justify-content: center;
        letter-spacing: 0.4px;
        text-transform: uppercase;
        color: var(--trend-up-color);
        padding: 6px 16px;
      }
    }
  }
  > .customers-group-details-sidebar-customers-list-container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding-top: 28px;
    padding-inline: 24px;
    > .customers-group-details-sidebar-customers-list-header {
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      color: var(--text-secondary-color);
      margin-bottom: 8px;
    }
    > .customers-group-details-sidebar-customers-list {
      overflow: auto;
      > .customers-group-details-sidebar-customers-item {
        height: 48px;
        background: rgba(49, 57, 79, 0.38);
        border-radius: 8px;
        margin-bottom: 8px;
        align-items: center;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;
        position: relative;
        > div:has(.customers-group-details-sidebar-customers-item-rank) {
          display: flex;
          .customers-group-details-sidebar-customers-item-rank {
            display: flex;
            align-items: center;
            gap: 4px;
            font-size: 13px;
            line-height: 16px;
            letter-spacing: 0.4px;
            background: var(--text-third-color);
            border-radius: 4px;
            padding: 2px 6px;
          }
        }
        > .customers-group-details-sidebar-customers-item-actions {
          display: flex;
          align-items: center;
          gap: 12px;
          > div {
            height: 20px;
            width: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            > * {
              height: 100%;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
        .customers-group-details-sidebar-customers-item-loading-overlay {
          position: absolute;
          width: 100%;
          height: 100%;
          display: flex;
          border-radius: 8px;
          backdrop-filter: blur(4px);
          z-index: 1;
        }
      }
    }
  }
  .customers-group-details-sidebar-customers-list-grid {
    display: grid;
    grid-template-columns: 1fr 0.7fr 1fr 52px;
    padding-inline: 16px;
  }
}

.customers-group-details-sidebar-error {
  flex: 1;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  .no-data-container {
    flex: unset;
  }
}
