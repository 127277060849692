.notify-popup-container {
  color: red;
  width: 450px;
  min-height: 300px;
  max-height: 600px;
  display: flex;
  flex-direction: column;
  padding: 24px;
  color: var(--text-primary-color);
  font-family: 'Rubik';
  letter-spacing: 0.25px;
  text-align: center;
  > .notify-popup-icon {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    transform: translateY(-50%);
  }
  > .notify-popup-subject {
    margin-top: 90px;
    margin-bottom: 10px;
    > h3 {
      margin: 0;
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
    }
  }
  > .notify-popup-body {
    > p {
      margin: 0;
      color: var(--text-secondary-color);
      font-size: 14px;
      line-height: 20px;
    }
  }
  > .notify-popup-btns {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    margin-top: auto;
  }
}

.notify-popup-service {
  color: red;
}
