.input-select-associate-container {
  position: relative;
  // width: 100%;
  // overflow: hidden;
  > .input-select-associate-value-container {
    border-radius: 10px;
    overflow: hidden;
    font-family: Rubik;
    > .input-select-associate-value {
      height: 56px;
      color: var(--text-primary-color);
      font-size: 16px;
      letter-spacing: 0.5px;
      line-height: 28px;
      cursor: pointer;
      background-color: var(--border-primary-color);
      filter: brightness(90%);
      display: flex;
      align-items: center;
      padding-right: 16px;
      padding-left: 16px;
      > .input-select-associate-value-arrow-wrapper {
        margin-inline-start: auto;
        padding-inline-start: 12px;
        > .input-select-associate-value-arrow {
          width: 10px;
          height: 5px;
          background-color: var(--text-primary-color);
          -webkit-clip-path: polygon(100% 0%, 0 0%, 50% 100%);
          clip-path: polygon(100% 0%, 0 0%, 50% 100%);
          &.flipped {
            transform: rotate(180deg);
          }
        }
      }
      > .input-select-associate-value-wrapper {
        display: flex;
        align-items: center;
        overflow: hidden;
        padding-top: 12px;
        gap: 8px;
        > .input-select-associate-value-content {
          cursor: default;
          height: 28px;
          background: rgba(255, 255, 255, 0.17);
          border: 1px solid rgba(255, 255, 255, 0.38);
          border-radius: 100px;
          white-space: nowrap;
          display: flex;
          align-items: center;
          gap: 8px;
          padding-inline: 5px;
          > .input-select-associate-value-icon {
            display: flex;
            align-items: center;
            justify-content: center;
          }
          > .input-select-associate-value {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 55px;
          }
          > .input-select-associate-value-dlt {
            > button {
              height: 18px;
              width: 18px;
            }
          }
        }
      }
      > .input-select-associate-value-label {
        color: var(--text-primary-color);
        font-size: 16px;
        letter-spacing: 0.5px;
        line-height: 20px;
        position: absolute;
        inset-block-start: 12px;
        bottom: 50%;
        transition: all 0.3s ease-in;
        transform: translateY(50%);
      }
      > .line {
        position: absolute;
        height: 2px;
        width: 100%;
        background: transparent;
        left: 0;
        bottom: 0;
        transition: transform 250ms ease-in-out;
        &:after {
          content: '';
          display: block;
          width: 100%;
          background: var(--trend-up-color);
          height: 2px;
          transition: all 0.5s;
          left: 0;
          bottom: 0;
          transform: scaleX(0);
        }
      }
    }
    &:focus:not(.has-values) {
      > .input-select-associate-value {
        > .input-select-associate-value-label {
          font-size: 16px;
          letter-spacing: 0.5px;
          line-height: 20px;
          color: var(--trend-up-color);
          bottom: 50%;
          transform: translateY(50%);
        }
      }
    }
    &:focus {
      outline: none;
      > .input-select-associate-value {
        background-color: var(--border-primary-color);
        filter: brightness(110%);
        > .input-select-associate-value-label {
          color: var(--trend-up-color);
          // font-size: 12px;
          // letter-spacing: 0.4px;
          // line-height: 16px;
          // bottom: 50%;
          // transform: translateY(calc(-100% + 6px));
        }
        > .line:after {
          transform: scaleX(1);
        }
      }
    }

    &:not(:focus).has-values {
      > .input-select-associate-value {
        > .input-select-associate-value-label {
          color: var(--text-primary-color);
          // font-size: 12px;
          // letter-spacing: 0.4px;
          // line-height: 16px;
          // bottom: 50%;
          // transform: translateY(calc(-100% + 6px));
        }
        > .line:after {
          background: var(--text-primary-color);
          transform: scaleX(1);
        }
      }
    }
    > .input-select-associate-options-wrapper {
      --options-container-height: 256px;
      background-color: var(--border-primary-color);
      max-height: var(--options-container-height);
      border-radius: 4px;
      position: absolute;
      bottom: 0;
      transform: translateY(calc(100% + 5px));
      z-index: 1;
      width: 100%;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      > .input-select-associate-options-container {
        overflow: auto;
        overscroll-behavior: contain;
        // remove margin-block from height
        max-height: calc(var(--options-container-height) - 16px);
        margin: 8px;
        &::-webkit-scrollbar {
          width: 8px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 4px;
        }
        > .input-select-associate-option {
          height: 48px;
          padding-inline: 8px;
          display: flex;
          gap: 8px;
          align-items: center;
          font-family: 'Rubik';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.17px;
          cursor: pointer;
          &:hover {
            opacity: 0.7;
          }
          > div {
            cursor: pointer;
            flex: 1;
          }
          > .input-select-associate-option-btn {
            height: 20px;
            width: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
  button {
    background: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:active {
      scale: 0.98;
    }
  }
}
