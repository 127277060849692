.early-main-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: 'Rubik', sans-serif;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: center;

  header {
    background-color: var(--background-secondary-color);
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .early-content-wrapper {
    .early-content-container {
      flex: 1;
      max-width: 800px;
      margin: 0 auto;

      > .early-heading-container {
        padding-top: 32px;

        h2 {
          font-weight: 900;
          font-size: 72px;
          line-height: 70px;
          letter-spacing: -1.17px;
          margin-bottom: 32px;
        }

        h4 {
          font-weight: 500;
          font-size: 32px;
          line-height: 38px;
          margin-bottom: 12px;
          color: var(--trend-up-color);
        }

        p {
          font-weight: 40;
          font-size: 24px;
          line-height: 32px;
          margin-bottom: 32px;
          white-space: pre-line;
        }
      }

      > .early-form-container {
        margin-bottom: 36px;

        > form {
          > h4 {
            font-weight: 400;
            font-size: 20px;
            line-height: 28px;
            letter-spacing: 0.25px;
            margin-bottom: 24px;
          }

          > .early-form-inputs-container {
            margin-bottom: 12px;
            position: relative;

            .early-form-inputs-row {
              display: flex;
              gap: 12px;
              margin-bottom: 12px;

              > .input-text-wrapper {
                flex: 1;
              }

              &.has-mobile-error {
                #mobile:focus + label,
                #mobile:not(:placeholder-shown) + label,
                #mobile:not(:focus):not(:placeholder-shown) + label {
                  color: var(--trend-down-color);
                }

                #mobile:focus ~ .line:after,
                #mobile:not(:placeholder-shown) ~ .line:after,
                #mobile:not(:focus):not(:placeholder-shown) ~ .line:after {
                  background-color: var(--trend-down-color);
                }
              }
            }

            .has-server-error {
              position: absolute;
              top: 0;
              inset-inline-start: 50%;
              transform: translate(50%, -85%);
              color: red;
              margin: 0;
              padding: 0;
              font-weight: normal;
            }
          }

          .input-text-area-wrapper {
            .input-text-area-container > label {
              top: 36px;
            }

            .input-text-area-container > textarea:focus + label,
            .input-text-area-container > textarea:not(:placeholder-shown) + label {
              top: 11px;
            }

            .input-text-area-container > textarea:not(:focus):not(:placeholder-shown) + label {
              color: var(--text-primary-color);
            }
          }

          button {
            border-radius: 24px;
            background-color: var(--trend-up-color);
            color: var(--text-primary-color);
            font-family: 'Rubik';
            font-size: 16px;
            letter-spacing: 0.5px;
            line-height: 28px;
            text-align: center;
            border: 2px solid transparent;
            padding: 10px 40px;
            margin-inline-start: auto;
            cursor: pointer;

            &:active:not(:disabled) {
              transform: scale(0.98);
            }

            &:disabled {
              background: none;
              border: 2px solid var(--trend-up-color);
              color: var(--text-secondary-color);
              opacity: 0.7;
            }
          }
        }
      }

      > .early-remark-container {
        h3 {
          font-weight: 700;
          font-size: 28px;
          line-height: 33px;
          margin-bottom: 16px;
        }

        p {
          font-weight: 400;
          font-size: 20px;
          line-height: 28px;
        }
      }
    }
  }

  h2,
  h3,
  h4,
  p {
    margin: 0;
  }
}

.mobile-logo {
  display: none;
}

.early-content-container{
  background-size: 0;
}

.mobile-back-arrow{
  display: none;
}

@media screen and (max-height: 767px) {
  .early-main-container {
    header {
      height: 60px;
    }

    .early-content-wrapper {
      .early-content-container {
        > .early-heading-container {
          padding-top: 16px;

          h2 {
            font-size: 54px;
            margin-bottom: 18px;
            line-height: 40px;
          }

          h4 {
            font-size: 24px;
            margin-bottom: 6px;
            line-height: 20px;
          }

          p {
            font-size: 18px;
            line-height: 20px;
            margin-bottom: 20px;
          }
        }

        > .early-form-container {
          margin-bottom: 14px;

          > form {
            > h4 {
              font-size: 18px;
              line-height: 18px;
              margin-bottom: 14px;
            }

            > .early-form-inputs-container {
              margin-bottom: 6px;

              .early-form-inputs-row {
                display: flex;
                gap: 6px;
                margin-bottom: 6px;
              }
            }
          }
        }

        > .early-remark-container {
          h3 {
            font-size: 24px;
            line-height: 26px;
            margin-bottom: 8px;
          }

          p {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .early-main-container .early-content-wrapper .early-content-container {
    padding: 1px 16px 31px;
  }
}

@media screen and (max-width: 767px) {
  .early-main-container{
    height: auto;
    header {
      height: 56px;
      background: #1D222F;
      position: relative;
      .mobile-back-arrow{
        display: block;
        position: absolute;
        left: 16px;
        top: 15px;
      }
      .logo.bborder{
        border: none;
      }
    }
    .early-content-wrapper {
      height: 100%;
      background: #1B1F2B !important;
      box-shadow: inset -1px 16px 19px -7px rgb(0 0 0 / 23%);

      .early-content-container {
        & > .early-heading-container {
          & h2 {
            font-size: 40px;
            line-height: 41px;
            max-width: 219px;
            margin: 0 auto 31px;
          }

          & h4 {
            font-size: 20px;
            line-height: 26px;
            margin-bottom: 9px;
          }

          & p {
            font-size: 16px;
            line-height: 19px;
            padding: 1px 30px;
          }
        }

        & > .early-remark-container {
          margin-bottom: 199px;
          padding: 1px 30px;

          & > h3 {
            font-size: 20px;
            line-height: 23px;
          }

          & > p {
            font-size: 14px;
            line-height: 16px;
          }
        }

        & > .early-form-container {
          margin-bottom: 64px;

          & > form {
            & > h4 {
              font-size: 13px;
              line-height: 16px;
              margin: 30px 1px 13px;
            }

            .input-text-container {
              border-radius: 8px;
              height: 52px;
            }

            & > .early-form-inputs-container {
              margin-bottom: 23px;

              .early-form-inputs-row {
                flex-direction: column;
                gap: 12px;
                margin-bottom: 12px;
              }

              .input-text-area-wrapper {
                .input-text-area-container {
                  & > label {
                    top: 26px;
                  }

                  & > textarea {
                    padding: 1px 10px 13px;
                  }
                }
              }
            }

            button {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .mobile-logo {
    display: flex;
  }

  .early-content-container{
    background-size: 380px;
    background-position: bottom center;
  }

  .early-main-container .early-content-wrapper .early-content-container{
    height: 100%;
  }
}