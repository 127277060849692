.workstation-screen {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: rgba(39, 45, 62, 0.6);
  .workstation-content {
    padding: 20px 40px;
    flex: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;
    > div:not(:last-child) {
      margin-bottom: 24px;
    }
  }
}

::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0.38);
  &:hover {
    background-color: rgba(0, 0, 0, 0.38);
  }
}

@media screen and (max-width: 767px) {
  .workstation-screen {
    height: 100svh;
    background: var(--background-primary-color);
    .workstation-content {
      padding: 16px;
    }
  }
  .workstation-screen + .sidebar {
    //display: none;
  }
}
