.customer-details-general {
  display: flex;
  flex-direction: column;
  gap: 8px;
  > div:first-child {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  > .customer-details-personal:not(.active) {
    opacity: 0.3;
  }
  > .customer-details-personal {
    font-family: Rubik;
    > .customer-details-name {
      flex: 1;
      display: flex;
      align-items: baseline;
      gap: 6px;
      color: var(--text-primary-color);
      font-size: 20px;
      font-weight: 500;
      letter-spacing: 0.25px;
      line-height: 29px;
      position: relative;
      > *:only-child {
        flex: 1;
      }
      .check-mark:after {
        top: -20px;
      }
    }
    > .customer-details-id-number {
      flex: 1;
      display: flex;
      gap: 6px;
      > p {
        color: var(--text-secondary-color);
        font-size: 14px;
        letter-spacing: 0.25px;
        line-height: 20px;
      }
      svg {
        height: 16px;
        color: var(--text-secondary-color);
      }
    }
  }
}
