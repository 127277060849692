.policy-types-changes-modal {
  width: 960px;
  height: 648px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  > header {
    padding-bottom: 16px;
    h5 {
      font-size: 24px;
      font-weight: 400;
      margin-bottom: 8px;
    }
    p {
      font-size: 14px;
      color: var(--text-secondary-color);
    }
  }
  > div {
    padding-block: 16px 24px;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-top: var(--border-primary);
    gap: 16px;
    > p {
      font-size: 16px;
    }
    .policy-types-changes-list {
      flex: 1;
      overflow: auto;
      overscroll-behavior: contain;
      .policy-types-changes-modal-grid {
        padding-inline: 16px;
        gap: 10px;
        display: grid;
        grid-template-columns: repeat(3, 1fr) 0.5fr repeat(4, 1fr);
        > :last-child {
          justify-self: end;
        }
      }
      .list-item {
        background-color: var(--border-primary-color);
        font-size: 16px;
        border-radius: 8px;
        height: 40px;
        align-items: center;
        &:not(:last-child) {
          margin-bottom: 16px;
        }
      }
      .list-heading {
        font-size: 12px;
        color: var(--text-secondary-color);
        align-items: center;
        margin-bottom: 16px;
        > :last-child {
          display: flex;
          gap: 8px;
          align-items: center;
          p {
            text-wrap: nowrap;
          }
        }
      }
      .direction-icon {
        color: var(--trend-up-color);
        font-size: 24px;
      }
    }
  }
  > footer {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
  }
}
