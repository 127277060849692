.customer-categories-modal-container {
  height: 600px;
  width: 450px;
  display: flex;
  flex-direction: column;
  > .customer-categories-modal-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 24px;
    padding-bottom: 8px;
    color: var(--text-primary-color);
    font-family: Rubik;
    line-height: 20px;
    > .customer-categories-modal-title {
      margin-bottom: 24px;
      font-size: 16px;
      letter-spacing: 0.5px;
    }
    > .customer-categories-lists-container {
      overflow-y: auto;
      > .customer-categories-list-container {
        &:not(:last-child) {
          margin-bottom: 30px;
        }
        > .customer-categories-list-title {
          margin-bottom: 8px;
          color: var(--text-secondary-color);
          font-size: 12px;
          letter-spacing: 0.4px;
          line-height: 16px;
          position: sticky;
          top: 0;
          z-index: 1;
          background-color: var(--background-secondary-color);
        }
        > .customer-categories-list {
          > .customer-categories-list-item {
            display: flex;
            align-items: center;
            gap: 8px;
            border-radius: 8px;
            min-height: 36px;
            background-color: var(--border-primary-color);
            padding: 8px 12px;
            &:not(:last-child) {
              margin-bottom: 8px;
            }
            > .customer-categories-list-item-text {
              color: var(--text-primary-color);
              font-family: Rubik;
              font-size: 14px;
              letter-spacing: 0.25px;
              line-height: 20px;
              &:not(.used) {
                color: var(--text-secondary-color);
              }
            }
            > .customer-categories-list-item-note {
              margin-inline-start: auto;
              > .customer-categories-list-item-members {
                color: var(--text-secondary-color);
                font-family: Rubik;
                font-size: 12px;
                letter-spacing: 0.4px;
                line-height: 16px;
              }
            }
            > .customer-categories-list-item-check {
              position: relative;
              bottom: 2px;
            }
          }
        }
      }
    }
  }
  > .customer-categories-modal-footer {
    margin-top: auto;
    padding: 10px 24px;
    display: flex;
    justify-content: flex-end;
    > div > .button {
      width: 120px;
    }
  }
}
