.policy-card-types-list {
  .policy-types-grid {
    display: grid;
    gap: 12px;
    grid-template-columns: 1fr 1fr 1fr 3fr;
    padding-inline: 16px;
    > div:last-child {
      display: flex;
    }
  }
  .policy-type-item {
    .date-cell {
      color: var(--text-secondary-color);
      font-size: 12px;
    }
  }
}
