.worksatation-header-mobile-filters {
  display: none;
}

@media screen and (max-width: 767px) {
  .worksatation-header-extras-container {
    display: flex;
    gap: 12px;
  }

  .worksatation-header-mobile-filters {
    display: block;
    padding: 16px;
    border-bottom: var(--border-primary);
  }
}
