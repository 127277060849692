.lobby-screen {
  display: flex;
  flex-direction: column;
  > .lobby-screen-banners {
    position: sticky;
    top: 0;
    z-index: 1;
  }
  > .lobby-user-greetings-wrapper {
    padding: 26px 40px 22px;
  }
  > .lobby-totals-wrapper {
    padding: 0 40px 20px;
    transition: 0.3s all;
    max-height: 290px;
    > div {
      transform: scale(1);
      transition: 0.3s all;
    }
    &.hidden {
      max-height: 0px;
      padding-top: 0;
      padding-bottom: 0;
      transition: 0.3s all;
      > div {
        transform: scale(0.2);
        transition: 0.3s all;
      }
    }
  }
  > .lobby-main-container {
    border-top: var(--border-primary);
    flex: 1;
    background: var(--card-background-secondary-color);
    padding: 20px 40px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 20px;
    position: relative;
    transition: 0.3s height;
    > .lobby-main-container-accordion {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 32px;
      height: 32px;
      border-radius: 16px;
      background-color: var(--background-primary-color);
      border: var(--border-primary);
      color: black;
      font-size: 25px;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
      span,
      img {
        transition: 0.3s all;
      }
      &.opened > span,
      &.opened > img {
        transform-origin: center;
        transform: rotate(180deg);
      }
    }
  }
  > div:has(> a) {
    display: flex;
    > a {
      margin-inline-start: auto;
      display: inline-flex;
      align-items: center;
      gap: 8px;
      background-color: var(--trend-up-color);
      height: 30px;
      border-radius: 15px;
      padding-inline: 10px;
      text-decoration: none;
      color: var(--text-primary-color);
    }
  }
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: rgba(255, 255, 255, 0.38);
    &:hover {
      background-color: rgba(0, 0, 0, 0.38);
    }
  }
}
