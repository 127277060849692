.policy-sidecard-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  > .policy-sidecard-details-container {
    background-color: var(--background-secondary-color);
    border-bottom: var(--border-primary);
    padding-top: 60px;
    color: var(--text-primary-color);
    > a {
      text-decoration: none;
      color: var(--text-primary-color);
      &:dir(rtl) img {
        transform: rotate(0deg);
      }
      > .policy-sidecard-customer-link {
        padding: 24px;
        position: absolute;
        top: 0;
        inset-inline-end: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 16px;
        cursor: pointer;
        margin-inline-start: auto;
        > .policy-sidecard-customer-link-text {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 16px;
          color: var(--text-prmary-color);
          font-family: Rubik;
          font-size: 14px;
          letter-spacing: 0.25px;
          line-height: 20px;
          text-align: right;
          text-decoration: none;
        }
        .policy-sidecard-customer-link-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          > img {
            transform: rotate(180deg);
          }
          &:dir(ltr) img {
            transform: rotate(0deg);
          }
        }
      }
    }
    > .policy-sidecard-details-heading {
      margin-bottom: 16px;
      display: flex;
      padding-inline: 24px;
      justify-content: space-between;
      > h3 {
        margin: 0;
        padding: 0;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0.25px;
        line-height: 22px;
      }
    }
    .policy-sidecard-details {
      margin-bottom: 3px;
      display: flex;
      justify-content: space-between;
      padding: 0 24px;
      font-size: 16px;
      letter-spacing: 0.5px;
      position: relative;
      &:not(:last-child) {
        margin-bottom: 16px;
      }
      > div:nth-child(2) {
        display: flex;
        align-items: center;
        gap: 6px;
        svg {
          fill: var(--text-secondary-color);
        }
      }
      .id-number {
        color: var(--text-secondary-color);
      }
      > .modal-selection-wrapper {
        position: absolute;
        top: 100%;
        inset-inline-end: 24px;
        z-index: 100;
      }
      .categories-selector-overlay {
        position: fixed;
        inset: 0;
        z-index: 2;
      }
      > .categories-selector {
        display: flex;
        gap: 6px;
        > .categories-selector-icon {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        &.selectable {
          cursor: pointer;
        }
        &.opened {
          > .categories-selector-icon {
            > img {
              transform: rotate(180deg);
            }
          }
        }
      }
      > div:first-child {
        color: var(--text-secondary-color);
      }
      > div > a {
        font-size: 16px;
        letter-spacing: 0.5px;
        line-height: 28px;
        color: var(--trend-up-color);
      }
    }
    .agents-list {
      border-top: var(--border-primary);
      padding-top: 16px;
      > div:nth-child(2) {
        flex-direction: column;
        align-items: flex-end;
      }
    }
    .policy-sidecard-details:last-child {
      margin-bottom: 24px;
    }
  }
  > .policy-sidecard-list-container {
    padding: 24px;
    padding-top: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow: auto;
    .policy-list-item-card:not(:last-child) {
      margin-bottom: 12px;
    }
    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.4px;
      padding-bottom: 16px;
      span {
        color: var(--text-secondary-color);
      }
    }
    .policy-sidecard-list-name {
      padding-top: 24px;
    }
    .policy-sidecard-list-name,
    .policy-sidecard-list-heading {
      position: sticky;
      top: 0;
      background-color: var(--background-primary-color);
      z-index: 1;
    }
    .policy-sidecard-list-heading {
      top: 41px;
      color: var(--text-secondary-color);
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.4px;
      margin-bottom: 12px;
      align-items: end;
    }
    &:not(:has(.policy-sidecard-list-name)):has(.policy-sidecard-list-heading) {
      .policy-sidecard-list-heading {
        padding-top: 24px;
        top: 0;
      }
    }
    .policy-irregs-grid {
      display: grid;
      padding-inline-start: 32px;
      grid-template-columns: 1fr 1.5fr 1fr 1fr 100px;
      &:not([class*='head']) {
        align-items: center;
        height: 48px;
      }
      > div:last-child {
        display: flex;
        justify-content: end;
        .policy-status-chip-container {
          flex: 1;
          .policy-status-chip {
            border-start-end-radius: unset;
            border-end-end-radius: unset;
            flex: 1;
          }
        }
      }
    }
    .policy-payments-grid {
      display: grid;
      grid-template-columns: 48px repeat(7, 1fr);
      gap: 8px;
      &.extended {
        grid-template-columns: 48px repeat(5, 1fr) 1.5fr 1.5fr 1fr 1fr;
      }
      &.elementary {
        grid-template-columns: 48px repeat(8, 1fr);
        &.extended {
          grid-template-columns: 48px repeat(6, 1fr) 1.5fr 1.5fr 1fr 1fr;
        }
      }
      &.abroad {
        grid-template-columns: 48px repeat(6, 1fr);
        &.extended {
          grid-template-columns: 48px repeat(4, 1fr) 1.5fr 1.5fr 1fr 1fr;
        }
      }
      &:not([class*='head']) {
        align-items: center;
        height: 48px;
        font-size: 12px;
        &:not(:first-child) {
          color: var(--text-secondary-color);
        }
      }
      &.subgrid {
        height: 36px;
        > :has(> .payment-commrate-cell) {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 8px;
          &:has(> :only-child) {
            grid-template-columns: 1fr;
            .payment-commrate-cell,
            .financial-value-cell {
              justify-self: center;
            }
          }
        }
        &.extended {
          > :has(> .payment-commrate-cell) {
            .payment-commrate-cell {
              justify-self: center;
            }
          }
        }
      }
      > div:first-child {
        display: flex;
        justify-content: center;
      }
      > div:nth-child(2) {
        font-size: 14px;
      }
      &:not(.extended):not(.elementary) > div:nth-child(5) {
        border-inline-end: 1px solid rgba(var(--text-primary-color-rgb), 0.17);
        padding-inline-end: 12px;
      }
      &:not(.extended):not(.elementary) > div:nth-child(6) {
        padding-inline-start: 12px;
      }
      &:not(.extended).elementary > div:nth-child(7) {
        border-inline-end: 1px solid rgba(var(--text-primary-color-rgb), 0.17);
        padding-inline-end: 12px;
      }
      &:not(.extended).elementary > div:nth-child(8) {
        padding-inline-start: 12px;
      }
      &.extended:not(.elementary) > div:nth-child(6) {
        border-inline-end: 1px solid rgba(var(--text-primary-color-rgb), 0.17);
        padding-inline-end: 12px;
      }
      &.extended:not(.elementary) > div:nth-child(7) {
        padding-inline-start: 12px;
      }
      &.extended.elementary > div:nth-child(8) {
        border-inline-end: 1px solid rgba(var(--text-primary-color-rgb), 0.17);
        padding-inline-end: 12px;
      }
      &.extended.elementary > div:nth-child(9) {
        padding-inline-start: 12px;
      }
      > div:last-child,
      > div:nth-last-child(2) {
        display: flex;
        justify-content: center;
        padding-inline: 6px;
        .button-rounded {
          background-color: rgba(var(--trend-up-color-rgb), 0.17);
          border: none;
          color: var(--trend-up-color);
          height: 34px;
          font-size: 12px;
          font-weight: 400;
          letter-spacing: 0.4px;
          line-height: unset;
          gap: 6px;
          svg {
            path {
              fill: var(--trend-up-color) !important;
            }
          }
        }
      }
      > div:last-child {
        border-inline-start: 1px solid rgba(var(--text-primary-color-rgb), 0.17);
      }
      .financial-value-cell {
        display: flex;
        align-items: center;
        gap: 2px;
      }
      div:has(.commission-rate-cells) {
        text-align: center;
      }
      .commission-rate-cells {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 8px;
        .financial-value-cell {
          text-align: center;
        }
        &:has(> :only-child) {
          grid-template-columns: 1fr;
          .financial-value-cell {
            justify-self: center;
          }
        }
      }
      &.extended {
        .commission-rate-cells,
        .payment-commrate-cell {
          .financial-value-cell {
            justify-content: center;
          }
        }
      }
      .payment-commrate-cell {
        display: flex;
      }
    }
  }
  > .policy-sidecard-btns-container {
    padding: 12px 24px 0;
    > .buttons-change-status-container {
      .tooltip-container {
        left: unset;
        top: 0;
        inset-inline-end: 0;
        transform: translate(0, -115%);
      }
    }
  }
  > .policy-sidecard-payments-container {
    padding: 0 24px;
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
    > .no-data-container {
      flex: unset;
    }
    > .loader-container {
      flex: unset;
    }
    > .policy-sidecard-payments-list-container {
      padding-bottom: 12px;
      > .policy-sidecard-payments-list {
        > .policy-sidecard-payments-list-header {
          position: sticky;
          z-index: 1;
          top: 0px;
          background-color: var(--background-primary-color);
          > .policy-sidecard-irreg-title-container,
          > .policy-sidecard-payments-title-container {
            margin-bottom: 8px;
          }
        }
      }
      > .policy-sidecard-payments-list:not(:last-child) {
        margin-bottom: 40px;
      }
    }
    &::-webkit-scrollbar {
      width: 5px;
      width: 12px;
    }
    &::-webkit-scrollbar-track {
    }
    &::-webkit-scrollbar-thumb {
      border: 4px solid transparent;
      background: var(--background-primary-color);
      // background-color: red;
      border-top: none;
      border-bottom: none;
      border-radius: 5px;
      background-clip: padding-box;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: var(--text-secondary-color);
    }
  }
  > .policy-sidecard-history-container {
    padding: 24px;
    padding-top: 0;
    flex: 1 1;
    overflow-y: auto;
    > .loader-container,
    > .no-data-container {
      margin-top: 24px;
    }
  }
}
