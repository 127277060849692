.polices-input-range-container {
  p {
    font-family: Rubik;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: var(--text-secondary-color);
  }
  > p {
    margin-bottom: 8px;
  }
  > div {
    display: grid;
    align-items: center;
    gap: 12px;
    grid-template-columns: 1fr min-content 1fr;
    position: relative;
    > button {
      position: absolute;
      inset-inline-end: 12px;
      height: 18px;
      width: 18px;
      color: var(--text-secondary-color);
      > svg {
        font-size: 18px;
      }
    }
  }
  input {
    width: 100%;
  }
}

[dir='rtl'] .polices-input-range-container input {
  direction: ltr;
  text-align: end;
}
