.add-agency-stepper-wrapper {
  background-color: rgb(var(--background-secondary-color-rgb));
  display: grid;
  // grid-template-columns: auto 1fr;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 2;
  .add-agency-stepper {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .add-agency-stepper-header {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 72px;
      position: relative;
      button {
        background: transparent;
        border: none;
        cursor: pointer;
        position: absolute;
        inset-inline-end: 12px;
        &:active {
          transform: scale(0.95);
        }
      }
      .add-agency-stepper-steps {
        display: flex;
        align-items: center;
        gap: 8px;
        .add-agency-stepper-step {
          position: relative;
          display: flex;
          align-items: center;
          gap: 8px;
          .add-agency-stepper-step-badge {
            background-color: var(--trend-up-color);
            border-radius: 12px;
            height: 24px;
            width: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: rgb(255, 255, 255);
            text-align: center;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            letter-spacing: 0.4px;
          }
          .add-agency-stepper-step-text {
            color: var(--text-primary-color);
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0.17px;
          }
          &.upcoming {
            .add-agency-stepper-step-badge {
              background-color: var(--text-third-color);
            }
            .add-agency-stepper-step-text {
              color: var(--text-secondary-color);
            }
          }
          &.current {
            .add-agency-stepper-step-badge {
              background-color: rgba(var(--trend-up-color-rgb), 0.6);
            }
            .add-agency-stepper-step-text {
              font-weight: 500;
            }
          }
          &:not(:last-child) {
            margin-inline-end: 48px;
            &:after {
              content: '';
              position: absolute;
              height: 1px;
              width: 40px;
              background-color: #bdbdbd;
              inset-inline-end: -8px;
              transform: translateX(-100%);
            }
          }
        }
      }
    }
    .add-agency-stepper-content {
      flex: 1;
      background-color: rgb(var(--background-primary-color-rgb));
      border-start-start-radius: 8px;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      > * {
        flex: 1;
        overflow: hidden;
      }
    }
  }
}

[dir='ltr'] .add-agency-stepper-wrapper {
  .add-agency-stepper {
    .add-agency-stepper-header {
      .add-agency-stepper-steps {
        .add-agency-stepper-step:not(:last-child):after {
          transform: translateX(100%);
        }
      }
    }
  }
}
