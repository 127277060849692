.modal-selection {
  border-radius: 4px;
  background-color: var(--border-primary-color);
  box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12),
    0 11px 15px -7px rgba(0, 0, 0, 0.2);
  padding: 6px;
  cursor: default;
  width: 224px;
  height: 300px;
  position: relative;
  display: flex;
  flex-direction: column;
  .modal-selection-items {
    overflow-y: auto;
    .modal-selection-item {
      color: var(--text-primary-color);
      font-family: Rubik;
      font-size: 14px;
      letter-spacing: 0.25px;
      line-height: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 12px;
      margin-bottom: 4px;
      cursor: pointer;
    }
    .modal-selection-item:hover,
    .modal-selection-item.selected {
      border-radius: 8px;
      background-color: rgba(var(--background-primary-color-rgb), 0.38);
    }
  }
  .modal-selection-loader {
    border-radius: 4px;
    position: absolute;
    height: 100%;
    width: 100%;
    backdrop-filter: blur(2px);
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-thumb {
    border: 4px solid transparent;
    background-color: var(--background-secondary-color);
    // background-color: red;
    border-top: none;
    border-bottom: none;
    border-radius: 4px;
    background-clip: padding-box;
  }
  ::-webkit-scrollbar-thumb:hover {
    border: 4px solid transparent;
    background-color: var(--text-secondary-color);
    // background-color: green;
    border-top: none;
    border-bottom: none;
    border-radius: 4px;
    background-clip: padding-box;
  }
}
