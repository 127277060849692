.agency-house-analytics-incomes-list-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: hidden;
  flex: 1;
  > *:nth-child(3) {
    flex: 1;
    overflow: auto;
  }
  .agency-house-analytics-incomes-list-view-selector {
    display: flex;
    margin-inline-start: auto;
    border-radius: 100px;
    background-color: var(--border-primary-color);
    padding: 4px;
    button {
      height: 34px;
      width: 34px;
      border-radius: 17px;
      color: var(--text-secondary-color);

      &.selected {
        color: var(--text-primary-color);
        background-color: var(--trend-up-color);
      }
    }
  }
}
