.mobile-header-wrapper {
  display: none;
}

@media screen and (max-width: 767px) {
  .mobile-header-wrapper {
    display: block;
    filter: drop-shadow(0px 4px 40px rgba(0, 0, 0, 0.2));
    background-color: var(--background-secondary-color);
    > .mobile-header {
      display: grid;
      grid-template-columns: 1fr max-content 1fr;
      align-items: center;
      padding: 14px 20px;
      > .mobile-header-text {
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 28px;
        text-align: center;
        letter-spacing: 0.15px;
      }
      > .mobile-header-back {
        justify-self: end;
        display: flex;
        height: 24px;
        width: 24px;
        align-items: center;
        justify-content: center;
        justify-self: end;
      }
    }
  }
}
