:root,
[data-theme='light'] {
  --background-primary-color: #f7faff;
  --background-primary-color-rgb: 250, 250, 250;
  --background-secondary-color: #ffffff;
  --background-secondary-color-rgb: 246, 246, 246;

  --text-primary-color: rgba(0, 0, 0, 0.87);
  --text-secondary-color: rgba(0, 0, 0, 0.6);
  --text-third-color: rgba(0, 0, 0, 0.38);

  --text-primary-color-rgb: 0, 0, 0;
  --text-inverted-primary-color-rgb: 255, 255, 255;

  --logo-primary-color: rgba(13, 13, 13, 1);

  --border-primary: 1px solid var(--border-primary-color);
  --border-primary-color: #f5f6f7;
  --border-primary-color-rgb: 245, 246, 247;

  --card-background-primary-color: #ffffff;
  --card-background-secondary-color: #f7faff;
  --card-background-third-color: #ffffff;
  --card-background-fourth-color: #fbfbfc;
  --card-background-selected: #ffffff;

  --icon-active-background: #02b64a;
  --icon-checked-background: #16382f;
  --icon-disabled-background: #60636c;

  --trend-up-color: #02b64a;
  --trend-up-color-rgb: 2, 182, 74;
  --trend-down-color: #fb4659;
  --trend-down-color-rgb: 251, 70, 89;

  --img-invert-color: 90%;

  --green-client: #02b64a;
  --orange-client: #ffbf00;
  --red-client: #fb4659;

  --in-follow-client: var(--text-primary-color);
  --irregular-client: rgba(251, 70, 89, 0.17);
  --normal-client: rgba(251, 70, 89, 0.17);

  --shadow-primary-color: rgba(255, 255, 255, 0.4);
}

[data-theme='dark'] {
  --background-primary-color: #1b1f2b;
  --background-primary-color-rgb: 27, 31, 43;
  --background-secondary-color: #272d3e;
  --background-secondary-color-rgb: 39, 45, 62;

  --text-primary-color: rgba(255, 255, 255, 1);
  --text-secondary-color: rgba(255, 255, 255, 0.6);
  --text-third-color: rgba(255, 255, 255, 0.38);

  --text-primary-color-rgb: 255, 255, 255;
  --text-inverted-primary-color-rgb: 0, 0, 0;

  --logo-primary-color: rgba(255, 255, 255, 1);

  --border-primary: 1px solid var(--border-primary-color);
  --border-primary-color: #31394f;
  --border-primary-color-rgb: 49, 57, 79;

  --card-background-primary-color: #1f2432;
  --card-background-secondary-color: #212736;
  --card-background-third-color: #1f2431;
  --card-background-fourth-color: #1d212e;
  --card-background-selected: #272e40;

  --icon-active-background: #02b64a;
  --icon-checked-background: #16382f;
  --icon-disabled-background: #60636c;

  --img-invert-color: 0%;

  --trend-up-color: #02b64a;
  --trend-down-color: #fb4659;

  --green-client: #02b64a;
  --orange-client: #ffbf00;
  --red-client: #fb4659;

  --in-follow-client: var(--text-primary-color);
  --irregular-client: rgba(251, 70, 89, 0.17);
  --normal-client: rgba(251, 70, 89, 0.17);

  --shadow-primary-color: rgba(255, 255, 255, 0.4);
}

* {
  box-sizing: border-box;
}

body {
  margin: 0 auto;
  padding: 0;
  background-color: var(--background-primary-color);
  color: var(--text-primary-color);
  height: 100vh;
  font-family: Rubik;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

button {
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  color: var(--text-primary-color);
  cursor: pointer;
}

button:disabled {
  cursor: default;
}

button:active:not(:disabled) {
  transform: scale(.98);
}

.download-button {
  color: var(--text-primary-color);
  display: flex;
  align-items: center;
  padding: 0;
  text-decoration: none;
  font-family: Rubik;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.46px;
  text-transform: uppercase;
  gap: 8px;
  height: 30px;
  padding-inline: 10px;
  border-radius: 15px;
  background-color: rgba(var(--text-primary-color-rgb), .17);
}

input {
  background: none;
  outline: none;
  border: none;
  color: var(--text-primary-color);
  font-family: Rubik;
  font-weight: 400;
}

input:disabled {
  color: var(--text-secondary-color);
}

img.img-icon {
  filter: invert(var(--img-invert-color));
}

img.img-icon.clickable:active {
  transform: scale(0.85);
  transition: all 0.1s ease-in-out;
}

/* **************************************************** */
/* Containers styles */
.wrapper {
  display: grid;
  grid-template-columns: 68px minmax(0, auto);
}

.wrapper-agency-house {
  background-color: rgb(var(--background-secondary-color-rgb));
  display: grid;
  grid-template-columns: auto 1fr auto;
  height: 100vh;
}

.agency-house-main {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.agency-house-main>:nth-child(2) {
  background-color: rgb(var(--background-primary-color-rgb));
  flex: 1;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 40px;
  overflow: auto;
}

@media screen and (max-height: 767px) {
  .agency-house-main>:nth-child(2) {
    padding-block: 20px;
  }
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

/* Material UI custom styles */
.MuiTooltip-tooltip-custom .MuiTooltip-tooltip,
.MuiTooltip-tooltip-custom .MuiTooltip-arrow:before {
  background-color: var(--background-primary-color);
  font-family: Rubik;
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  text-align: center;
  max-width: 200px;
}

.MuiSwitch-switchBase {
  color: var(--text-primary-color);
}

span>span.MuiSwitch-track {
  background-color: var(--text-secondary-color);
  filter: opacity(50%) brightness(250%);
}

.MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb,
.MuiSwitch-switchBase.Mui-checked .MuiTouchRipple-root {
  color: var(--trend-up-color);
}

.MuiSwitch-root>.MuiButtonBase-root.Mui-checked+.MuiSwitch-track {
  background-color: var(--trend-up-color);
  filter: opacity(50%) brightness(250%);
}

.MuiSwitch-switchBase.Mui-disabled+.MuiSwitch-track {
  opacity: 1;
}

.MuiSwitch-root .MuiSwitch-colorSecondary.Mui-disabled~.MuiSwitch-track {
  background: var(--background-secondary-color);
}

/* Material UI custom styles */

/* **************************************************** */
.trend-up {
  color: var(--trend-up-color);
}

.trend-down {
  color: var(--trend-down-color);
}

/* Icons*/
.icon {
  height: 52px;
  width: 52px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon.action {
  background-color: rgba(2, 182, 74, 0.17);
  font-size: 26px;
  font-weight: bold;
  color: var(--trend-up-color);
}

/* Statuses */
.active-status {
  color: var(--trend-up-color);
  background-color: rgba(var(--trend-up-color-rgb), 0.17);
}

.inactive-status {
  color: var(--text-primary-color);
  background-color: rgba(var(--text-primary-color-rgb), 0.38);
}

.irregular-status {
  color: var(--trend-down-color);
  background-color: rgba(var(--trend-down-color-rgb), 0.17);
}

.new-status {
  color: rgb(87, 185, 255);
  background-color: rgba(87, 185, 255, 0.17);
}

.future-status {
  color: rgb(87, 185, 255);
  background-color: rgba(87, 185, 255, 0.17);
}

/* Satutses */

/* Dir alignments */
:dir(ltr).financial-value-cell {
  justify-content: start;
}

:dir(rtl).financial-value-cell {
  direction: ltr;
  text-align: right;
  justify-content: end;
}

:dir(rtl).financial-value-cell input {
  text-align: right;
}

/* Dir alignments */

#tech-support {
  position: fixed;
  bottom: 50px;
  inset-inline-end: 50px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

#tech-support .tech-support-whats-up {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 68px;
  width: 68px;
  background-color: #25D366;
  border-radius: 34px;
}

@media screen and (max-width: 767px) {
  body {
    height: 100svh;
  }

  .wrapper {
    display: block;
  }

  .mobile-hide {
    display: none !important;
  }
}