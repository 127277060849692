.calendar-base-wrapper {
  width: 286px;
  .react-calendar {
    box-shadow: 0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
      0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    background-color: var(--border-primary-color);
    border-radius: 8px;
    padding: 8px;
    * {
      font-family: Rubik;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.25px;
    }
    &__navigation {
      display: flex;
      height: 40px;
      padding: 12px 10px;
      border-bottom: 1px solid var(--text-secondary-color);
      margin-bottom: 3px;
      &__arrow {
        height: 16px;
        width: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--text-secondary-color);
      }
    }
    &__tile {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      &--active,
      &:hover {
        background-color: rgba(var(--text-primary-color-rgb), 0.17);
      }
    }
    &__month-view__days__day {
      height: 36px;
      width: 36px;
      &--neighboringMonth {
        color: var(--text-secondary-color);
      }
    }
    &__month-view__weekdays {
      margin-bottom: 3px;
      &__weekday {
        height: 36px;
        width: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        > abbr {
          text-decoration: none;
        }
      }
    }
    &__century-view,
    &__year-view,
    &__decade-view {
      .react-calendar__tile {
        height: 60px;
      }
    }
  }
  &--closed {
    display: none;
  }
}
