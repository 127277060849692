.step-agent-house-details {
  grid-column: span 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  // padding-block: 200px 40px;
  font-family: Rubik;
  padding-bottom: 20px;
  h5,
  p {
    margin: 0;
  }
  .step-agent-house-details-warn {
    background-color: rgba(255, 167, 38, 0.12);
    width: 768px;
    height: 48px;
    border-radius: 8px;
    margin-top: 24px;
    display: flex;
    align-items: center;
    padding-inline: 16px;

    p {
      color: #ffa726;
      font-size: 14px;
      line-height: 143%; /* 20.02px */
      letter-spacing: 0.17px;
    }
  }
  .agent-house-details-back-btn {
    background: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    cursor: pointer;
    height: 36px;
    font-size: 14px;
    font-family: Rubik;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    &:active {
      transform: scale(0.95);
    }
    &:disabled {
      opacity: 0.3;
      cursor: default;
    }
    > div:has(img),
    > div:has(svg) {
      height: 20px;
      width: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .agent-house-form-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 56px;
    min-width: 450px;
    .agent-house-form-footer {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 34px;
    }
  }
}

@media screen and (max-height: 767px) {
  .step-agent-house-details {
    padding-block: 20px 10px;
    .step-agent-house-details-warn {
      margin-top: 0;
      margin-bottom: 12px;
    }
    .agent-house-form-container {
      gap: 32px;
    }
  }
}

[data-theme='light'] .step-agent-house-details .agent-house-form-container form .agent-house-form-footer {
  .agent-house-details-back-btn {
    color: var(--trend-up-color);
    svg > * {
      fill: var(--trend-up-color);
    }
  }
  .agent-house-details-finish-btn:disabled {
    color: var(--text-primary-color);
    opacity: 0.7;
  }
}

[dir='ltr'] .step-agent-house-details .agent-house-form-container form .agent-house-form-footer {
  .agent-house-details-back-btn {
    svg {
      transform: rotate(180deg);
    }
  }
}
