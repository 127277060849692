.customers-applied-filters-banner-container {
  padding: 16px 26px;
  padding-bottom: 0;
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.5px;
  p {
    margin: 0;
    padding: 0;
  }
  button {
    border: none;
    background: transparent;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  > .customers-applied-filters-title {
  }
  > .customers-applied-filters-type-title {
    font-weight: 600;
  }
  > .customers-applied-filter-chip {
    height: 26px;
    display: flex;
    align-items: center;
    padding-inline: 8px;
    gap: 10px;
    background: rgba(255, 255, 255, 0.17);
    border: 1px solid rgba(255, 255, 255, 0.38);
    border-radius: 100px;
    font-size: 14px;
    line-height: 17px;
    &:has(+ p) {
      margin-inline-end: 8px;
      position: relative;
      &::after {
        content: '';
        width: 2px;
        height: 100%;
        position: absolute;
        inset-inline-end: -10px;
        background-color: var(--text-primary-color);
      }
    }
  }
}
