.task-monetary-value {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  > span {
    font-weight: 600;
    color: var(--trend-up-color);
    letter-spacing: normal;
  }
}
