.agent-groups-selector {
  background: var(--background-secondary-color);
  border-bottom: var(--border-primary);
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.4px;
  cursor: pointer;
  position: relative;
  .agent-groups-avatars {
    display: flex;
    align-items: center;
    justify-content: center;
    .agent-groups-avatar {
      border: 1px solid white;
      &:not(:first-child) {
        margin-inline-start: -24px;
      }
      &.default {
        background-color: #bdbdbd;
        color: black;
      }
      &:hover:not(.default):not(:only-child) {
        z-index: 1;
      }
    }
  }
  .agent-groups-avatar {
    height: 32px;
    width: 32px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    // transition: transform 0.1s ease-in-out;
    position: relative;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.4px;
    .agent-groups-avatar-deselect {
      position: absolute;
      inset-inline-end: 0;
      inset-block-start: 0;
      transform: translate(-50%, -50%);
      border: none;
      background: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 14px;
      width: 14px;
      cursor: pointer;
      transition: transform 0.1s ease-in-out;
      &:active {
        transform: scale(0.95) translate(-50%, -50%);
      }
    }
  }
  .agent-groups-all {
    height: 68px;
    cursor: default;
    display: flex;
    gap: 16px;
    align-items: center;
    padding: 17px 20px;
    background-color: var(--border-primary-color);
    border: 1px solid #545b6d;
    border-inline-start: none;
    position: absolute;
    position: fixed;
    border-start-end-radius: 8px;
    border-end-end-radius: 8px;
    inset-inline-start: 0;
    transform: translateX(100%);
    transition: transform 0.55s ease-in-out;
    transform-origin: right;
    z-index: 1;
    .agent-groups-avatar {
      transition: transform 0.35s ease-in-out;
      transform: scale(0);
    }
  }
  &:hover {
    .agent-groups-all {
      transform: translateX(0);
      .agent-groups-avatar {
        transition: height 0.35s ease-in-out, width 0.35s ease-in-out;
        height: 32px;
        width: 32px;
        transition: transform 0.35s ease-in-out;
        transform: scale(1);
      }
    }
  }
  .agent-groups-empty {
    white-space: pre-wrap;
  }
}

[dir='ltr'] {
  .agent-groups-selector .agent-groups-avatar .agent-groups-avatar-deselect {
    transform: translate(50%, -50%);
  }

  .agent-groups-all {
    transform: translateX(-100%);
  }
}
