.hierarchy-picker-container {
  position: relative;
  font-family: 'Rubik';
  .hierarchy-picker-btn-container {
    .hierarchy-picker-btn {
      border: 1px solid rgba(var(--text-primary-color-rgb), 0.6);
      border-radius: 18px;
      height: 36px;
      padding: 6px 16px;
      padding-inline-end: 8px;
      font-size: 14px;
      font-weight: 500;
      line-height: 24px; /* 171.429% */
      letter-spacing: 0.4px;
      text-transform: uppercase;
      align-items: center;
      display: flex;
      &:not(:disabled) {
        cursor: pointer;
      }
      &.open {
      }
      &.has-applied {
        border-color: transparent;
        background-color: rgba(var(--text-primary-color-rgb), 0.16);
        gap: 4px;
      }
      button {
        svg {
          height: 20px;
          width: 20px;
        }
      }
    }
  }
  .hierarchy-picker-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    height: 100vh;
    width: 100vw;
    .hierarchy-picker-main-container {
      z-index: 1;
      position: fixed;
      background: var(--border-primary-color);
      box-shadow: 0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
        0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      padding: 12px 8px;
      .hierarchy-picker-agents-container {
        flex: 1;
        padding-inline-end: 6px;
        overflow: auto;
        .hierarchy-picker-agent-container {
          display: flex;
          align-items: center;
          gap: 8px;
          height: 36px;
          cursor: pointer;
          span {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.15px;
          }
          svg {
            color: var(--text-secondary-color);
          }
          &.selected {
            svg {
              color: var(--trend-up-color);
            }
          }
          &:hover {
            opacity: 0.7;
          }
        }
      }
    }
  }
}
