.profile-info-container {
  display: flex;
  gap: 12px;
  justify-content: space-between;
  .profile-info {
    width: 460px;
    .profile-avatar {
      margin-bottom: 40px;
    }
    .profile-info-details {
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-bottom: 16px;
      position: relative;
      .input-text-container {
        border-radius: 4px 4px 0 0;
      }
      span {
        color: red;
        position: absolute;
        bottom: 0;
        inset-inline-start: 0;
        transform: translateY(100%);
      }
      .profile-info-version {
        font-size: 12px;
        span {
          position: static;
          color: var(--text-secondary-color);
        }
      }
    }
    .profile-info-delete {
      margin-top: 16px;
      border-top: var(--border-primary);
      padding-top: 16px;
      h6 {
        font-size: 20px;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: 0.15px;
      }
      p {
        white-space: pre-line;
        color: var(--text-secondary-color);
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.17px;
      }
    }
  }
}
