.policy-types-list-wrapper {
  overflow: auto;
  flex: 1;
  .policy-types-list-grid {
    display: grid;
    grid-template-columns: 1.5fr repeat(5, 1fr);
    gap: 16px;
    padding-inline: 16px;
    div:has(.columns-picker-container) {
      justify-self: end;
    }
  }
  .policy-types-list-header {
    min-height: 32px;
    align-items: center;
    margin-bottom: 10px;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: var(--text-secondary-color);
  }
  .policy-types-list {
  }
}
