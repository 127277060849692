.profile-settings-container {
  > .profile-settings-toggle {
    display: flex;
    align-items: center;
    gap: 16px;
    > label {
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.5px;
    }
    .switch {
      flex-shrink: 0;
    }
  }
}
