.modal-sidebar-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  height: 100%;
  width: 100vw;
  z-index: 100;
  -webkit-backdrop-filter: blur(4px) brightness(70%);
  backdrop-filter: blur(4px) brightness(70%);
  > .modal-sidebar-overlay {
    flex: 1;
  }
  > .modal-sidebar {
    background-color: var(--background-primary-color);
    width: 600px;
    height: 100%;
    position: relative;
    box-shadow: 12px 2px 64px 0 rgba(0, 0, 0, 0.5);
    > .sidebar-close-btn {
      position: absolute;
      top: 0;
      inset-inline-start: 0;
      width: 40px;
      height: 40px;
      background-color: var(--border-primary-color);
      display: flex;
      justify-content: center;
      align-items: center;
      border-end-end-radius: 10px;
      cursor: pointer;
      &.disabled {
        cursor: not-allowed;
        opacity: 0.7;
      }
    }
    > .modal-sidebar-content {
      display: flex;
      height: 100%;
    }
    > .modal-sidebar-loading-overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      display: flex;
      backdrop-filter: blur(4px);
      z-index: 1;
    }
  }
}
