.buttons-change-status-container {
  display: flex;
  align-items: center;
  gap: 4px;
  button {
    position: relative;
    background-color: transparent;
    border: none;
    height: 36px;
    width: 36px;
    border-radius: 12px;
    outline: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    .tooltip-container {
      transition-delay: 100ms;
      width: auto;
      white-space: nowrap;
    }
    .tooltip-container:after {
      content: none;
    }
    .on-hover {
      display: none;
    }
    .off-hover {
      display: block;
    }
  }
  button:hover:not(:active) {
    .tooltip-container {
      visibility: visible;
    }
    .on-hover {
      display: block;
    }
    .off-hover {
      display: none;
    }
  }
  button.treat:hover {
    background-color: rgba(255, 191, 0, 0.17);
  }
  button.treat:not(:hover) {
    img {
      width: 20px;
    }
  }
  button.treated:hover {
    background-color: rgba(43, 184, 218, 0.17);
  }
  button.deactivate:hover {
    background-color: rgba(211, 212, 217, 0.38);
  }
  button.deactivate:not(:hover) {
    img {
      width: 18px;
    }
  }
  button.ignore:hover {
    background-color: rgba(2, 182, 74, 0.17);
  }
  button:active {
    transform: scale(0.95);
    transition: all 0.1s ease-in-out;
  }
}
