.agency-house-lobby-changes {
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: Rubik;
  > div {
    display: flex;
    flex-direction: column;
    .agency-house-lobby-changes-value {
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0.1px;
      .agency-house-lobby-changes-value-currency {
        font-size: 12px;
        font-weight: 400;
        line-height: 166%;
        letter-spacing: 0.4px;
      }
    }
    .agency-house-lobby-changes-text {
      color: var(--text-secondary-color);
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.4px;
    }
  }
}
