.customer-totals {
  background-color: var(--background-secondary-color);
  padding: 26px 30px;
  border-bottom: var(--border-primary);
  display: flex;
  gap: 26px;
  > * {
    flex: 1;
    &:only-child {
      min-height: 348px;
    }
  }
}
