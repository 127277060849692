.modal-note-container {
  width: 330px;
  display: flex;
  flex-direction: column;
  .modal-note-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 24px;
    color: var(--text-primary-color);
    font-family: Rubik;
    line-height: 20px;
    .modal-note-title {
      margin-bottom: 24px;
      font-size: 16px;
      letter-spacing: 0.5px;
    }
    .modal-note-subtitle {
      margin-bottom: 8px;
      color: var(--text-secondary-color);
      font-size: 12px;
      letter-spacing: 0.4px;
      line-height: 16px;
    }
  }
  .modal-note-btns {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
    margin: 24px;
    margin-top: 0;
    > div > .button {
      width: 120px;
    }
    > .back-button {
      display: flex;
      cursor: pointer;
      background: none;
      border: none;
      padding: 0;
      > img {
        width: 13px;
      }
    }
  }
}
