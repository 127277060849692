.profile-company-item {
  height: 52px;
  border-radius: 8px;
  background-color: rgba(var(--text-primary-color-rgb), 0.08);
  align-items: center;
  position: relative;
  &.selected {
    background-color: rgba(var(--trend-up-color-rgb), 0.12);
  }
  &:not(:last-child) {
    margin-bottom: 12px;
  }
  .profile-company-info,
  .profile-company-actions {
    display: flex;
    align-items: center;
  }
  .profile-company-info {
    gap: 8px;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.46px;
    text-transform: uppercase;
    > img {
      width: 18px;
      height: 18px;
    }
  }
  .profile-company-fields {
    font-size: 13px;
    font-weight: 400;
    line-height: 18px; /* 138.462% */
    letter-spacing: 0.16px;
  }
  .profile-company-site > a {
    text-decoration: none;
    color: var(--text-primary-color);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.17px;
    &:hover {
      opacity: 0.7;
    }
  }
  .profile-company-actions {
    gap: 10px;
    justify-content: end;
    button {
      height: 44px;
      width: 44px;
    }
  }
}
