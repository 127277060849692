.confirm-delete-dialog-modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-backdrop-filter: blur(4px) brightness(70%);
  backdrop-filter: blur(4px) brightness(70%);
  z-index: 101;
  .confirm-delete-dialog-modal-container {
    background-color: var(--background-secondary-color);
    box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12),
      0 11px 15px -7px rgba(0, 0, 0, 0.2);
    width: 500px;
    border-radius: 8px;
    color: var(--text-primary-color);
    header {
      padding: 16px 24px;
      h5 {
        font-weight: 500;
        font-size: 24px;
      }
    }
    section {
      padding: 8px 24px;
      p {
        white-space: pre-wrap;
      }
    }
    footer {
      display: flex;
      justify-content: flex-end;
      gap: 16px;
      padding: 8px 24px 20px;
    }
  }
}
