.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-backdrop-filter: blur(4px) brightness(70%);
  backdrop-filter: blur(4px) brightness(70%);
  z-index: 101;
  /* pointer-events: none; */
}

.modal-wrapper > .modal-container {
  background-color: var(--background-secondary-color);
  box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12),
    0 11px 15px -7px rgba(0, 0, 0, 0.2);
  /* width: 450px;
  height: 430px; */
  border-radius: 8px;
  /* padding: 24px; */
  position: relative;
  display: flex;
}

.modal-wrapper > .modal-container > .modal-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.modal-wrapper > .modal-container > .modal-content-loading-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  border-radius: 8px;
  backdrop-filter: blur(4px);
  z-index: 1;
}

.modal-container > .modal-close-icon {
  position: absolute;
  top: 0;
  inset-inline-end: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background-color: var(--border-primary-color);
  border-start-end-radius: 8px;
  border-end-start-radius: 8px;
  cursor: pointer;
}

.modal-container > .modal-close-icon:hover {
  filter: brightness(150%) saturate(140%);
}
