.agency-house-analytics-screen-wrapper {
  padding: unset !important;
  display: flex;
  flex-direction: column;
  .agency-house-analytics-screen {
    display: flex;
    flex: 1;
    gap: 40px;
    padding: 24px;
    overflow: hidden;
    width: calc(100vw - 72px - 72px);
    justify-content: center;
    > *:nth-child(2) {
      flex: 1;
    }
  }
}

::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
}
::-webkit-scrollbar-thumb {
  border: 2px solid transparent;
  background-color: var(--border-primary-color);
  border-top: none;
  border-bottom: none;
  border-radius: 4px;
  background-clip: padding-box;
}
::-webkit-scrollbar-thumb:hover {
  background-color: var(--text-secondary-color);
}

@media screen and (max-width: 1366px) {
  .agency-house-analytics-screen-wrapper {
    .agency-house-analytics-screen {
    }
  }
}
