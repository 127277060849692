.customers-filter-dropdown {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  padding-inline: 16px;
  border-radius: 52px;
  background-color: var(--background-secondary-color);
  color: var(--text-secondary-color);
  font-family: Rubik;
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 20px;
  text-align: right;
  cursor: pointer;
  position: relative;
  border: 1px solid var(--background-secondary-color);
}

.customers-filter-dropdown.open {
  border-color: rgba(211, 212, 217, 0.6);
}

.customers-filter-dropdown.has-checked,
.customers-filter-dropdown.open {
  background-color: var(--border-primary-color);
  color: var(--text-primary-color);
}

.customers-filter-dropdown.disabled {
  opacity: .5;
  background-color: var(--background-secondary-color);
  color: var(--text-secondary-color);
  cursor: default;
}

.customers-filter-dropdown>.customers-filter-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.customers-filter-dropdown>.customers-filter-content>div:first-child {
  display: flex;
  align-items: center;
  gap: 4px;
}

.customers-filter-dropdown>.customers-filter-content p {
  margin: 0;
  padding: 0;
}

.customers-filter-dropdown>.customers-filter-content span {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 16px;
  height: 16px;
  border-radius: 8px;
  background: var(--trend-up-color);
  color: var(--text-primary-color);
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  padding-inline: 4px;
}

.customers-filter-dropdown>.customers-filter-content>.customers-filter-dropdown-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.customers-filter-dropdown>.customers-filter-dropdown-items {
  position: absolute;
  top: 36px;
  inset-inline-end: 0;
  width: 280px;
  max-height: 350px;
  overflow: auto;
  background-color: var(--background-primary-color);
  border-radius: 4px;
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
  margin-top: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  z-index: 100;
}