.register-step.step-details {
  grid-column: span 2;
  display: flex;
  align-items: center;
}

.step-details {
  > .form {
    > form.form-container {
      > .form-inputs-group {
        gap: 20px;
      }
      > .input-container:not(:last-of-type) {
        margin-bottom: 20px;
      }
      > h4.form-inputs-header:not(:first-of-type) {
        margin-top: 90px;
      }
      .chars-count {
        position: absolute;
        bottom: -20px;
        inset-inline-end: 0;
        font-family: Rubik;
        font-size: 12px;
        letter-spacing: 0.4px;
        line-height: 16px;
      }
    }
    p:last-child {
      color: var(--text-secondary-color);
      text-align: start;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.4px;
      margin-top: 16px;
    }
  }
  .agent-details-back-btn {
    background: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    cursor: pointer;
    height: 36px;
    font-size: 14px;
    font-family: Rubik;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    &:active {
      transform: scale(0.95);
    }
    &:disabled {
      opacity: 0.3;
      cursor: default;
    }
    > div:has(img),
    > div:has(svg) {
      height: 20px;
      width: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

@media screen and (max-height: 768px) {
  .step-details {
    > .form > form.form-container > h4.form-inputs-header:not(:first-of-type) {
      margin-top: 36px;
    }
  }
}
