.add-agency-group-modal {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  width: 1070px;
  height: 775px;
  display: flex;
  flex-direction: column;
  position: relative;
  .add-agency-group-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #272d3e;
    padding: 16px 24px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    letter-spacing: 0.15px;
    border-bottom: var(--border-primary);
    > button {
      border: none;
      background: none;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      cursor: pointer;
      transition: all 0.25 ease-in;
      &:active {
        transform: scale(0.95);
      }
    }
  }
  .add-agency-group-modal-content {
    display: flex;
    flex: 1;
    overflow: hidden;
    .add-agency-group-modal-sidebar {
      background-color: #222837;
      padding: 24px;
      width: 370px;
      display: flex;
      flex-direction: column;
      .add-agency-group-modal-selected-heading {
        margin-top: 31px;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;
        color: var(--text-secondary-color);
        margin-bottom: 12px;
      }
      .add-agency-group-modal-selected-items {
        display: flex;
        gap: 8px;
        flex-wrap: wrap;
        overflow: auto;
        overscroll-behavior: contain;
        .add-agency-group-modal-selected-item {
          height: 32px;
          display: flex;
          gap: 8px;
          align-items: center;
          background-color: #2b3245;
          border-radius: 16px;
          padding-inline: 12px;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.25px;
          .add-agency-group-modal-list-selected-item-avatar {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            font-size: 12px;
            height: 20px;
            width: 20px;
          }
        }
      }
    }
    .add-agency-group-modal-main {
      display: flex;
      flex-direction: column;
      flex: 1;
      background: var(--background-secondary-color);
      .add-agency-group-modal-main-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        // height: 630px;
        .add-agency-group-search-container {
        }
        .add-agency-group-modal-list-container {
          padding-inline: 26px;
          overflow: auto;
          overscroll-behavior: contain;
          border-top: var(--border-primary);
          flex: 1;
          .add-agency-group-modal-list-grid {
            display: grid;
            padding-inline: 24px;
            grid-template-columns: 20px 1fr;
            align-items: center;
            gap: 16px;
            div:first-child {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 20px;
              height: 20px;
              cursor: pointer;
            }
          }
          .add-agency-group-modal-list-heading {
            padding-top: 24px;
            padding-bottom: 8px;
            position: sticky;
            top: 0;
            background: var(--background-secondary-color);
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.4px;
            color: var(--text-secondary-color);
            > .sortable-column {
              cursor: pointer;
              i {
                margin-inline-start: 6px;
              }
            }
          }
          .add-agency-group-modal-list {
            .add-agency-group-modal-list-item {
              height: 48px;
              border-radius: 8px;
              background-color: var(--border-primary-color);
              font-size: 14px;
              line-height: 20px;
              letter-spacing: 0.25px;
              &:not(:last-child) {
                margin-bottom: 8px;
              }
              .add-agency-group-modal-list-item-name {
                display: flex;
                align-items: center;
                gap: 12px;
                .add-agency-group-modal-list-item-avatar {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  height: 24px;
                  width: 24px;
                  border-radius: 4px;
                }
              }
              .add-agency-group-modal-list-item-company {
                display: flex;
                align-items: center;
                gap: 8px;
              }
            }
          }
          .add-agency-group-modal-no-data-container {
            height: 100%;
            display: flex;
          }
        }
      }
      .add-agency-group-modal-footer {
        display: flex;
        padding: 24px;
        justify-content: flex-end;
      }
    }
    .add-agency-group-modal-company-logo {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--background-primary-color);
      height: 24px;
      width: 24px;
      border-radius: 5px;
      img {
        height: 16px;
        width: 16px;
      }
    }
  }
  .add-agency-group-modal-loader-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    border-radius: 8px;
    backdrop-filter: blur(4px);
    z-index: 1;
  }
}

@media screen and (max-height: 767px) {
  .add-agency-group-modal {
    width: 830px;
    height: 600px;
    .add-agency-group-modal-content {
      .add-agency-group-modal-sidebar {
        width: 280px;
      }
      .add-agency-group-modal-main {
        .add-agency-group-modal-main-content {
          // height: 430px;
        }
        .add-agency-group-modal-footer {
        }
      }
    }
  }
}
