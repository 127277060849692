.agency-house-lobby-signals {
  margin-top: 100px;
  font-family: Rubik;
  font-style: normal;
  > header {
    margin-bottom: 12px;
    h5 {
      font-feature-settings: 'clig' off, 'liga' off;
      font-size: 24px;
      font-weight: 400;
      line-height: 32px;
    }
  }
  > section {
    display: flex;
    gap: 40px;
    min-height: 328px;
    > div {
      flex: 0 1 31%;
    }
  }
  .no-signals-data {
    border-radius: 20px;
    background-color: var(--border-primary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    text-align: center;
  }
}
