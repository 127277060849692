.step-companies {
  grid-template-rows: minmax(100px, 120px) 1fr min-content !important;
  .insurance-fields-container {
    overflow: auto;
    min-height: 360px;
    margin-bottom: 36px !important;
    &.list-loading {
      display: flex;
    }
    > .insurance-field-header {
      display: flex;
      justify-content: space-between;
      padding: 0 16px 10px;
      background-color: var(--background-primary-color);
      position: sticky;
      top: 0;
      &:nth-child(3) {
        top: 32px;
      }
      &:nth-child(5) {
        top: 64px;
      }
      &:nth-child(7) {
        top: 96px;
      }
      > .insurance-field-header-title {
        color: var(--text-primary-color);
        font-family: Rubik;
        font-size: 14px;
        letter-spacing: 0.25px;
        line-height: 20px;
        > .insurance-field-header-title-count {
          color: var(--text-secondary-color);
        }
      }
      > .select-all-companies {
        border: 1px solid transparent;
        background-color: transparent;
        cursor: pointer;
        color: var(--text-secondary-color);
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 0;
        box-sizing: content-box;
        font-family: Heebo;
        font-size: 14px;
        letter-spacing: 0.25px;
        line-height: 20px;
        &:hover {
          color: var(--text-primary-color);
        }
        &:active {
          transform: scale(0.95);
          transform-origin: center;
          transition: all 0.2s ease-in;
        }
        > img {
          height: 20px;
          width: 20px;
        }
      }
    }
    > .insurance-field {
      &:not(:last-child) {
        margin-bottom: 30px;
      }
      > .list {
        background-color: var(--card-background-secondary-color);
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        padding: 12px 0;
        > .list-item-container {
          > input[type='checkbox'] {
            appearance: none;
            position: absolute;
            &:checked + label i {
              color: var(--icon-active-background);
            }
          }
          > label {
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            padding: 14px 16px;
            > img {
              height: 20px;
              width: 20px;
            }
            > .company-info {
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 1.5rem;
              > .company-name {
                > div {
                  color: var(--text-primary-color);
                  font-family: Rubik;
                  font-size: 16px;
                  font-weight: 400;
                  letter-spacing: 0.15px;
                  line-height: 28px;
                }
              }
              > .company-avatar {
                height: 18px;
                width: 18px;
                display: flex;
                justify-content: center;
                align-items: center;
                > img {
                  height: 100%;
                  border-radius: 12px;
                }
              }
            }
            &:hover {
              > .company-info {
                > .company-name {
                  > div {
                    color: var(--text-secondary-color);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
