.policy-types-list-item {
  align-items: center;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  margin-bottom: 8px;
  position: relative;
  .company-info {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .item-input {
    width: 160px;
    height: 40px;
    border-radius: 8px;
    background: var(--background-primary-color);
    padding: 12px 8px;
    padding-inline-end: unset;
    justify-content: space-between;
    &:has(input) {
      display: flex;
      justify-content: flex-start;
      input {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: var(--text-primary-color);
        border: none;
        background: none;
        outline: none;
        width: 100%;
        padding-inline-end: 8px;
      }
    }
    &.error {
      border: 1px solid var(--trend-down-color);
    }
  }
  .category-selector {
    position: relative;
    .modal-selection-wrapper {
      position: absolute;
      z-index: 1;
      top: calc(100% + 4px);
      &.position-top {
        top: unset;
        bottom: calc(100% + 4px);
      }
    }
  }
}
