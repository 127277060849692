.register-terms-footer {
  display: flex;
  align-items: center;
  p {
    margin: 0;
    font-size: 14px;
    font-family: Rubik;
    line-height: 20px;
    letter-spacing: 0.25px;
    cursor: pointer;
    &:not(:last-of-type) {
      margin-inline-end: 12px;
      padding-inline-end: 12px;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        inset-inline-end: 0;
        top: 50%;
        transform: translate(calc(-100% + 2px), calc(-100% + 2px));
        height: 4px;
        width: 4px;
        border-radius: 2px;
        background-color: var(--text-primary-color);
      }
    }
  }
}
