.agency-house-lobby-screen-wrapper {
  display: flex;
  flex-direction: column;
  padding: unset !important;
}
.agency-house-lobby-screen {
  max-width: 1180px;
  margin-inline: auto;
  font-family: Rubik;
  overflow: auto;
  padding: 40px;
  > button:only-of-type {
    margin-inline: auto;
  }
}

@media screen and (max-width: 900px) {
  .agency-house-lobby-screen-wrapper {
    .agency-house-lobby-screen {
      padding-block: 20px;
      // width: 700px;
    }

    .agency-house-lobby-screen {
      padding-block: 20px;
      // width: 700px;
    }
  }
}
