.workstation-header-full {
  display: flex;
  align-items: center;
  padding: 22px 40px;
  border-bottom: var(--border-primary);
  .workstation-header-search-container {
    flex: 1;
  }
  .workstation-header-filters-container {
    padding-inline: 16px;
    border-inline-end: var(--border-primary);
  }
  .workstation-header-add-container {
    padding-inline-start: 16px;
  }
}

@media screen and (max-width: 767px) {
  .workstation-header-full {
    display: none;
  }
}
