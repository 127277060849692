.register-step {
  font-family: 'Rubik';
  text-align: center;
  font-weight: 400;
  height: calc(100vh - 64px);
  grid-area: register-step;
  display: grid;
  grid-template-rows: minmax(100px, 280px) 1fr min-content;
  justify-content: center;
  margin: 0 auto;
  padding-bottom: 24px;
  > * {
    // border: 1px solid red;
  }
  > .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    > h2 {
      font-size: 24px;
      line-height: 32px;
    }
    > h3 {
      font-size: 16px;
      line-height: 28px;
      letter-spacing: 0.15px;
      color: var(--text-secondary-color);
    }
    > .register-header-error {
      position: absolute;
      bottom: 0;
      transform: translateY(-90%);
      color: red;
      margin: 0;
      padding: 0;
      font-weight: normal;
      font-size: 12px;
      white-space: pre-line;
      line-height: 14px;
    }
  }
  > .form,
  > .insurance-fields-container {
    width: 480px;
    margin: 0 auto;
  }
  > .form {
    .form-inputs-header {
      margin-bottom: 16px;
      text-align: start;
    }
    > form.form-container {
      margin-bottom: 34px;
      .select-container {
        background-color: var(--card-background-secondary-color);
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        > .select {
          width: 100%;
          border-radius: inherit;
          color: var(--text-primary-color);
          background-color: transparent;
          font-family: Rubik;
          font-size: 16px;
          letter-spacing: 0.5px;
          line-height: 28px;
          cursor: pointer;
          display: grid;
          grid-template-areas: 'select';
          align-items: center;
          position: relative;
          height: 100%;
          grid-template-columns: 1fr;
          grid-template-rows: 1fr;
          &:before {
            content: '';
            width: 10px;
            height: 5px;
            background-color: var(--text-primary-color);
            clip-path: polygon(100% 0%, 0 0%, 50% 100%);
            grid-area: select;
            justify-self: self-end;
            margin-right: 16px;
            margin-left: 16px;
          }
          > select {
            appearance: none;
            background-color: transparent;
            color: var(--text-primary-color);
            border: none;
            outline: none;
            font-family: inherit;
            font-size: inherit;
            cursor: inherit;
            line-height: inherit;
            text-align-last: right;
            height: inherit;
            padding-inline-start: 16px;
            padding-inline-end: 40px;
            border-radius: inherit;
            grid-area: select;
            &:focus {
              background-color: var(--background-secondary-color);
              & + .focus {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border: var(--border-primary);
                border-radius: inherit;
              }
            }
          }
        }
      }
      .input-container {
        background-color: var(--card-background-secondary-color);
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        > img {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 14px;
          margin-left: 14px;
          position: absolute;
          inset-inline-end: 0;
          top: 50%;
          transform: translateY(-50%);
        }
        > input {
          background-color: transparent;
          color: var(--text-primary-color);
          border-radius: 10px;
          width: 100%;
          border: 1px solid transparent;
          outline: none;
          display: flex;
          padding-right: 16px;
          padding-left: 16px;
          padding-top: 20px;
          padding-bottom: 4px;
          font-family: Rubik;
          font-size: 16px;
          letter-spacing: 0.5px;
          line-height: 28px;
          &:focus,
          &:not(:placeholder-shown) {
            border: var(--border-primary);
            & + label {
              color: var(--trend-up-color);
              font-size: 12px;
              letter-spacing: 0.4px;
              line-height: 16px;
              transform: translateY(6px);
            }
            & ~ .line:after {
              width: 100%;
              transform-origin: 50% 0%;
            }
          }
          &:disabled {
            color: var(--text-secondary-color);
          }
        }
        > label {
          color: var(--text-primary-color);
          font-family: Rubik;
          font-size: 16px;
          letter-spacing: 0.5px;
          line-height: 28px;
          margin-right: 16px;
          margin-left: 16px;
          position: absolute;
          inset-block-start: 0;
          top: 0;
          transition: all 0.6s ease-in;
          transform: translateY(16px);
          pointer-events: none;
        }
        > .line {
          position: absolute;
          height: 2px;
          width: calc(100% - 20px);
          background: transparent;
          left: 50%;
          bottom: 0;
          transform: translate(-50%);
          &:after {
            position: inherit;
            content: '';
            display: block;
            width: 0;
            background: var(--trend-up-color);
            height: 2px;
            transition: all 0.5s;
            left: 50%;
            bottom: 0;
            transform: translateX(-50%);
          }
        }
        > .input-container-error {
          position: absolute;
          color: red;
          font-family: Rubik;
          font-size: 10px;
          bottom: 0;
          transform: translateY(110%);
          inset-inline-start: 14px;
        }
      }
      .form-inputs-group {
        display: flex;
        .input-container {
          flex: 1;
        }
      }
      .error {
        position: absolute;
        color: red;
        font-family: Rubik;
        font-size: 14px;
        bottom: -25px;
        right: 16px;
        &.small {
          font-size: 10px;
          bottom: -18px;
          right: 0;
        }
      }
    }
  }
  .form-buttons-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 28px;
  }
  .form-button {
    color: var(--text-primary-color);
    background-color: var(--trend-up-color);
    border: 1px solid var(--trend-up-color);
    border-radius: 4px;
    height: 36px;
    min-width: 96px;
    outline: none;
    font-family: Rubik;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1.25px;
    line-height: 16px;
    text-align: center;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    &:focus-visible {
      outline: 1px solid;
    }
    &:hover {
      opacity: 0.8;
    }
    &:active {
      transform: scale(0.96);
    }
    &:disabled {
      opacity: 0.7;
      color: var(--trend-up-color);
      background-color: transparent;
      cursor: none;
      pointer-events: none;
    }
  }
  h2,
  h3,
  h4 {
    margin: 0;
    padding: 0;
    font-weight: normal;
  }
  & ::-webkit-scrollbar {
    width: 8px;
  }
  & ::-webkit-scrollbar-track {
  }
  & ::-webkit-scrollbar-thumb {
    border: 2px solid transparent;
    background-color: var(--border-primary-color);
    border-top: none;
    border-bottom: none;
    border-radius: 4px;
    background-clip: padding-box;
  }
  & ::-webkit-scrollbar-thumb:hover {
    background-color: var(--text-secondary-color);
  }
}
@media screen and (max-height: 768px) {
  .register-step {
    grid-template-rows: minmax(100px, 150px) 1fr min-content;
  }
}
@media screen and (max-width: 992px) {
  .register-step {
    grid-template-rows: minmax(100px, 160px) 1fr min-content;
    & > .form {
      width: 419px;
    }
  }
}

@media screen and (max-width: 768px) {
  .register-step {
    display: block;
    height: 100%;
    margin: 1px;
    order: 1;
    padding-bottom: 1px;
    & > .header {
      & > h2 {
        margin-top: 51px;
        font-size: 20px;
        line-height: 31px;
      }
      & > h3 {
        font-size: 14px;
        line-height: 19px;
        color: white;
      }
      & > .register-header-error {
        transform: translateY(100%);
        font-size: 10px;
        line-height: 12px;
      }
    }
    & > .form {
      display: flex;
      flex-direction: column;
      max-width: 360px;
      width: 100%;
      height: auto;
      padding: 1px 16px;
      .form-inputs-header {
        font-size: 12px;
        line-height: 19px;
        margin: 31px 0 13px !important;
        color: #a4a5aa;
      }
      .form-buttons-container {
        margin-top: 9%;
        .button-back {
          display: none;
        }
      }
      & > form.form-container {
        height: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 1px;
        .form-inputs-group {
          flex-direction: column;
          gap: 1px;
        }
        .input-container {
          // margin-bottom: 13px;
          &:not(:last-of-type) {
            margin-bottom: 13px;
          }
          & > input {
            padding-top: 23px;
            &:focus {
              border: none;
              border-bottom: 1px solid white;
            }
          }
          & > input:focus + label {
            color: white;
          }
          & > .line {
            display: none;
          }
        }
        .input-checkboxes-container {
          margin-top: 36px;
          width: 100%;
          & > .checkbox-container > label > .checkbox-icon {
            border-radius: 4px;
          }
        }
      }
    }
    .form-buttons-container {
      margin: 9% auto;
      .button-back {
        position: fixed;
        left: 16px;
        top: 21px;
        z-index: 3;
      }
    }
    & > .insurance-fields-container {
      max-width: 348px;
      width: 100%;
      padding: 1px 16px;
      & > .insurance-field-header {
        margin-top: 35px;
      }
    }
  }
}
