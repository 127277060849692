.cookies-banner-wrapper {
  height: 100vh;
  width: 100vw;
  position: fixed;
  background: rgba(27, 31, 43, 0.6);
  backdrop-filter: blur(4.07742px);
  top: 0;
  z-index: 11;
  display: flex;
  align-items: center;
  justify-content: center;
  .cookies-banner-container {
    width: 360px;
    position: relative;
    padding: 40px;
    border-radius: 8px;
    background: var(--background-secondary-color);
    border: var(--border-primary);
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14),
      0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.5px;
    animation: fade-in-cookies-banner 0.45s ease-in-out;
    > h4,
    > p {
      margin: 0;
      padding: 0;
    }
    > h4 {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      letter-spacing: 0.15px;
      margin-bottom: 8px;
    }
    > p {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.15px;
      color: var(--text-secondary-color);
      margin-bottom: 24px;
    }
    > .cookies-banner-accept-btn-container {
      > button {
        width: 100%;
        background-color: var(--trend-up-color);
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.4px;
        text-transform: uppercase;
        color: var(--text-primary-color);
        padding: 10px 20px;
        border-radius: 22px;
        min-width: 120px;
      }
    }
    > .cookies-banner-close-btn-container {
      position: absolute;
      top: 0;
      inset-inline-end: 0;
      background: var(--border-primary-color);
      border-end-start-radius: 8px;
      border-start-end-radius: 8px;
      button {
        height: 32px;
        width: 32px;
      }
    }
    &.fade-out {
      animation: fade-out-cookies-banner 0.45s ease-in-out;
    }
  }
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: transparent;
    cursor: pointer;
    transition: all 0.25s ease-in;
    &:active {
      transform: scale(0.98);
    }
    &:hover {
      opacity: 0.7;
    }
  }
}

@keyframes fade-in-cookies-banner {
  0% {
    transform: scale(0);
    opacity: 0.1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes fade-out-cookies-banner {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0);
    opacity: 0.1;
  }
}

@keyframes fade-in-cookies-banner-mobile {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes fade-out-cookies-banner-mobile {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100%);
  }
}

@media screen and (max-width: 767px) {
  .cookies-banner-wrapper {
    height: 100svh;
    align-items: flex-end;
    > .cookies-banner-container {
      padding: 22px 26px 26px;
      width: 100%;
      border-bottom-left-radius: unset;
      border-bottom-right-radius: unset;
      animation-name: fade-in-cookies-banner-mobile;
      > h4 {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.15px;
        width: 204px;
      }
      > p {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;
        margin-bottom: 16px;
      }
      &.fade-out {
        animation-name: fade-out-cookies-banner-mobile;
      }
    }
  }
}
