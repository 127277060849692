.agency-house-analytics-incomes-changes-details-card {
  background-color: var(--background-secondary-color);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow: hidden;
  width: 450px;
  &:has(.with-changes),
  &.with-changes {
    width: 570px;
    position: absolute;
    inset-inline-end: 0;
    height: 100%;
  }
  .agency-house-analytics-incomes-changes-details-card-empty {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    svg {
      color: var(--text-secondary-color);
    }
    p {
      color: var(--text-secondary-color);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 166%; /* 19.92px */
      letter-spacing: 0.4px;
      max-width: 154px;
      text-align: center;
    }
  }
  .agency-house-analytics-incomes-changes-details-card-header {
    padding: 24px;
    border-bottom: 1px solid rgba(var(--text-primary-color-rgb), 0.12);
    > div {
      display: flex;
      justify-content: space-between;
    }
    > div:first-child {
      margin-bottom: 24px;
      p {
        font-size: 20px;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: 0.15px;
      }
      > div:has(button) {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 8px;
        > p {
          color: var(--text-secondary-color);
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: 0.4px;
        }
      }
    }
    > div:nth-child(2) {
      align-items: center;
      > p {
        font-size: 20px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.5px;
      }
    }
  }
  .agency-house-analytics-incomes-changes-details-card-hierarchy {
    display: flex;
  }
  .agency-house-analytics-incomes-changes-details-card-list-container {
    display: flex;
    flex-direction: column;
    overflow: auto;
    .agency-house-analytics-incomes-changes-gird {
      display: grid;
      grid-template-columns: 1.2fr 1fr 1fr 10px 24px;
      padding-inline: 24px;
      padding-inline-end: 8px;
      gap: 4px;
    }
    &.with-changes {
      .agency-house-analytics-incomes-changes-gird {
        grid-template-columns: 1.2fr 1fr 1fr 1fr 10px 24px;
      }
    }
    .agency-house-analytics-incomes-changes-details-card-list-header {
      color: var(--text-secondary-color);
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.4px;
      align-items: flex-end;
      margin-bottom: 8px;
    }
    .agency-house-analytics-incomes-changes-details-card-list {
      .agency-house-analytics-incomes-changes-details-card-list-item {
        height: 64px;
        align-items: center;
        &.open {
          background-color: rgba(var(--text-primary-color-rgb), 0.08);
        }
        div:has(> div > div.agency-house-analytics-incomes-changes-details-item-logo) {
          display: flex;
          align-items: center;
          gap: 10px;
        }
        p {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0.25px;
        }
        & > div:has(.commission-vat) {
          display: flex;
          flex-direction: column;
          .commission-vat {
            font-size: 12px;
            line-height: 10px;
            color: var(--text-secondary-color);
          }
        }
      }
      .agency-house-analytics-incomes-changes-details-card-sublist {
        background-color: rgba(var(--text-primary-color-rgb), 0.12);
        border-top: 1px solid rgba(var(--text-primary-color-rgb), 0.23);
        border-bottom: 1px solid rgba(var(--text-primary-color-rgb), 0.23);
        & > div:has(> div.commission-vat) {
          display: flex;
          flex-direction: column;
          .commission-vat {
            font-size: 12px;
            line-height: 10px;
            color: var(--text-secondary-color);
          }
        }
      }
      .agency-house-analytics-incomes-changes-details-item-logo {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 24px;
        width: 24px;
        border-radius: 4px;
        &.small {
          height: 10px;
          width: 10px;
          border-radius: 2px;
        }
      }
    }
  }
}
