.agency-house-lobby-signal-card {
  overflow: hidden;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  border: 1px solid transparent;
  font-family: Rubik;
  &.clickable {
    cursor: pointer;
  }
  &.selected {
    border: 1px solid var(--trend-up-color);
  }
  > div {
    padding: 24px;
  }
  .agency-house-lobby-signal-card-body {
    background-color: var(--border-primary-color);
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex: 1;
    h6 {
      flex: 1;
      font-size: 20px;
      font-weight: 500;
      line-height: 32px;
      letter-spacing: 0.15px;
    }
    p {
      font-size: 34px;
      font-weight: 400;
      line-height: 42px;
      letter-spacing: 0.25px;
    }
  }
  .agency-house-lobby-signal-card-footer {
    background-color: rgba(var(--border-primary-color-rgb), 0.6);
    height: 110px;
  }
}
