.agency-house-lobby-totals {
  display: grid;
  grid-template-columns: repeat(3, 340px);
  // grid-template-rows: 333px;
  gap: 40px;
  margin-bottom: 50px;
  > div:last-child {
    grid-column: 2/4;
  }
  .agency-house-lobby-totals-card-family-toggle-wrapper {
    position: absolute;
    top: 24px;
    inset-inline-end: 24px;
    z-index: 1;
  }
}
