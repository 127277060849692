.profile-next-payment {
  > .profile-next-payment-cancel-container {
    position: relative;
    background-color: var(--border-primary-color);
    padding: 16px;
    border-radius: 8px;
    margin-bottom: 16px;
    grid-template-columns: 1fr auto;
    > .profile-next-payment-cancel-info {
      > h5 {
        margin-bottom: 4px;
        font-size: 20px;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: 0.15px;
      }
      > p {
        margin-bottom: 16px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.17px;
        color: var(--text-secondary-color);
      }
      > button.button-rounded {
        width: 214px;
      }
      > p:not(:has(+ button)) {
        margin-bottom: 0;
      }
    }
    > .profile-next-payment-cancel-in {
      > p {
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.4px;
      }
      > p:nth-child(2) {
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0.1px;
        color: var(--trend-up-color);
      }
    }
  }
  > header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    p {
      font-size: 20px;
      font-weight: 500;
      line-height: 32px;
      letter-spacing: 0.15px;
    }
    div {
      display: flex;
      gap: 16px;
    }
    .button-text {
      color: var(--trend-up-color);
      height: unset;
    }
  }
  > section {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;
    .profile-next-payment-details-card {
      border: 1px solid rgba(var(--text-primary-color-rgb), 0.12);
      border-radius: 4px;
      padding: 16px;
      color: rgba(var(--text-primary-color-rgb), 0.7);
      P:first-child {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.17px;
      }
      P:last-child {
        font-size: 20px;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: 0.15px;
      }
    }
  }
}
