.agency-house-download-summary-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  .agency-house-download-summary-padding-wrapper {
    padding-inline-start: 384px;
  }
  .agency-house-download-summary-width-wrapper {
    width: 916px;
    margin-inline: auto;
    > div {
      padding-bottom: 24px;
    }
  }
  h5 {
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    margin: 0;
    margin-block: 40px 25px;
  }
  .agency-house-download-summary-cards {
    display: flex;
    gap: 40px;
    margin-bottom: 40px;
    p {
      margin: 0;
    }
    // card
    > div {
      padding: 24px;
      flex: 1;
      background-color: var(--border-primary-color);
      border-radius: 20px;
      // card header
      > div:first-child {
        margin-bottom: 16px;
        display: flex;
        gap: 16px;
        align-items: center;
        justify-content: space-between;
        // card icon
        > div:first-child {
          height: 56px;
          width: 56px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 8px;
          background-color: rgba(var(--text-primary-color-rgb), 0.17);
          > svg {
            font-size: 40px;
          }
        }
        > p {
          font-size: 34px;
          font-weight: 400;
          line-height: 42px;
          letter-spacing: 0.25px;
        }
      }
      > p {
        font-size: 20px;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: 0.15px;
      }
    }
  }
  .agency-house-download-summary-content {
    background-color: rgba(var(--text-primary-color-rgb), 0.05);
    flex: 1;
    overflow: auto;
    padding-block: 24px;
  }
}

@media screen and (max-width: 1536px) {
  .agency-house-download-summary-container {
    .agency-house-download-summary-padding-wrapper {
    }
    .agency-house-download-summary-width-wrapper {
      width: 700px;
    }
  }
}
