.modal-register-agreement {
  width: 1080px;
  height: 800px;
  display: flex;
  flex-direction: column;
  > .modal-register-agreement-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 40px;
    margin-bottom: 20px;
    > h4 {
      margin: 0;
      padding: 0;
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 20px;
      letter-spacing: 0.25px;
    }
    > button {
      border: none;
      background: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  > .modal-register-agreement-content {
    margin: 0 40px;
    display: flex;
    gap: 30px;
    overflow: hidden;
    > .modal-register-agreement-list {
      width: 110px;
      li {
        cursor: pointer;
        font-family: 'Rubik';
        font-style: normal;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        letter-spacing: 0.15px;
        &.active-agreement {
          font-weight: 600;
          color: var(--trend-up-color);
        }
        &:not(:last-child) {
          margin-bottom: 40px;
        }
      }
    }
    > .modal-register-agreement-text {
      flex: 1;
      overflow-x: auto;
      margin-bottom: 40px;
      font-family: 'Rubik';
      font-style: normal;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.15px;
      white-space: pre-wrap;
    }
  }
  > .modal-register-agreement-footer {
    margin-top: auto;
    background: var(--border-primary-color);
    box-shadow: 0px -4px 14px var(--background-secondary-color);
    padding: 12px 16px 24px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    > button {
      width: 100%;
      background-color: var(--trend-up-color);
      border: none;
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      cursor: pointer;
      letter-spacing: 0.15px;
      padding: 8px 32px;
      border-radius: 24px;
      color: rgb(255, 255, 255);
      white-space: nowrap;
      &:disabled {
        opacity: 0.38;
      }
    }
  }
  & ::-webkit-scrollbar {
    width: 8px;
  }
  & ::-webkit-scrollbar-track {
  }
  & ::-webkit-scrollbar-thumb {
    border: 2px solid transparent;
    background-color: var(--border-primary-color);
    border-top: none;
    border-bottom: none;
    border-radius: 4px;
    background-clip: padding-box;
  }
  & ::-webkit-scrollbar-thumb:hover {
    background-color: var(--text-secondary-color);
  }
}

@media screen and (max-width: 1366px), screen and (max-height: 800px) {
  .modal-register-agreement {
    width: 900px;
    height: 90vh;
    > .modal-register-agreement-content > .modal-register-agreement-text {
      font-size: 16px;
    }
  }
}

@media screen and (max-width: 767px) {
  .modal-register-agreement {
    width: 90vw;
    height: 70vh;
    > .modal-register-agreement-header {
      margin: 16px;
      margin-bottom: 20px;
    }
    > .modal-register-agreement-content {
      margin: 0 16px;
      > .modal-register-agreement-list {
        display: none;
      }
      > .modal-register-agreement-text {
        font-size: 14px;
        margin-bottom: 16px;
      }
    }
  }
}
