.workstation-filter-container {
  position: relative;
  font-family: 'Rubik';
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  ul,
  li {
    margin: 0;
    padding: 0;
  }
  .workstation-filter-list {
    position: absolute;
    background-color: red;
    background: var(--background-primary-color);
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    min-width: 200px;
    z-index: 1;
    top: calc(100% + 6px);
    ul {
      padding-block: 8px;
    }
    li {
      position: relative;
      cursor: default;
      padding: 6px 16px;
      & > .workstation-filter-list {
        display: none;
      }
      &:hover {
        background: var(--card-background-primary-color);
        > .workstation-filter-list {
          display: block;
          inset-inline-start: calc(100% + 0px);
          top: -8px;
        }
      }
    }
    .workstation-filter-list-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
    }
  }
}

@media screen and (max-width: 767px) {
  .workstation-filter-container > .workstation-filter-list {
    display: none;
  }
  .workstation-filter-list {
    position: static;
    font-family: 'Rubik';
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.1px;
    &:first-child {
      padding: 16px;
      > ul > li {
        padding-top: 16px;
        > .workstation-filter-list-item {
          padding-block: 4px;
        }
        > .workstation-filter-list {
          padding-block: 16px;
          overflow: auto;
          -ms-overflow-style: none; /* IE and Edge */
          scrollbar-width: none; /* Firefox */
          /* Hide scrollbar for Chrome, Safari and Opera */
          &::-webkit-scrollbar {
            display: none;
          }
          > ul {
            display: flex;
            gap: 8px;
            > li {
              > .workstation-filter-list-item {
                border: 1px solid var(--text-secondary-color);
                border-radius: 16px;
                padding: 6px 12px;
                font-family: 'Rubik';
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.17px;
                white-space: nowrap;
                display: flex;
                align-items: center;
                gap: 8px;
                justify-content: space-between;
                &.selected {
                  border-color: rgba(var(--trend-up-color-rgb), 0.38);
                  background-color: rgba(var(--trend-up-color-rgb), 0.17);
                  color: var(--trend-up-color);
                }
                .workstation-filters-list-item-ico {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
              }
            }
          }
        }
        span {
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0.17px;
          color: var(--text-secondary-color);
        }
        &:not(:last-child) {
          border-bottom: var(--border-primary);
        }
      }
    }
  }
  .workstation-filter-bottomsheet-footer {
    display: flex;
    padding: 16px;
    gap: 16px;
    > button {
      flex: 1;
    }
  }
}
