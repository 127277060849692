button.button-checkbox {
  background: transparent;
  border: none;
  color: var(--text-primary-color);
  cursor: pointer;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.17px;
  &:active:not(:disabled) {
    transform: scale(0.95);
  }
  &:disabled {
    cursor: default;
    > div {
      opacity: 0.17;
    }
    svg {
      opacity: 0.5;
    }
  }
}
