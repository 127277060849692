.theme-switcher {
  position: fixed;
  bottom: 0;
  inset-inline-end: 0;
  background: red;
  color: white;
  font-weight: bold;
  height: 50px;
  width: 150px;
  z-index: 1000;
}

.change-lng-btn {
  position: fixed;
  bottom: 0;
  inset-inline-end: 0;
  background: red;
  color: white;
  font-weight: bold;
  height: 25px;
  width: 50px;
  z-index: 1000;
}

.change-lng-btn:nth-of-type(2) {
  bottom: 30px;
}



@media screen and (max-width: 767px) {
  .change-lng-wrapper {
    /* display: none; */
  }

  .change-lng-btn {
    inset-inline-start: unset;
    top: unset;
    inset-inline-end: 0;
    bottom: 0;
  }

  .change-lng-btn:nth-of-type(2) {
    top: unset;
    bottom: 30px;
  }
}