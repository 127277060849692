.wrapper-register {
  height: 100vh;
  display: grid;
  grid-template-rows: min-content auto;
  grid-template-columns: 3fr 4fr;
  grid-template-areas:
    'register-header register-header'
    'register-sidebar register-step';
}

.register-wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 992px) {
  .wrapper-register {
    grid-template-columns: 2fr 4fr;
  }
}

@media screen and (max-width: 767px) {
  .wrapper-register {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 100vh;
  }
}
