@import '../../../../mixins.scss';

.modal-wrapper > .modal-container:has(.scan-upload-report-modal) {
  border-radius: 8px;
  background: var(--border-primary-color);
  box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14),
    0px 11px 15px -7px rgba(0, 0, 0, 0.2);
}

.scan-upload-report-modal {
  width: 650px;
  > header {
    padding: 16px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      font-size: 20px;
      font-weight: 500;
      line-height: 32px;
      letter-spacing: 0.15px;
    }
  }
  > main {
    padding: 8px 24px;
    > div:first-child {
      margin-bottom: 16px;
      p {
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.4px;
        margin-bottom: 8px;
      }
    }
    .modal-upload-report-drop-zone {
      position: relative;
      .dropzone {
        border: 1px dashed var(--border-primary-color);
        border-radius: 12px;
        background: rgba(255, 255, 255, 0.17);
        backdrop-filter: blur(5px);
        height: 152px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 24px 16px;
        gap: 16px;
        &.disabled {
          opacity: 0.5;
        }
        svg {
          color: var(--trend-up-color);
        }
        .drop-zone-actions {
          display: flex;
          gap: 4px;
          p,
          button {
            font-family: Rubik;
            font-size: 16px;
            font-weight: 500;
            line-height: 28px;
            letter-spacing: 0.15px;
          }
          button {
            text-decoration: underline;
            color: var(--trend-up-color);
          }
        }
        .drop-zone-remark {
          color: var(--text-secondary-color);
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0.17px;
        }
      }
      .errors {
        position: absolute;
        bottom: 0;
        color: red;
        margin: 6px;
        font-size: 12px;
        letter-spacing: 0.4px;
        line-height: 16px;
      }
    }
    .max-files-width {
      height: 170px;
      overflow-y: scroll;
    }

    .modal-upload-report-files {
      padding: 16px;
      padding-bottom: 0;
      
      .modal-upload-report-file {
        display: flex;
        gap: 16px;
        align-items: flex-start;
        .file-ico {
          height: 40px;
          width: 40px;
          border-radius: 20px;
          @include flex-hv-center;
          background-color: rgba(var(--trend-up-color-rgb), 0.17);
          &.error {
            background-color: rgba(var(--trend-down-color-rgb), 0.17);
          }
        }
        .file-info {
          flex: 1;
          p {
            font-size: 16px;
            font-weight: 500;
            line-height: 28px;
            letter-spacing: 0.15px;
          }
          div {
            display: flex;
            p {
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              letter-spacing: 0.17px;
              &:not(:last-child) {
                position: relative;
                padding-inline-end: 16px;
                &::after {
                  content: '';
                  position: absolute;
                  inset-inline-end: 6px;
                  top: 50%;
                  transform: translateY(-50%);
                  height: 4px;
                  width: 4px;
                  border-radius: 2px;
                  background: var(--text-primary-color);
                }
              }
            }
            &.error {
              p {
                color: var(--trend-down-color);
                &:after {
                  background: var(--trend-down-color);
                }
              }
            }
            &.success {
              p {
                color: var(--trend-up-color);
                &:after {
                  background: var(--trend-up-color);
                }
              }
            }
          }
        }
      }
    }
  }
  > div {
    padding: 24px;
    @include flex-hv-center;
  }
}
