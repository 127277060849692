.policies-lost-list-item {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.4px;
  &.has-policy-number > div:nth-child(2) {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  &:not(:last-child) {
    margin-bottom: 8px;
  }
  .policies-lost-list-item-icons {
    display: flex;
  }
}
