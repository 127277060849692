.input-text-area-wrapper {
  > .input-text-area-container {
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    overflow: hidden;
    &.disabled {
      opacity: 0.5;
    }
    > textarea {
      padding: 10px;
      padding-top: 29px;
      flex: 1;
      resize: none;
      background-color: var(--border-primary-color);
      filter: brightness(90%);
      border: 1px solid transparent;
      outline: none;
      display: flex;
      color: var(--text-primary-color);
      font-family: Rubik;
      font-size: 14px;
      letter-spacing: 0.25px;
      line-height: 20px;
      caret-color: var(--trend-up-color);
    }
    > textarea:focus,
    > textarea:not(:placeholder-shown) {
      background-color: var(--border-primary-color);
      filter: brightness(110%);
    }
    > label {
      color: var(--text-primary-color);
      font-family: Rubik;
      font-size: 16px;
      letter-spacing: 0.5px;
      line-height: 20px;
      text-align: right;
      margin-right: 10px;
      margin-left: 10px;
      position: absolute;
      top: 29px;
      transition: all 0.3s ease-in;
      pointer-events: none;
    }
    > textarea:focus + label,
    > textarea:not(:placeholder-shown) + label {
      color: var(--trend-up-color);
      font-size: 12px;
      letter-spacing: 0.4px;
      line-height: 16px;
      top: 11px;
      transform: translateY(calc(-100% + 10px));
    }
    > textarea:not(:focus):not(:placeholder-shown) + label {
      color: var(--text-primary-color);
    }
    > .line {
      position: absolute;
      height: 2px;
      width: 100%;
      background: transparent;
      left: 0;
      bottom: 0;
      transition: transform 250ms ease-in-out;
    }
    > .line:after {
      content: '';
      display: block;
      width: 100%;
      background: var(--trend-up-color);
      height: 2px;
      transition: all 0.5s;
      left: 0;
      bottom: 0;
      transform: scaleX(0);
    }
    > textarea:focus ~ .line:after,
    > textarea:not(:placeholder-shown) ~ .line:after {
      transform: scaleX(1);
    }
    > textarea:not(:focus):not(:placeholder-shown) ~ .line:after {
      transform: scaleX(1);
      background: var(--text-primary-color);
    }
  }
}
