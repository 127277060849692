.customer-details-list-item {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  > .customer-details-list-item-icon {
    padding-top: 3px;
  }
  > .customer-details-list-item-content {
    flex: 1;
    position: relative;
  }
  .customer-details-list-item-title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    position: relative;
  }
  .customer-details-list-item-title {
    color: var(--text-secondary-color);
    font-family: Rubik;
    font-size: 14px;
    letter-spacing: 0.25px;
    line-height: 20px;
    text-align: right;
    padding-bottom: 2px;
  }
  .customer-details-list-item-text {
    color: var(--text-piomary-color);
    font-family: Rubik;
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 20px;
    text-align: right;
    padding-bottom: 4px;
    direction: ltr;
  }
  .customer-details-list-item-family {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 4px;
    > .customer-details-list-item-family-member {
      background-color: var(--background-secondary-color);
      border-radius: 8px;
      padding: 8px 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      > .family-member-name {
        color: var(--text-primary-color);
        font-family: Rubik;
        font-size: 14px;
        letter-spacing: 0.25px;
        line-height: 20px;
        text-align: right;
        &.inactive {
          color: var(--text-secondary-color);
        }
      }
      > .family-member-icons {
        display: flex;
        justify-content: center;
        gap: 12px;
        > a {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        img {
          position: static;
          width: 14px;
        }
        .family-member-delete-icon {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
  .customer-details-list-item-empty {
    display: flex;
    gap: 10px;
    align-items: baseline;
    > * {
      cursor: pointer;
    }
    > .customer-details-list-item-empty-icon {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .customer-details-list-item-text {
      color: var(--text-secondary-color);
      font-family: Rubik;
      font-size: 16px;
      letter-spacing: 0.5px;
      text-align: right;
    }
  }
}

.customer-details-list-item:not(:last-child) > .customer-details-list-item-content {
  border-bottom: var(--border-primary);
  padding-bottom: 12px;
  margin-bottom: 20px;
}
