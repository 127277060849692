.customers-screen {
  height: 100vh;
  display: flex;
  flex-direction: column;
  > .customers-screen-banners {
    grid-column: span 2;
  }
  > .customers-main-container {
    flex: 1;
    background-color: var(--card-background-secondary-color);
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
  }
  ::-webkit-scrollbar-thumb {
    // background: var(--border-primary-color);
    background: var(--border-primary-color);
    border-radius: 3px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: var(--background-primary-color);
  }
}
