.register-header {
  grid-area: register-header;
  background-color: rgb(var(--background-secondary-color-rgb));
  border-bottom: var(--border-primary);
  box-shadow: 0px -1px 0px 0px var(--border-primary-color-rgb) inset;
  padding: 14px 40px;
  display: flex;
  align-items: center;
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  height: 64px;
  p,
  h2 {
    margin: 0;
  }
  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: var(--logo-primary-color);
  }
  & > &-logo {
    justify-self: flex-start;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    > img {
      height: 100%;
    }
  }
  > h2 {
    color: var(--text-primary-color);
    font-size: 20px;
    font-family: Rubik;
    font-weight: 500;
    line-height: 160%;
    letter-spacing: 0.15px;
  }
  & > &-actions {
    justify-self: flex-end;
    display: flex;
    align-items: center;
    gap: 20px;
    > p {
      color: var(--text-secondary-color);
      font-size: 14px;
      font-family: Rubik;
      line-height: 143%;
      letter-spacing: 0.17px;
    }
    > a {
      height: 36px;
      border-radius: 18px;
      padding: 6px 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid var(--trend-up-color);
      color: var(--trend-up-color);
      text-decoration: none;
      font-size: 14px;
      font-family: Rubik;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.4px;
      text-transform: uppercase;
    }
  }
}

@media screen and (max-width: 480px) {
  .register-header {
    z-index: 1;
    display: flex;
    justify-content: center;
    > h2,
    & > &-actions {
      display: none;
    }
  }
}
