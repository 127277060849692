.marketing-hero-container {
  max-width: 1080px;
  margin: 0 auto;
  font-family: Rubik;
  padding-top: 82px;
  padding-bottom: 32px;
  h1,
  h4,
  h5,
  h6,
  p {
    padding: 0;
    margin: 0;
    font-weight: normal;
  }
  > .marketing-hero-tagline {
    margin-bottom: 89px;
    width: 50%;
    h4 {
      font-weight: 400;
      font-size: 40px;
      line-height: 80px;
      letter-spacing: -0.28px;
    }
    h1 {
      white-space: pre-line;
      line-height: 70px;
      font-weight: 700;
      font-size: 80px;
      letter-spacing: -0.28px;
      span {
        font-family: Poppins;
        font-size: 75px;
      }
    }
  }
  > .marketing-hero-cards-container {
    margin-bottom: 108px;
    > h4 {
      font-weight: 400;
      font-size: 34px;
      line-height: 40px;
      letter-spacing: -0.28px;
      margin-bottom: 32px;
    }
    > .marketing-hero-cards {
      gap: 30px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      > .marketing-hero-card {
        padding: 24px;
        flex: 1;
        background: rgba(39, 45, 62, 0.8);
        backdrop-filter: blur(3px);
        border-radius: 16px;
        display: flex;
        gap: 20px;
        align-items: center;
        > .marketing-hero-card-ico {
          height: 50px;
          width: 50px;
          background: rgba(var(--trend-up-color-rgb), 0.17);
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 6px;
          > img {
            min-width: 21px;
          }
        }
        > .marketing-hero-card-content {
          flex: 1;
          > .marketing-hero-card-field {
            font-weight: 500;
            font-size: 20px;
            letter-spacing: 0.15px;
          }
          > .marketing-hero-card-categories {
            font-weight: 400;
            font-size: 16px;
            letter-spacing: 0.17px;
            color: var(--text-secondary-color);
          }
        }
      }
    }
  }
  > .marketing-hero-remark {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.283333px;
    color: var(--text-secondary-color);
    span {
      font-weight: 600;
    }
  }
}
@media screen and (max-width: 1366px) {
  .marketing-hero-container {
    max-width: 980px;
  }
}

@media screen and (max-width: 480px) {
  .marketing-hero-wrapper {
    background: rgba(39, 45, 62, 0.38);
    .marketing-hero-container {
      width: 100%;
      padding: 50px 16px 523px;
      h1,
      h4,
      h5,
      h6,
      p {
        text-align: center;
      }
      > .marketing-hero-tagline {
        margin-bottom: 50px;
        h4 {
          font-size: 24px;
          line-height: 24px;
          margin-bottom: 8px;
        }
        h1 {
          font-weight: 800;
          font-size: 45px;
          line-height: 48px;
          span {
            font-size: 48px;
          }
        }
      }
      > .marketing-hero-cards-container {
        margin-bottom: 28px;
        > h4 {
          font-size: 24px;
          line-height: 24px;
          margin-bottom: 16px;
        }
        > .marketing-hero-cards {
          gap: 12px;
          > .marketing-hero-card {
            padding: 16px 11px;
            gap: 12px;
            flex-direction: column;
            background: #272d3e;
            > .marketing-hero-card-ico {
              height: 36px;
              width: 36px;
            }
            > .marketing-hero-card-content {
              > .marketing-hero-card-field {
                font-size: 16px;
                line-height: 25.6px;
              }
              > .marketing-hero-card-categories {
                line-height: 18.59px;
                font-size: 13px;
              }
            }
          }
        }
      }
      > .marketing-hero-remark {
        display: none;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}
