.paginate-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 40px;
}

.paginate-container>.paginate-size {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}

.paginate-container>.paginate-size>.paginate-size-text {
  color: var(--text-primary-color);
  font-family: Rubik;
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 20px;
}

.paginate-container>.paginate-size>.paginate-size-selector>input {
  background-color: var(--card-background-secondary-color);
  color: var(--text-secondary-color);
  font-family: Rubik;
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 20px;
  border: 1px solid var(--text-primary-color);
  border-radius: 52px;
  outline: none;
  display: flex;
  /* height: 52px;
  width: 100%; */
  padding-left: 16px;
  padding-right: 16px;
  width: 80px;
}

.paginate-container>.paginate-btns-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.paginate-container>.paginate-btns-container>.paginate-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  border: none;
  background-color: transparent;
  color: var(--text-primary-color);
}

.paginate-container>.paginate-btns-container>.paginate-btn.disabled {
  color: var(--text-third-color);
}

.paginate-container>.paginate-btns-container>.paginate-btn:not(.disabled) {
  cursor: pointer;
}

.paginate-container>.paginate-content {
  color: var(--text-primary-color);
  font-family: Rubik;
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 20px;
}

[dir='ltr'] .paginate-container>.paginate-btns-container>.paginate-btn {
  transform: scale(-1, 1);
}