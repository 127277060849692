.sub-agency-item-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr 2.2fr;
  align-items: center;
  .agency-fields {
    display: flex;
    p {
      position: relative;
      &:not(:last-child) {
        padding-inline-end: 12px;
        &:after {
          content: '';
          position: absolute;
          inset-inline-end: -2px;
          top: calc(50% - 2px);
          width: 4px;
          height: 4px;
          border-radius: 2px;
          background-color: var(--text-primary-color);
        }
      }
      &:not(:first-child) {
        padding-inline-start: 12px;
      }
    }
  }
  .agency-companies {
    display: flex;
    gap: 8px;
    align-items: center;
    .agency-companies-list {
      display: flex;
      align-items: center;
      gap: 8px;
    }
    .agency-companies-hidden-badge {
      background-color: rgba(255, 255, 255, 0.17);
      display: flex;
      align-items: center;
      justify-content: center;
      height: 24px;
      min-width: 24px;
      border-radius: 12px;
      font-family: Rubik;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 166%;
      letter-spacing: 0.4px;
      border-radius: 64px;
      border: 1.5px solid rgba(189, 189, 189, 1);
      padding-inline: 6px;
      cursor: pointer;
    }
  }
}
