.marketing-connection-banner-wrapper {
  background: rgba(39, 45, 62, 0.38);
  // background-color: red;
}

.marketing-connection-banner-container {
  max-width: 1080px;
  margin: 0 auto;
  font-family: Rubik;
  font-style: normal;
  font-weight: 400;
  padding-block: 120px;
  // background-color: green;
  h4,
  h5,
  h6,
  p {
    padding: 0;
    margin: 0;
  }
  > h4 {
    font-weight: 400;
    font-size: 24px;
    line-height: 40px;
    letter-spacing: -0.283333px;
    margin-bottom: 15px;
  }
  > .marketing-connection-banner-cards {
    display: flex;
    gap: 26px;
    > .marketing-connection-banner-card {
      flex: 1;
      background: rgba(20, 23, 32, 0.6);
      backdrop-filter: blur(100px);
      border-radius: 20px;
      padding: 24px;
      display: flex;
      flex-direction: column;
      > .marketing-connection-banner-card-icon {
        height: 24px;
        width: 24px;
        margin-bottom: 12px;
        > img {
          height: 100%;
          width: 100%;
        }
      }
      > .marketing-connection-banner-card-name {
        margin-bottom: 12px;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.25px;
      }
      > .marketing-connection-banner-card-desc {
        flex: 1;
        font-weight: 400;
        font-size: 16px;
        line-height: 143%;
        letter-spacing: 0.17px;
        color: var(--text-secondary-color);
      }
    }
  }
}

@media screen and (max-width: 1366px) {
  .marketing-connection-banner-container {
    max-width: 980px;
  }
}

@media screen and (max-width: 480px) {
  .marketing-connection-banner-wrapper {
    display: none;
  }
}
