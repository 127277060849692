.stop-scan-warn-modal {
  width: 440px;
  main {
    padding: 16px 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    h5 {
      font-size: 20px;
      font-weight: 500;
      line-height: 32px;
      letter-spacing: 0.15px;
    }
    p {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.17px;
    }
  }
  footer {
    padding: 8px 16px;
    display: flex;
    justify-content: flex-end;
    gap: 8px;
  }
}
