button.button-rounded {
  background-color: var(--trend-up-color);
  color: rgba(255, 255, 255, 1);
  border: 1px solid var(--trend-up-color);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-family: Rubik;
  font-weight: 500;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  padding: 6px 16px;
  height: 36px;
  min-width: 68px;
  border-radius: 18px;
  cursor: pointer;
  &.outlined {
    background-color: transparent;
    color: var(--trend-up-color);
    border-color: rgba(var(--trend-up-color-rgb), 0.5);
    &:is(:hover, :active) {
      background-color: rgba(var(--trend-up-color-rgb), 0.08);
      border-color: var(--trend-up-color);
    }
  }
  &.danger {
    border-color: rgba(var(--trend-down-color-rgb), 0.5);
    color: var(--text-primary-color);
    &:is(:hover, :active) {
      background-color: rgba(var(--trend-down-color-rgb), 0.08);
      border-color: var(--trend-down-color);
      color: var(--trend-down-color);
    }
  }
  &:active:not(:disabled) {
    transform: scale(0.98);
  }
  &:disabled {
    opacity: 0.6;
    // color: rgba(255, 255, 255, 0.3);
    // background-color: rgba(2, 182, 74, 0.17);
    // border-color: rgba(2, 182, 74, 0.17);
    cursor: default;
  }
  &.large {
    height: 42px;
    padding: 8px 22px;
    gap: 8px;
    font-size: 15px;
    line-height: 26px; /* 173.333% */
    letter-spacing: 0.46px;
    border-radius: 21px;
    &:has(svg:first-child) {
      padding-inline-start: 12px;
    }
  }
  &.danger {
    background-color: var(--trend-down-color);
    border: 1px solid var(--trend-down-color);
    &:disabled {
      background-color: rgba(var(--trend-down-color-rgb), 0.17);
      border-color: rgba(var(--trend-down-color-rgb), 0.17);
    }
  }
}
