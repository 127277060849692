.agency-company-chip {
  height: 34px;
  border-radius: 17px;
  background-color: rgba(255, 255, 255, 0.16);
  display: flex;
  align-items: center;
  gap: 6px;
  color: var(--text-primary-color);
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.17px;
  padding-inline: 10px;
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    background-color: transparent;
    border: none;
    cursor: pointer;
    width: 24px;
    height: 24px;
    svg {
      width: 100%;
      height: 100%;
    }
  }
}
