.policies-screen-wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
  .policies-list-header {
    padding: 24px;
    border-bottom: var(--border-primary);
    h3 {
      font-size: 20px;
      font-weight: 500;
      letter-spacing: 0.25px;
      margin-bottom: 12px;
    }
  }
  .policies-list-tabs {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
  }
  .policies-filters-wrapper {
    padding: 24px;
  }
  .policy-badges {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    align-items: center;
    justify-content: center;
  }
  .policies-lists-appbar-wrapper {
    display: flex;
    justify-content: center;
    position: sticky;
    bottom: 0;
    pointer-events: none;
    z-index: 1;
  }
}

.policies-screen {
  height: 100vh;
  display: flex;
  flex-direction: column;
  > .policies-screen-banners {
    grid-column: span 2;
  }
  .policies-main-container {
    flex: 1;
    background-color: var(--card-background-secondary-color);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .policies-applied-dates-filter-container {
      padding: 14px 26px;
      padding-bottom: 0;
      display: flex;
      gap: 8px;
      align-items: center;
      p {
        margin: 0;
        padding: 0;
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        letter-spacing: 0.5px;
      }
      .policies-applied-dates-filter {
        display: flex;
        align-items: center;
        padding: 8px;
        gap: 10px;
        background: rgba(255, 255, 255, 0.17);
        border: 1px solid rgba(255, 255, 255, 0.38);
        border-radius: 100px;
        button {
          background-color: transparent;
          border: none;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
      }
    }
    > .notify-modals-wrapper {
      position: fixed;
      bottom: 26px;
      left: 26px;
      z-index: 110;
      > .notify-modals-container {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
    }
  }
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
  }
  ::-webkit-scrollbar-thumb {
    // background: var(--border-primary-color);
    background: var(--border-primary-color);
    border-radius: 3px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: var(--background-primary-color);
  }
}
