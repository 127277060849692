.policy-list-item-card {
  background-color: var(--border-primary-color);
  border-radius: 8px;
  min-height: 48px;
  align-items: center;
  &.inactive {
    background-color: rgba(var(--text-primary-color-rgb), 0.17);
  }
  &.future {
    background: linear-gradient(90deg, #354163 2.04%, rgba(46, 60, 100, 0.21) 98.5%);
  }
  &.outdated {
    background: linear-gradient(90.13deg, rgba(250, 254, 56, 0.13) 13.24%, rgba(250, 254, 56, 0.0507) 50.12%);
  }
  &.clickable {
    cursor: pointer;
  }
  &.selected {
    background: rgba(var(--trend-up-color-rgb), 0.12);
    .policy-list-item-select {
      svg {
        color: var(--trend-up-color);
      }
    }
  }
  .policy-list-item-select {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .not-downloaded-grid{
    grid-template-columns: 48px repeat(3, 1fr) !important;
  }
  .not-downloaded-button-container{
    display: flex;
    border-inline-start: none !important;
    justify-content: center;
  }
    .not-found-in-report-container {
      align-items: center;
      gap: 5px;
      border-inline-start: none !important;
    }
  .not-downloaded-button{
    width: 182px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 8px;
    padding: 8px 12px;
    gap: 8px;
    display: flex;
    justify-content: center;
  }
  .not-dowloaded-block{
    display: block !important;
    padding-inline: 0px !important;
  }
  .not-found-in-the-report-label{
    color: rgba(255, 255, 255, 0.2);
    font-size:14px;
  }
}
