.button-back {
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

[dir='ltr'] .button-back > img {
  transform: rotate(180deg);
}
