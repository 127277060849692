.entity-status-chip-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .entity-status-chip {
    border-radius: 4px;
    padding: 2px 6px;
    white-space: nowrap;
    font-size: 12px;
    letter-spacing: 0.4px;
    text-align: center;
  }
}
