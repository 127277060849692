.agency-house-download-sidebar {
  background-color: var(--background-secondary-color);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  width: 360px;
  margin: 24px;
  margin-inline-end: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  > h4 {
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0.15px;
    margin: 0;
    padding: 16px 24px;
    border-bottom: var(--border-primary);
  }
  ul {
    overflow: auto;
    flex: 1;
  }
  a {
    display: flex;
    justify-content: space-between;
    padding-inline: 24px 16px;
    color: var(--text-primary-color);
    text-decoration: none;
    height: 48px;
    align-items: center;
    font-family: Rubik;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
    & + ul a + ul {
      overflow: visible;
      a {
        padding-inline-start: 58px;
      }
    }
    > div {
      display: flex;
      align-items: center;
      &:first-child {
        gap: 10px;
        div:first-child {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 24px;
          height: 24px;
          border-radius: 6px;
          font-size: 12px;
          font-weight: 400;
          line-height: 20px; /* 19.92px */
          letter-spacing: 0.4px;
        }
      }
      &:last-child {
        gap: 12px;
      }
    }
    &.current-link {
      background-color: rgba(var(--text-primary-color-rgb), 0.08);
      &:has(+ ul) {
        border-bottom: 1px solid rgba(var(--text-primary-color-rgb), 0.12);
      }
    }
    button {
      color: var(--text-primary-color);
      border: none;
      background: none;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      height: 20px;
      width: 20px;
    }
  }
  ul a.current-link + ul a {
    background-color: rgba(var(--text-primary-color-rgb), 0.08);
    &.current-link {
      background-color: rgba(var(--text-primary-color-rgb), 0.2);
    }
  }
}
