.search-customer-modal {
  width: 230px;
  height: 300px;
  border-radius: 4px;
  background-color: var(--background-primary-color);
  box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12),
    0 11px 15px -7px rgba(0, 0, 0, 0.2);
  padding-bottom: 6px;
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: default;
  > .search-customer-input-container,
  > .search-customer-list-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  > .search-customer-input-container {
    background-color: var(--card-background-selected);
    border-radius: 8px;
    margin: 6px;
    margin-bottom: 4px;
    height: 32px;
    > div {
      margin: 9px;
    }
    > input {
      background-color: transparent;
      color: var(--text-primary-color);
      caret-color: var(--trend-up-color);
      display: flex;
      width: 100%;
      outline: none;
      border: 1px solid transparent;
      font-family: Rubik;
      font-size: 12px;
      letter-spacing: 0.4px;
      line-height: 16px;
      text-align: right;
    }
    > .search-customer-input-icon {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  > .search-customer-list-container {
    flex: 1;
    align-items: flex-start;
    overflow: hidden;
    > .search-customer-list {
      display: flex;
      flex-direction: column;
      flex: 1;
      height: 100%;
      overflow-y: scroll;
      margin-left: 16px;
      margin-right: 16px;
      > .search-customer-list-item {
        margin-top: 8px;
        display: flex;
        gap: 16px;
        cursor: pointer;
        > .search-customer-list-item-content {
          flex: 1;
          > .search-customer-list-item-name {
            color: var(--text-primary-color);
            font-family: Rubik;
            font-size: 14px;
            letter-spacing: 0.25px;
            line-height: 20px;
            text-align: right;
            > span {
              font-weight: bold;
            }
          }
          > .search-customer-list-item-idnum {
            color: var(--text-secondary-color);
            font-family: Rubik;
            font-size: 12px;
            letter-spacing: 0.4px;
            line-height: 16px;
            text-align: right;
            margin-bottom: 3px;
          }
        }
        &:hover {
          > .search-customer-list-item-content {
            > .search-customer-list-item-name {
              color: var(--text-secondary-color);
            }
          }
        }
        &:not(:last-child) {
          > .search-customer-list-item-content {
            border-bottom: 1px solid var(--text-secondary-color);
          }
        }
      }
      &::-webkit-scrollbar {
        width: 8px;
      }
      &::-webkit-scrollbar-track {
        padding-inline-start: 3px;
        background-clip: padding-box;
        border-inline-start: 3px solid transparent;
      }
      &::-webkit-scrollbar-thumb {
        background-color: green;
        background: var(--card-background-selected);
        padding-inline-start: 3px;
        border-inline-start: 3px solid transparent;
        background-clip: padding-box;
        border-radius: 4px;
        border-end-end-radius: 2px;
        border-start-end-radius: 2px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background-color: cyan;
        background: var(--background-primary-color);
        padding-inline-start: 3px;
        border-inline-start: 3px solid transparent;
        background-clip: padding-box;
        border-radius: 4px;
        border-end-end-radius: 2px;
        border-start-end-radius: 2px;
      }
    }
  }
  > .search-customers-add-btn-container {
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid var(--text-secondary-color);
    height: 46px;
    > button {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.17px;
      color: var(--text-primary-color);
      > .search-customers-add-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        > img {
          height: 16px;
          width: 16px;
        }
      }
    }
  }
  > .search-customer-modal-loader-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    border-radius: 4px;
    backdrop-filter: blur(2px);
    z-index: 1;
  }
}
