.agencies-screen {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  font-family: Rubik;
  h5 {
    margin: 0;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 133.4%;
  }
}
