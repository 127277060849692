.profile-connected-agency {
  width: 420px;
  background-color: var(--border-primary-color);
  border-radius: 8px;
  padding: 24px;
  > div {
    &:first-child {
      padding-bottom: 24px;
      display: flex;
      justify-content: space-between;
      > div {
        display: flex;
        p:first-child {
          font-size: 16px;
          font-weight: 500;
          line-height: 28px;
          letter-spacing: 0.15px;
        }
        p:last-child {
          color: var(--text-secondary-color);
          font-size: 12px;
          line-height: 20px;
          letter-spacing: 0.4px;
        }
      }
    }
    &:last-child {
      padding-top: 24px;
      border-top: 1px solid rgba(var(--text-primary-color-rgb), 0.12);
      p {
        color: var(--text-secondary-color);
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.4px;
      }
    }
  }
}

.cancel-connection-warn-modal-content {
  margin: 8px 24px;
  padding: 14px 16px;
  border-radius: 4px;
  background: linear-gradient(
      0deg,
      rgba(var(--text-primary-color-rgb), 0.9) 0%,
      rgba(var(--text-primary-color-rgb), 0.9) 100%
    ),
    #d32f2f;
  color: #d32f2f;
  p:first-child {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
    margin-bottom: 6px;
  }
  p:last-child {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.17px;
  }
}
