.add-user-modal-container {
  height: 420px;
  width: 330px;
  display: flex;
  flex-direction: column;
  .add-user-modal-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 24px;
    padding-bottom: 8px;
    color: var(--text-primary-color);
    font-family: Rubik;
    line-height: 20px;
    .add-user-modal-title {
      margin-bottom: 24px;
      font-size: 16px;
      letter-spacing: 0.5px;
    }
    .add-user-modal-subtitle {
      margin-bottom: 8px;
      color: var(--text-secondary-color);
      font-size: 12px;
      letter-spacing: 0.4px;
      line-height: 16px;
    }
    .add-user-modal-list {
      overflow-y: auto;
      .add-user-modal-list-item {
        display: flex;
        align-items: center;
        cursor: pointer;
        .add-user-modal-list-item-ico {
          width: 36px;
          height: 36px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .add-user-modal-list-item-text {
          padding-top: 8px;
          padding-bottom: 7px;
          flex: 1;
          border-bottom: var(--border-primary);
          font-size: 14px;
          letter-spacing: 0.25px;
        }
      }
      &::-webkit-scrollbar {
        width: 8px;
      }
      &::-webkit-scrollbar-track {
        padding-inline-start: 3px;
        background-clip: padding-box;
        border-inline-start: 3px solid transparent;
      }
      &::-webkit-scrollbar-thumb {
        background-color: green;
        background: var(--border-primary-color);
        padding-inline-start: 3px;
        border-inline-start: 3px solid transparent;
        background-clip: padding-box;
        border-radius: 4px;
        border-end-end-radius: 2px;
        border-start-end-radius: 2px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background-color: cyan;
        background: var(--background-primary-color);
        padding-inline-start: 3px;
        border-inline-start: 3px solid transparent;
        background-clip: padding-box;
        border-radius: 4px;
        border-end-end-radius: 2px;
        border-start-end-radius: 2px;
      }
    }
  }
  .add-user-modal-footer {
    margin-top: auto;
    padding: 10px 24px;
    display: flex;
    justify-content: flex-end;
    box-shadow: 0 -2px 15px 0 rgba(27, 31, 43, 0.6);
    > div > .button {
      width: 120px;
    }
  }
}
