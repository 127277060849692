.customers-list-item-wrapper {
  border-radius: 8px;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  margin-bottom: 8px;
  color: var(--text-primary-color);
  font-family: Rubik;
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 20px;
  text-decoration: none;
  // border: var(--border-primary);
  background-color: var(--background-secondary-color);
  &.inactive {
    background-color: rgba(255, 255, 255, 0.17);
  }
  &.selected {
    border: 1px solid var(--text-third-color);
    background-color: var(--text-third-color);
  }
  &.checked {
    background-color: rgba(var(--trend-up-color-rgb), 0.17);
  }
  &.checked:not(.selected) {
    border-color: transparent;
  }
  .customers-list-item {
    justify-content: center;
    align-items: center;
    min-height: 48px;
    cursor: pointer;
    > .customers-list-item-checkbox {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    > .customer-name {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;
    }
    > .customer-id-number {
      color: var(--text-secondary-color);
    }
    > .commission-rate {
      > .commission-split {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }
    }
    > .customer-status {
      display: flex;
      gap: 4px;
      flex-wrap: wrap;
      justify-content: center;
      padding: 4px;
    }
    > .customer-menu {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    > * > a {
      color: var(--text-primary-color);
      font-family: Rubik;
      font-size: 14px;
      letter-spacing: 0.25px;
      line-height: 20px;
      text-align: right;
      text-decoration: none;
    }
    &.inactive > div:not(.customer-status) {
      opacity: 0.7;
    }
  }
}
