.profile-fields>p {
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.15px;
  margin-bottom: 20px;
}

.fields-container {
  display: flex;
  gap: 36px;
  flex-wrap: wrap;
}