.agencies-navbar {
  // background-color: red;
  ul {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid var(--border-primary-color);
    li {
      display: flex;
      // flex: 1;
      align-items: flex-end;
      a {
        display: block;
        padding: 8px 16px;
        text-decoration: none;
        color: var(--text-secondary-color);
        font-size: 14px;
        font-family: Rubik;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.4px;
        text-transform: uppercase;
        border-bottom: 2px solid transparent;
        &.agencies-navbar-active-item {
          color: var(--trend-up-color);
          border-bottom-color: var(--trend-up-color);
        }
      }
      // &:has(.agencies-navbar-active-item) {
      //   border-bottom: none;
      // }
    }
  }
}
