.customer-details-input-container {
  background-color: var(--card-background-secondary-color);
  border-radius: 10px;
  overflow: hidden;
  > .customer-details-input-title {
    color: var(--trend-up-color);
    font-family: Rubik;
    font-size: 12px;
    letter-spacing: 0.4px;
    line-height: 16px;
    text-align: right;
    padding-top: 3px;
    padding-right: 10px;
    padding-left: 10px;
  }
  input {
    position: relative;
    background-color: transparent;
    border: none;
    width: 100%;
    color: var(--text-primary-color);
    outline: none;
    border-bottom: 2px solid var(--trend-up-color);
    caret-color: var(--trend-up-color);
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 4px;
    font-family: Rubik;
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 20px;
    text-align: right;
  }
  > .customer-details-input-btns-container {
    position: absolute;
    display: flex;
    gap: 6px;
    bottom: -25px;
    inset-inline-end: 0;
    > .customer-details-input-btn {
      height: 30px;
      width: 30px;
      border-radius: 12px;
      background-color: var(--background-secondary-color);
      box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12),
        0 11px 15px -7px rgba(0, 0, 0, 0.2);
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--text-primary-color);
      cursor: pointer;
    }
    > .customer-details-input-btn:hover {
      filter: brightness(150%) saturate(140%);
    }
    > .customer-details-input-btn:active {
      filter: brightness(150%) saturate(140%);
      transform: scale(0.9);
      transition: all 0.1s ease-in-out;
    }
  }
}
