.payment-conatiner {
  font-family: 'Rubik';
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.15px;
  color: var(--text-secondary-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .payment-conatiner {
    flex: 1;
  }
}
