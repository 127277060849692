.customer-actions-modal-container {
  padding: 24px;
  width: 330px;
  display: flex;
  flex-direction: column;
  color: var(--text-primary-color);
  font-family: Rubik;
  line-height: 20px;
  .customer-actions-modal-title {
    margin-bottom: 24px;
    font-size: 16px;
    letter-spacing: 0.5px;
  }
  .customer-actions-modal-subtitle {
    margin-bottom: 8px;
    color: var(--text-secondary-color);
    font-size: 12px;
    letter-spacing: 0.4px;
    line-height: 16px;
  }
  .customer-actions-modal-actions {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 1fr;
    gap: 12px;
    margin-bottom: 24px;
    .customer-actions-modal-action {
      cursor: pointer;
      border-radius: 8px;
      background-color: var(--border-primary-color);
      padding-bottom: 8px;
      padding-top: 8px;
      min-height: 64px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      .customer-actions-modal-action-title {
        font-size: 14px;
        letter-spacing: 0.25px;
        line-height: 20px;
        text-align: center;
      }
    }
    .customer-actions-modal-action:hover,
    .customer-actions-modal-action.selected {
      background-color: rgba(var(--trend-up-color-rgb), 0.17);
      img {
        filter: invert(57%) sepia(58%) saturate(5102%) hue-rotate(115deg) brightness(95%) contrast(102%);
      }
      .customer-actions-modal-action-title {
        color: var(--trend-up-color);
      }
    }
    .customer-actions-modal-action:active {
      transform: scale(0.95);
      transition: all 0.05s ease-in-out;
    }
  }
  .customer-actions-modal-btns {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
    > div > .button {
      width: 120px;
    }
    > .back-button {
      display: flex;
      cursor: pointer;
      background: none;
      border: none;
      padding: 0;
      > img {
        width: 13px;
      }
    }
  }
}
