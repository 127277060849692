.scan-company-creds-form {
  --password-icon-translate-x: 16px;
  --line-after-translate-x: 50%;
}

[dir='ltr'] .scan-company-creds-form {
  --password-icon-translate-x: -16px;
  --line-after-translate-x: -50%;
}

.scan-company-creds-form {
  .inputs-container {
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
    margin-bottom: 24px;
    .input-container {
      flex: 1 0 48%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      input {
        flex: 1;
        background-color: rgba(var(--text-primary-color-rgb), 0.09);
        color: var(--text-primary-color);
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
        border: none;
        outline: none;
        display: flex;
        padding-inline: 16px;
        padding-top: 20px;
        padding-bottom: 4px;
        font-family: Rubik;
        font-size: 16px;
        letter-spacing: 0.5px;
        line-height: 28px;
        &:has(+ .password-icon) {
          padding-inline-end: 44px;
        }
      }
      input:not([value='']):not(:placeholder-shown) ~ .password-icon {
        display: block;
      }

      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: var(--text-secondary-color) !important;
      }
      input:focus ~ label,
      input:not(:placeholder-shown) ~ label {
        color: var(--text-secondary-color);
        font-size: 12px;
        letter-spacing: 0.4px;
        line-height: 16px;
        transform: translateY(6px);
      }
      .line {
        position: absolute;
        height: 2px;
        width: 100%;
        background: transparent;
        inset-inline-start: 50%;
        bottom: 0;
      }
      .line:after {
        content: '';
        display: block;
        width: 0;
        background: var(--text-primary-color);
        height: 2px;
        transition: all 0.5s;
        inset-inline-start: 50%;
        bottom: 0;
        transform: translateX(var(--line-after-translate-x));
      }
      input:focus ~ .line:after,
      input:not(:placeholder-shown) ~ .line:after {
        width: 100%;
        transform-origin: 50% 0%;
      }
      .password-icon {
        position: absolute;
        inset-inline-end: 0;
        top: 0;
        transform: translate(var(--password-icon-translate-x), 25px);
        cursor: pointer;
        display: none;
        font-size: 16px;
      }
      label {
        color: var(--text-primary-color);
        font-size: 16px;
        letter-spacing: 0.5px;
        line-height: 28px;
        margin-inline: 16px;
        position: absolute;
        inset-inline-start: 0;
        top: 0;
        transition: all 0.3s ease-in;
        transform: translateY(16px);
        pointer-events: none;
      }
      input.warn {
        background-color: rgba(var(--trend-down-color-rgb), 0.17) !important;
      }
      label.warn {
        color: var(--trend-down-color) !important;
      }
      .line.warn:after {
        background: var(--trend-down-color) !important;
      }
    }
  }
  > div:last-child {
    display: flex;
    justify-content: center;
  }
}
