.workstation-banner {
  display: flex;
  gap: 12px;
  background: rgba(var(--trend-up-color-rgb), 0.17);
  border-radius: 8px;
  padding: 12px 16px;
}

@media screen and (max-width: 767px) {
  .workstation-banner {
    align-items: center;
    padding: 16px;
  }
}
