.add-agency-summary-container {
  display: flex;
  flex-direction: column;
  h5,
  h6,
  p {
    font-family: Rubik;
    margin: 0;
  }
  > header {
    padding-top: 60px;
    > h6 {
      font-size: 16px;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: 0.15px;
      text-align: center;
    }
    > h5 {
      font-size: 24px;
      font-weight: 400;
      line-height: 32px;
      letter-spacing: 0px;
      text-align: center;
    }
  }
  .add-agency-summary-content {
    // flex: 1;
    overflow: auto;
    // width: 610px;
    margin-inline: auto;
    margin-top: 120px;
    margin-bottom: auto;
    // align-items: center;
    // justify-content: center;
    display: flex;
    gap: 40px;
    // display: grid;
    // grid-template-columns: repeat(auto-fill, 280px); /* see notes below */
    // grid-gap: 40px;
  }
  footer {
    background-color: var(--background-primary-color);
    box-shadow: 0px -4px 20px 0px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 34px;
    height: 72px;
  }
}
