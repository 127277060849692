.add-company-modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-backdrop-filter: blur(4px) brightness(70%);
  backdrop-filter: blur(4px) brightness(70%);
  z-index: 100;
  /* pointer-events: none; */
}

.profile-add-company-modal-container {
  background-color: var(--background-secondary-color);
  box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.2);
  width: 450px;
  height: 430px;
  border-radius: 8px;
  padding: 24px;
  position: relative;

  display: flex;
}

.profile-add-company-modal-container>.add-company-modal-close-icon {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background-color: var(--border-primary-color);
  border-radius: 8px 0 8px 0;
  cursor: pointer;
}

.profile-add-company-modal-container>.add-company-modal-close-icon:hover {
  filter: brightness(150%) saturate(140%);
}

.profile-add-company-modal-container>.add-company-modal-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.profile-add-company-modal-container>.add-company-modal-content>.no-data-container>.no-data-text {
  max-width: 220px;
}

.profile-add-company-modal-container>.add-company-modal-content>.add-company-modal-title {
  color: var(--text-primary-color);
  font-family: Rubik;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 20px;
  text-align: right;
  margin-bottom: 24px;
}

.profile-add-company-modal-container>.add-company-modal-content>.add-company-modal-companies-list-title {
  color: var(--text-secondary-color);
  font-family: Rubik;
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 16px;
  text-align: right;
  margin-bottom: 14px;
}

.profile-add-company-modal-container>.add-company-modal-content>.add-company-modal-companies-list {
  margin-bottom: 34px;
  overflow-y: scroll;
}

.profile-add-company-modal-container>.add-company-modal-content>.add-company-modal-companies-list>.no-data-container>.no-data-text {
  max-width: 220px;
}

.profile-add-company-modal-container>.add-company-modal-content>.add-company-modal-companies-list>ul {
  overflow-y: scroll;
}

.profile-add-company-modal-container>.add-company-modal-content>.add-company-modal-companies-list>ul:not(:last-child) {
  margin-bottom: 12px;
}

.profile-add-company-modal-container>.add-company-modal-content>.add-company-modal-companies-list>ul>.add-company-modal-company-item {
  display: flex;
  justify-content: space-between;
  gap: 24px;
  cursor: pointer;
}

.profile-add-company-modal-container>.add-company-modal-content>.add-company-modal-companies-list>ul>.add-company-modal-company-item:not(:first-child) {
  margin-top: 10px;
}

.profile-add-company-modal-container>.add-company-modal-content>.add-company-modal-companies-list>ul>.add-company-modal-company-item>.company-name-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  border-bottom: var(--border-primary);
}

.profile-add-company-modal-container>.add-company-modal-content>.add-company-modal-companies-list>ul>.add-company-modal-company-item>.company-name-container>.company-name {
  color: var(--text-primary-color);
  font-size: 16px;
  letter-spacing: 0;
  line-height: 28px;
  font-family: Rubik;
}

.profile-add-company-modal-container>.add-company-modal-content>.add-company-modal-companies-list>ul>.add-company-modal-company-item>.company-name-container>.company-select {
  display: flex;
}

.profile-add-company-modal-container>.add-company-modal-content>.add-company-modal-companies-list>ul>.add-company-modal-company-item>.company-name-container>.company-select>img {
  height: 20px;
  width: 20px;
}

.profile-add-company-modal-container>.add-company-modal-content>.add-company-modal-companies-list>ul>.add-company-modal-company-item>.company-logo {
  height: 53px;
  min-width: 53px;
  border-radius: 12px;
  background-color: var(--background-primary-color);
  border: var(--border-primary);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .add-company-modal-content > .add-company-modal-companies-list > .add-company-modal-company-item > .company-logo:after {
  content: 'no logo';
  position: absolute;
} */

.profile-add-company-modal-container>.add-company-modal-content>.add-company-modal-companies-list>ul>.add-company-modal-company-item>.company-logo>img {
  height: 36px;
  border-radius: 12px;
}

.profile-add-company-modal-container>.add-company-modal-content>.add-company-modal-companies-list>h4 {
  margin: 0;
  padding: 0;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: var(--text-secondary-color);
}

.profile-add-company-modal-container .add-company-modal-submit-btn {
  margin-top: auto;
  display: flex;
  justify-content: center;
}

/* width */
.profile-add-company-modal-container ::-webkit-scrollbar {
  width: 6px;
}

/* Track */
.profile-add-company-modal-container ::-webkit-scrollbar-track {}

/* Handle */
.profile-add-company-modal-container ::-webkit-scrollbar-thumb {
  background: var(--border-primary-color);
  border-radius: 3px;
}

/* Handle on hover */
.profile-add-company-modal-container ::-webkit-scrollbar-thumb:hover {
  background: var(--background-primary-color);
}