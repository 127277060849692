@import '../../../../mixins.scss';

.scan-company-popup-login-error {
  width: 560px;
  > header {
    padding: 16px 24px;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    p {
      color: #f44336;
      font-size: 20px;
      font-weight: 500;
      line-height: 32px;
      letter-spacing: 0.15px;
    }
  }
  > p {
    padding: 8px 24px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
  }
  > .scan-company-creds-form {
    padding: 24px;
    > .inputs-container {
      > .input-container {
        flex: 1 1 230px;
      }
    }
  }
}
