.customer-group-chart-wrapper {
  border: var(--border-primary);
  border-radius: 20px;
  background-color: var(--card-background-secondary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  height: 100%;
  .customer-group-chart-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    position: relative;
    > .customer-group-chart-header {
      display: flex;
      flex-direction: column;
      padding: 8px 12px 14px;
      > .customer-group-chart-value,
      > .customer-group-chart-changes {
        display: flex;
        // flex-direction: column;
        justify-content: space-between;
        align-items: center;
      }
      > .customer-group-chart-value {
        position: relative;
        > .customer-group-chart-value-title {
          letter-spacing: 0.5px;
          line-height: 20px;
        }
        > .customer-group-chart-value-content {
          font-weight: 500;
          font-size: 12px;
          line-height: 28px;
          letter-spacing: 0.8125px;
          .trend-container {
            gap: 5px;
            .trend-value-container {
              gap: unset;
              .trend-value,
              .trend-currency {
                font-family: 'Rubik';
                font-weight: 500;
                font-size: 12px;
                line-height: 28px;
                letter-spacing: 0.625px;
                color: var(--text-primary-color);
              }
            }
            img {
              height: 11px;
            }
          }
        }
        &:not(:only-child):after {
          content: '';
          position: absolute;
          bottom: 0;
          height: 1px;
          width: 40px;
          background-color: var(--border-primary-color);
          left: 50%;
          transform: translateX(-50%);
        }
      }
      > .customer-group-chart-changes {
        > .customer-group-chart-changes-title {
          letter-spacing: 0.25px;
          line-height: 20px;
          color: var(--text-secondary-color);
        }
        > .customer-group-chart-changes-content {
          font-weight: 500;
          font-size: 12px;
          line-height: 28px;
          letter-spacing: 0.625px;
          direction: ltr;
          text-align: right;
        }
      }
    }
    > .customer-group-chart {
      padding-inline-start: 12px;
      flex: 1;
      //apex chart height hack for flex 1
      height: 1%;
    }
    > .customer-group-chart-remark {
      border-top: var(--border-primary);
      background-color: var(--card-background-fourth-color);
      color: var(--text-secondary-color);
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      padding: 4px 6px;
    }
  }
  .no-data-container {
    padding: 16px;
    .no-data-text {
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.25px;
    }
  }
  .customer-group-chart-no-access-blured {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    backdrop-filter: blur(6px);
    z-index: 1;
  }
}
