.tooltip-container {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -115%);
  z-index: 10;
}

.tooltip-container {
  visibility: hidden;
  width: 110px;
  border-radius: 4px;
  padding: 4px 12px;
  background-color: var(--border-primary-color);
  color: var(--text-primary-color);
  font-family: Rubik;
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 16px;
  text-align: center;
  /* opacity: 0;
  transition: opacity 1s; */
  box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.2);
  transition-delay: 250ms;
}

.tooltip-container.left {
  top: 50%;
  left: -5px;
  transform: translate(-110%, -50%);
}

.tooltip-container.right {
  top: 50%;
  left: unset;
  right: -5px;
  transform: translate(110%, -50%);
}

.tooltip-container.lg {
  width: 140px;
  padding: 14px 12px;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 20px;
}

div:hover>.tooltip-container>.tooltip-title {
  /* visibility: visible; */
  /* opacity: 1; */
}

.tooltip-container:after {
  /* .tooltip-container > .tooltip-content:after { */
  content: ' ';
  position: absolute;
  top: 100%;
  /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: var(--border-primary-color) transparent transparent transparent;
}

.tooltip-container.left:after {
  top: 50%;
  /* At the bottom of the tooltip */
  left: 100%;
  margin: unset;
  margin-top: -5px;
  border-color: transparent transparent transparent var(--border-primary-color);
}

.tooltip-container.right:after {
  top: 50%;
  /* At the bottom of the tooltip */
  right: 100%;
  margin: unset;
  margin-top: -5px;
  border-color: transparent var(--border-primary-color) transparent transparent;
}