.input-radio-container .MuiFormGroup-row {
  gap: 32px;
}

.input-radio-container .MuiFormControlLabel-root {
  margin: 0;
  margin-top: 20px;
  display: flex;
  gap: 10px;
}

.input-radio-container .MuiRadio-root {
  color: var(--text-primary-color);
  padding: 0;
}

.input-radio-container .MuiRadio-colorSecondary.Mui-checked,
.input-radio-container .Mui-checked .MuiSvgIcon-root {
  color: var(--trend-up-color);
}

.input-radio-container .PrivateSwitchBase-root-1 {
  padding: 0;
}

.input-radio-container.disabled {
  opacity: .7;
}