.agents-search-input-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    &:first-child {
      top: 50%;
      left: 18px;
      transform: translateY(-50%);
    }
    &:last-child {
      right: 18px;
    }
  }
  > input {
    background-color: var(--card-background-secondary-color);
    background-color: #2b3142;
    color: var(--text-third-color);
    font-family: Rubik;
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 28px;
    text-align: right;
    border: 1px solid transparent;
    outline: none;
    display: flex;
    height: 52px;
    width: 100%;
    padding-inline: 50px;
    &:focus,
    &:not(:placeholder-shown) {
      color: var(--text-primary-color);
      text-align: right;
    }
  }
}
