.search-input-container {
  background-color: var(--border-primary-color);
  filter: brightness(110%);
  border-radius: 8px;
  margin-bottom: 6px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    margin: 9px;
  }
  > input {
    background-color: transparent;
    color: var(--text-primary-color);
    caret-color: var(--trend-up-color);
    display: flex;
    width: 100%;
    outline: none;
    border: 1px solid transparent;
    font-family: Rubik;
    font-size: 12px;
    letter-spacing: 0.4px;
    line-height: 16px;
    text-align: right;
  }
  > .search-input-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.search-input-container.empty {
  filter: brightness(90%);
}
