.add-agent-group-modal {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  width: 1280px;
  height: 775px;
  display: flex;
  flex-direction: column;
  position: relative;
  .add-agent-group-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #272d3e;
    padding: 16px 24px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    letter-spacing: 0.15px;
    border-bottom: var(--border-primary);
    > button {
      border: none;
      background: none;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      cursor: pointer;
      transition: all 0.25 ease-in;
      &:active {
        transform: scale(0.95);
      }
    }
  }
  .add-agent-group-modal-content {
    display: flex;
    flex: 1;
    overflow: hidden;
    .add-agent-group-modal-sidebar {
      background-color: #222837;
      padding: 24px;
      width: 370px;
      display: flex;
      flex-direction: column;
      .add-agent-group-modal-selected-heading {
        margin-top: 31px;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;
        color: var(--text-secondary-color);
        margin-bottom: 12px;
      }
      .add-agent-group-modal-selected-items {
        display: flex;
        gap: 8px;
        flex-wrap: wrap;
        overflow: auto;
        overscroll-behavior: contain;
        .add-agent-group-modal-selected-item-wrapper {
          display: flex;
          gap: 8px;
          align-items: start;
          background-color: #2b3245;
          border-radius: 16px;
          padding: 12px;
          padding-inline-end: 6px;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.25px;
          .add-agent-group-modal-selected-item {
            color: var(--text-secondary-color);
            > :not(:first-child) {
              border-top: 1px solid var(--text-secondary-color);
              margin-top: 4px;
              padding-top: 4px;
            }
            .add-agent-group-modal-selected-item-main {
              display: flex;
              align-items: center;
              gap: 8px;
              color: var(--text-primary-color);
            }
          }
        }
      }
    }
    .add-agent-group-modal-main {
      display: flex;
      flex-direction: column;
      flex: 1;
      background: var(--background-secondary-color);
      .add-agent-group-modal-main-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        .add-agent-group-modal-lists-container {
          padding: 24px 26px;
          overflow: hidden;
          overscroll-behavior: contain;
          border-top: var(--border-primary);
          flex: 1;
          display: flex;
          flex-direction: column;
          .add-agent-group-modal-list-container {
            overflow: hidden;
            display: flex;
            flex-direction: column;
            flex: 1;
            gap: 12px;
          }
          .add-agent-group-modal-list-grid {
            display: grid;
            padding-inline: 24px;
            grid-template-columns: 20px 1.5fr 2fr 1.5fr 1.5fr 1.5fr 24px;
            align-items: center;
            gap: 16px;
            &.extended {
              grid-template-columns: 20px 1.5fr 2fr 1fr 1.5fr 1.5fr 1.5fr 24px;
            }
            > div:first-child:not(.loading-overlay) {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 20px;
              height: 20px;
              cursor: pointer;
            }
          }
          .add-agent-group-modal-list-heading {
            padding-top: 12px;
            background: var(--background-secondary-color);
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.4px;
            color: var(--text-secondary-color);
            z-index: 1;
            > .sortable-column {
              cursor: pointer;
              i {
                margin-inline-start: 6px;
              }
            }
            .agency-house-analytics-filters-picker-btn {
              height: 16px;
              padding: unset;
              font-size: 12px;
              line-height: 16px;
              letter-spacing: 0.4px;
              justify-content: unset;
              background-color: unset;
            }
            .add-agent-group-modal-list-heading-btn-container {
              height: 24px;
              cursor: pointer;
              display: flex;
              align-items: center;
              gap: 4px;
              &.applied {
                color: var(--text-primary-color);
                svg {
                  height: 18px;
                }
              }
              svg {
                color: var(--text-secondary-color);
              }
            }
          }
          .add-agent-group-modal-list {
            overflow: auto;
            flex: 1;
            .add-agent-group-modal-list-item {
              position: relative;
              min-height: 48px;
              border-radius: 8px;
              background-color: var(--border-primary-color);
              font-size: 14px;
              line-height: 20px;
              letter-spacing: 0.25px;
              &:not(:last-child) {
                margin-bottom: 8px;
              }
              .add-agent-group-modal-list-item-name {
                display: flex;
                align-items: center;
                gap: 12px;
                .add-agent-group-modal-list-item-avatar {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  height: 24px;
                  width: 24px;
                  border-radius: 4px;
                }
              }
              .add-agent-group-modal-list-item-company {
                display: flex;
                align-items: center;
                gap: 8px;
              }
              .loading-overlay {
                backdrop-filter: blur(6px) brightness(70%);
              }
            }
          }
          .add-agent-group-modal-no-data-container {
            height: 100%;
            display: flex;
          }
          > div:not(:last-child) {
            margin-bottom: 24px;
          }
          button:disabled {
            opacity: 0.3;
          }
          .loader-container {
            flex: unset;
          }
        }
      }
      .add-agent-group-modal-footer {
        display: flex;
        padding: 24px;
        justify-content: flex-end;
      }
    }
    .add-agent-group-modal-company-logo {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--background-primary-color);
      height: 24px;
      width: 24px;
      border-radius: 5px;
      img {
        height: 16px;
        width: 16px;
      }
    }
  }
  .add-agent-group-modal-loader-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    border-radius: 8px;
    backdrop-filter: blur(4px);
    z-index: 1;
  }
}

@media screen and (max-height: 767px) {
  .add-agent-group-modal {
    width: 1080px;
    height: 580px;
    .add-agent-group-modal-content {
      .add-agent-group-modal-sidebar {
        width: 280px;
      }
    }
  }
}
