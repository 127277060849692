.agent-groups-banner {
  border-bottom: var(--border-primary);
  background: #18312f;
  padding: 22px 24px;
  display: flex;
  gap: 12px;
  align-items: center;
  .agent-groups-banner-ico {
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .agent-groups-banner-text {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.5px;
    > span {
      font-weight: 500;
      color: var(--trend-up-color);
    }
  }
}

@media screen and (max-width: 767px) {
  .agent-groups-banner {
    //display: none;
  }
}
