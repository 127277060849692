.policies-pagination {
  display: flex;
  border-top: var(--border-primary);
  justify-content: flex-end;
  padding: 12px 24px;
  gap: 40px;
  .paginate-size-selector {
    display: flex;
    gap: 12px;
    > input {
      background-color: var(--card-background-secondary-color);
      color: var(--text-secondary-color);
      font-size: 14px;
      letter-spacing: 0.25px;
      border: 1px solid var(--text-primary-color);
      border-radius: 52px;
      outline: none;
      display: flex;
      padding-inline: 16px;
      width: 80px;
    }
  }
  .paginate-btns-container {
    display: flex;
    gap: 20px;
    button {
      height: 20px;
      width: 20px;
      &:disabled {
        opacity: 0.3;
      }
    }
  }
}
