.two-factor-inputs-container {
  width: 460px;
  background: rgba(49, 57, 79, 0.38);
  border: 1px solid var(--border-primary-color);
  border-radius: 39px;
  overflow: hidden;
  display: flex;
  position: relative;
  > .two-factor-inputs-group {
    flex: 1;
    display: flex;
    direction: ltr;
    align-items: center;
    justify-content: space-around;
    > input {
      padding: 0 28px;
      text-align: right;
      flex: 1;
      background: transparent;
      border: none;
      outline: none;
      caret-color: var(--trend-up-color);
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      letter-spacing: 0.5px;
      color: var(--text-primary-color);
      &::placeholder {
        color: var(--text-secondary-color);
      }
      &:disabled {
        opacity: 0.7;
      }
      &.input-otp {
        padding: 0;
        height: 100%;
        text-align: center;
        &:not(:last-child) {
          border-inline-end: var(--border-primary);
          border-radius: unset;
        }
      }
      &:is(:-webkit-autofill, :autofill),
      &:is(:-webkit-autofill, :autofill):focus {
        -webkit-box-shadow: 0 0 0 1000px transparent inset !important;
        -webkit-text-fill-color: var(--text-primary-color) !important;
        transition: background-color 5000s ease-in-out 0s !important; /* Prevent autofill flash */
        background-color: transparent !important;
      }
    }
  }
  > button {
    min-width: 140px;
    background-color: var(--trend-up-color);
    border: none;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    padding: 8px 32px;
    border-radius: 24px;
    margin: 6px;
    color: var(--text-primary-color);
    white-space: nowrap;
    cursor: pointer;
    &:disabled {
      opacity: 0.38;
    }
  }
  > .two-factor-error {
    position: absolute;
    color: var(--trend-down-color);
  }
  &.has-error {
    background: rgba(251, 70, 89, 0.38);
    border-color: #fb4659;
  }
}

[data-theme='light'] .two-factor-inputs-container {
  border-color: var(--trend-up-color);
  background: rgba(255, 255, 255, 0.38);
}
