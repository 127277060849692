.tabs-navbar {
  // background-color: red;
  &.disabled {
    opacity: 0.6;
    li {
      cursor: default;
      &:not(.tabs-navbar-active-item):hover {
        opacity: unset;
      }
    }
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid var(--border-primary-color);
    li {
      display: flex;
      // flex: 1;
      align-items: flex-end;
      padding: 8px 16px;
      text-decoration: none;
      color: var(--text-secondary-color);
      font-size: 14px;
      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.4px;
      text-transform: uppercase;
      border-bottom: 2px solid transparent;
      cursor: pointer;
      &.tabs-navbar-active-item {
        cursor: default;
        color: var(--trend-up-color);
        border-bottom-color: var(--trend-up-color);
      }
      &:not(.tabs-navbar-active-item):hover {
        opacity: 0.7;
      }
    }
  }
}
