.scan-history-sidebar-item {
  padding-inline-start: 18px;
  .scan-history-sidebar-item-header {
    margin-bottom: 8px;
    p {
      position: relative;
      color: var(--text-secondary-color);
      font-size: 12px;
      line-height: 16px; /* 133.333% */
      letter-spacing: 0.4px;
      &:before {
        content: '';
        position: absolute;
        inset-inline-start: -18px;
        transform: translateY(-50%);
        top: 50%;
        height: 6px;
        width: 6px;
        border-radius: 3px;
        background-color: var(--text-primary-color);
      }
    }
  }
  .scan-history-sidebar-item-companies {
    display: flex;
    flex-direction: column;
    gap: 12px;
    .scan-history-sidebar-item-company {
      .scan-history-sidebar-item-company-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;
        > div:first-child {
          display: flex;
          gap: 10px;
          p {
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.15px;
          }
        }
      }
      .scan-history-sidebar-item-records {
        display: flex;
        flex-direction: column;
        gap: 8px;
        .scan-history-sidebar-item-record {
          display: flex;
          padding: 12px 14px;
          flex-direction: column;
          gap: 10px;
          border-radius: 8px;
          background-color: rgba(var(--text-primary-color-rgb), 0.17);
          > div:first-child {
            display: flex;
            align-items: center;
            gap: 10px;
          }
          > div:last-child {
            p:first-child {
              font-size: 14px;
              line-height: 20px; /* 142.857% */
              letter-spacing: 0.25px;
            }
            p:last-child {
              font-size: 12px;
              line-height: 16px; /* 133.333% */
              letter-spacing: 0.4px;
              &.error:not(.disabled) {
                color: var(--trend-down-color);
              }
              &.disabled {
                color: var(--text-secondary-color);
              }
            }
          }
          .summary {
            p {
              color: var(--text-secondary-color);
              font-size: 12px;
              line-height: 16px; /* 133.333% */
              letter-spacing: 0.4px;
            }
          }
          .record-status-chip {
            height: 6px;
            width: 6px;
            border-radius: 3px;
            background-color: var(--text-primary-color);
            &.disabled {
              background-color: var(--text-secondary-color);
            }
            &.error {
              background-color: var(--trend-down-color);
            }
            &.saved {
              background-color: var(--trend-up-color);
            }
          }
        }
      }
    }
  }
}
