.navigation {
  > ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    > li {
      position: relative;
      .navbar-submenu {
        display: none;
        z-index: 1;
        min-width: 300px;
        position: absolute;
        transform: translateX(calc(-100%));
        inset-inline-end: 6px;
        top: 16px;
        align-items: unset;
        gap: unset;
        border-radius: 4px;
        background-color: var(--background-secondary-color);
        box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2);
        li {
          &:not(:last-child) {
            border-bottom: 1px solid rgba(var(--text-primary-color-rgb), 0.2);
          }
          a {
            display: flex;
            align-items: center;
            gap: 10px;
            padding: 14px 26px;
            color: var(--text-primary-color);
            span {
              font-size: 16px;
              font-weight: 400;
              line-height: 19px;
              letter-spacing: 0.12px;
            }
            &:is(:hover, .active-subitem) {
              background-color: var(--card-background-secondary-color);
              color: var(--text-secondary-color);
            }
          }
        }
      }
      .navbar-hidden-subitems {
        display: none;
        z-index: 1;
        min-width: 300px;
        position: absolute;
        transform: translateX(calc(-100%));
        inset-inline-end: 6px;
        top: 16px;
        border-radius: 4px;
        background-color: var(--background-secondary-color);
        box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2);
      }
      &:hover {
        .navbar-submenu {
          display: unset;
        }
        .navbar-hidden-subitems {
          display: unset;
        }
        a:not(.active) {
          .icon-nav {
            background-color: var(--border-primary-color);
          }
        }
      }
    }
    a {
      text-decoration: none;
      color: var(--text-secondary-color);
      .icon-nav {
        position: relative;
        cursor: pointer;
        width: 52px;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        gap: 6px;
        justify-content: center;
        align-items: center;
        font-family: 'Rubik';
        font-size: 12px;
        letter-spacing: 0.4px;
        line-height: 16px;
        text-align: center;
        padding: 10px 2px 4px;
      }
      &.active {
        .icon-nav {
          color: var(--trend-up-color);
          background-color: var(--border-primary-color);
          &:after {
            content: '';
            position: absolute;
            background-color: var(--trend-up-color);
            width: 16px;
            height: 2px;
            transform: translateX(50%);
            right: 50%;
            top: 0;
          }
        }
        img.img-icon {
          filter: none;
        }
      }
    }
  }
}

[dir='ltr'] .navigation > ul > li :is(.navbar-hidden-subitems, .navbar-submenu) {
  transform: translateX(100%);
}
