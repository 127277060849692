.agencies-list-item {
  flex-shrink: 0;
  background-color: rgba(255, 255, 255, 0.08);
  height: 52px;
  border-radius: 8px;
  margin-bottom: 12px;
  color: var(--text-primary-color);
  font-size: 14px;
  font-family: Rubik;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.17px;
  position: relative;
  &.clickable {
    cursor: pointer;
  }
  &:last-child {
    margin-bottom: 0;
  }
  > :is(:first-child, :last-child) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

[data-theme='light'] .agencies-list-item {
  background-color: rgba(243, 247, 250, 1);
}
