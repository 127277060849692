.treatment-appbar {
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  > .appbar-items-count {
    min-width: 44px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--trend-up-color);
    border-radius: 8px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    letter-spacing: 0.625px;
  }
  > .appbar-actions {
    display: flex;
    gap: 16px;
    > .appbar-action-container {
      &:nth-last-child(2) {
        position: relative;
        &:after {
          content: '';
          position: absolute;
          transform: translateY(-50%);
          top: 50%;
          inset-inline-end: -8px;
          width: 1px;
          height: 40px;
          background-color: var(--border-primary-color);
        }
      }
      > .appbar-action {
        position: relative;
        cursor: pointer;
        min-width: 60px;
        height: 50px;
        margin-top: 12px;
        margin-bottom: 12px;
        padding-block: 4px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        border-radius: 4px;
        background: transparent;
        border: none;
        &.disabled {
          cursor: default;
          > .appbar-action-ico {
            > img {
              filter: opacity(0.6);
            }
          }
          > .appbar-action-text {
            color: var(--text-secondary-color);
          }
        }
        &:not(.disabled):not(.opened):hover {
          background-color: rgba(var(--background-primary-color-rgb), 0.38);
          > .appbar-action-ico {
            > img {
              filter: invert(57%) sepia(58%) saturate(5102%) hue-rotate(115deg) brightness(95%) contrast(102%);
            }
          }
          > .appbar-action-text {
            color: var(--trend-up-color);
          }
        }
        > .appbar-action-text {
          color: var(--text-primary-color);
          font-family: Rubik;
          font-size: 12px;
          letter-spacing: 0.4px;
          line-height: 16px;
          text-align: center;
        }
      }
      &.update-status-wrapper {
        .task-item-status-menu-wrapper {
          position: absolute;
          top: 0;
          right: 50%;
          transform: translate(50%, -100%);
        }
      }
    }
  }
}
