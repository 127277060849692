@import '../../../../mixins.scss';

.scan-company-report {
  border-radius: 8px;
  background-color: var(--border-primary-color);
  padding-inline: 16px;
  .scan-company-report-summary {
    @include flex-hv-center;
    height: 48px;
    gap: 8px;
    p {
      font-size: 16px;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: 0.15px;
    }
    .scan-company-report-desc {
      color: var(--text-secondary-color);
    }
    .scan-company-report-actions {
      margin-inline-start: auto;
      display: flex;
      gap: 12px;
      button.button-rounded {
        border: none;
        height: 30px;
        background-color: rgba(255, 255, 255, 0.17);
        padding: 4px 12px;
        font-size: 13px;
        font-weight: 500;
        line-height: 22px; /* 169.231% */
        letter-spacing: 0.46px;
        text-transform: uppercase;
        gap: 8px;
      }
    }
  }
  &:not(:last-child) {
    margin-bottom: 16px;
  }
  &.saved-report {
    background-color: rgba(var(--trend-up-color-rgb), 0.17);
    svg {
      fill: var(--trend-up-color);
    }
  }
  &.error-report:not(.disabled-report) {
    background-color: rgba(var(--trend-down-color-rgb), 0.17);
    .scan-company-report-desc {
      color: var(--trend-down-color);
    }
  }
  &.disabled-report {
    //background-color: rgba(var(--trend-down-color-rgb), 0.17);
    p {
      color: var(--text-secondary-color);
    }
  }
  &.scan-in-progress {
    background-color: rgba(211, 212, 217, 0.17);
    &.current {
      background-color: var(--border-primary-color);
      .scan-company-report-actions > p {
        font-size: 12px;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 1px;
        text-transform: uppercase;
      }
    }
  }
}
