.marketing-companies-container {
  max-width: 1080px;
  margin: 0 auto;
  font-family: Rubik;
  padding-top: 60px;
  padding-bottom: 40px;
  h1,
  h4,
  h5,
  h6,
  p {
    padding: 0;
    margin: 0;
    font-weight: normal;
  }
  > .marketing-companies-list-container {
    margin-bottom: 50px;
    &:last-of-type {
      margin-bottom: 22px;
    }
    > .marketing-companies-list-header {
      margin-bottom: 24px;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: -0.283333px;
    }
    > .marketing-companies-list {
      > .marketing-company {
        display: flex;
        align-items: center;
        // gap: 32px;
        padding-inline: 32px;
        height: 84px;
        background-color: #272d3e;
        &.disabled {
          background-color: red;
          background: rgba(39, 45, 62, 0.6);
        }
        > .marketing-company-main-container {
          flex: 1;
          display: flex;
          gap: 32px;
          align-items: center;
          > .marketing-company-logo-container {
            > .marketing-company-logo-icon {
              height: 75px;
              width: 75px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
          > .marketing-company-info-container {
            flex: 1;
            display: flex;
            align-items: center;
            > .marketing-company-name {
              flex-basis: 160px;
              font-size: 20px;
              line-height: 24px;
              letter-spacing: 0.25px;
            }
            > .marketing-company-fields {
              flex: 1;
              display: grid;
              grid-template-columns: repeat(3, auto);
              > .marketing-company-field {
                display: flex;
                gap: 11px;
                align-items: center;
                &.disabled {
                  opacity: 0.38;
                }
                > .marketing-company-field-icon {
                  height: 22px;
                  width: 22px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  > img {
                    height: 16px;
                  }
                }
                > .marketing-company-field-info {
                  font-weight: 400;
                  letter-spacing: 0.25px;
                  > .marketing-company-field-name {
                    font-size: 20px;
                    line-height: 24px;
                    &:not(:last-child) {
                      margin-bottom: 4px;
                    }
                  }
                  > .marketing-company-field-status {
                    font-size: 16px;
                    line-height: 19px;
                    opacity: 0.7;
                    &.progress {
                      color: var(--orange-client);
                      opacity: 1;
                    }
                  }
                }
              }
            }
          }
        }
        > .marketing-company-connections {
          flex-basis: 202px;
          display: flex;
          align-items: center;
          justify-content: center;
          > .marketing-company-connection {
            display: flex;
            align-items: center;
            gap: 8px;
            > .marketing-company-connection-icon {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 24px;
              width: 24px;
              > img {
                height: 100%;
                width: 100%;
              }
            }
            > .marketing-company-connection-name {
              font-weight: 400;
              font-size: 20px;
              line-height: 24px;
              letter-spacing: 0.25px;
              color: var(--trend-up-color);
              text-align: center;
              white-space: nowrap;
            }
          }
        }
        &:first-child {
          border-top-right-radius: 12px;
          border-top-left-radius: 12px;
        }
        &:last-child {
          border-bottom-right-radius: 12px;
          border-bottom-left-radius: 12px;
        }
        &:not(:last-child) {
          margin-bottom: 6px;
        }
      }
    }
  }
  > .marketing-companies-remark {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: var(--text-secondary-color);
  }
}

@media screen and (max-width: 1366px) {
  .marketing-companies-container {
    max-width: 980px;
  }
}

@media screen and (max-width: 480px) {
  .marketing-companies-wrapper {
    background: rgba(39, 45, 62, 0.38);
    .marketing-companies-container {
      width: 100%;
      padding: 60px 16px 50px;
      > .marketing-companies-list-container {
        &:last-of-type {
          margin-bottom: 0;
        }
        > .marketing-companies-list {
          > .marketing-company {
            overflow: hidden;
            padding-inline: 0;
            height: unset;
            flex-direction: column;
            &:not(:last-child) {
              margin-bottom: 12px;
            }
            > .marketing-company-main-container {
              padding: 16px;
              > .marketing-company-logo-container {
                > .marketing-company-logo-icon {
                  height: 47px;
                  width: 47px;
                  > img {
                    max-width: 98%;
                    max-height: 98%;
                  }
                }
              }
              > .marketing-company-info-container {
                flex-direction: column;
                align-items: start;
                gap: 8px;
                > .marketing-company-name {
                  flex-basis: unset;
                  font-weight: 500;
                  font-size: 15.6138px;
                  line-height: 19px;
                  letter-spacing: 0.243966px;
                }
                > .marketing-company-fields {
                  width: 100%;
                  gap: 6px;
                  > .marketing-company-field {
                    gap: 4px;
                    > .marketing-company-field-icon {
                      height: 20px;
                      width: 20px;
                    }
                    > .marketing-company-field-info {
                      > .marketing-company-field-name {
                        font-size: 13.6621px;
                        line-height: 16px;
                        letter-spacing: 0.243966px;
                        white-space: nowrap;
                      }
                      > .marketing-company-field-status {
                        font-size: 12px;
                        line-height: 14px;
                        letter-spacing: 0.243966px;
                      }
                    }
                  }
                }
              }
            }
            > .marketing-company-connections {
              flex-basis: unset;
              // background-color: blueviolet;
              width: 100%;
              > .marketing-company-connection {
                justify-content: center;
                border-top: var(--border-primary);
                width: 100%;
                height: 30px;
                > .marketing-company-connection-icon {
                  height: 16px;
                  width: 16px;
                }
                > .marketing-company-connection-name {
                  font-size: 12px;
                  line-height: 20px;
                  letter-spacing: 0.4px;
                }
              }
            }
          }
        }
      }
      > .marketing-companies-remark {
        display: none;
      }
    }
  }
}
