.month-calendar-container {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  user-select: none;
  letter-spacing: 0.17px;
  > .month-calendar-controls {
    padding-top: 16px;
    padding-inline: 24px;
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > .month-calendar-date {
      position: relative;
      > button {
        height: 34px;
        display: flex;
        gap: 6px;
        align-items: center;
        font-weight: 500;
        font-size: 16px;
        line-height: 28px;
        letter-spacing: 0.15px;
        > .month-calendar-date-btn-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 32px;
          width: 32px;
          &.opened {
            transform: rotate(180deg);
          }
        }
      }
      > .month-calendar-date-months-list {
        position: absolute;
        background: var(--background-primary-color);
        box-shadow: 0px 8px 10px rgb(0 0 0 / 14%), 0px 3px 14px rgb(0 0 0 / 12%), 0px 5px 5px rgb(0 0 0 / 20%);
        border-radius: 4px;
        min-width: 140px;
        max-height: 200px;
        overflow: auto;
        z-index: 1;
        top: calc(100% + 6px);
        padding-block: 8px;
        > .month-calendar-date-months-list-item {
          cursor: pointer;
          display: flex;
          gap: 10px;
          justify-content: space-between;
          align-items: center;
          padding: 8px 10px;
          &:hover {
            opacity: 0.7;
          }
        }
      }
    }
    > .month-calendar-set-month {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 24px;
      > button {
        height: 32px;
        width: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.2s all ease-in-out;
        transform-origin: center;
        &:disabled {
          opacity: 0.3;
          transform: scale(0.8);
          cursor: default;
        }
        &:active:not(:disabled) {
          transform: scale(1, 0.92);
        }
      }
      [dir='ltr'] > button {
        transform: rotate(180deg);
      }
    }
    button {
      border-radius: unset;
    }
  }
  .month-dates {
    min-height: 216px;
    margin-bottom: 26px;
  }
  .week-row {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    padding-inline: 28px;
    .day-cell {
      height: 36px;
      width: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      user-select: none;
      &.selected {
        background-color: var(--trend-up-color);
        &.from {
          border-start-start-radius: 18px;
          border-end-start-radius: 18px;
        }
        &.to {
          border-start-end-radius: 18px;
          border-end-end-radius: 18px;
        }
      }
      &.in-range:not(.disabled):not(.from):not(.to) {
        background-color: rgba(var(--trend-up-color-rgb), 0.17);
      }
      &.disabled {
        cursor: default;
        opacity: 0.5;
      }
    }
    .today {
      border: 1px solid var(--trend-up-color);
      opacity: 1 !important;
      &.disabled {
        color: var(--text-secondary-color);
      }
      &:not(.selected) {
        border-radius: 18px;
      }
    }
  }
  .day-names {
    font-size: 12px;
    letter-spacing: 0.4px;
    color: var(--text-secondary-color);
    height: 40px;
  }
  .week-row.day-names {
    .day-cell {
      cursor: default;
    }
  }
  button {
    border-radius: unset;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.17px;
    color: var(--text-primary-color);
  }
}

[dir='ltr'] .month-calendar-set-month > button {
  transform: rotate(180deg);
}

@media screen and (max-width: 767px) {
  .month-calendar-container {
    > .month-calendar-controls {
      padding-block: 8px;
      justify-content: center;
      > .month-calendar-set-month {
        display: none;
      }
      > .month-calendar-date {
        button {
          background: transparent;
          border: none;
          font-family: 'Rubik';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.17px;
          color: var(--trend-up-color);
          > .month-calendar-date-btn-icon {
            display: none;
          }
        }
      }
      .month-calendar-date-months-list {
        left: 50%;
        transform: translateX(-50%);
      }
    }
    > .month-dates {
      min-height: unset;
      margin-bottom: 6px;
    }
    .day-cell {
      width: 100% !important;
    }
    .in-range {
      color: var(--trend-up-color);
    }
    .from,
    .to {
      background-color: rgba(var(--trend-up-color-rgb), 0.17) !important;
    }
  }
}
