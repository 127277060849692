.reports-download-list-modal {
  background-color: var(--border-primary-color);
  height: 680px;
  width: 500px;
  padding: 24px;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 8px;
  overflow: hidden;
  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-block: 16px;
    h4 {
      font-size: 20px;
      font-weight: 500;
      line-height: 32px;
      letter-spacing: 0.15px;
    }
  }
  P {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.17px;
  }
  main {
    flex: 1;
    overflow: auto;
  }
  .reports-download-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--background-secondary-color);
    height: 54px;
    border-radius: 8px;
    padding-inline: 16px;
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}
