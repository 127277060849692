.sidebar {
  height: 100vh;
  background-color: var(--background-primary-color);
  border-inline-end: var(--border-primary);
  position: sticky;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  padding-bottom: 1.6rem;
  /* z-index: 50; */
  z-index: 2;
  /* -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px); */
  .logo {
    border-bottom: var(--border-primary);
  }
}

@media screen and (max-width: 767px) {
  .sidebar {
    // display: none;
    height: 100svh;
    width: 60px;
    position: fixed;
    inset-inline-start: 0;
    transform: translateX(100%);
    transition: 0.5s transform cubic-bezier(0.4, 0, 0.2, 1);
    &.mobile-open {
      height: 100svh;
      display: flex !important;
      width: 60px;
      position: fixed;
      inset-inline-start: 0;
      transform: translateX(0);
      transition: 0.5s transform cubic-bezier(0.4, 0, 0.2, 1);
    }
  }
  [dir='ltr'] .sidebar {
    transform: translateX(-100%);
    &.mobile-open {
      transform: translateX(0);
    }
  }
}

@supports not ((-webkit-backdrop-filter: blur(5px)) or (backdrop-filter: blur(5px))) {
  .sidebar {
    background-color: var(--background-primary-color);
  }
}
