.sortable-column {
  display: grid;
  align-items: center;
  cursor: pointer;
  grid-template-columns: min-content auto;
  gap: 4px;
  user-select: none;
  i {
  }
}

[class*='commission'] .sortable-column {
  justify-content: center;
}
