.agency-house-analytics-policies-details-card {
  background-color: var(--background-secondary-color);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow: hidden;
  .agency-house-analytics-policies-details-card-empty {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    svg {
      color: var(--text-secondary-color);
    }
    p {
      color: var(--text-secondary-color);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 166%; /* 19.92px */
      letter-spacing: 0.4px;
      max-width: 154px;
      text-align: center;
    }
  }
  .agency-house-analytics-policies-details-card-header {
    padding: 16px 24px;
    border-bottom: 1px solid rgba(var(--text-primary-color-rgb), 0.12);
    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        font-size: 20px;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: 0.15px;
      }
    }
    span {
      color: var(--text-secondary-color);
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.4px;
    }
  }
  .agency-house-analytics-policies-details-card-hierarchy {
    padding-inline: 24px;
    display: flex;
  }
  .agency-house-analytics-policies-details-card-chart {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .agency-house-analytics-policies-details-card-list-container {
    display: flex;
    flex-direction: column;
    overflow: auto;
    h5 {
      height: 35px;
      padding-inline: 24px;
      font-size: 16px;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: 0.15px;
    }
    .agency-house-analytics-policies-details-card-list {
      display: flex;
      flex-direction: column;
      overflow: auto;
      .agency-house-analytics-policies-details-card-list-item {
        padding: 16px 24px;
        padding-inline-end: 10px;
        display: flex;
        justify-content: space-between;
        &.open {
          background-color: rgba(var(--text-primary-color-rgb), 0.08);
        }
        > div {
          display: flex;
          align-items: center;
          &:first-child {
            gap: 10px;
          }
          &:last-child {
            gap: 10px;
          }
        }
      }
      .agency-house-analytics-policies-details-card-sublist {
        background-color: rgba(var(--text-primary-color-rgb), 0.12);
        border-top: 1px solid rgba(var(--text-primary-color-rgb), 0.23);
        border-bottom: 1px solid rgba(var(--text-primary-color-rgb), 0.23);
        .agency-house-analytics-policies-details-card-sublist-item {
          // padding-inline: 58px 68px;
          // padding-inline-end: 38px;
          padding: 16px 46px;
          padding-inline-end: 32px;
          height: 56px;
          display: flex;
          justify-content: space-between;
          font-size: 14px;
          > div {
            display: flex;
            align-items: center;
            &:first-child {
              gap: 10px;
            }
          }
          &.inner {
            padding-inline: 80px 70px;
          }
          > div {
            display: flex;
            align-items: center;
            &:last-child {
              gap: 10px;
            }
          }
        }
      }
      .agency-house-analytics-policies-details-item-logo {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 24px;
        width: 24px;
        border-radius: 4px;
        &.small {
          height: 10px;
          width: 10px;
          border-radius: 2px;
        }
      }
    }
  }
}

.details-card-grid {
  display: grid;
  grid-template-columns: repeat(3, 42px);
  font-size: 10px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.4px;
  > div {
    direction: ltr;
    //text-align: center;
  }
  .details-card-grid-header {
    color: var(--text-secondary-color);
  }
}
