.profile-connected-agencies {
  > header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    > p {
      font-size: 16px;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: 0.15px;
    }
  }
  > div:first-of-type {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
  }
}
