.workstation-filter-date-container {
  position: relative;
  > .workstation-date-range-picker-wrapper {
    z-index: 1;
    position: absolute;
    bottom: 0;
    inset-inline-end: 0;
    transform: translateY(calc(100% + 4px));
  }
}

@media screen and (max-width: 767px) {
  .workstation-date-range-picker-wrapper {
    height: 400px;
    overflow: auto;
  }
}
