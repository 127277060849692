.meatballs-container {
  > .meatballs {
    background-color: var(--background-secondary-color);
    cursor: pointer;
    height: 36px;
    width: 36px;
    border-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
    &.transparent {
      background-color: transparent;
    }
    &:hover,
    &:active,
    &.opened {
      background-color: rgba(var(--trend-up-color-rgb), 0.17);
      > .meatball {
        background-color: var(--trend-up-color);
      }
    }
    &:active {
      transform-origin: center;
      transform: scale(0.95);
    }
    > .meatball {
      border-radius: 1.5px;
      height: 3px;
      width: 3px;
      background-color: var(--text-primary-color);
    }
  }
  &.sm {
    > .meatballs {
      height: 26px;
      width: 26px;
      border-radius: 13px;
      gap: 2px;
    }
  }
  &.xs {
    > .meatballs {
      height: 20px;
      width: 20px;
      border-radius: 10px;
      gap: 2px;
    }
  }
}
