.scan-totals-container {
  // background-color: salmon;
  display: flex;
  flex-direction: column;
  > header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 48px;
    margin-bottom: 12px;
    h5 {
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
    }
  }
  .scan-totals-content {
    section {
      display: grid;
      grid-template-columns: 2fr 1fr;
      gap: 24px;
      &.all-downloaded {
        grid-template-columns: 240px auto;
      }
      .scan-totals-card {
        display: flex;
        flex-direction: column;
        height: 360px;
        border-radius: 20px;
        background-color: var(--border-primary-color);
        padding: 24px;
        > header {
          min-height: 36px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          h5 {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.15px;
          }
          .button-text {
            height: unset;
            color: var(--trend-up-color);
            &:disabled {
              opacity: 0.6;
            }
          }
        }
        &.pending {
          overflow: hidden;
          justify-content: space-between;
          .scan-totals-card-content {
            overflow: hidden;
            .scan-totals-card-summary {
              p:first-child {
                font-size: 60px;
                font-weight: 600;
                line-height: 72px;
                letter-spacing: -0.5px;
              }
              p:last-child {
                font-size: 20px;
                font-weight: 500;
                line-height: 32px;
                letter-spacing: 0.15px;
              }
            }
            .scan-totals-card-companies {
              display: flex;
              gap: 10px;
              overflow: auto;
              .scan-totals-card-company {
                display: flex;
                align-items: center;
                gap: 24px;
                background-color: rgba(var(--text-primary-color-rgb), 0.08);
                border-radius: 16px;
                padding: 4px 8px;
                text-decoration: none;
                > div:first-child {
                  display: flex;
                  gap: 8px;
                  align-items: center;
                  p {
                    white-space: nowrap;
                    color: var(--text-primary-color);
                    text-decoration: none;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 22px;
                    letter-spacing: 0.1px;
                  }
                }
              }
            }
          }
        }
        &.downloaded {
          width: 368px;
          .scan-totals-card-radial-bar {
            flex: 1;
            //background-color: blueviolet;
            overflow: hidden;
          }
          .scan-totals-card-radial-bar-values {
            position: relative;
            p:first-child {
              position: absolute;
              transform: translate(50%, calc(12px - 100%));
              right: 50%;
              font-size: 90px;
              font-weight: 600;
              line-height: 108px;
              letter-spacing: -0.5px;
            }
            p:last-child {
              font-size: 20px;
              font-weight: 500;
              line-height: 32px;
              letter-spacing: 0.15px;
              text-align: center;
              margin-bottom: 26px;
            }
          }
          .scan-totals-card-downloaded-banner {
            border-radius: 8px;
            text-align: center;
            background-color: rgba(var(--trend-up-color-rgb), 0.17);
            padding: 8px 16px;
          }
        }
        &.all-downloaded {
          padding: 24px;
          background-color: rgba(var(--trend-up-color-rgb), 0.17);
          p {
            margin-top: 24px;
            text-align: center;
          }
        }
      }
    }
  }
  .scan-totals-banner-container:not(:empty) {
    margin-bottom: 24px;
  }
  .scan-totals-warn-banner {
    display: flex;
    padding: 24px;
    border-radius: 14px;
    &.prev-month {
      background-color: #fff9c4;
      p {
        color: #272d3e;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.15px;
      }
    }
    &.some-downloaded,
    &.no-downloads {
      position: relative;
      height: 76px;
      padding-inline-start: 200px;
      margin-top: 50px;
      background-color: rgba(var(--trend-up-color-rgb), 0.17);
      p {
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 160%; /* 32px */
        letter-spacing: 0.15px;
      }
      img {
        position: absolute;
        inset-inline-start: 0;
        bottom: -15px;
      }
    }
  }
}
