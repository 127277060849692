.meatballs-menu-container {
  position: relative;
  > .meatballs-menu {
    position: absolute;
    inset-block-start: 50%;
    z-index: 1;
    &.position-top {
      transform: translateY(-100%);
    }
  }
}

[dir='rtl'] .meatballs-menu-container {
  > .meatballs-menu {
    inset-inline-end: 50%;
    inset-inline-start: unset !important;
  }
}

[dir='ltr'] .meatballs-menu-container {
  > .meatballs-menu {
    inset-inline-start: 50%;
    inset-inline-end: unset !important;
    transform: translateX(-100%);
    &.position-top {
      transform: translate(-100%, -100%);
    }
  }
}
