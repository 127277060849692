.customer-details-container-wrapper {
  position: relative;
  .customer-details-container {
    border: var(--border-primary);
    border-radius: 20px;
    // min-height: 430px;
    display: flex;
    flex-direction: column;
    > .customer-details-header {
      border-bottom: var(--border-primary);
      background-color: var(--card-background-secondary-color);
      padding: 20px;
      border-top-left-radius: 19px;
      border-top-right-radius: 19px;
    }
    > .customer-details-template-section {
      border-bottom: var(--border-primary);
      display: flex;
      justify-content: space-between;
    }
    > .customer-details-loader-container {
    }
  }
}
