.bottomsheet-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100svh;
  -webkit-backdrop-filter: blur(4px) brightness(70%);
  backdrop-filter: blur(4px) brightness(70%);
  display: flex;
  flex-direction: column;
  z-index: 1;
  > .bottomsheet-container {
    margin-top: auto;
    background-color: var(--background-primary-color);
    //background-color: red;
    box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12),
      0 11px 15px -7px rgba(0, 0, 0, 0.2);
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    > .bottomsheet-handle {
      width: 24px;
      height: 4px;
      border-radius: 2px;
      background-color: var(--text-third-color);
      margin: 8px auto;
    }
  }
}
