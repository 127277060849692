.logout-confirm-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100svh;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-backdrop-filter: blur(4px) brightness(70%);
  backdrop-filter: blur(4px) brightness(70%);
  z-index: 100;
  .logout-confirm-container {
    background-color: var(--background-secondary-color);
    box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12),
      0 11px 15px -7px rgba(0, 0, 0, 0.2);
    min-width: 400px;
    border-radius: 8px;
    padding: 24px;
    position: relative;
    > .logout-confirm-close-btn {
      position: absolute;
      top: 0;
      inset-inline-end: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      background-color: var(--border-primary-color);
      border-start-end-radius: 8px;
      border-end-start-radius: 8px;
      cursor: pointer;
      &:hover {
        filter: brightness(150%) saturate(140%);
      }
    }
    > .logout-confirm-content {
      > .logout-confirm-heading {
        color: var(--text-primary-color);
        font-family: Rubik;
        font-size: 16px;
        letter-spacing: 0.5px;
        line-height: 20px;
        margin-bottom: 24px;
      }
      > .logout-confirm-buttons-group {
        display: flex;
        justify-content: space-evenly;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .logout-confirm-container {
    min-width: 80vw;
  }
  .logout-confirm-buttons-group {
    justify-content: space-around;
  }
}
