.payment-calc {
  width: 1080px;
  padding-inline: 12px;
  margin-inline: auto;
  .payment-calc-text {
    margin-bottom: 100px;
    width: 600px;
    > p {
      margin-bottom: 8px;
    }
    > p:first-child {
      color: var(--trend-up-color);
      font-size: 24px;
      line-height: 32px;
    }
    > h5 {
      font-size: 60px;
      font-weight: 800;
      line-height: 60px; /* 100% */
      letter-spacing: -0.283px;
      margin-bottom: 24px;
    }
    > h5 + p {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.15px;
    }
    > p:last-child {
      font-size: 20px;
      font-weight: 400;
      line-height: 24px; /* 120% */
      letter-spacing: -0.283px;
    }
  }
  .payment-calc-slider {
    margin-bottom: 60px;
    .MuiSlider-valueLabel {
      left: unset;
      background-color: unset;
      > span {
        background-color: var(--text-primary-color);
        transform: unset;
        height: 34px;
        width: auto;
        display: flex;
        padding: 10px;
        border-radius: 17px;
        > span {
          transform: unset;
          color: rgba(var(--text-inverted-primary-color-rgb), 1);
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%;
          letter-spacing: 0.17px;
        }
      }
    }
    .MuiSlider-thumb {
      height: 14px;
      width: 14px;
      background-color: var(--text-primary-color);
      border: 3px solid var(--background-primary-color);
    }
    .MuiSlider-thumb,
    .MuiSlider-thumb.Mui-focusVisible,
    .MuiSlider-thumb:hover {
      box-shadow: unset;
    }
    .MuiSlider-rail {
      background: var(--text-primary-color);
      opacity: 1;
      height: 2px;
    }
    .MuiSlider-track {
      background: var(--trend-up-color);
      border: none;
    }
    .MuiSlider-mark {
      height: 10px;
      color: var(--text-primary-color);
      transform: translateY(calc(-100% + 2px));
    }
    .MuiSlider-markLabel,
    .MuiSlider-markLabelActive {
      color: var(--text-primary-color);
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 143%; /* 20.02px */
      letter-spacing: 0.17px;
    }
  }
  .payment-calc-results {
    display: flex;
    padding: 40px 60px;
    gap: 24px;
    border-radius: 20px;
    border-right: 4px solid var(--trend-up-color);
    background: rgba(49, 57, 79, 0.38);
    backdrop-filter: blur(30px);
    align-items: flex-end;
    justify-content: center;
    margin-bottom: 20px;
    .labeled-container {
      display: flex;
      flex-direction: column;
      gap: 16px;
      align-items: center;
      > :first-child {
        display: block;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px; /* 125% */
      }
    }
    .value-container {
      height: 64px;
      min-width: 160px;
      padding-inline: 24px;
      border-radius: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: Rubik;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 133.4%; /* 32.016px */
      border: var(--border-primary);
      background-color: rgba(var(--border-primary-color-rgb), 0.6);
      &.input {
        width: 160px;
        color: var(--text-primary-color);
        caret-color: var(--trend-up-color);
        background-color: rgba(var(--text-primary-color-rgb), 0.17);
        outline: none;
        text-align: center;
      }
      &.cost {
        color: var(--trend-up-color);
        border: 1px solid var(--trend-up-color);
        background-color: rgba(var(--trend-up-color-rgb), 0.17);
        font-weight: 600;
      }
    }
    .svg-ico-container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 64px;
      width: 64px;
      font-size: 28px;
    }
    &:has(> :last-child:nth-child(3)) {
      /* has 3 elements */
      .cost {
        min-width: 300px;
      }
    }
  }
  .payment-calc-results-remark {
    color: var(--text-secondary-color);
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.4px;
    text-align: center;
  }
}
