.tasks-search-input-container {
  display: flex;
  position: relative;
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    transform: translateY(-50%);
    inset-block-start: 50%;
    &:first-child {
      inset-inline-start: 18px;
    }
    &:last-child {
      inset-inline-end: 18px;
    }
  }
  > input {
    flex: 1;
    background-color: var(--card-background-secondary-color);
    color: var(--text-third-color);
    font-family: Rubik;
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 28px;
    text-align: start;
    border: 1px solid transparent;
    outline: none;
    display: flex;
    height: 40px;
    padding-inline-start: 50px;
    padding-inline-end: 12px;
    &:focus,
    &:not(:placeholder-shown) {
      background-color: var(--card-background-selected);
      color: var(--text-primary-color);
      border-radius: 8px;
    }
  }
}

@media screen and (max-width: 767px) {
  .tasks-search-input-container {
    display: none;
  }
}
