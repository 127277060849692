.cross-mark {
  &:before,
  &:after {
    position: absolute;
    inset-inline-start: 9px;
    top: 3px;
    content: ' ';
    height: 15px;
    width: 2px;
    background-color: var(--text-primary-color);
  }
  &.green::before,
  &.green::after {
    background-color: var(--trend-up-color);
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
}
