.commission-view-selector-wrapper {
  position: relative;
  .commission-view-selector {
    background: var(--background-secondary-color);
    border-bottom: var(--border-primary);
    height: 68px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.4px;
    padding: 2px;
    cursor: pointer;
    ul {
      display: none;
      position: absolute;
      inset-inline-start: 100%;
      inset-block-start: 0;
      min-width: 280px;
      background-color: var(--background-secondary-color);
      box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2);
      border-radius: 8px;
      li {
        height: 58px;
        font-size: 16px;
        font-weight: 400;
        display: flex;
        align-items: center;
        padding-inline: 26px;
        &:hover {
          opacity: 0.7;
        }
        &:not(:first-child) {
          border-top: var(--border-primary);
        }
      }
    }
    &:hover ul {
      display: block;
    }
  }
}
