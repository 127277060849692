:root {
  --associate-agency-form-container-base-padding: 60px;
  --associate-agency-form-base-padding: 40px;
  --associate-agency-form-base-width: 880px;
  --associate-agency-form-base-inputs-width: 500px;
}

@media screen and (max-height: 767px) {
  :root {
    --associate-agency-form-container-base-padding: 10px;
    --associate-agency-form-base-padding: 10px;
    --associate-agency-form-base-width: 600px;
    --associate-agency-form-base-inputs-width: 400px;
  }
}

.associate-agency-form-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-top: var(--associate-agency-form-container-base-padding);
  h4,
  h5,
  h6,
  p {
    margin: 0;
    color: var(--text-primary-color);
    font-family: Rubik;
    font-style: normal;
  }
  .associate-agency-form-body {
    flex: 1;
    width: var(--associate-agency-form-base-width);
    margin: auto;
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding-bottom: 10px;
    padding-inline: 10px;
    .associate-agency-form-grid-row {
      display: grid;
      grid-template-columns: 1fr var(--associate-agency-form-base-inputs-width);
      gap: 10px;
      &:not(:last-child) {
        padding-bottom: var(--associate-agency-form-base-padding);
      }
      &:not(:first-child):not(:last-child) {
        border-bottom: var(--border-primary);
      }
      &:has(.details-inputs, .payments-inputs, .companies-inputs, .access-inputs) {
        padding-top: var(--associate-agency-form-base-padding);
      }
      .associate-agency-form-grid-row-desc {
        h4 {
          font-size: 24px;
          font-weight: 400;
          line-height: 133.4%;
        }
        h4 + p {
          color: var(--text-secondary-color);
          font-size: 16px;
          font-weight: 500;
          line-height: 175%;
          letter-spacing: 0.15px;
        }
        h5 {
          font-size: 16px;
          font-weight: 500;
          line-height: 175%;
          letter-spacing: 0.15px;
        }
        h5 + p,
        h5 + p + p {
          font-size: 14px;
          color: var(--text-secondary-color);
          font-weight: 400;
          line-height: 143%;
          letter-spacing: 0.17px;
        }
        h5 + p + p {
          font-size: 12px;
        }
      }
      .associate-agency-form-grid-row-content {
        display: flex;
        flex-direction: column;
        gap: 8px;
        &.details-inputs {
          position: relative;
          > .associate-agency-details-inputs-container {
            display: flex;
            gap: 16px;
            > div {
              flex: 1;
            }
          }
          > .server-error {
            color: red;
            position: absolute;
            bottom: 0;
            inset-inline-start: 0;
            transform: translateY(calc(100% + 4px));
          }
        }
        &.payments-inputs {
          .MuiFormGroup-root {
            flex-direction: row;
            label {
              gap: 4px;
              &:not(:last-child) {
                margin-inline-end: 16px;
              }
            }
          }
        }
        &.companies-inputs {
          .selected-companies-chips {
            display: flex;
            flex-wrap: wrap;
            gap: 12px;
          }
        }
        &.access-inputs {
          .access-inputs-group-container {
            .access-inputs-group-heading {
              height: 42px;
              margin-bottom: 8px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding-inline: 12px;
              p {
                color: var(--text-secondary-color);
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 143%;
                letter-spacing: 0.17px;
              }
              > div {
                display: flex;
                align-items: center;
                gap: 16px;
              }
            }
            &:not(:last-child) {
              margin-bottom: 24px;
            }
          }
        }
        .associate-agency-form-grid-row-item {
          height: 42px;
          flex-shrink: 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: rgba(255, 255, 255, 0.09);
          padding-inline: 12px;
          border-radius: 8px;
          &.selected {
            background-color: rgba(var(--trend-up-color-rgb), 0.12);
          }
          p {
            color: var(--text-primary-color);
            font-size: 14px;
            font-weight: 400;
            line-height: 143%;
            letter-spacing: 0.17px;
          }
          &:not(:last-child) {
            margin-bottom: 8px;
          }
        }
      }
    }
  }
  .associate-agency-form-footer {
    background-color: var(--background-primary-color);
    box-shadow: 0px -4px 20px 0px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 34px;
    height: 72px;
  }
  .associate-agency-form-alert {
    border-radius: 8px;
    background: rgba(211, 212, 217, 0.6);
    padding: 6px 16px;
    font-family: Rubik;
    h6 {
      font-size: 16px;
      font-weight: 500;
      line-height: 150%;
      letter-spacing: 0.15px;
    }
    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 143%;
      letter-spacing: 0.17px;
      white-space: pre-wrap;
    }
  }
}

[data-theme='light'] .associate-agency-form-container {
  .associate-agency-form-body {
    .associate-agency-form-grid-row {
      .associate-agency-form-grid-row-content {
        .associate-agency-form-grid-row-item {
          background-color: rgba(243, 247, 250, 1);
        }
      }
    }
  }
  .associate-agency-form-footer {
    box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14),
      0px 11px 15px -7px rgba(0, 0, 0, 0.2);
  }
}
