.landing-portfolio {
  padding-top: 100px;
  padding-bottom: 525px;

  p,
  h5 {
    text-align: center;
    margin: 0;
    padding: 0;
    font-weight: 400;
    white-space: pre-line;
  }

  > .landing-section-container {
    background-size: 0;
    > * {
      margin: 0 auto;
      max-width: 700px;
    }

    .landing-portfolio-tagline {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.25px;
      color: var(--trend-up-color);
      margin-bottom: 26px;
    }

    .landing-portfolio-title {
      font-size: 48px;
      line-height: 57px;
      margin-bottom: 15px;
    }

    .landing-portfolio-subtitle {
      font-size: 20px;
      line-height: 24px;
    }
  }
}

.landing-section-container{
  background-size: 0;
}

@media screen and (max-width: 1460px) {
  .landing-portfolio {
    margin-top: 160px;

    .landing-portfolio-img-container {
      left: -292px;
      width: 801px;
      top: -9px;

      & > img {
        width: 100%;
      }
    }

    .landing-portfolio-info-container {
      max-width: 386px;

      .landing-portfolio-text-container {
        .landing-portfolio-subtitle {
          font-size: 21px;
        }

        .landing-portfolio-title {
          font-size: 43px;
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .landing-portfolio {
    padding: 35px 13px 301px;
    margin-top: 61px;

    .landing-portfolio-info-container {
      max-width: 352px;

      .landing-portfolio-text-container {
        margin-bottom: 30px;
        .landing-portfolio-tagline {
          font-size: 16px;
          line-height: 13px;
        }

        .landing-portfolio-title {
          font-size: 39px;
        }

        .landing-portfolio-subtitle {
          font-size: 18px;
        }
      }

      .landing-portfolio-cards-container .landing-portfolio-card .landing-portfolio-card-text-container .landing-portfolio-card-title {
        font-size: 16px
      }
    }
    .landing-portfolio-img-container {
      left: -292px;
      width: 718px;
      top: 89px;
    }
  }
}

@media screen and (max-width: 767px) {
  .landing-portfolio{
    margin-top: 16px;
    padding: 1px;
    background-image: none !important;

    & > div.landing-section-container{
      padding: 51px 16px;
      background-size: 380px;
      background-position: bottom right;

      .landing-portfolio-tagline{
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 13px;
      }
      .landing-portfolio-title{
        font-size: 30px;
        line-height: 34px;
      }
      .landing-portfolio-subtitle{
        font-size: 18px;
        line-height: 19px;
        margin-bottom: 409px;
      }
    }

  }
}