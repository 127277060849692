.set-task-status-menu-container {
  > .set-task-status-menu {
    width: 160px;
    border-radius: 4px;
    background-color: var(--background-primary-color);
    box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12),
      0 5px 5px -3px rgba(0, 0, 0, 0.2);
    padding: 8px;
    z-index: 2;
    > .set-task-status-menu-item {
      cursor: pointer;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: 40px;
      border-radius: 4px;
      background-color: var(--text-third-color);
      border: unset;
      color: var(--text-primary-color);
      font-family: 'Rubik';
      font-size: 16px;
      letter-spacing: 0.5px;
      line-height: 24px;
      &.treated {
        background-color: rgba(var(--trend-up-color-rgb), 0.38);
        color: var(--trend-up-color);
      }
      &:active {
        transform: scaleX(0.98);
      }
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .set-task-status-menu-container {
    > .set-task-status-menu {
      width: unset;
      background-color: transparent;
      box-shadow: unset;
    }
  }
}
