.agency-house-analytics-rating {
  > div:first-child {
    display: flex;
    margin-bottom: 16px;
  }
  h5 {
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0.15px;
  }
  p {
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.15px;
  }
  main {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .agency-house-analytics-charts {
      display: flex;
      justify-content: center;
      gap: 48px;
      > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        > p {
          font-size: 24px;
          font-weight: 500;
        }
      }
    }
  }
}
