.register-flow-type-card {
  width: 300px;
  height: 400px;
  display: flex;
  flex-direction: column;
  .register-flow-type-card-body {
    position: relative;
    padding-block: 100px 12px;
    background-color: rgba(255, 255, 255, 0.09);
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-bottom: none;
    .register-flow-type-card-body-ico {
      position: absolute;
      transform: translate(-50%, -50%);
      top: 0;
      left: 50%;
      width: 220px;
      display: flex;
      align-items: center;
      justify-content: center;
      > img {
        width: 100%;
      }
    }
    .register-flow-type-card-body-title {
      color: var(--text-primary-color);
      font-size: 20px;
      font-weight: 500;
      line-height: 160%;
      letter-spacing: 0.15px;
      margin-bottom: 8px;
    }
    .register-flow-type-card-body-desc {
      color: var(--text-secondary-color);
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.15px;
    }
  }
  .register-flow-type-card-footer {
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-top: none;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    background-color: rgba(246, 246, 246, 1);
    padding-block: 20px 24px;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    ul {
      width: 100%;
      padding-inline: 36px 18px;
      list-style: disc;
      font-size: 12px;
      line-height: 166%;
      letter-spacing: 0.4px;
      color: var(--text-primary-color);
    }
    button {
      margin-top: auto;
      background-color: transparent;
      color: var(--trend-up-color);
      border: 1px solid var(--trend-up-color);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-family: Rubik;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.4px;
      text-transform: uppercase;
      padding: 6px 16px;
      height: 36px;
      min-width: 90px;
      border-radius: 18px;
      cursor: pointer;
      &:active {
        transform: scale(0.98);
      }
    }
  }
  &:hover {
    .register-flow-type-card-body {
      border: 1px solid var(--trend-up-color);
      border-bottom: none;
    }
    .register-flow-type-card-footer {
      background-color: rgba(var(--trend-up-color-rgb), 0.04);
      border: 1px solid var(--trend-up-color);
      border-top: none;
      button {
        color: rgba(255, 255, 255, 1);
        background-color: var(--trend-up-color);
      }
    }
  }
}

[data-theme='dark'] .register-flow-type-card {
  .register-flow-type-card-body {
    border: 1px solid transparent;
    background-color: rgba(255, 255, 255, 0.09);
    border-bottom: none;
  }
  .register-flow-type-card-footer {
    border: 1px solid transparent;
    background-color: rgba(255, 255, 255, 0.17);
    border-top: none;
  }
  &:hover {
    .register-flow-type-card-body {
      background-color: rgba(var(--trend-up-color-rgb), 0.12);
      border: 1px solid var(--trend-up-color);
      border-bottom: none;
    }
    .register-flow-type-card-footer {
      background-color: rgba(var(--trend-up-color-rgb), 0.3);
      border: 1px solid var(--trend-up-color);
      border-top: none;
    }
  }
}
