.agency-house-details-container {
  display: flex;
  justify-content: space-between;
  overflow: auto;
  padding-top: 16px;
  gap: 16px;
  > div:first-child {
    overflow: auto;
    padding-inline-end: 6px;
    flex: 1;
    > div:first-child {
      padding-bottom: 24px;
      border-bottom: var(--border-primary);
      margin-bottom: 8px;
      width: 460px;
    }
  }
  .button-text {
    svg {
      font-size: 18px;
    }
  }
  .danger {
    color: var(--trend-down-color);
  }
}
