.agency-house-analytics-navbar {
  z-index: 1;
  p,
  a {
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0.12px;
    color: var(--text-primary-color);
    padding: 14px 26px;
    text-decoration: none;
    color: var(--text-primary-color);
    display: flex;
    align-items: center;
    gap: 10px;
  }
  ul {
    > li {
      &:has(ul) {
        border-bottom: 1px solid rgba(var(--text-primary-color-rgb), 0.2);
      }
      > a {
        text-decoration: none;
        color: var(--text-primary-color);
        display: flex;
        align-items: center;
        gap: 10px;
        &:is(:hover, .current) {
          background-color: var(--card-background-secondary-color);
          color: var(--text-secondary-color);
        }
        &:not(:has(img)) {
          padding-inline-start: 60px;
        }
      }
    }
    ul a {
      padding-inline-start: 60px;
    }
  }
}

[dir='ltr'] .sidebar-agent-house li .navbar-hidden-subitems {
  transform: translateX(100%);
}
