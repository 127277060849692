.edit-subagency-form {
  background-color: var(--background-secondary-color-rgb);
  display: grid;
  // grid-template-columns: auto 1fr;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 2;
}
