.policies-lost-list-container {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
  .policies-lost-lists-wrapper {
    padding: 24px;
    padding-top: unset;
    overflow: auto;
    flex: 1;
  }
}
