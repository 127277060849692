.customers-dashboard {
  padding: 24px;
  display: flex;
  justify-content: space-between;
  p > span {
    color: var(--text-secondary-color);
  }

  .customers-rating-cards {
    display: flex;
    gap: 24px;
    > div {
      padding: 20px 24px;
      position: relative;
      display: flex;
      justify-content: space-between;
      width: 244px;
      background-color: rgba(var(--border-primary-color-rgb), 0.38);
      border: 1px solid transparent;
      border-radius: 20px;
      cursor: pointer;
      &.current {
        background-color: var(--border-primary-color);
        border-color: rgba(var(--text-primary-color-rgb), 0.6);
      }
      p:first-child {
        font-size: 16px;
        line-height: 28px;
        letter-spacing: 0.5px;
      }
      p:last-child {
        font-size: 20px;
        font-weight: 700;
        line-height: 28px;
        letter-spacing: 0.625px;
      }
      &:after {
        content: '';
        position: absolute;
        width: 2px;
        height: 16px;
        inset-inline-start: 0;
        top: 50%;
        transform: translateY(-50%);
      }
      &.red:after {
        background-color: var(--red-client);
      }
      &.orange:after {
        background-color: var(--orange-client);
      }
      &.green:after {
        background-color: var(--green-client);
      }
    }
  }
}
