@media screen and (max-width: 767px) {
  .task-details-mobile-container {
    position: fixed;
    width: 100%;
    height: 100svh;
    top: 0;
    display: flex;
    flex-direction: column;
    z-index: 1;
    > .task-details-mobile {
      flex: 1;
      background: var(--background-primary-color);
      padding: 24px 16px;
      > .task-details-mobile-row {
        display: grid;
        grid-template-columns: 1fr 2.3fr;
        gap: 8px;
        align-items: center;
        font-family: 'Rubik';
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.17px;
        &:not(:last-child) {
          margin-bottom: 12px;
        }
        & > div:first-child {
          color: var(--text-secondary-color);
        }
        & > div:last-child {
          justify-self: end;
          text-align: end;
        }
        .task-details-mobile-status {
          position: relative;
          > .task-details-mobile-status-chip {
            cursor: pointer;
            display: flex;
            gap: 9px;
            align-items: center;
            justify-content: center;
            text-align: center;
            height: 28px;
            width: 90px;
            border-radius: 4px;
            background-color: var(--text-third-color);
            border: unset;
            color: var(--text-primary-color);
            font-family: 'Rubik';
            font-size: 12px;
            letter-spacing: 0.4px;
            line-height: 16px;
            &.treated {
              background-color: rgba(var(--trend-up-color-rgb), 0.38);
              color: var(--trend-up-color);
            }
            &:active {
              transform: scaleX(0.98);
            }
            > .task-details-mobile-status-chip-arrow-ico {
              display: flex;
              align-items: center;
              justify-content: center;
            }
            &.opened {
              > .task-details-mobile-status-chip-arrow-ico {
                > img {
                  transform: rotate(180deg);
                }
              }
            }
          }
          > .task-details-mobile-status-menu-wrapper {
            position: absolute;
            top: calc(100% + 4px);
            inset-inline-start: 50%;
            transform: translateX(50%);
            z-index: 1;
          }
          > .task-details-mobile-status-loader-overlay {
            position: absolute;
            width: 80px;
            height: 28px;
            display: flex;
            border-radius: 4px;
            backdrop-filter: blur(2px);
          }
        }
      }
    }
  }
}
