.profile-screen {
  padding: 40px;
  display: flex;
  flex-direction: column;
  height: 100vh;
  > header {
    margin-bottom: 24px;
    h5 {
      font-size: 24px;
      font-weight: 400;
      line-height: 32px;
      margin-bottom: 14px;
    }
  }
  .profile-screen-content {
    overflow: auto;
  }
}
