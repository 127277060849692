.add-agency-summary-item-card {
  background-color: var(--border-primary-color);
  border-radius: 20px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  height: 333px;
  width: 280px;
  .add-agency-summary-item-card-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    .add-agency-summary-item-card-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 56px;
      width: 56px;
      border-radius: 8px;
      background-color: rgba(255, 255, 255, 0.17);
    }
  }
  .add-agency-summary-item-card-heading {
    font-size: 20px;
    font-weight: 500;
    line-height: 160%; /* 32px */
    letter-spacing: 0.15px;
    margin-bottom: 60px;
    flex: 1;
  }
  .add-agency-summary-item-card-content {
    // margin-top: auto;
    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 150%; /* 24px */
      letter-spacing: 0.15px;
      &:has(+ p) {
        margin-bottom: 12px;
      }
    }
    .add-agency-summary-item-card-content-pairs {
      display: flex;
      flex-direction: column;
      gap: 12px;
      &.pairs-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }
      > div {
        display: flex;
        gap: 8px;
        align-items: center;
        height: 32px;
      }
      > .button-text {
        color: var(--trend-up-color);
      }
    }
  }
}
