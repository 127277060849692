.agency-house-download-screen {
  font-family: Rubik;
  padding: 0 !important;
  position: relative;
  display: flex;
  overflow: hidden !important;
  .agency-house-download-screen-padding-wrapper {
    padding-inline-start: 384px;
  }
  .agency-house-download-screen-width-wrapper {
    width: 920px;
    height: 100%;
    margin-inline: auto;
  }
  > .agency-house-download-screen-totals-wrapper {
    padding-block: 24px;
    padding-inline-start: 408px;
    display: flex;
    flex-direction: column;
    margin-inline: auto;
    overflow: hidden;
    width: clamp(80%, 100%, 1080px);
  }
  > div:nth-child(2) {
    //padding-inline-start: 384px;
  }
}

.agency-house-download-screen-collapsible-header {
  display: flex;
  padding-inline: 16px;
  align-items: center;
  gap: 10px;
  > p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.17px;
  }
  > span {
    flex: 1;
    height: 1px;
    background: var(--border-primary-color);
  }
  button {
    height: 48px;
    width: 48px;
  }
}

@media screen and (max-width: 900px) {
  .agency-house-download-screen {
  }
}
