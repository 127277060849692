.agencies-groups-sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  > div {
    padding-block: 12px;
    border-top: var(--border-primary);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }
  > div:first-child {
    button {
      height: 40px;
      width: 40px;
      border-radius: 8px;
      border: 2px solid var(--text-primary-color);
      background: var(--trend-up-color);
    }
  }
  > div:not(:first-child):not(:last-child) {
    border-top-style: dashed;
    padding-block: 8px;
  }
  .agencies-group-avatar-container {
    position: relative;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    .agencies-group-avatar {
      height: 32px;
      width: 32px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 20px;
      letter-spacing: 0.14px;
    }
    .agencies-group-avatar-sm {
      height: 24px;
      width: 24px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      &:last-child {
        top: 0;
        inset-inline-end: 0;
      }
      &:first-child {
        bottom: 0;
        inset-inline-start: 0;
      }
    }
    &.selected {
      .agencies-group-avatar,
      .agencies-group-avatar-sm {
        border: 2px solid var(--text-primary-color);
      }
    }
  }
}
