.payment-plans-modal {
  padding: 24px;
  padding-top: 60px;
  min-height: 864px;
  min-width: 1128px;
  &.fullscreen-modal {
    min-width: fit-content;
    > .payment-plans-fullscreen-modal-header {
      h3,
      h4 {
        padding: 0;
        margin: 0;
        font-family: 'Rubik';
        font-style: normal;
        letter-spacing: 0.15px;
        text-align: center;
      }
      h3 {
        font-weight: 500;
        font-size: 20px;
        line-height: 32px;
        margin-bottom: 6px;
      }
      h4 {
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        color: var(--text-secondary-color);
        margin-bottom: 32px;
      }
    }
    .payment-plans-modal-paymentframe-container {
      height: unset;
      .payment-plans-modal-paymentframe-footer {
        div {
          display: flex;
          justify-content: center;
        }
      }
    }
  }
  > .payment-plans-modal-icon {
    pointer-events: none;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    inset-inline-start: 50%;
    transform: translate(50%, -75%);
  }
  > .payment-plans-modal-header {
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.5px;
    margin-bottom: 16px;
  }
  > .payment-plans-modal-paymentframe-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    height: 100%;
    > .payment-plans-modal-paymentframe {
      padding: 12px;
      display: flex;
      flex-direction: column;
      flex: 1;
      background-color: var(--background-primary-color);
      border-radius: 25px;
      > .payment-plans-modal-paymentframe-header {
        display: flex;
      }
      & > .payment-plans-modal-paymentframe-content {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

@media screen and (max-height: 890px) {
  .payment-plans-modal {
    min-height: unset;
    height: 570px;
    padding-top: 35px;
    > .payment-plans-modal-icon {
      transform: translate(50%, -60%);
    }
    > .payment-plans-modal-paymentframe-container {
      > .payment-plans-modal-paymentframe {
      }
    }
  }
}
