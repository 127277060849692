.customer-policies-list-container {
  .customer-policies-list-grid {
    display: grid;
    align-items: center;
    gap: 8px;
    > *:first-child {
      padding-inline-start: 16px;
    }
    &.extended {
      grid-template-columns: repeat(9, 1fr) 1fr 42px 0.5fr;
      > p:nth-child(2) {
        color: var(--text-secondary-color);
      }
    }
    &.split-delta-commission {
      grid-template-columns: repeat(7, 1fr) 1fr 1fr 42px 0.5fr;
    }
    &.split-delta-commission.extended {
      grid-template-columns: repeat(9, 1fr) 1fr 1fr 42px 0.5fr;
    }
    &.elementary {
      grid-template-columns: repeat(11, 1fr) 1fr 42px 0.5fr;
    }
    &.elementary.extended {
      grid-template-columns: repeat(13, 1fr) 1fr 42px 0.5fr;
    }
    &.elementary.split-delta-commission {
      grid-template-columns: repeat(11, 1fr) 1fr 1fr 42px 0.5fr;
    }
    &.elementary.split-delta-commission.extended {
      grid-template-columns: repeat(13, 1fr) 1fr 1fr 42px 0.5fr;
    }
    .commission-rate-split {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 8px;
      text-align: center;
    }
    div:has(> .commission-rate-split) {
      > p {
        text-align: center;
      }
    }
    div:has(.columns-picker-container) {
      justify-self: end;
    }
  }
  header {
    color: var(--text-secondary-color);
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    margin-bottom: 5px;
  }
  .customer-policies-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
  .customer-policies-list-item {
    height: 48px;
    background-color: var(--border-primary-color);
    border-radius: 8px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    cursor: pointer;
    &.out-dated {
      background: linear-gradient(90.13deg, rgba(250, 254, 56, 0.13) 13.24%, rgba(250, 254, 56, 0.0507) 50.12%);
    }
    &.inactive {
      background-color: rgba(255, 255, 255, 0.17);
    }
    > div:nth-last-child(-n + 2) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .customer-policies-list-item-badges-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 8px;
      padding-inline-end: 8px;
    }
    > p:first-child {
      padding-inline: 0 16px;
    }
  }
  .customer-policies-list-item-totals {
    height: 48px;
    background-color: rgba(var(--border-primary-color-rgb), 0.7);
    border-radius: 8px;
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.25px;
    position: relative;
    > p.totals-main-cell {
      border-start-start-radius: 8px;
      border-end-start-radius: 8px;
      background-color: var(--background-secondary-color);
      align-self: stretch;
      display: flex;
      align-items: center;
      direction: unset;
      position: absolute;
      height: 100%;
      padding-inline: 16px;
    }
  }
}

.customer-policies-list-item,
.customer-policies-list-item-totals {
  > p {
    direction: ltr;
    text-align: end;
  }
}

[dir='ltr'] {
  .customer-policies-list-container .customer-policies-list-item > div:last-child button {
    transform: scaleX(-1);
  }
  .customer-policies-list-item,
  .customer-policies-list-item-totals {
    > p {
      text-align: unset;
    }
  }
  .customer-policies-list-item {
    > p:first-child {
      padding-inline: 16px 0;
    }
  }
}
