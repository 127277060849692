.associated-agency-details-sidebar-overlay {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  inset-inline-end: 0;
  -webkit-backdrop-filter: blur(4px) brightness(70%);
  backdrop-filter: blur(4px) brightness(70%);
  z-index: 101;
  .associated-agency-details-sidebar {
    position: relative;
    margin-inline-start: auto;
    height: 100vh;
    width: 400px;
    display: flex;
    flex-direction: column;
    border-inline-start: 1px solid rgba(var(--text-primary-color-rgb), 0.17);
    background: var(--border-primary-color);
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.5);
    header {
      border-bottom: 1px solid rgba(var(--text-primary-color-rgb), 0.17);
      display: flex;
      align-items: center;
      padding: 12px 16px;
      p {
        flex: 1;
        font-size: 20px;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: 0.15px;
      }
      button {
        margin: 12px;
      }
    }
    > div {
      overflow: auto;
      flex: 1;
      padding: 20px 24px;
      display: flex;
      flex-direction: column;
      gap: 24px;
      section {
        p {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0.17px;
        }
        > div:first-child {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 7px;
          p {
            font-size: 16px;
            font-weight: 500;
            line-height: 28px;
            letter-spacing: 0.15px;
          }
          button {
            color: var(--trend-up-color);
          }
        }
        .associated-agency-details-sidebar-main-details {
          padding: 24px;
          border-radius: 8px;
          background-color: rgba(var(--text-primary-color-rgb), 0.09);
          display: flex;
          flex-direction: column;
          gap: 16px;
          div {
            display: flex;
            justify-content: space-between;
            span {
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              letter-spacing: 0.17px;
              &.agent-phone-number {
                direction: ltr;
                text-align: end;
              }
            }
          }
          hr {
            width: 100%;
            border: unset;
            border-top: 1px solid rgba(var(--text-primary-color-rgb), 0.12);
            margin: 0;
          }
        }
        .associated-agency-details-sidebar-list {
          display: flex;
          flex-direction: column;
          gap: 8px;
          .associated-agency-details-sidebar-list-item {
            border-radius: 8px;
            background-color: rgba(var(--text-primary-color-rgb), 0.09);
            padding-inline: 12px;
            display: flex;
            align-items: center;
            height: 42px;
            gap: 8px;
            > button:last-child {
              margin-inline-start: auto;
            }
            &.no-access {
              background-color: rgba(var(--text-primary-color-rgb), 0.04);
              > p {
                color: var(--text-secondary-color);
              }
              > p + p {
                color: var(--trend-down-color);
                margin-inline-start: auto;
              }
            }
          }
        }
      }
    }
  }

  ::-webkit-scrollbar {
    width: 16px;
  }
  ::-webkit-scrollbar-track {
  }
  ::-webkit-scrollbar-thumb {
    border: 6px solid transparent;
    background-color: rgba(var(--text-primary-color-rgb), 0.09);
    // border-top: none;
    // border-bottom: none;
    border-radius: 30px;
    background-clip: padding-box;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: var(--text-secondary-color);
  }
}
