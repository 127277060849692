.add-task-btn {
  height: 40px;
  padding: 8px 16px;
  border-radius: 27px;
  background-color: var(--trend-up-color);
  display: flex;
  gap: 10px;
  align-items: center;
  border: none;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  color: var(--text-primary-color);
  white-space: nowrap;
  cursor: pointer;
  &:active {
    transform: scale(0.98);
  }
}

@media screen and (max-width: 767px) {
  .add-task-btn {
    background-color: transparent;
    padding: unset;
    height: unset;
    justify-content: center;
    align-items: center;
    border-radius: unset;
    span {
      display: none;
    }
  }
}
