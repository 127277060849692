.no-data-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.no-data-container>.no-data-text {
  color: var(--text-secondary-color);
  font-family: Rubik;
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 20px;
  text-align: center;
  max-width: 190px;
  white-space: pre-line;
}