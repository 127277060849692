.customers-group-type-container {
  > .customers-group-type-name {
    margin-bottom: 12px;
    padding-inline-start: 10px;
  }
  .customers-group-item {
    cursor: pointer;
    align-items: center;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    border-radius: 8px;
    background-color: var(--background-secondary-color);
    height: 48px;
    position: relative;
    > .customers-group-item-selector-container,
    > .customers-group-item-menu-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    > .customers-group-item-selector-container {
      > button {
        border: none;
        background-color: transparent;
        width: 18px;
        height: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
    .meatballs-menu {
      z-index: 2 !important;
      .context-menu {
        min-width: 200px !important;
        .context-menu-item {
          padding: 6px 16px !important;
          .customers-group-menu-item {
            display: flex;
            gap: 6px;
            justify-content: space-between;
            align-items: center;
          }
        }
      }
    }
    .customers-group-item-loading-overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      border-radius: 8px;
      backdrop-filter: blur(4px);
      z-index: 1;
    }
  }
  .customers-group-item-totals {
    margin-bottom: 8px;
    position: relative;
    // background-color: var(--background-secondary-color);
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.25px;
    > div:first-child {
      padding-inline: 18px;
      display: flex;
      align-items: center;
      background-color: rgba(var(--border-primary-color-rgb), 0.6);
      position: absolute;
      top: 0;
      height: 100%;
      border-start-start-radius: 8px;
      border-end-start-radius: 8px;
    }
  }
  > .customers-group-items-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 28px;
  }
  .confirm-dialog-modal-container {
    width: 336px;
    padding: 16px 24px 28px;
    min-width: unset;
    font-family: 'Rubik';
    .confirm-dialog-modal-content-title {
      padding: 0;
      margin: 0;
      margin-bottom: 8px;
    }
    .confirm-dialog-modal-content-text {
      padding: 0;
      margin: 0;
      margin-bottom: 8px;
    }
    .confirm-dialog-modal-content-btns {
      padding: 0;
      margin: 0;
      button {
        padding: 6px 16px;
        border: 1px solid var(--trend-up-color);
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.4px;
        text-transform: uppercase;
        color: var(--trend-up-color);
        border-radius: 18px;
        &:nth-child(2) {
          background-color: var(--trend-up-color);
          color: var(--text-primary-color);
        }
        &:hover {
          border-width: 1px;
          font-weight: 500;
          border-radius: 18px;
          opacity: 0.7;
        }
      }
    }
  }
}
