.switch {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 20px;
  flex-shrink: 0;
  &.disabled {
    opacity: 0.5;
  }
  > input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  > .slider {
    position: absolute;
    cursor: pointer;
    top: 3.5px;
    left: 0;
    right: 0;
    height: 14px;
    background-color: #c7c7c7;
    transition: 0.3s;
    &:before {
      content: '';
      position: absolute;
      height: 20px;
      width: 20px;
      inset-inline-end: -1px;
      top: -3px;
      background-color: #f5f5f5;
      transition: 0.3s;
    }
    &.round {
      border-radius: 7px;
      &:before {
        border-radius: 50%;
      }
    }
  }
  > input:checked + .slider {
    background-color: #9be0b7;
    &:before {
      background-color: var(--trend-up-color);
      transform: translateX(17px);
    }
  }
  > input:focus + .slider {
    box-shadow: 0 0 1px var(--trend-up-color);
  }
  > input:disabled + .slider {
    cursor: default;
  }
}
