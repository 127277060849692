.workstation-filters {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  button {
    height: 40px;
    border-radius: 20px;
    background: transparent;
    border: 1px solid transparent;
    cursor: pointer;
    span {
      white-space: nowrap;
    }
  }
  .workstation-filter-button {
    background: rgba(211, 212, 217, 0.17);
    border: 1px solid rgba(211, 212, 217, 0.6);
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: space-between;
    padding: 10px 16px;
    .workstation-filter-button-icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    > .workstation-filter-button-content {
      display: flex;
      align-items: center;
      gap: 6px;
      > span {
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        letter-spacing: 0.25px;
        color: var(--text-primary-color);
      }
      > .workstation-filter-button-chip {
        height: 16px;
        min-width: 16px;
        border-radius: 8px;
        padding-inline: 6px;
        background-color: var(--trend-up-color);
        &.hidden {
          // display: none;
          visibility: hidden;
        }
      }
    }
    &.open {
      background: rgba(211, 212, 217, 0.38);
      .workstation-filter-button-icon:last-child {
        transform: rotate(180deg);
      }
    }
  }
  .workstation-filter-clear {
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid var(--trend-up-color);
      color: var(--trend-up-color);
      font-family: 'Rubik';
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.4px;
      text-transform: uppercase;
      padding: 16px 8px;
      &:disabled {
        border-color: rgba(var(--trend-up-color-rgb), 0.38);
      }
      &:active:not(:disabled) {
        transform: scale(0.98);
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .workstation-filters {
    justify-content: start;
    .workstation-filter-button {
      gap: 10px;
      .workstation-filter-button-chip {
        &.hidden {
          display: none;
        }
      }
    }
  }
}
