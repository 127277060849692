.policies-dates-filter-dropdown-container {
  position: relative;
  font-family: 'Rubik';
  .policies-dates-filter-dropdown-btn-container {
    .policies-dates-filter-dropdown-btn {
      border-radius: 18px;
      height: 36px;
      padding: 6px 16px;
      color: var(--text-secondary-color);
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.25px;
      align-items: center;
      display: flex;
      justify-content: space-between;
      background-color: rgba(var(--border-primary-color-rgb), 0.38);
      gap: 4px;
      &:not(:disabled) {
        cursor: pointer;
      }
      &.has-applied,
      &.open {
        border-color: transparent;
        background-color: rgba(var(--border-primary-color-rgb), 0.8);
        color: var(--text-primary-color);
      }
      button {
        svg {
          height: 18px;
          width: 18px;
          color: var(--text-secondary-color);
        }
      }
    }
  }
  .policies-dates-filter-dropdown-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    height: 100vh;
    width: 100vw;
    .policies-dates-filter-dropdown-main-container {
      z-index: 1;
      position: fixed;
      background: var(--background-secondary-color);
      box-shadow: 0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
        0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      min-width: 212px;
      width: 470px;
      .policies-dates-filter-dropdown-body {
        padding: 24px;
        > div {
          &:not(:last-child) {
            margin-bottom: 24px;
          }
          > p {
            color: var(--text-secondary-color);
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.25px;
            margin-bottom: 8px;
          }
        }
      }
      .policies-dates-filter-dropdown-footer {
        border-top: var(--border-primary);
        padding: 24px 14px;
        display: flex;
        justify-content: flex-end;
        gap: 10px;
      }
    }
  }
}
