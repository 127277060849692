.agency-house-analytics-incomes-charts-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.agency-house-analytics-incomes-chart-x-labels {
  color: red;
  fill: var(--text-secondary-color);
  font-family: Rubik !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 143% !important; /* 20.02px */
  letter-spacing: 0.17px !important;
  cursor: pointer;
  &:hover {
    fill: var(--text-primary-color);
  }
}
