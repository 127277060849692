.add-customer-group-modal {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  width: 1070px;
  height: 776px;
  display: flex;
  flex-direction: column;
  position: relative;
  .add-customer-group-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #272d3e;
    padding: 16px 24px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    letter-spacing: 0.15px;
    border-bottom: var(--border-primary);
    > button {
      border: none;
      background: none;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      cursor: pointer;
      transition: all 0.25 ease-in;
      &:active {
        transform: scale(0.95);
      }
    }
  }
  .add-customer-group-modal-content {
    display: flex;
    flex: 1;
    overflow: hidden;
    .add-customer-group-modal-sidebar {
      background-color: #222837;
      padding: 24px;
      width: 370px;
      display: flex;
      flex-direction: column;
      gap: 24px;
      .add-customer-group-modal-sidebar-inputs {
        display: flex;
        flex-direction: column;
        gap: 24px;
      }
      .add-customer-group-modal-sidebar-selected {
        display: flex;
        flex-direction: column;
        overflow: hidden;
      }
      .add-customer-group-modal-sidebar-main-member {
        min-height: 97px;
      }
      .add-customer-group-modal-selected-heading {
        margin-top: 5px;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;
        color: var(--text-secondary-color);
        margin-bottom: 12px;
      }
      .add-customer-group-modal-selected-items {
        overflow: auto;
        overscroll-behavior: contain;
        .add-customer-group-modal-selected-item {
          height: 32px;
          display: flex;
          gap: 8px;
          align-items: center;
          background-color: #2b3245;
          border-radius: 16px;
          padding-inline: 12px;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.25px;
        }
      }
    }
    .add-customer-group-modal-main {
      display: flex;
      flex-direction: column;
      flex: 1;
      background: var(--background-secondary-color);
      .add-customer-group-modal-main-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        // height: 630px;
        .add-customer-group-search-container {
        }
        .add-customer-group-modal-list-container {
          padding: 30px 26px;
          overflow: auto;
          overscroll-behavior: contain;
          border-top: var(--border-primary);
          flex: 1;
          scroll-behavior: smooth;
          .add-customer-group-modal-list {
          }
          .add-customer-group-modal-no-data-container {
            height: 100%;
            display: flex;
          }
        }
      }
      .add-customer-group-modal-footer {
        display: flex;
        padding: 24px;
        justify-content: flex-end;
        box-shadow: 4px 0px 50px rgba(0, 0, 0, 0.12);
        .button {
          border-radius: 30px;
          padding: 6px 16px;
          min-width: unset;
        }
      }
    }
    .add-customer-group-modal-list-item {
      display: flex;
      gap: 10px;
      align-items: center;
      border-radius: 8px;
      background-color: var(--border-primary-color);
      padding: 10px 18px 14px;
      &:not(:last-child) {
        margin-bottom: 8px;
      }
      > .add-customer-group-modal-list-item-checkbox {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
      > .add-customer-group-modal-list-item-info {
        font-family: 'Rubik';
        font-style: normal;
        > .add-customer-group-modal-list-item-name {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.25px;
        }
        > .add-customer-group-modal-list-item-number {
          color: var(--text-secondary-color);
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 0.4px;
        }
      }
      > .add-customer-group-modal-list-item-actions {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-inline-start: auto;
        > button {
          color: var(--text-secondary-color);
          background: transparent;
          border: none;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 24px;
          width: 24px;
          cursor: pointer;
          transition: all 0.25s ease-in-out;
          &:active {
            transform: scale(0.92);
          }
        }
      }
      > .add-customer-group-modal-list-item-extras {
        margin-inline-start: auto;
        display: flex;
        gap: 6px;
        align-items: center;
        color: var(--text-secondary-color);
      }
      &.no-main {
        background-color: rgba(var(--trend-down-color-rgb), 0.17);
        p {
          color: var(--trend-down-color);
          &:first-child {
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.25px;
          }
          &:last-child {
            font-size: 12px;
            line-height: 14px;
            letter-spacing: 0.5px;
          }
        }
      }
    }
  }
  .add-customer-group-modal-loader-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    border-radius: 8px;
    backdrop-filter: blur(4px);
    z-index: 1;
  }
}

@media screen and (max-height: 767px) {
  .add-customer-group-modal {
    width: 830px;
    height: 600px;
    .add-customer-group-modal-content {
      .add-customer-group-modal-sidebar {
        width: 280px;
      }
      .add-customer-group-modal-main {
        .add-customer-group-modal-main-content {
          // height: 430px;
        }
        .add-customer-group-modal-footer {
        }
      }
    }
  }
}
