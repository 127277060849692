.confirm-dialog-modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-backdrop-filter: blur(4px) brightness(70%);
  backdrop-filter: blur(4px) brightness(70%);
  z-index: 101;
}

.confirm-dialog-modal-container {
  background-color: var(--background-secondary-color);
  box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.2);
  min-width: 450px;
  max-width: 550px;
  width: 500px;
  border-radius: 8px;
  /* padding: 30px 24px 8px; */
  position: relative;
  display: flex;
  color: var(--text-primary-color);
  font-family: Rubik;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 20px;
}

.confirm-dialog-modal-container>.confirm-dialog-modal-content {
  flex: 1;
}

.confirm-dialog-modal-container>.confirm-dialog-modal-content>.confirm-dialog-modal-content-title {
  margin: 30px 24px 13px 24px;
}

.confirm-dialog-modal-container>.confirm-dialog-modal-content>.confirm-dialog-modal-content-text {
  margin: 24px;
  margin-top: 0;
  color: var(--text-secondary-color);
  font-family: Rubik;
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 20px;
}

.confirm-dialog-modal-container>.confirm-dialog-modal-content>.confirm-dialog-modal-content-btns {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  /* margin: 0 8px 8px; */
  margin: 8px;
}

.confirm-dialog-modal-container>.confirm-dialog-modal-content>.confirm-dialog-modal-content-btns>button {
  border: none;
  background: transparent;
  color: var(--trend-up-color);
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1.25px;
  line-height: 16px;
  text-align: center;
  cursor: pointer;
  min-width: 64px;
  height: 36px;
  border: 2px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}

.confirm-dialog-modal-container>.confirm-dialog-modal-content>.confirm-dialog-modal-content-btns>button:hover {
  border-color: var(--trend-up-color);
  border-radius: 5px;
  font-weight: bold;
}

.confirm-dialog-modal-container>.confirm-dialog-modal-content>.confirm-dialog-modal-content-btns>button:active {
  transform: scale(0.95);
}