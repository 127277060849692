.profile-companies-container {
  > header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    p {
      font-size: 16px;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: 0.15px;
    }
  }
  .profile-company-item-grid {
    display: grid;
    padding-inline: 10px;
    // grid-template-columns: 44px repeat(3, 1fr) 98px;
    grid-template-columns: repeat(3, 1fr) 98px;
    gap: 10px;
    align-items: center;
    > button:first-child.selected {
      color: var(--trend-up-color);
    }
  }
  .profile-companies-table-header {
    margin-bottom: 12px;
    height: 36px;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px; /* 171.429% */
    letter-spacing: 0.17px;
  }
  .notify-modals-wrapper {
    position: fixed;
    bottom: 26px;
    left: 26px;
    z-index: 50;
    .notify-modals-container {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }
}
