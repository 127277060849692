.policies-selected-filters-content {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.policies-selected-filters-items {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  overflow: auto;
  overscroll-behavior: contain;
}

.policies-selected-filters-selected-item {
  height: 32px;
  display: flex;
  gap: 8px;
  align-items: center;
  background-color: #2b3245;
  border-radius: 16px;
  padding-inline: 12px;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
}

.policies-selected-filters-button {
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  height: 36px;
  padding-inline: 8px;
  border-radius: 52px;
  background-color: var(--border-primary-color);
  color: var(--text-primary-color);
  font-family: Rubik;
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 20px;
  text-align: right;
  cursor: pointer;
  border: 1px solid transparent;
  margin-left: 5px;
}

.clear-button {
  border: 1px solid var(--text-primary-color);
}

.selected-filters-position-hebrew {
  justify-content: right;
}

.label-filter-value {
  margin-left: 5px;
  color: rgba(255, 255, 255, 0.6);
}
