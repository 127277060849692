.columns-picker-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  > button:first-child {
    background-color: rgba(var(--border-primary-color-rgb), 0.6);
    height: 32px;
    width: 32px;
    border-radius: 4px;
    &.has-disabled-columns {
      border: 1px solid var(--text-secondary-color);
    }
  }
}

.columns-picker-list {
  overflow: auto;
  width: 220px;
  max-height: 360px;
  border-radius: 8px;
  background: rgba(39, 45, 62, 1);
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2);
  ul {
    li {
      display: flex;
      gap: 8px;
      align-items: center;
      padding: 8px 16px;
      font-family: Rubik;
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;
      svg {
        height: 20px;
        width: 20px;
      }
      &:hover {
        opacity: 0.7;
      }
      &.select-all {
        border-bottom: 1px solid var(--text-third-color);
      }
    }
  }
}
