@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;900&display=swap');

.landing-container {
  color: var(--text-primary-color);
  font-family: 'Rubik', sans-serif;
  .landing-section-container {
    // max-width: 1080px;
    max-width: 1400px;
    margin: 0 auto;
  }
  .landing-btns-container {
    display: flex;
    > a {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 12px 24px;
      background-color: var(--trend-up-color);
      color: var(--text-primary-color);
      border-radius: 24px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.15px;
      cursor: pointer;
      text-decoration: none;
      &:active {
        transform: scale(0.97);
      }
    }
  }
}

.landing-container ::-webkit-scrollbar {
  display: none;
}

.landing-container .landing-section-container {
  width: 1440px;
  margin: 0 auto;
}
.landing-header-mobile-wrapper{
  background: #1D222F;
  height: 56px

}

@media screen and (max-width: 1460px){
  .landing-container .landing-section-container{
    width: 991px
  }
}

@media screen and (max-width: 991px) {
  .landing-container .landing-section-container{
    width: 768px;
  }
}

@media screen and (max-width: 767px) {
  .landing-container{
    overflow-x: hidden;
    position: relative;
    .landing-section-container{
      width: 100%;
    }
  }
}
