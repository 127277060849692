.customer-screen {
  height: 100vh;
  display: grid;
  grid-template-columns: 95fr 248fr;
  grid-template-rows: min-content;
  overflow: hidden;
  > .customer-screen-banners {
    grid-column: span 2;
    position: sticky;
    top: 0;
    z-index: 1;
  }
  > div:last-child {
    display: grid;
    grid-template-rows: min-content 1fr;
    overflow: auto;
  }
  ::-webkit-scrollbar {
    width: 12px;
  }
  ::-webkit-scrollbar-thumb {
    border: 4px solid transparent;
    background-color: var(--border-primary-color);
    // background-color: red;
    border-top: none;
    border-bottom: none;
    border-radius: 5px;
    background-clip: padding-box;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: var(--text-secondary-color);
  }
  > .notify-modals-wrapper {
    position: fixed;
    bottom: 26px;
    left: 26px;
    z-index: 110;
    > .notify-modals-container {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }
}
