.policies-search-input-container {
  flex: 1;
  color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.policies-search-input-container>div {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}

.policies-search-input-container>div:first-child {
  top: 50%;
  inset-inline-end: 18px;
  transform: translateY(-50%);
}

.policies-search-input-container>div:last-child {
  inset-inline-start: 18px;
}

.policies-search-input-container>input {
  background-color: var(--card-background-secondary-color);
  color: var(--text-third-color);
  font-family: Rubik;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 28px;
  text-align: start;
  border: 1px solid transparent;
  outline: none;
  display: flex;
  height: 52px;
  width: 100%;
  border-radius: 8px;
  padding-inline: 50px 48px;
}

.policies-search-input-container>input:focus,
.policies-search-input-container>input:not(:placeholder-shown) {
  background-color: var(--card-background-selected);
  color: var(--text-primary-color);
  text-align: start;
  border-radius: 8px;
  /* width: 100%; */
}