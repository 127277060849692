.agencies-groups-banner {
  border-bottom: var(--border-primary);
  padding: 24px;
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  .agencies-groups-banner-item {
    position: relative;
    display: flex;
    align-items: center;
    margin-inline-end: 23px;
    .agencies-groups-banner-avatar-container {
      height: 32px;
      width: 32px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      .agencies-groups-banner-avatar {
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.4px;
        height: 24px;
        width: 24px;
      }
      .agencies-groups-banner-avatar-sm {
        height: 20px;
        width: 20px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        font-size: 10px;
        line-height: 15.6px;
        letter-spacing: 0.4px;
        &:first-child {
          top: 0;
          inset-inline-end: 0;
          z-index: 1;
        }
        &:last-child {
          position: absolute;
          bottom: 0;
          inset-inline-start: 0;
        }
      }
      &:has(.agencies-groups-banner-avatar-sm) {
        margin-inline-end: 4px;
      }
    }
    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.17px;
      white-space: nowrap;
    }
  }
}
