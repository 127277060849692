.modal-import-wrapper {
  padding: 24px;
  .modal-import-title {
    color: var(--text-primary-color);
    font-family: Rubik;
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 20px;
    margin-bottom: 24px;
  }
  .modal-import-content {
    position: relative;
    .modal-import-content-heading {
      color: var(--text-secondary-color);
      font-family: Rubik;
      font-size: 12px;
      letter-spacing: 0.4px;
      line-height: 16px;
      margin-bottom: 14px;
    }
    .modal-import-content-file-wrapper {
      min-height: 160px;
      width: 400px;
      margin-bottom: 12px;
      .modal-import-content-file {
        position: relative;
        width: 400px;
        display: flex;
        align-items: center;
        border-radius: 8px;
        background-color: var(--border-primary-color);
        margin-bottom: 12px;
        box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12),
          0 11px 15px -7px rgba(0, 0, 0, 0.2);
        .modal-import-content-file-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 52px;
          width: 52px;
          border-radius: 12px;
          background-color: var(--background-primary-color);
          margin: 8px 6px;
        }
        .modal-import-content-file-name {
          margin-left: 8px;
          margin-right: 8px;
          color: var(--text-primary-color);
          font-family: Rubik;
          font-size: 16px;
          letter-spacing: 0.5px;
          line-height: 20px;
        }
        .modal-import-content-file-close {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 14px;
          margin-inline-start: auto;
        }
      }
    }

    .modal-import-content-drop-zone {
      position: relative;
      .dropzone {
        border: 1px dashed var(--border-primary-color);
        border-radius: 12px;
        background-color: var(--background-primary-color);
        width: 400px;
        height: 160px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 12px;
        p {
          margin: 0;
          color: var(--text-secondary-color);
          font-family: Rubik;
          font-size: 12px;
          letter-spacing: 0.4px;
          line-height: 16px;
          white-space: pre-line;
          text-align: center;
        }
        .import-button {
          cursor: pointer;
          color: var(--trend-up-color);
          font-family: Rubik;
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 1.25px;
          line-height: 16px;
          border: 2px solid transparent;
          border-radius: 4px;
          height: 36px;
          min-width: 86px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 16px;
        }
        .import-button:hover,
        .import-button:active {
          border: 2px solid var(--trend-up-color);
        }
        .import-button:active {
          transform: scale(0.95);
        }
      }
      .errors {
        position: absolute;
        bottom: 0;
        color: red;
        margin: 6px;
        font-family: Rubik;
        font-size: 12px;
        letter-spacing: 0.4px;
        line-height: 16px;
      }
    }
    .modal-import-content-template {
      display: flex;
      gap: 10px;
      margin-bottom: 24px;
      div {
        cursor: pointer;
      }
      .template-ico {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .template-title {
        color: var(--text-primary-color);
        font-family: Rubik;
        font-size: 14px;
        letter-spacing: 0.25px;
        line-height: 20px;
      }
    }
    .modal-import-content-loader-overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      border-radius: 8px;
      backdrop-filter: blur(2px);
    }
  }
  .modal-import-buttons {
    display: flex;
    justify-content: flex-end;
    button.button:disabled {
      //opacity: 0.7;
      color: var(--trend-up-color);

      background-color: rgba(var(--trend-up-color-rgb), 0.17);
      border: 1px solid transparent;
      cursor: none;
      pointer-events: none;
    }
  }
}
