.step-payment {
  grid-column: span 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
  padding-block: 100px;
  .step-payment-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 28px;
    button.button-rounded.large {
      height: 56px;
      border-radius: 28px;
      font-size: 20px;
      line-height: 30px;
    }
  }
}
