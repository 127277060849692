.profile-connected-agency-settings-modal {
  display: flex;
  flex-direction: column;
  height: 600px;
  width: 700px;
  > header {
    display: flex;
    padding: 16px 24px;
    justify-content: space-between;
    padding-inline-end: 32px;
    height: 80px;
    align-items: center;
  }
  > div {
    flex: 1;
    overflow: auto;
    margin-inline: 24px;
    margin-bottom: 24px;
    > section {
      > div {
        padding-inline: 12px;
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: rgb(var(--text-primary-color-rgb), 0.09);
        border-radius: 8px;
        &.modal-section-heading {
          background-color: transparent;
          p {
            color: var(--text-secondary-color);
          }
        }
        &.selected {
          background-color: rgb(var(--trend-up-color-rgb), 0.12);
        }
        &:not(:last-child) {
          margin-bottom: 8px;
        }
        > div {
          display: flex;
          gap: 8px;
          p {
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.17px;
          }
        }
      }
      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
    button {
      height: 42px;
      gap: 8px;
      > span {
        color: var(--text-secondary-color);
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.17px;
      }
    }
  }
  > footer {
    padding: 12px 24px;
    border-top: var(--border-primary);
    display: flex;
    justify-content: flex-end;
    gap: 8px;
  }
}
