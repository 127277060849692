.agency-house-download-summary-item {
  &:not(:last-child) {
    margin-bottom: 24px;
  }
  > header {
    background-color: rgba(var(--text-primary-color-rgb), 0.17);
    height: 54px;
    padding-inline: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    &:not(:only-child) {
      margin-bottom: 12px;
    }
    > div {
      display: flex;
      align-items: center;
      //gap: 16px;
      div:first-child {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        border-radius: 8px;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.15px;
        margin-inline-end: 16px;
      }
      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.17px;
        margin-inline-end: 4px;
        &:last-child {
          color: var(--text-secondary-color);
        }
      }
    }
    > button {
      height: 48px;
      width: 48px;
    }
  }
  main {
    > div {
      background-color: var(--border-primary-color);
      height: 54px;
      padding-inline: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 8px;
      &.success {
        background-color: rgba(var(--trend-up-color-rgb), 0.12);
      }
      &.fail {
        background-color: rgba(var(--trend-down-color-rgb), 0.12);
      }
      &:not(:last-child) {
        margin-bottom: 12px;
      }
      > div {
        display: flex;
        align-items: center;
      }
      // company info
      > div:first-child {
        img {
          margin-inline-end: 6px;
        }
        p:first-of-type {
          font-size: 14px;
          font-weight: 500;
          line-height: 22px;
          letter-spacing: 0.1px;
          margin-inline-end: 10px;
        }
        p + p {
          color: var(--text-secondary-color);
          font-size: 12px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0.4px;
        }
      }
      > div:last-child {
        gap: 12px;
        > p {
          color: var(--text-secondary-color);
          font-size: 12px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0.4px;
        }
        > svg {
          font-size: 28px;
          fill: var(--trend-up-color);
        }
      }
    }
  }
}
