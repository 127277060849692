.tasks-list-item {
  background-color: var(--background-secondary-color);
  border-radius: 8px;
  height: 48px;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  &:not(:last-child) {
    margin-bottom: 8px;
  }
  > .task-item-checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .task-item-text-container {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-inline-end: 2px;
  }
  > .task-item-status {
    position: relative;
    > .task-item-status-chip {
      cursor: pointer;
      display: flex;
      gap: 9px;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: 28px;
      width: 70px;
      border-radius: 4px;
      background-color: var(--text-third-color);
      border: unset;
      color: var(--text-primary-color);
      font-family: 'Rubik';
      font-size: 12px;
      letter-spacing: 0.4px;
      line-height: 16px;
      &.treated {
        background-color: rgba(var(--trend-up-color-rgb), 0.38);
        color: var(--trend-up-color);
      }
      &:active {
        transform: scaleX(0.98);
      }
      > .task-item-status-chip-arrow-ico {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &.opened {
        > .task-item-status-chip-arrow-ico {
          > img {
            transform: rotate(180deg);
          }
        }
      }
    }
    > .task-item-status-menu-wrapper {
      position: absolute;
      top: calc(100% + 4px);
      inset-inline-start: 50%;
      transform: translateX(50%);
      z-index: 1;
    }
    > .task-item-status-loader-overlay {
      position: absolute;
      width: 80px;
      height: 28px;
      display: flex;
      border-radius: 4px;
      backdrop-filter: blur(2px);
    }
  }
  > .task-item-agent-container {
    > .task-item-agent {
      display: grid;
      grid-template-columns: minmax(40px, min-content) min-content;
      gap: 8px;
      position: relative;
      &.editable {
        cursor: pointer;
      }
      &.opened {
        > .task-item-agent-arrow-ico {
          > img {
            transform: rotate(180deg);
          }
        }
      }
      > .task-item-agent-arrow-ico {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      > .task-item-agent-menu-wrapper {
        position: absolute;
        top: 100%;
        z-index: 1;
      }
    }
  }
  > .task-item-extras {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 6px;
    > .task-item-details-arrow-ico {
      display: none;
    }
  }
  &.clickable {
    cursor: pointer;
  }
}

@media screen and (max-width: 767px) {
  .tasks-list-item {
    > .task-item-extras {
      gap: 8px;
      > .task-item-details-arrow-ico {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
      }
    }
  }
}
