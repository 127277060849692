.landing-preferences {
  padding-bottom: 100px;
  p,
  h5 {
    margin: 0;
    padding: 0;
    font-weight: 400;
    white-space: pre-line;
  }
  .landing-preferences-showcase {
    padding-top: 100px;
    padding-bottom: 100px;
    .landing-preferences-desc {
      max-width: 520px;
      > .landing-preferences-tagline {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.25px;
        color: var(--trend-up-color);
        margin-bottom: 12px;
      }
      > .landing-preferences-title {
        font-size: 48px;
        line-height: 57px;
        margin-bottom: 15px;
      }
      > .landing-preferences-subtitle {
        font-size: 24px;
        line-height: 32px;
      }
    }
  }
  .landing-preferences-cards {
    display: flex;
    > .landing-preferences-card {
      flex: 1;
      background: var(--background-secondary-color);
      box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.24);
      border-radius: 20px;
      padding: 24px 30px;
      &:not(:last-child) {
        margin-inline-end: 30px;
      }
      > .landing-preferences-card-icon {
        margin-bottom: 22px;
      }
      > .landing-preferences-card-title {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.25px;
        margin-bottom: 12px;
        white-space: pre-line;
      }
      > .landing-preferences-card-text {
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 0.5625px;
        color: var(--text-secondary-color);
      }
    }
  }
}
.landing-preferences-container{
  background-size: 0 !important;
}
@media screen and (max-width: 1460px) {
  .landing-preferences .landing-preferences-showcase{
    background-size: 51% !important;
  }
}
@media screen and (max-width: 1200px) {
  .landing-preferences .landing-preferences-showcase {
    background-size: 50%;
    .landing-preferences-desc {
      & > .landing-preferences-tagline{
        font-size: 18px;
      }
      & > .landing-preferences-title{
        font-size: 41px;
      }
      & > .landing-preferences-subtitle {
        font-size: 21px;
      }
    }
  }
}
@media screen and (max-width: 992px) {
  .landing-preferences .landing-preferences-showcase {
    background-size: 50%;
    .landing-preferences-desc {
      max-width: 490px;
      & > .landing-preferences-tagline{
        font-size: 16px;
      }
      & > .landing-preferences-title{
        font-size: 39px;
      }
      & > .landing-preferences-subtitle {
        font-size: 19px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .landing-preferences{
    padding-bottom: 13px;
    .landing-preferences-cards{
      flex-direction: column;
      & > .landing-preferences-card > .landing-preferences-card-icon{
        display: flex;
        justify-content: center;
        margin-bottom: 13px;
      }
      .landing-preferences-card{
        width: 100%;
        max-width: 328px;
        margin: 10px auto !important;
        .landing-preferences-card-title{
          text-align: center;
          font-size: 16px;
          line-height: 19px;
        }
        .landing-preferences-card-text{
          text-align: center;
          font-size: 14px;
        }
      }
    }
    .landing-preferences-showcase {
      background-image: none !important;
      padding: 51px 1px;
      .landing-preferences-desc {
        margin: 0 auto;
        & > .landing-preferences-tagline{
          font-size: 14px;
          line-height: 16px;
          text-align: center;
        }
        & > .landing-preferences-title{
          font-size: 30px;
          line-height: 34px;
          text-align: center;
        }
        & > .landing-preferences-subtitle {
          font-size: 16px;
          line-height: 19px;
          text-align: center;
        }
      }
    }
    div.landing-preferences-container{
      padding: 1px 16px;
      padding-bottom: 269px;
      background-position: bottom center !important;
      background-size: auto 23% !important;
    }
  }
}