.agency-house-analytics-datepicker-container {
  position: relative;
  font-family: 'Rubik';
  .agency-house-analytics-datepicker-btn-container {
    .agency-house-analytics-datepicker-btn {
      border: 1px solid rgba(var(--text-primary-color-rgb), 0.6);
      border-radius: 18px;
      height: 36px;
      padding: 6px 16px;
      padding-inline-end: 8px;
      font-size: 14px;
      font-weight: 500;
      line-height: 24px; /* 171.429% */
      letter-spacing: 0.4px;
      text-transform: uppercase;
      align-items: center;
      display: flex;
      span {
        white-space: nowrap;
      }
      &:not(:disabled) {
        cursor: pointer;
      }
      &.open {
      }
      &.has-applied {
        border-color: transparent;
        background-color: rgba(var(--text-primary-color-rgb), 0.16);
        gap: 4px;
      }
      button {
        svg {
          height: 20px;
          width: 20px;
        }
      }
    }
  }
  .agency-house-analytics-datepicker-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    height: 100vh;
    width: 100vw;
    .agency-house-analytics-datepicker-main-container {
      z-index: 1;
      position: fixed;
      background: var(--border-primary-color);
      box-shadow: 0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
        0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      display: flex;
      .agency-house-analytics-datepicker-calendars-container {
        > main {
          padding-block: 12px;
          position: relative;
          min-height: 228px;
          display: flex;
          > button {
            position: absolute;
            inset-inline-end: 6px;
            top: 6px;
          }
          .agency-house-analytics-datepicker-controls {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
            h3 {
              font-weight: 500;
              font-size: 16px;
              line-height: 28px;
              letter-spacing: 0.15px;
              min-width: 45px;
            }
            button {
              height: 24px;
              width: 24px;
              transition: unset;
            }
          }
          .agency-house-analytics-datepicker {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            padding: 12px 24px;
            width: 402px;
            .agency-house-analytics-datepicker-month {
              background: rgba(39, 45, 62, 0.6);
              border-radius: 50px;
              display: flex;
              align-items: center;
              justify-content: center;
              padding-block: 6px;
              font-family: 'Rubik';
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              letter-spacing: 0.17px;
              min-width: 110px;
              cursor: pointer;
              &:not(:nth-child(3n)) {
                margin-inline-end: 8px;
              }
              &:not(:nth-last-child(-n + 3)) {
                margin-bottom: 8px;
              }
              &.nodata {
                color: var(--text-third-color);
                background: #141720;
              }
              &.selected {
                background-color: var(--trend-up-color);
                &:has(+ .selected) {
                  margin-inline-end: 0;
                  border-start-end-radius: unset;
                  border-end-end-radius: unset;
                }
                + .selected:not(:last-child) {
                  background-color: rgba(var(--trend-up-color-rgb), 0.38);
                  margin-inline-end: 0;
                  border-radius: unset;
                }
                + .selected:has(+ :not(.selected)),
                + .selected:last-child {
                  background-color: var(--trend-up-color);
                  border-start-start-radius: unset;
                  border-end-start-radius: unset;
                  border-start-end-radius: 50px;
                  border-end-end-radius: 50px;
                }
                + .selected:has(+ :not(.selected)).selected:not(:nth-child(3n)) {
                  margin-inline-end: 8px;
                }
                &.nodata {
                  background: var(--text-third-color) !important;
                }
              }
            }
          }
        }
        > footer {
          display: flex;
          padding: 12px 24px;
          justify-content: flex-end;
          button {
            height: 36px;
            border-radius: 18px;
            padding: 6px 16px;
            background-color: var(--trend-up-color);
            border: none;
            font-family: 'Rubik';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.4px;
            text-transform: uppercase;
            color: var(--tet-primary-color);
          }
        }
      }
      .agency-house-analytics-datepicker-period-container {
        display: flex;
        flex-direction: column;
        padding: 16px;
        min-width: 124px;
        border-inline-end: 1px solid rgba(var(--text-primary-color-rgb), 0.17);
        button {
          white-space: nowrap;
          justify-content: flex-start;
          text-align: start;
          height: 36px;
          border-radius: 8px;
          padding-inline: 8px;
          font-size: 14px;
          font-weight: 500;
          line-height: 24px; /* 171.429% */
          letter-spacing: 0.4px;
          text-transform: uppercase;
          &.selected {
            cursor: default;
            background-color: rgba(var(--text-primary-color-rgb), 0.08);
            color: var(--trend-up-color);
          }
        }
      }
      .agency-house-analytics-datepicker-nodata {
        width: 402px;
        height: 288px;
        display: flex;
      }
    }
  }
}

[dir='ltr'] .agency-house-analytics-datepicker-controls {
  button {
    img {
      transform: rotate(180deg);
    }
  }
}
