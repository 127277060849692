.customers-filterbar {
  padding: 15px 1.5rem;
  border-bottom: var(--border-primary);
  display: flex;
  grid-gap: 24px;
  gap: 24px;
  justify-content: space-between;
  align-items: center;
  /* position: sticky;
  top: 0; */
  background-color: var(--card-background-secondary-color);
  /* z-index: 10; */
}

.customers-filterbar>.customers-filterbar-filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.customers-filterbar>.customers-filterbar-filters .customers-clear-filters {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  padding-right: 16px;
  padding-left: 16px;
  border-radius: 52px;
  background-color: var(--border-primary-color);
  color: var(--text-primary-color);
  font-family: Rubik;
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 20px;
  text-align: right;
  cursor: pointer;
  border: 1px solid transparent;
}

.customers-filterbar>.customers-filterbar-filters .customers-clear-filters:not(:disabled) {
  border: 1px solid var(--text-secondary-color);
}

.customers-filterbar>.customers-filterbar-filters .customers-clear-filters:active:not(:disabled) {
  transform: scale(.95);
}

.customers-filterbar>.customers-filterbar-filters .customers-clear-filters:disabled {
  cursor: default;
  background-color: var(--background-secondary-color);
  color: var(--text-secondary-color);
}

.customers-filterbar>.customers-filterbar-filters>.customers-filterbar-import {
  position: relative;
}

.customers-filterbar>.customers-filterbar-filters>.customers-filterbar-import>.three-dots-container {
  background-color: var(--background-secondary-color);
  cursor: pointer;
  height: 36px;
  width: 36px;
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
}

.customers-filterbar>.customers-filterbar-filters>.customers-filterbar-import>.three-dots-container.open {
  background-color: var(--border-primary-color);
}

.customers-filterbar>.customers-filterbar-filters>.customers-filterbar-import>.three-dots-container:not(.open):hover,
.customers-filterbar>.customers-filterbar-filters>.customers-filterbar-import>.three-dots-container:not(.open):active {
  background-color: rgba(var(--trend-up-color-rgb), 0.17);
}

.customers-filterbar>.customers-filterbar-filters>.customers-filterbar-import>.three-dots-container:not(.open):active {
  transform: scale(0.9);
}

.customers-filterbar>.customers-filterbar-filters>.customers-filterbar-import>.context-menu-container {
  inset-inline-start: unset;
  top: 100%;
}

.customers-filterbar>.customers-filterbar-filters>.customers-filterbar-import>.context-menu-container>.context-menu {
  background-color: var(--background-primary-color);
  border-radius: 4px;
  box-shadow: 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%), 0 5px 5px -3px rgb(0 0 0 / 20%);
  margin-top: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  z-index: 100;
}

.customers-filterbar>.customers-filterbar-filters>.customers-filterbar-import>.context-menu-container>.context-menu>.context-menu-item {
  color: var(--text-primary-color);
  font-family: Rubik;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 20px;
  text-align: right;
  padding: 16px;
  padding-top: 12px;
  display: flex;
  justify-content: flex-end;
}

.customers-filterbar>.customers-filterbar-filters>.customers-filterbar-import>.context-menu-container>.context-menu>.context-menu-item:hover {
  background-color: var(--background-secondary-color);
  opacity: 1;
}

.customers-filterbar>.customers-filterbar-filters>.export-irreg-btn {
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: center;
  height: 36px;
  padding-right: 16px;
  padding-left: 16px;
  border-radius: 52px;
  background-color: var(--background-secondary-color);
  color: var(--text-secondary-color);
  font-family: Rubik;
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 20px;
  text-align: right;
  cursor: pointer;
}

.customers-filterbar>.customers-filterbar-filters>.export-irreg-btn:active {
  transform: scale(0.9);
}

.customers-filterbar>.customers-filterbar-filters>.export-irreg-btn>.export-irreg-btn-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.customers-filterbar>.customers-filterbar-filters>.export-irreg-btn>.export-irreg-btn-title {
  display: flex;
  align-items: center;
  justify-content: center;
}

.customers-filterbar>.customers-filterbar-search {
  flex: 1;
}

.dot {
  border-radius: 1.5px;
  height: 3px;
  width: 3px;
}

.dot.green {
  background-color: var(--trend-up-color);
}

.customers-filterbar .customers-filterbar-filters .button-rounded:has(>svg) {
  gap: 8px;
}