.profile-next-payment-details-container {
  border: 1px solid rgba(var(--text-primary-color-rgb), 0.12);
  border-radius: 4px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  > p {
    color: rgba(var(--text-primary-color-rgb), 0.7);
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.17px;
  }
  > .profile-next-payment-details-item {
    background: rgba(var(--text-primary-color-rgb), 0.09);
    border-radius: 8px;
    &:last-child {
      background: rgba(var(--trend-up-color-rgb), 0.17);
      > div {
        grid-template-columns: 1fr 1fr;
        padding-inline-end: 24px;
      }
    }
    > div {
      height: 52px;
      align-items: center;
      padding-inline: 16px;
      display: grid;
      grid-template-columns: 1fr 1fr 36px;
      div:has(button) {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      p {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.17px;
      }
      > p:not(:first-child) {
        justify-self: end;
        font-size: 16px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0.15px;
      }
    }
    > div:not(:first-child) {
      padding-inline-start: 40px;
    }
  }
}
