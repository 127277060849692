.tasks-lists-container {
  > .tasks-list-container {
    > div:first-child {
      margin-bottom: 16px;
    }
    .tasks-list-grid {
      display: grid;
      grid-template-columns: 50px 2.5fr 3fr 3fr 5fr 82px 2fr 2fr 2fr 2fr 70px;
      align-items: center;
      > div {
        padding-inline-start: 16px;
        &:first-child,
        &:last-child,
        &:nth-child(2) {
          padding: 0;
        }
        &:nth-child(6) {
          padding-inline: 6px;
        }
        &:last-child {
          padding-inline-end: 16px;
        }
      }
    }
    .tasks-list-header {
      align-items: flex-end;
      margin-bottom: 8px;
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      color: var(--text-secondary-color);
      > .sortable {
        cursor: pointer;
        display: flex;
        grid-template-columns: auto auto;
        gap: 6px;
        align-items: flex-end;
      }
    }
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
}
.tasks-list-snackbar-wrapper {
  position: sticky;
  bottom: 0px;
  display: flex;
  justify-content: center;
  z-index: 1;
  margin-top: auto;
}

@media screen and (max-width: 767px) {
  .tasks-lists-container {
    > .tasks-list-container {
      .tasks-list-grid {
        grid-template-columns: 50px 1fr 70px;
      }
      .tasks-list-header {
        display: none;
      }
    }
  }
}
