button.button-text {
  background-color: transparent;
  color: var(--text-primary-color);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 14px;
  font-family: Rubik;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  padding-inline: 8px;
  height: 36px;
  cursor: pointer;
  > span {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: Rubik;
    font-weight: 500;
    letter-spacing: 0.4px;
    text-transform: uppercase;
  }
  &.danger {
    color: var(--trend-down-color);
  }
  &.success {
    color: var(--trend-up-color);
  }
  &:active:not(:disabled) {
    transform: scale(0.98);
  }
  &:disabled {
    opacity: 0.6;
    cursor: default;
  }
}
