.policies-actions-panel-container {
  position: relative;
  pointer-events: all;
  .policies-actions-panel {
    min-width: 410px;
    background-color: var(--background-secondary-color);
    box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12),
      0 11px 15px -7px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    border: 1px solid var(--text-primary-color);
    padding-inline-end: 48px;
    > .actions-close-icon {
      position: absolute;
      top: 2px;
      inset-inline-end: 1px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      background-color: var(--border-primary-color);
      border-start-end-radius: 8px;
      border-end-start-radius: 8px;
      cursor: pointer;
      &:hover {
        filter: brightness(150%) saturate(140%);
      }
      &:active > img {
        transform: scale(0.95);
      }
    }
    .actions-content {
      display: flex;
      justify-content: space-between;
      > .policies-count {
        background-color: var(--trend-up-color);
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 16px;
        padding-right: 16px;
        color: var(--text-primary-color);
        font-family: 'Montserrat';
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0.63px;
        line-height: 28px;
        min-width: 44px;
      }
      > .actions-container {
        display: flex;
        position: relative;
        > .policy-action {
          position: relative;
          cursor: pointer;
          min-width: 60px;
          margin: 12px 6px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          &.disabled {
            cursor: default;
            > .action-ico {
              > img {
                filter: opacity(0.6);
              }
            }
            > .action-text {
              color: var(--text-secondary-color);
            }
          }
          &:not(:last-child) {
            border-inline-end: 1px solid var(--text-third-color);
          }
          &:not(.disabled):not(.opened):hover {
            > .action-ico {
              > img {
                filter: invert(57%) sepia(58%) saturate(5102%) hue-rotate(115deg) brightness(95%) contrast(102%);
              }
            }
            > .action-text {
              color: var(--trend-up-color);
            }
          }
          > .action-text {
            color: var(--text-primary-color);
            font-family: Rubik;
            font-size: 12px;
            letter-spacing: 0.4px;
            line-height: 16px;
            text-align: center;
          }
          .action-ico {
            min-height: 24px;
          }
        }
        > .categories-modal-wrapper {
          .modal-selection-wrapper {
            position: absolute;
            top: -10px;
            transform: translateY(-100%);
            inset-inline-start: -82px;
          }
        }
      }
    }
  }
}
