.step-fields {
  > .form > form {
    > .input-checkboxes-container {
      display: flex;
      flex-direction: column;
      gap: 24px;
      width: 250px;
      min-height: 270px;
      margin: 0 auto;

      > .checkbox-container {
        input[type='checkbox'] {
          appearance: none;
          position: absolute;

          &:checked + label {
            border: 1px solid var(--trend-up-color);
            color: var(--trend-up-color);

            > .checkbox-icon {
              background-color: var(--icon-checked-background);
              color: var(--icon-active-background);
            }
          }

          &:focus-visible + label {
            outline: 1px solid var(--text-secondary-color);
            outline-offset: 1px;
          }
        }

        > label {
          display: flex;
          align-items: center;
          gap: 16px;
          color: var(--text-primary-color);
          font-family: Rubik;
          font-size: 16px;
          letter-spacing: 0.5px;
          line-height: 28px;
          text-align: center;
          background-color: var(--card-background-primary-color);
          border: 1px solid var(--card-background-primary-color);
          border-radius: 20px;
          padding: 12px;

          > .checkbox-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 48px;
            width: 48px;
            border-radius: 16px;
            background-color: var(--background-secondary-color);
            color: var(--text-primary-color);
          }
          > .checkbox-icon + div {
            flex: 1;
            text-align: start;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .step-fields {
    > .form > form {
      > .input-checkboxes-container {
        > .checkbox-container {
          input[type='checkbox'] {
            appearance: none;
            position: absolute;

            &:checked + label {
              border: 1px solid var(--trend-up-color);
              color: white;

              > .checkbox-icon {
                background-color: var(--icon-checked-background);
                color: var(--icon-active-background);
              }
            }

            &:focus-visible + label {
              outline: 1px solid var(--text-secondary-color);
              outline-offset: 1px;
            }
          }
          & > label {
            border-radius: 8px;
            & > .checkbox-icon {
              height: 30px;
              width: 30px;
              img {
                width: 12px;
              }
            }
          }
        }
      }
    }
  }
}
