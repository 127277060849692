.agency-house-lobby-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
  > div {
    h5 {
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
    }
    p {
      color: var(--text-secondary-color);
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.15px;
    }
  }
  > p {
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0.15px;
  }
}
