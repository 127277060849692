.customers-fields-policies {
  overflow: auto;
  > section {
    &:not(:last-child) {
      margin-bottom: 24px;
    }
    > p {
      font-size: 20px;
      font-weight: 500;
      line-height: 32px;
      letter-spacing: 0.15px;
      margin-bottom: 16px;
      > span {
        color: var(--text-secondary-color);
      }
    }
    > .customers-fields-policies-categories {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 16px;
      > .customers-fields-policies-category {
        display: flex;
        flex-direction: column;
        cursor: pointer;
        padding: 16px 24px;
        background-color: rgba(var(--border-primary-color-rgb), 0.6);
        border-radius: 8px;
        border-inline-start: 2px solid var(--text-primary-color);
        > div:first-child {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 24px;
          p {
            font-size: 20px;
            font-weight: 500;
            line-height: 32px;
            letter-spacing: 0.15px;
          }
          svg {
            font-size: 32px;
          }
        }
        > div:nth-child(2) {
          margin-bottom: 8px;
          p:first-child {
            color: var(--text-secondary-color);
            font-size: 12px;
            line-height: 20px;
            letter-spacing: 0.4px;
          }
          p:last-child {
            font-size: 30px;
            font-weight: 500;
            line-height: 40px;
            letter-spacing: 0px;
          }
        }
        > div:last-child {
          display: flex;
          margin-top: auto;
          > div {
            p {
              font-size: 14px;
              line-height: 20px;
              letter-spacing: 0.17px;
            }
            &:not(:last-child) {
              padding-inline-end: 25px;
              position: relative;
              &:after {
                content: '';
                position: absolute;
                top: 50%;
                inset-inline-end: 12px;
                width: 1px;
                height: calc(100% - 10px);
                background-color: var(--text-secondary-color);
                transform: translateY(-50%);
              }
            }
          }
        }
        &.potential {
          border-inline-start-color: var(--trend-up-color);
          > div:first-child {
            svg {
              color: var(--trend-up-color);
            }
          }
        }
      }
    }
  }
}
