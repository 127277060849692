.scan-sidebar {
  background-color: var(--background-secondary-color);
  width: 360px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  > header {
    padding: 16px 24px;
    display: flex;
    gap: 10px;
    border-bottom: 1px solid rgba(var(--text-primary-color-rgb), 0.12);
    p {
      flex: 1;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 160%; /* 32px */
      letter-spacing: 0.15px;
    }
  }
  .scan-sidebar-items {
    overflow: auto;
  }
  .scan-sidebar-item {
    display: flex;
    padding-inline: 24px 16px;
    height: 48px;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    .scan-sidebar-item-link {
      flex: 1;
      display: flex;
      position: relative;
      --scan-sidebar-item-link-flex-gap: 10px;
      a {
        flex: 1;
        display: flex;
        align-items: center;
        gap: var(--scan-sidebar-item-link-flex-gap);
        font-size: 16px;
        text-decoration: none;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
        letter-spacing: 0.15px;
        color: var(--text-primary-color);
        p {
          position: relative;
        }
      }
      .scan-sidebar-item-status {
        position: absolute;
        font-size: 14px;
        color: var(--trend-up-color);
        bottom: 0;
        inset-inline-start: calc(24px + var(--scan-sidebar-item-link-flex-gap));
        transform: translateY(calc(100% - 8px));
        &.bad {
          color: var(--trend-down-color);
        }
      }
    }
    .scan-sidebar-item-extras {
      display: flex;
      align-items: center;
      gap: 12px;
      .scan-sidebar-item-extras-loader {
      }
    }
  }
  .current-scan-link {
    background-color: rgba(var(--text-primary-color-rgb), 0.08);
  }
}
