.button-tab {
  background-color: rgba(var(--border-primary-color-rgb), 0.38);
  height: 44px;
  padding: 10px 20px;
  display: flex;
  gap: 10px;
  border-radius: 12px;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: var(--text-secondary-color);
  .button-tab-badge {
    height: 24px;
    padding-inline: 8px;
    display: flex;
    gap: 4px;
    border-radius: 8px;
    background-color: var(--border-primary-color);
    color: var(--text-primary-color);
  }
  &.selected {
    cursor: default;
    background-color: var(--trend-up-color);
    color: var(--text-primary-color);
    &:active {
      pointer-events: none;
    }
    .button-tab-badge {
      color: var(--trend-up-color);
      background-color: var(--text-primary-color);
    }
  }
}
