.add-policy-customer-modal-container {
  background-color: var(--background-secondary-color);
  box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12),
    0 11px 15px -7px rgba(0, 0, 0, 0.2);
  width: 560px;
  border-radius: 8px;
  position: relative;
  > .add-policy-customer-modal-close-icon {
    position: absolute;
    top: 0;
    inset-inline-end: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    background-color: var(--border-primary-color);
    border-start-end-radius: 8px;
    border-end-start-radius: 8px;
    cursor: pointer;
    &:hover {
      filter: brightness(150%) saturate(140%);
    }
  }
  > .add-policy-customer-modal-content {
    padding: 24px;
    > .add-policy-customer-modal-title {
      color: var(--text-primary-color);
      font-family: Rubik;
      font-size: 16px;
      letter-spacing: 0.5px;
      line-height: 20px;
      margin-bottom: 24px;
    }
    > .add-policy-customer-modal-inputs-group-container {
      > .add-policy-customer-modal-inputs-group-title {
        color: var(--text-secondary-color);
        font-family: Rubik;
        font-size: 12px;
        letter-spacing: 0.4px;
        line-height: 16px;
        margin-bottom: 12px;
      }
      > form {
        > .add-policy-customer-modal-inputs-group {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 30px 10px;
          margin-bottom: 44px;
        }
        > .add-policy-customer-modal-add-btn {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
  > .add-policy-customer-modal-content-loader-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    border-radius: 8px;
    backdrop-filter: blur(2px);
    z-index: 1;
  }
}
