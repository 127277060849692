.landing-downloads {
  padding-top: 106px;
  padding-bottom: 106px;
  p,
  h5 {
    margin: 0;
    padding: 0;
    font-weight: 400;
    white-space: pre-line;
  }
  .landing-downloads-showcase {
    > .landing-downloads-desc {
      max-width: 500px;
      margin-inline-start: auto;
      > .landing-downloads-tagline {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.25px;
        color: var(--trend-up-color);
        margin-bottom: 12px;
      }
      > .landing-downloads-title {
        font-size: 48px;
        line-height: 57px;
        margin-bottom: 15px;
      }
      > .landing-downloads-subtitle {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 38px;
      }
      > .landing-btns-container {
        margin-bottom: 50px;
      }
      > .landing-downloads-desc-title,
      > .landing-downloads-desc-text {
        font-size: 24px;
        line-height: 32px;
      }
      > .landing-downloads-desc-title {
        font-weight: 600;
      }
      > .landing-downloads-desc-text {
        margin-bottom: 16px;
      }
      > .landing-downloads-desc-remark {
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.4px;
      }
    }
  }
}

@media screen and (max-width: 1460px) {
  .landing-downloads{
    background-size: 41% !important;
    .landing-downloads-img-container img{
      height: 419px;
    }
    .landing-downloads-text-container{
      .landing-downloads-title{
        font-size: 36px;
      }
      .landing-downloads-subtitle{
        font-size: 19px;
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .landing-downloads{
    background-size: 46% !important;
    .landing-downloads-showcase > .landing-downloads-desc{
      max-width: 430px;
      & > .landing-downloads-desc-title,
      & > .landing-downloads-desc-text {
        font-size: 21px;
      }
      & > .landing-downloads-subtitle{
        font-size: 21px;
      }
    }
  }
}
@media screen and (max-width: 991px) {

}

@media screen and (max-width: 767px) {
  .landing-downloads {
    padding: 51px 1px 464px;
    background-position: bottom center !important;
    background-size: auto 42% !important;
    .landing-downloads-showcase > .landing-downloads-desc{
      margin: 0 auto;
      padding: 1px 16px;
      & > .landing-downloads-tagline{
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        margin-bottom: 13px;
      }
      & > .landing-downloads-title{
        font-size: 30px;
        line-height: 32px;
        margin-bottom: 23px;
        text-align: center;
      }
      & > .landing-downloads-subtitle{
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 23px;
        text-align: center;
      }
      & > .landing-btns-container{
        justify-content: center;
        margin-bottom: 31px;
      }
      & > .landing-downloads-desc-title{
        font-size: 16px;
        line-height: 19px;
        text-align: center;
      }
      & > .landing-downloads-desc-text{
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        margin-bottom: 1px;
      }
      & > .landing-downloads-desc-remark{
        font-size: 16px;
        line-height: 19px;
        text-align: center;
      }
    }
  }
}