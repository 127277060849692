.prices-calc-page-container {
  .prices-calc-page-container-calc-wrapper {
    padding-block: 40px;
  }
  .landing-contacts {
    .landing-section-container {
      max-width: 1080px;
      margin: auto;
    }
  }
}
