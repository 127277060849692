.profile-irreg-settings {
  > div:not(:last-child) {
    margin-bottom: 32px;
  }
  > div:not(:first-child) {
    border-top: var(--border-primary);
    margin-bottom: 32px;
    padding-top: 32px;
  }
  > div {
    > h5 {
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
      margin-bottom: 32px;
    }
    p {
      margin-bottom: 16px;
      font-size: 16px;
      font-weight: 500;
      line-height: 28px;
    }
  }
  .profile-irreg-settings-group {
    > h5 {
      font-size: 16px;
      font-weight: 500;
      line-height: 28px;
      margin-bottom: 24px;
    }
    > div {
      gap: 16px;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(440px, 1fr));
    }
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
  .profile-irreg-limits {
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    .profile-irreg-limit-container {
      width: 440px;
      .profile-irreg-limit-form-container {
        display: flex;
        gap: 16px;
        label {
          display: inline-block;
          font-size: 14px;
          font-weight: 400;
          margin-bottom: 8px;
          color: var(--text-secondary-color);
        }
        .profile-irreg-limit-input-wrapper {
          background-color: var(--border-primary-color);
          height: 36px;
          min-width: 140px;
          padding: 8px 14px;
          border-radius: 18px;
          display: flex;
          > span {
            color: var(--text-secondary-color);
          }
        }
      }
    }
  }
}
