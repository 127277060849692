.family-groups-toggle {
  background-color: var(--background-secondary-color);
  border: 1px solid rgba(var(--text-primary-color-rgb), 0.6);
  border-radius: 6px;
  padding: 4px;
  display: flex;
  gap: 4px;
  > button {
    height: 30px;
    width: 30px;
    border-radius: 2px;
    display: flex;
    opacity: 0.6;
    cursor: pointer;
    &.selected {
      background-color: var(--trend-up-color);
      opacity: 1;
      cursor: default;
    }
  }
}
