.customers-list-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  > .customers-list-heading,
  .customers-list-item,
  .customers-list-item-totals {
    display: grid;
    grid-template-columns: 48px 1.5fr 1fr 1fr 1fr 1fr 1fr 1fr 0.5fr 60px;
    gap: 4px;
    &.split-commission {
      grid-template-columns: 48px 1.5fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 0.5fr 60px;
    }
    div:has(.columns-picker-container) {
      justify-self: end;
    }
  }
  > .customers-list-heading {
    align-items: flex-end;
    padding-top: 1.5rem;
    padding-inline-start: 1.5rem;
    padding-inline-end: 1.5rem;
    padding-bottom: 5px;
    background-color: var(--card-background-secondary-color);
    > div {
      display: flex;
      align-items: center;
      color: var(--text-secondary-color);
      font-family: Rubik;
      font-size: 12px;
      letter-spacing: 0.4px;
      line-height: 16px;
      text-align: center;
      > span:has(+ i) {
        width: min-content;
      }
    }
    > .commission-rate {
      display: block;
      > .commission-split {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 4px;
        .sortable {
          justify-content: center;
        }
      }
    }
    .sortable {
      cursor: pointer;
      display: flex;
      align-items: center;
      i {
        margin-left: 4px;
        margin-right: 4px;
      }
    }
  }
  .customers-list-item-totals {
    margin-inline: 1.5rem;
    background-color: rgba(var(--border-primary-color-rgb), 0.6);
    height: 40px;
    border-radius: 8px;
    align-items: center;
    position: relative;
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.25px;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin-bottom: 8px;
    &.overall {
      height: 64px;
    }
    > div:first-child {
      padding-inline: 18px;
      display: flex;
      align-items: center;
      background-color: var(--background-secondary-color);
      position: absolute;
      top: 0;
      height: 100%;
      border-start-start-radius: 8px;
      border-end-start-radius: 8px;
    }
  }
  > .customers-list {
    overflow-x: hidden;
    overflow-y: auto;
    flex: 1;
    display: flex;
    flex-direction: column;
    .sublist-heading {
      display: flex;
      gap: 10px;
      justify-content: space-between;
      align-items: center;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      margin-bottom: 6px;
      &:not(:first-of-type) {
        margin-top: 24px;
      }
    }
    .sublist-heading-line {
      display: block;
      width: 100%;
      border: 0;
      border-top: var(--border-primary);
      margin: 1em 0;
      padding: 0;
    }
    .sublist-heading-title {
      white-space: nowrap;
      font-family: Rubik;
      line-height: 20px;
      text-align: right;
      display: flex;
      gap: 4px;
      > .items-count {
        color: var(--text-secondary-color);
        font-size: 12px;
        letter-spacing: 0.38px;
      }
      > .title-content {
        color: var(--text-primary-color);
        font-size: 16px;
        letter-spacing: 0.5px;
        vertical-align: text-bottom;
      }
    }
    .sublist-heading img,
    .sublist-heading-title {
      cursor: pointer;
    }
  }
  .customers-actions-panel-wrapper {
    position: sticky;
    display: flex;
    justify-content: center;
    bottom: 14px;
    margin-top: auto;
    padding-top: 19px;
    pointer-events: none;
    z-index: 100;
  }
  .customers-paginate-wrapper {
    border-top: var(--border-primary);
    border-bottom: var(--border-primary);
    background-color: var(--card-background-secondary-color);
    padding: 12px 1.5rem;
    // position: sticky;
    // bottom: 0;
    // z-index: 10;
    // margin-top: auto;
  }
}
