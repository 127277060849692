@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;900&display=swap');

.marketing-page-container {
  > .landing-contacts > .landing-section-container {
    max-width: 1080px;
    margin: auto;
  }
  &::-webkit-scrollbar {
    display: none;
  }
}

@media screen and (max-width: 1460px) {
  .marketing-page-container > .landing-contacts > .landing-section-container {
    width: 991px;
  }
}

@media screen and (max-width: 991px) {
  .marketing-page-container > .landing-contacts > .landing-section-container {
    width: 768px;
  }
}

@media screen and (max-width: 767px) {
  .marketing-page-container {
    overflow-x: hidden;
    position: relative;
    > .landing-contacts > .landing-section-container {
      width: 100%;
    }
  }
}
