.agency-house-download-history {
  > div {
    > header {
      display: flex;
      padding-inline: 16px;
      align-items: center;
      gap: 10px;
      > p {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.17px;
      }
      > span {
        flex: 1;
        height: 1px;
        background: var(--border-primary-color);
      }
      button {
        height: 48px;
        width: 48px;
      }
    }
  }
}
