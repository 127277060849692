.agency-house-analytics-incomes-no-data-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  color: var(--text-secondary-color);
  align-items: center;
  justify-content: center;
  p {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.4px;
    white-space: pre-wrap;
    text-align: center;
  }
  a {
    text-decoration: none;
    font-family: Rubik;
    color: var(--trend-up-color);
    font-size: 14px;
    font-weight: 500;
    line-height: 24px; /* 171.429% */
    letter-spacing: 0.4px;
    text-transform: uppercase;
    border: 1px solid var(--trend-up-color);
    padding: 6px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 19px;
  }
}
