.analytics-changes-value {
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.1px;
  direction: ltr;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: var(--text-secondary-color);
  &.positive {
    color: var(--trend-up-color);
  }
  &.negative {
    color: var(--trend-down-color);
  }
  > svg {
    height: 16px;
    width: 16px;
  }
  > .analytics-changes-value-sign {
    font-size: 14px;
    margin-inline-end: 2px;
  }
}

[dir='ltr'] .analytics-changes-value {
  justify-content: start;
}
