.modal-container-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 101;
  display: flex;
  align-items: center;
  justify-content: center;
  &.blured {
    -webkit-backdrop-filter: blur(4px) brightness(70%);
    backdrop-filter: blur(4px) brightness(70%);
  }
}
