.agency-house-analytics-signals {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;
  > header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    gap: 20px;
    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.15px;
      margin-inline-end: auto;
    }
  }
  .no-signals-data {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  section:first-of-type {
    margin-bottom: 40px;
    display: flex;
    min-height: 272px;
    gap: 24px;
    .agency-house-lobby-signal-card .agency-house-lobby-signal-card-body {
      gap: unset;
    }
    > div {
      flex: 0 1 31%;
    }
  }
  .signal-details {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
    > .signal-details-hierarchy-picker {
      margin-bottom: 24px;
      display: flex;
    }
    > .signal-details-list-container {
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow: auto;
      .signal-details-list-header {
        font-size: 14px;
        font-weight: 500;
        line-height: 24px; /* 171.429% */
        letter-spacing: 0.17px;
        margin-bottom: 12px;
        > div:not(:empty):first-child,
        > div:empty + div {
          padding-inline-start: 12px;
        }
      }
      .signal-details-list-item-grid {
        display: grid;
        grid-template-columns: 68px 1fr 1fr 1fr;
        align-items: center;
      }
      .signal-details-list {
        overflow: auto;
        flex: 1;
        .signal-details-list-item {
          border-radius: 8px;
          height: 52px;
          background-color: rgba(var(--text-primary-color-rgb), 0.08);
          font-weight: 400;
          line-height: 18px; /* 138.462% */
          margin-bottom: 12px;
          > .signal-details-item-details-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-inline: 12px;
          }
          .signal-details-item-name {
            display: flex;
            align-items: center;
            gap: 6px;
            padding-inline-start: 12px;
            .signal-details-item-logo {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 24px;
              width: 24px;
              border-radius: 4px;
              font-size: 12px;
            }
            .signal-details-subitem-logo {
              display: flex;
              align-items: center;
            }
            .signal-details-subitem-category-badge {
              height: 10px;
              width: 10px;
              border-radius: 2px;
            }
            p {
              font-size: 13px;
              font-weight: 400;
              line-height: 18px; /* 138.462% */
              letter-spacing: 0.16px;
            }
          }
        }
      }
      .signal-details-list-item-subgrid {
        display: grid;
        margin-inline-start: 68px;
        grid-template-columns: 1fr 1fr 1fr;
        align-items: center;
      }
    }
  }
}
