.agencies-groups-panel-overlay {
  position: fixed;
  top: 0;
  inset-inline-end: 72px;
  width: calc(100vw - 72px);
  -webkit-backdrop-filter: blur(4px) brightness(70%);
  backdrop-filter: blur(4px) brightness(70%);
  z-index: 101;
  .agencies-groups-panel {
    margin-inline-start: auto;
    height: 100vh;
    width: 350px;
    display: flex;
    flex-direction: column;
    background-color: var(--border-primary-color);
    box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12),
      0 11px 15px -7px rgba(0, 0, 0, 0.2);
    header {
      border-bottom: 1px solid rgba(var(--text-primary-color-rgb), 0.12);
      padding: 12px 16px;
      display: flex;
      align-items: center;
      h4 {
        flex: 1;
        font-size: 20px;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: 0.15px;
      }
      input {
        all: unset;
        flex: 1;
      }
      div {
        display: flex;
        button {
          margin: 12px;
        }
      }
    }
    main {
      padding: 24px 16px;
      flex: 1;
      overflow: auto;
      section {
        &:not(:last-child) {
          margin-bottom: 24px;
        }
        & > *:not(:last-child) {
          margin-bottom: 12px;
        }
        h5 {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0.17px;
        }
        .agencies-groups-panel-items-container {
          .agencies-groups-panel-item-container {
            display: flex;
            align-items: center;
            gap: 8px;
            &:not(:last-child) {
              margin-bottom: 12px;
            }
            .agencies-groups-panel-item {
              position: relative;
              background-color: rgba(var(--text-primary-color-rgb), 0.17);
              border-radius: 8px;
              padding: 4px;
              flex: 1;
              display: flex;
              align-items: center;
              gap: 16px;
              .agencies-groups-panel-avatar-container {
                height: 40px;
                width: 40px;
                position: relative;
                .agencies-groups-panel-avatar {
                  border-radius: 8px;
                  height: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-size: 20px;
                  line-height: 20px;
                  letter-spacing: 0.14px;
                }
                .agencies-groups-panel-avatar-sm {
                  height: 24px;
                  width: 24px;
                  border-radius: 4px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  position: absolute;
                  font-size: 12px;
                  line-height: 15.6px;
                  letter-spacing: 0.4px;
                  &:first-child {
                    top: 0;
                    inset-inline-end: 0;
                    z-index: 1;
                  }
                  &:last-child {
                    position: absolute;
                    bottom: 0;
                    inset-inline-start: 0;
                  }
                }
              }
              & > :last-child {
                margin-inline-start: auto;
              }
              p {
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.17px;
                max-width: 175px;
                overflow: hidden;
              }
              &.selected {
                background-color: rgba(var(--trend-up-color-rgb), 0.17);
              }
              .meatballs-menu {
                z-index: 2;
              }
              .agencies-groups-panel-item-menu-item {
                display: flex;
                align-items: center;
                gap: 8px;
              }
            }
            &.selected {
              button {
                color: var(--trend-up-color);
              }
            }
          }
        }
      }
    }
  }
}

.agencies-groups-panel-item-loader-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  border-radius: 8px;
  backdrop-filter: blur(4px);
  z-index: 2;
  inset-inline-start: 0;
}
