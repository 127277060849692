.extras {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  > .download-reports-link,
  > .user-profile-link,
  > .logout-link {
    position: relative;
  }
  > .download-reports-link:hover > .tooltip-container,
  > .user-profile-link:hover > .tooltip-container,
  > .logout-link:hover > .tooltip-container {
    visibility: visible;
  }
  > .download-reports-link:active > .tooltip-container,
  > .user-profile-link:active > .tooltip-container,
  > .logout-link:active > .tooltip-container {
    visibility: hidden;
  }
  > .download-reports-link {
    > a {
      > .icon-download {
        height: 40px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 12px;
        background-color: var(--trend-up-color);
        color: var(--text-primary-color);
      }
      &.active {
        > .icon-download {
          color: var(--text-secondary-color);
          background-color: var(--border-primary-color);
        }
      }
      &:not(.active) {
        > .icon-download:hover {
          color: var(--text-secondary-color);
          background-color: var(--border-primary-color);
        }
      }
    }
    > .download-reports-link-attention {
      position: absolute;
      height: 20px;
      width: 20px;
      top: 0;
      inset-inline-end: 0;
      transform: translate(-50%, -50%);
      border-radius: 10px;
      background-color: var(--trend-down-color);
      color: var(--text-primary-color);
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: Heebo;
      font-size: 12px;
      letter-spacing: 0.4px;
      line-height: 16px;
      text-align: center;
    }
  }
  > .user-profile-link {
    > a {
      text-decoration: none;
      color: var(--text-primary-color);
      > .user-avatar {
        height: 42px;
        min-width: 42px;
        border: 1px solid #d3d4d9;
        border-radius: 12px;
        padding: 2px;
        > .user-avatar-img {
          width: 100%;
          height: 100%;
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: Rubik;
          font-size: 16px;
          font-weight: bold;
          letter-spacing: 0.25px;
          line-height: 29px;
        }
      }
      &:active {
        > .user-avatar {
          border-color: var(--trend-up-color);
        }
      }
    }
  }
  > .logout-link {
    > .icon-signout {
      height: 32px;
      width: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        border-radius: 12px;
        background-color: var(--border-primary-color);
        color: var(--text-primary-color);
      }
    }
  }
}

[dir='ltr'] .extras .download-reports-link-attention {
  transform: translate(50%, -50%);
}

@media screen and (max-width: 1200px) {
  .extras {
    gap: 0.5rem;
  }
}
