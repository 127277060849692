@import '../../../../mixins.scss';

.scan-company-popup-sms-error {
  width: 560px;
  > header {
    padding: 16px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      color: #f44336;
      font-size: 20px;
      font-weight: 500;
      line-height: 32px;
      letter-spacing: 0.15px;
    }
  }
  > p {
    padding: 8px 24px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
  }
  .input-sms-form-container {
    margin: 0 auto;
    padding-inline: 24px;
    padding-bottom: 24px;
    form {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .input-sms-inputs-container {
      display: flex;
      gap: 8px;
      position: relative;
      margin-bottom: 40px;
      input {
        background-color: var(--background-secondary-color);
        background-color: rgba(var(--text-primary-color-rgb), 0.17);
        color: var(--text-primary-color);
        text-align: center;
        border-radius: 10px;
        border: 1px solid transparent;
        outline: none;
        height: 56px;
        width: 100%;
        font-family: Rubik;
        font-size: 16px;
        letter-spacing: 0.5px;
        line-height: 28px;
        padding: 0;
        margin: 0;
      }
      input:focus {
        box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12),
          0 11px 15px -7px rgba(0, 0, 0, 0.2);
      }
      input:focus,
      input:not(:placeholder-shown) {
        background-color: var(--border-primary-color);
        border: var(--border-primary);
      }
      .input-sms-form-error {
        position: absolute;
        top: 100%;
        color: #fb4659;
        font-family: Rubik;
        font-size: 12px;
        letter-spacing: 0.4px;
        line-height: 16px;
        text-align: center;
        left: 0;
        right: 0;
      }
    }
    .input-sms-inputs-container.error-code {
      input {
        background-color: rgba(var(--trend-down-color-rgb), 0.17);
        border: none;
      }
      input::placeholder {
        color: var(--text-primary-color);
      }
    }
    .input-sms-button-container {
      div {
        display: flex;
        button {
          flex: 1;
        }
      }
    }
  }
}
