.sidebar-agent-house-additional {
  width: 72px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-block: 16px;
  > div:first-child {
    padding-bottom: 8.5px;
    > * {
      height: 48px;
      width: 48px;
      border-radius: 24px;
      border: 2px solid var(--trend-up-color);
      text-decoration: none;
      color: var(--text-primary-color);
      padding: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      > * {
        background-color: #7c4dff;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        height: 100%;
        width: 100%;
        border-radius: 50%;
      }
    }
  }
}
