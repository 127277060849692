.register-step-phone-wrapper {
  flex: 1;
  > .register-step-phone {
    max-width: 1240px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    > .register-step-phone-content {
      display: flex;
      flex-direction: column;
      gap: 24px;
      > h4 {
        text-align: center;
        font-weight: 700;
        font-size: 34px;
        line-height: 123.5%;
        letter-spacing: 0.25px;
        color: var(--trend-up-color);
      }
      > .register-step-phone-text-container {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 50%;
        margin-inline-start: auto;
        > p,
        > h3 {
          margin: 0;
          padding: 0;
          font-family: 'Rubik';
          font-style: normal;
          font-weight: 400;
          font-size: 24px;
          line-height: 60px;
          letter-spacing: -0.283333px;
        }
        > .register-step-phone-text-pretitle {
          font-weight: 500;
          color: var(--trend-up-color);
          line-height: 30px;
          margin-bottom: 16px;
        }
        > .register-step-phone-text-title {
          font-weight: 800;
          font-size: 60px;
          margin-bottom: 14px;
        }
        > .register-step-phone-text-content {
          font-weight: 400;
          font-size: 20px;
          line-height: 24px;
        }
      }
      > .register-step-phone-form-container {
        width: 540px;
        margin-bottom: 100px;
        padding: 39px 36px 32px;
        background: rgba(49, 57, 79, 0.38);
        border-inline-end: 4px solid var(--trend-up-color);
        backdrop-filter: blur(30px);
        border-radius: 20px;
        display: flex;
        gap: 12px;
        position: relative;
        overflow: hidden;
        > div,
        > form {
          flex: 1;
        }
        > .register-step-phone-form-desc {
          display: flex;
          flex-direction: column;
          > h5,
          > h6,
          > p {
            margin: 0;
            padding: 0;
            font-family: 'Rubik';
            font-style: normal;
            font-size: 28px;
            font-weight: 400;
            max-width: 420px;
          }
          h5 {
            margin-top: auto;
            font-weight: 700;
            font-size: 28px;
            line-height: 14px;
            margin-bottom: 13px;
          }
          h6 {
            font-size: 27px;
            line-height: 27px;
            margin-bottom: 15px;
          }
          p {
            font-size: 16px;
            line-height: 20px;
          }
          > p.form-desc-remark {
            font-size: 12px;
          }
          > .register-step-phone-form-card-title {
            font-family: 'Rubik';
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 32px;
            letter-spacing: 0.15px;
            color: var(--trend-up-color);
            margin-bottom: 29px;
          }
        }
        > form.register-step-phone-form {
          display: block;
          align-self: center;
          .tabs-navbar ul {
            border-bottom: unset;
            margin-bottom: 32px;
            li {
              flex: 1;
              justify-content: center;
              &:not(.tabs-navbar-active-item) {
                border-bottom: 1px solid var(--border-primary-color);
                line-height: 25px;
              }
            }
          }
          > .register-step-phone-form-header {
            margin-bottom: 19px;
            margin-inline-start: 16px;
            > h5,
            > p {
              margin: 0;
              padding: 0;
              font-family: 'Rubik';
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
            }
            > h5 {
              font-size: 16px;
              line-height: 20px;
            }
            > p {
              font-size: 14px;
              line-height: 20px;
              color: var(--text-secondary-color);
            }
          }
          > .register-step-phone-form-inputs-container {
            background: rgba(49, 57, 79, 0.38);
            border: 1px solid var(--border-primary-color);
            border-radius: 39px;
            overflow: hidden;
            display: flex;
            margin-bottom: 14px;
            &.has-error {
              background: rgba(251, 70, 89, 0.38);
              border-color: #fb4659;
            }
            > .register-step-phone-form-inputs-group {
              flex: 1;
              display: flex;
              direction: ltr;
              align-items: center;
              justify-content: space-around;
              > input {
                padding: 0 28px;
                text-align: right;
                flex: 1;
                background: transparent;
                border: none;
                outline: none;
                caret-color: var(--trend-up-color);
                font-family: 'Rubik';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 28px;
                letter-spacing: 0.5px;
                color: var(--text-primary-color);
                &::placeholder {
                  color: var(--text-secondary-color);
                }
                &.input-otp {
                  padding: 0;
                  height: 100%;
                  text-align: center;
                  &:not(:last-child) {
                    border-radius: unset;
                    border-inline-end: var(--border-primary);
                  }
                }
                &:-webkit-autofill,
                &:-webkit-autofill:hover,
                &:-webkit-autofill:focus,
                &:-webkit-autofill:active {
                  -webkit-box-shadow: 0 0 0 30px #293242 inset !important;
                }
              }
            }
            > button {
              min-width: 140px;
              background-color: var(--trend-up-color);
              border: none;
              font-family: 'Rubik';
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              letter-spacing: 0.15px;
              padding: 8px 32px;
              border-radius: 24px;
              margin: 6px;
              color: rgba(255, 255, 255, 1);
              white-space: nowrap;
              cursor: pointer;
              &:disabled {
                opacity: 0.38;
              }
            }
          }
          > .register-step-phone-form-footer {
            margin-inline-start: 16px;
            > .register-step-phone-form-terms {
              display: flex;
              cursor: pointer;
              align-items: center;
              gap: 10px;
              > .policy-terms-icon {
                display: flex;
                cursor: pointer;
                align-items: center;
                justify-content: center;
                min-width: 18px;
              }
            }
            h4 {
              font-family: 'Rubik';
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 24px;
              letter-spacing: -0.283333px;
              margin: 0;
              > span {
                cursor: pointer;
                text-decoration: underline;
                color: var(--text-primary-color);
              }
            }
          }
        }
        > .register-step-phone-form-error {
          position: absolute;
          bottom: 0;
          inset-inline-end: 0;
          width: 100%;
          background: rgba(251, 70, 89, 0.38);
          padding: 0 16px;
          > span {
            font-family: 'Rubik';
            color: red;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 24px;
            letter-spacing: -0.283333px;
          }
        }
      }
    }
  }
}

[data-theme='light'] .register-step-phone-wrapper > .register-step-phone > .register-step-phone-form-container,
[data-theme='light']
  .register-step-phone-wrapper
  > .register-step-phone
  > .register-step-phone-content
  > .register-step-phone-form-container
  > form.register-step-phone-form
  > .register-step-phone-form-inputs-container {
  background: rgba(255, 255, 255, 0.38);
}

[data-theme='light']
  .register-step-phone-wrapper
  > .register-step-phone
  > .register-step-phone-content
  > .register-step-phone-form-container
  > form.register-step-phone-form
  > .register-step-phone-form-inputs-container {
  border-color: var(--trend-up-color);
}

@media screen and (max-width: 767px) {
  .register-step-phone-wrapper {
    > .register-step-phone {
      padding: 0 30px;
      > .register-step-phone-content {
        > .register-step-phone-text-container {
          align-items: center;
          width: unset;
          text-align: center;
          margin-top: 376px;
          margin-bottom: 52px;
          > p,
          > h3 {
          }
          > .register-step-phone-text-pretitle {
            font-size: 14px;
            margin-bottom: 4px;
          }
          > .register-step-phone-text-title {
            font-weight: 800;
            font-size: 32px;
            line-height: 32px;
            letter-spacing: -0.283333px;
            margin-bottom: 4px;
          }
          > .register-step-phone-text-content {
            font-size: 16px;
            line-height: 22px;
            letter-spacing: -0.283333px;
          }
        }
        > .register-step-phone-form-container {
          flex-direction: column;
          border: unset;
          border-top: 4px solid var(--trend-up-color);
          padding: 24px 16px;
          margin-bottom: 52px;
          display: block;
          > .register-step-phone-form-desc {
            text-align: center;
            margin-bottom: 40px;
            > h5 {
              font-weight: 700;
              font-size: 20px;
              line-height: 22.5px;
            }
            > h6 {
              font-size: 16px;
              line-height: 18px;
            }
            p {
              font-size: 14px;
              line-height: 20px;
            }
            > p.form-desc-remark {
              font-size: 12px;
            }
            > .register-step-phone-form-card-title {
              margin-bottom: 15px;
            }
          }
          > form.register-step-phone-form {
            text-align: center;
            > .register-step-phone-form-header {
              margin: 0;
              margin-bottom: 12px;
              > h5 {
                font-size: 14px;
                line-height: 20px;
              }
              > p {
                font-size: 12px;
                line-height: 20px;
                color: var(--text-secondary-color);
              }
            }
            > .register-step-phone-form-inputs-container {
              > .register-step-phone-form-inputs-group {
                > input {
                  padding: 10px 16px;
                  font-size: 14px;
                }
              }
              > button {
                min-width: unset;
                padding: 8px 14px;
                margin: 5px;
                font-weight: 500;
                font-size: 13px;
                line-height: 22px;
              }
            }
            > .register-step-phone-form-footer {
              margin: 0;
              > .register-step-phone-form-terms {
                font-size: 12px;
                gap: 2px;
                h4 {
                  margin: 0;
                  padding: 0;
                  letter-spacing: -0.4px;
                }
              }
            }
          }
          > .register-step-phone-form-error {
            width: 100%;
          }
        }
      }
    }
  }
}
