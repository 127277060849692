.sidebar-agent-house {
  width: 72px;
  height: 100vh;
  padding-block: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  > nav {
    width: 100%;
    > ul {
      display: flex;
      flex-direction: column;
      gap: 24px;
      > li {
        > a {
          padding-block: 6px;
          margin-inline-end: 9px;
          padding-inline-start: 9px;
          border-start-end-radius: 8px;
          border-end-end-radius: 8px;

          display: flex;
          flex-direction: column;
          gap: 2px;
          align-items: center;
          text-decoration: none;
          color: var(--text-secondary-color);

          span {
            text-align: center;
            font-family: Rubik;
            font-size: 12px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.4px;
          }
          &:is(:hover, .current-link) {
            background-color: rgba(var(--trend-up-color-rgb), 0.17);
            padding-inline-start: 5px;
            color: var(--trend-up-color);
            border-inline-start: 4px solid var(--trend-up-color);
          }
        }
      }
    }
  }
  .bottom-links {
    .download-link {
      width: 48px;
      height: 48px;
      border-radius: 8px;
      //background-color: navy;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--text-primary-color);
      &:is(:hover, .current-link) {
        background-color: rgba(var(--trend-up-color-rgb), 0.17);
        color: var(--text-primary-color);
      }
    }
  }
  li {
    position: relative;
    .navbar-hidden-subitems {
      display: none;
      z-index: 1;
      min-width: 300px;
      position: absolute;
      transform: translateX(calc(-100%));
      inset-inline-end: 50%;
      top: 50%;
      border-radius: 4px;
      background-color: var(--background-secondary-color);
      box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2);
    }
    &:hover {
      .navbar-submenu {
        display: unset;
      }
      .navbar-hidden-subitems {
        display: unset;
      }
    }
  }
}
