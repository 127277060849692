.scan-company-register-modal {
  padding: 40px 60px;
  width: 70vw;
  background-color: var(--border-primary-color);
  border-radius: 8px;
  header {
    margin-bottom: 24px;
    display: flex;
    align-items: baseline;
    gap: 8px;
    p,
    input {
      font-family: Rubik;
      padding: unset;
      font-size: 24px;
      font-weight: 500;
      line-height: 37.68px;
      letter-spacing: 0.1px;
      color: var(--text-primary-color);
      &.no-name {
        color: var(--text-secondary-color);
      }
      &::placeholder {
        color: var(--text-secondary-color);
        opacity: 1; /* Firefox */
      }
    }
    input {
      background: none;
      border: none;
      outline: none;
      height: unset;
      caret-color: var(--text-primary-color);
    }
    svg {
      fill: var(--text-secondary-color);
    }
  }
  div {
    .report-types-selector-container {
      margin-bottom: 24px;
      .report-types-selector {
        background-color: rgba(var(--text-primary-color-rgb), 0.09);
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: var(--text-primary-color);
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
        padding: 16px;
        font-size: 16px;
        letter-spacing: 0.5px;
        &:has(+ .report-types-list),
        &:has(.report-type-chip) {
          border-bottom: 2px solid var(--text-primary-color);
        }
        > div {
          display: flex;
          flex-wrap: wrap;
          gap: 12px;
          font-size: 16px;
          letter-spacing: 0.5px;
          .report-type-chip {
            display: flex;
            align-items: center;
            background-color: var(--trend-up-color);
            height: 28px;
            padding: 2px 12px;
            gap: 10px;
            border-radius: 14px;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0.15px;
            font-family: Rubik;
          }
        }
      }
      .report-types-list {
        div {
          height: 48px;
          display: flex;
          align-items: center;
          padding-inline: 12px;
          border-bottom: 1px solid var(--text-third-color);
          background-color: var(--background-secondary-color);
          button {
            display: flex;
            gap: 12px;
            align-items: center;
            font-family: Rubik;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.15px;
          }
        }
      }
    }
  }
  footer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
    button {
      min-width: 120px;
      color: var(--text-primary-color) !important;
    }
  }
}
