.profile-payments-history-container {
  > p:first-child {
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0.15px;
    margin-bottom: 20px;
  }
  .profile-payments-history-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr) 40px;
    padding-inline: 16px;
    margin-bottom: 20px;
  }
  .profile-payments-history-item-heading {
    color: rgba(var(--text-primary-color-rgb), 0.7);
    font-size: 14px;
    font-weight: 500;
    line-height: 24px; /* 171.429% */
    letter-spacing: 0.17px;
  }
  .profile-payments-history-item {
    height: 52px;
    background: rgba(var(--text-primary-color-rgb), 0.09);
    border-radius: 8px;
    margin-bottom: 8px;
    align-items: center;
    div:last-child {
      display: flex;
      justify-content: center;
    }
  }
  .button-text {
    color: var(--trend-up-color);
  }
}
