.add-task-modal-container {
  padding: 24px;
  padding-top: 16px;
  width: 600px;
  font-family: 'Rubik';
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.15px;
  > .add-task-modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent;
      border: none;
      height: 24px;
      width: 24px;
      cursor: pointer;
      &:active {
        transform: scale(0.98);
      }
      &:hover {
        opacity: 0.7;
      }
    }
  }
  > .add-task-modal-form-container {
    margin-top: 24px;
    .add-task-modal-form-inputs {
      > div {
        margin-bottom: 24px;
      }
      > .add-task-modal-form-inputs-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 24px;
      }
    }
    .add-tasks-serach-container {
      position: relative;
      .hidden-oopen-search-button {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        opacity: 0;
      }
    }
    .add-tasks-serach-results {
      position: absolute;
      top: calc(100% + 4px);
      background-color: blueviolet;
      width: 100%;
      z-index: 1;
      background: var(--background-secondary-color);
      box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
      border-radius: 4px;
      height: 256px;
      overflow: auto;
      ul {
        margin-block: 8px;
      }
      li {
        padding: 6px 16px;
        font-family: 'Rubik';
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        cursor: pointer;
        .add-tasks-serach-results-name {
          line-height: 24px;
          color: var(--text-secondary-color);
        }
        .add-tasks-serach-results-number {
          direction: ltr;
          line-height: 24px;
        }
        .add-tasks-serach-results-category {
          font-size: 14px;
          line-height: 24px;
          color: var(--text-secondary-color);
        }
        .add-tasks-serach-results-main {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        &:hover {
          background-color: rgba(var(--border-primary-color-rgb), 0.38);
        }
      }
    }
  }
  .add-task-modal-form-footer {
    display: flex;
    justify-content: flex-end;
  }
}

@media screen and (max-width: 767px) {
  .add-task-modal-container {
    flex: 1;
    background: red;
    background-color: var(--background-primary-color);
    padding: 16px;
    width: unset;
    display: flex;
    flex-direction: column;
    .add-task-modal-header {
      display: none;
    }
    > .add-task-modal-form-container {
      flex: 1;
      margin-top: 0;
      display: flex;
      flex-direction: column;
      form {
        flex: 1;
        display: flex;
        flex-direction: column;
        .add-task-modal-form-inputs > .add-task-modal-form-inputs-grid {
          display: flex;
          flex-direction: column;
          gap: unset;
          margin-bottom: 16px;
          > div:not(:last-child) {
            margin-bottom: 16px;
          }
        }
        .add-task-modal-form-footer {
          margin-top: auto;
          > div:has(.button) {
            flex: 1;
            display: flex;
            flex-direction: column;
            > button.button {
              border-radius: 18px;
              &:disabled {
                opacity: 0.4;
                color: unset;
                background-color: var(--trend-up-color);
              }
            }
          }
        }
      }
    }
  }
  .mobile-bottom-search-container {
    > .mobile-bottom-search-input-container {
      padding-inline: 16px;
      margin-bottom: 16px;
    }
    > .mobile-bottom-search-results-container {
      height: 204px;
      overflow: auto;
      .add-tasks-serach-results {
        ul {
        }
        li {
          padding: 8px 16px;
          font-family: 'Rubik';
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.17px;
          &:not(:last-child) {
            border-bottom: var(--border-primary);
          }
          .add-tasks-serach-results-name {
            font-size: 12px;
            line-height: 12px;
            letter-spacing: 0.15px;
            color: var(--text-secondary-color);
          }
          .add-tasks-serach-results-number {
          }
          .add-tasks-serach-results-category {
            color: var(--text-secondary-color);
          }
          .add-tasks-serach-results-main {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
          &:hover {
            background-color: rgba(var(--border-primary-color-rgb), 0.38);
          }
        }
      }
    }
  }
}
