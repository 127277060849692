.scan-company-error-report {
  margin-top: 6px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 16px;
  main {
    display: flex;
    flex-direction: column;
    gap: 8px;
    > p {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.17px;
    }
    > h6 {
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.4px;
    }
    .download-error-modal-screenshot-placeholder {
      flex: 1;
      min-width: 350px;
      min-height: 150px;
      background-color: rgba(var(--text-primary-color-rgb), 0.09);
      display: flex;
      flex-direction: column;
      gap: 12px;
      align-items: center;
      justify-content: center;
      img {
        max-width: 80%;
        max-height: 65vh;
      }
    }
  }
  footer {
    display: flex;
    flex-direction: column;
    gap: 14px;
    p,
    a,
    span {
      color: var(--text-primary-color);
      font-size: 12px;
      font-weight: 400;
      line-height: 12px;
      letter-spacing: 0.15px;
    }
    p {
      color: var(--text-secondary-color);
    }
    a,
    span {
      cursor: pointer;
      font-weight: 500;
      text-decoration: none;
    }
    div {
      display: flex;
      > a {
        display: flex;
        align-items: center;
        gap: 8px;
        border-radius: 100px;
        border: 1px solid var(--text-primary-color);
        padding: 4px 10px;
        font-size: 13px;
        font-weight: 500;
        line-height: 22px; /* 169.231% */
        letter-spacing: 0.46px;
        text-transform: uppercase;
      }
    }
  }
}
