.landing-banner {
  padding-top: 100px;
  padding-bottom: 100px;

  > .landing-section-container {
    background-size: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > .landing-btns-container {
      > a {
        height: 64px;
        border-radius: 100px;
      }
    }
  }

  p,
  h5 {
    text-align: center;
    margin: 0;
    padding: 0;
    font-weight: 400;
    white-space: pre-line;
  }

  .landing-banner-title {
    font-weight: 700;
    font-size: 36px;
    line-height: 36px;
    letter-spacing: 0.25px;
    margin-bottom: 24px;
  }
}

@media screen and (max-width: 991px) {
  .landing-banner {
    .landing-banner-title {
      font-size: 29px;
    }
  }
}

@media screen and (max-width: 767px) {
  .landing-banner {
    background-image: none !important;
    padding: 0;

    & > .landing-section-container {
      background-size: 100% 100%;
      padding-top: 0 !important;
      height: 337px;

      & > .landing-btns-container > a {
        height: 56px;
      }
    }
    .landing-banner-title {
      font-size: 24px;
      line-height: 30px;
      padding: 6px 16px 1px;
    }

    .landing-btns-container {
      margin-top: 13px;
    }
  }
}
