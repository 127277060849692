.agency-house-analytics-incomes-totals-card-wrapper {
  border-radius: 20px;
  padding: 24px;
  background-color: var(--border-primary-color);
  min-height: 158px;
  display: grid;
  .agency-house-analytics-incomes-totals-card {
    display: flex;
    flex-direction: column;
    gap: 24px;
    flex: 1;
    header {
      display: flex;
      gap: 18px;
      align-items: center;
      .agency-house-analytics-incomes-totals-card-icon {
        width: 44px;
        height: 44px;
        border-radius: 8px;
        background-color: rgba(var(--text-primary-color-rgb), 0.17);
        display: flex;
        align-items: center;
        justify-content: center;
        > * {
          font-size: 40px;
        }
      }
      p {
        font-size: 20px;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: 0.15px;
        svg {
          vertical-align: middle;
          font-size: 20px;
        }
      }
    }
    .agency-house-analytics-incomes-totals-card-content {
      margin-top: auto;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      > div:first-child > *:first-child {
        font-size: 34px;
        font-weight: 500;
        line-height: 123.5%;
        letter-spacing: 0.25px;
        span {
          font-size: 24px;
          font-weight: 400;
          line-height: 32px;
        }
      }
      .commission-vat {
        color: var(--text-secondary-color);
        font-size: 16px;
      }
    }
    &:not(:first-child) {
      border-top: 1px solid var(--text-third-color);
      padding-top: 12px;
    }
    &:not(:last-child) {
      padding-bottom: 12px;
    }
  }
}
