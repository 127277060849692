.loading-overlay {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 1;
  backdrop-filter: blur(4px) brightness(70%);
  top: 0;
  left: 0;
  > .loader-container {
    overflow: unset;
  }
  &.fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 10;
  }
  &:has(p) {
    display: flex;
    flex-direction: column;
    gap: 40px;
    > div {
      flex: unset;
    }
  }
}
